import React, { useEffect, useState } from 'react'
import { API_Link, MD5hash } from '../commons/Constants';
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import axios from 'axios';

const MaybeAbstractComponent = () => {

    const [arrayData, setArrayData] = useState([]);
    const [isActive, setIsActive] = useState(null);
    const [excluded, setExcluded] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const studyLocalId = JSON.parse(localStorage.getItem("studyId"));
    const token = user.token;

    // code for capitalize
    const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
    function shouldBeUppercase(word, index, length) {
        //always capitalize the first and last words
        if (index == 0 || index == length - 1)
            return true;

        //only capitalize other words if they don't match the small words pattern
        return !smallWords.test(word.toLowerCase());
    }

    function convertFirstLetterToUpperCase(str) {
        //split into words 
        const words = str.split(' ');

        //change the case and rejoin into a string
        return words
            .map((word, index, length) => shouldBeUppercase(word, index, length) ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())
            .join(' ');
    }
    const getReviewMaybe = () => {
        var config = {
            method: "get",
            url: `${API_Link}getReviewArticles`,

            params: {
                review_id: studyLocalId,
                included: "Yes",
            },
            headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, "misr-auth": MD5hash },
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                setExcluded(resData.data.excluded);

                setArrayData(resData.data.articles.filter((array) => array.current_stage === 2 && !resData.data.excluded.includes(array.id)));
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    console.log('arrayData', arrayData)

    useEffect(() => {
        // Call get Review Maybe Function
        getReviewMaybe();
    }, [])

    const handleClick = (index) => {
        setIsActive(!isActive);
        if (isActive === index) {
            return setIsActive(null);
        }
        setIsActive(index);
    };

    return (
        <div className='page--content'>
            <div className='row'>
                <div className='col-lg-6'>
                    <h1 className='page--heading'>Maybe Abstract </h1>
                </div>
                <div className='col-lg-6 text-right-flex'>
                    <p>
                        Total Maybe Articles Count <strong>{arrayData.length}</strong>
                    </p>
                </div>
            </div>
            <div className='row boxes-row'>
                {React.Children.toArray(
                    arrayData && arrayData.length > 0 ? (
                        arrayData.map(({ id, title, authors, journal, abstract }, index) => (
                            <div className='col-lg-12' key={index}>
                                {/* Handle Background Color Change On Include, Exclude  */}
                                <div className='inner--box--row' >
                                    <span className='index--numbering'>{index + 1}</span>
                                    <div className='width--80'>
                                        <h1 className='heading3'>{convertFirstLetterToUpperCase(title)}</h1>
                                        <p className='small text-muted'>{authors}</p>
                                        {journal ? <label className='form-check-label'> Journal {journal} </label> : ""}
                                        <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{abstract}</p>
                                    </div>

                                    <div className='width-20'>
                                        <div className=''>
                                            <div className='btn--new--ul'>
                                                <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleClick(index)}>
                                                    <FaEye />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))
                    ) : (
                        <p className='p-3 text-muted'>There are no included abstracts.</p>
                    )
                )}
            </div>
        </div>
    )
}

export default MaybeAbstractComponent