import React, { useState } from 'react';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import type { IHighlight } from 'react-pdf-highlighter';

interface Props {
  highlights: Array<IHighlight>;

  biasHeadings: any;
  isShow: any;
  biasType: any;
  activeAccordion: any;
  resetHighlights: () => void;
  toggleDocument: () => void;
  removeHighlight: () => void;
  UpdatBiasType: () => void;
  openAccordion: () => void;
  saveHighlights: () => void;
}

const updateHash = (highlight: IHighlight) => {
  document.location.hash = `highlight-${highlight.id}`;

  document.addEventListener('DOMContentLoaded', () => {
    updateHash(highlight);
  });
};

export function Sidebar({
  highlights,
  isShow,
  biasType,
  activeAccordion,
  biasHeadings,
  openAccordion,
  saveHighlights,
  removeHighlight,
  toggleDocument,
  resetHighlights,
  UpdatBiasType,
}: Props) {
  const RemoveHighlight = (id) => {
    console.log(id);
    highlights = [];
  };
  console.log(biasType);
  return (
    <div className='sidebar' style={{ width: '25vw', paddingLeft: '10px' }}>
      <h2 className='risk-of-bias'>Risk of Bias</h2>
      {biasHeadings &&
        biasHeadings.map((heading, index) => {
          return (
            <div className='card mb-2' key={index}>
              <div
                className={
                  isShow[heading.id]
                    ? 'card-header robot-reviewer-card-header robot-reviewer-card-header-font'
                    : 'card-header robot-reviewer-card-header'
                }
                style={{
                  backgroundColor:
                    isShow[heading.id] === true && heading.id === 1
                      ? 'rgb(168, 191, 18)'
                      : isShow[heading.id] === true && heading.id === 2
                      ? 'rgb(0,191,181)'
                      : isShow[heading.id] === true && heading.id === 3
                      ? 'rgb(255, 159, 0)'
                      : isShow[heading.id] === true && heading.id === 4
                      ? 'rgb(244, 28, 84)'
                      : isShow[heading.id] === true && heading.id === 5
                      ? '#004358'
                      : isShow[heading.id] === true && heading.id === 6
                      ? '#BF0426'
                      : '',
                }}
              >
                <span
                  className='btn d-flex justify-content-between align-items-center'
                  onClick={() => openAccordion(heading.id, heading.biasType)}
                >
                  <span className='text-start'>{heading.headingText}</span>
                  <span>{isShow[heading.id] ? <FaEyeSlash /> : <FaEye />}</span>
                </span>
              </div>
              <div
                className={
                  isShow[heading.id] ? 'display-accordion' : 'hide-accordion'
                }
              >
                <div className='card-body'>
                  <ul className='sidebar__highlights'>
                    {highlights.map((highlight, index) => (
                      <li
                        key={index}
                        className='sidebar__highlight'
                        onClick={() => {
                          updateHash(highlight);
                        }}
                      >
                        <div>
                          {/* <strong>{highlight.comment.text}</strong> */}
                          {highlight.biasType == heading.biasType &&
                          highlight.content.text ? (
                            <span
                              style={{ marginTop: '0.5rem' }}
                              className='d-flex justify-content-between align-items-center'
                            >
                              <span className='acc-list-text'>
                                {`${highlight.content.text
                                  .slice(0, 37)
                                  .trim()}…`}
                              </span>
                              <span className='acc-list-icon'>
                                <FaTimes
                                  onClick={() => removeHighlight(highlight.id)}
                                />
                              </span>
                            </span>
                          ) : null}

                          {highlight.content.image ? (
                            <div
                              className='highlight__image'
                              style={{ marginTop: '0.5rem' }}
                            >
                              <img
                                src={highlight.content.image}
                                alt={'Screenshot'}
                              />
                            </div>
                          ) : null}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}

      <div className='d-flex justify-content-center'>
        <button
          className='btn save-highlight-btn'
          onClick={() => saveHighlights()}
        >
          Save
        </button>
      </div>
    </div>
  );
}
