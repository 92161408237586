import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEye, FaMinus, FaPlus, FaStickyNote } from "react-icons/fa";
import { GrRotateRight } from "react-icons/gr";
import { BsFillCircleFill, BsFileEarmarkExcel, BsQuestionSquareFill, BsQuestionLg } from "react-icons/bs";

import { MD5hash, API_Link } from "../../commons/Constants";
import "./../../assets/sass/style.css";
import "./../../assets/sass/_screening.scss";
import "./../../assets/sass/_topbar.scss";
import "./../../assets/css/topbar.css";
import axios from "axios";

function Completed() {
  const [isActive, setIsActive] = useState(null);
  const [arrayData, setArrayData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  useEffect(() => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles?review_id=2`,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, "misr-auth": MD5hash },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        setArrayData(resData.data.articles);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //Handle FullText
  const handleClick = (index) => {
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  //Handle FullText
  console.log("Abstract API responce Lenght", arrayData.length);

  // const options = [
  //   {
  //     label: "#4816 - Alish 2007",
  //     p: "Alish, Yaakov; Birger, Moshe; Manor, Nira; Kertzman, Simion; Zerzion, Moshe; Kotler, Moshe; Strous, Real D.",
  //     h1: "Schizophrenia sex offenders: A clinical and epidemiological comparison study",
  //     pa: "International Journal of Law & Psychiatry 2007;30(6):459-466",
  //     par: "2007",
  //     ls: "Your last edit was about 1 hour ago",
  //   },
  //   {
  //     label: "#5241 - Ash 2015",
  //     p: "Curabitur sodales placerat leo, vitae fringilla ex molestie sit amet",
  //     h1: "Mauris dapibus porttitor neque eget varius Fusce aliquam efficitur vehicula",
  //     pa: "International Journal of Law & Psychiatry 2007;30(6):459-466",
  //     par: "2015",
  //     ls: "Your last edit was about 4 hour ago",
  //   },
  // ];
  return (
    <div>
      <div className='page--content completed'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-3'>
            <h1 className='page--heading'>Total Abstract</h1>
          </div>
          <div className=' col-sm-12 col-md-12 col-lg-9 text-right'>
            <ul className='ul-export'>
              <li>
                <div className='dropdown cst-dropdown'>
                  <select name='' id=''>
                    <option value=''>Author </option>
                    <option value=''>Year </option>
                    <option value=''>Author </option>
                  </select>
                  <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                    <li>
                      <a className='dropdown-item' href='#'>
                        Action
                      </a>
                    </li>
                    <li>
                      <a className='dropdown-item' href='#'>
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className='dropdown-item' href='#'>
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
                <input type='text' name='' placeholder='Search by Author' className='input--new form-control' id='' />
              </li>
              <li>
                <a href='#' className='btn--export'>
                  {" "}
                  <i className='fa-solid fa-file-arrow-down'></i> Export
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='row boxes-row'>
          {React.Children.toArray(
            arrayData.map(({ title, authors, journal, abstract }, index) => (
              <div className='col-lg-12'>
                <div className='inner--box--row'>
                  <span className='index--numbering'>{index + 1}</span>
                  <div className='width--70'>
                    <h1 className='heading3'>{title}</h1>
                    <p className='small text-muted'>{authors}</p>
                    {/* <h1 className='heading3'>{Journal}</h1> */}
                    <label className='form-check-label' htmlFor='flexCheckDefault2'>
                      Journal:{journal}
                    </label>
                    {/* Handle Hight of Paragraph Here */}
                    <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{abstract}</p>
                  </div>
                  <div className='width-30'>
                    <div className=''>
                      <Link to='#' data-bs-toggle='tooltip' className='link' title='View Full Text' onClick={() => handleClick(index)}>
                        <FaEye />
                      </Link>
                      <Link to='#' data-bs-toggle='tooltip' className='link' title='Add Note'>
                        <FaStickyNote />
                      </Link>
                      <Link to='#' data-bs-toggle='tooltip' className='link' title='Include'>
                        <FaPlus />
                      </Link>
                      <Link to='#' data-bs-toggle='tooltip' className='link' title='Exclude'>
                        <FaMinus />
                      </Link>
                      <Link to='#' data-bs-toggle='tooltip' className='link' title='Maybe'>
                        <BsQuestionLg />
                      </Link>
                      <p>
                        <small className='circle--warning'>
                          <BsFillCircleFill />
                          Your last edit was about 1 hour ago
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Completed;
