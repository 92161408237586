import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { MD5hash, API_Link } from '../commons/Constants'
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas';


const PrismaComponent = () => {

    const [prismaValue, setPrismaValue] = useState([])
    const [reasons, setReasons] = useState([])

    const user = JSON.parse(localStorage.getItem('user'))
    const studyId = localStorage.getItem('studyId')
    const token = user.token
    useEffect(() => {
        var config = {
            method: 'get',
            url: `${API_Link}getPrismaCounts?review_id=${studyId}`, headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token,
                'misr-auth': MD5hash
            }
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                // document.querySelector(".preload").style.display = "none"//stop the load
                setPrismaValue(resData.data.counts);
                console.log('getPrisma', prismaValue)
                console.warn('reasons', resData.data.counts[0].excluded)
                setReasons(resData.data.counts[0].excluded)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const ExportDocx = () => {

        if (!window.Blob) {
            alert('Your legacy browser does not support this action.');
            return;
        }

        var html, link, blob, url, css;

        // EU A4 use: size: 841.95pt 595.35pt;
        // US Letter use: size:11.0in 8.5in;

        css = (
            '<style>' +
            '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
            'div.WordSection1 {page: WordSection1;}' +
            '.box-blue{background: #8ecae6;border-radius: 3px;transform: rotate(90deg);padding: 17px;}' +
            `table{border-collapse:collapse; width: 100%;}td{} .export-prisma-table {
                margin-top: 50px;
              }
              
              .box-blue div {
                background: #8ecae6;
                border-radius: 3px;
                transform: rotate(90deg);
                padding: 24px;
                display: inherit;
                min-height: 30px;
              }
              
              .box-gray div {
                background: #d9d9d9;
                font-weight: 500;
                font-size: 14px;
                border-radius: 4px;
                display: block;
                padding: 10px;
                min-height: 30px;

              }
              
              .export-prisma-table td {
                border: 10px solid white;
              }
              
              .box-yellow div {
                background: #e3ffa7;
                font-weight: 500;
                font-size: 14px;
                border-radius: 4px;
                display: block;
                padding: 10px;
                min-height: 30px;
              }` +
            '</style>'
        );

        html = window.docx.innerHTML;
        blob = new Blob(['\ufeff', css + html], {
            type: 'application/msword'
        });
        url = URL.createObjectURL(blob);
        link = document.createElement('A');
        link.href = url;
        // Set default file name. 
        // Word will append file extension - do not add an extension here.
        link.download = 'Document';
        document.body.appendChild(link);
        if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc'); // IE10-11
        else link.click();  // other browsers
        document.body.removeChild(link);
    };

    const handleCaptureClick = async () => {
        try {
          const pricingTableElmt = document.getElementById('prisma-chart');
          if (!pricingTableElmt) {
            console.error('Could not find pricing table element');
            return;
          }
      
          let canvas = await html2canvas(pricingTableElmt);
          let dataURL = canvas.toDataURL('image/png');
          downloadjs(dataURL, 'download.png', 'image/png');
        } catch (error) {
          console.error(error);
        }
      };
      
    return (
        <div className='page--content'>
            <div className='row'>
                <div className='col-lg-8'>
                    <h1 className='page--heading'>Prisma Flowchart</h1>
                </div>
                <div className="col-lg-4 text-end">
                    <a onClick={handleCaptureClick} className="export-btn"><i class="bi bi-file-earmark-image"></i> Download</a>
                </div>
            </div>
            {
                prismaValue && prismaValue.length > 0 && prismaValue.map(() => {
                    return (
                        <>
                            <div className='container pb-2' id='prisma-chart'>
                                <div className='row pt-5'>
                                    <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                                        <div className='identification'>
                                            <p className='text'>Identification</p>
                                        </div>
                                    </div>
                                    <div className='col-col-sm-12 col-md-9 col-lg-10'>
                                        <div className='row mb-3'>
                                            <div className='col-sm-12 col-md-12 col-lg-6 mt-2 '>
                                                <div className='Number1972'>Number of records identified through electronic databases searching (n={prismaValue[0].onlineSearch})</div>
                                            </div>

                                            <div className='col-sm-12 col-md-12 col-lg-6 mt-2'>
                                                <div className='Number53'>Number of additional records identified from other sources (n={prismaValue[0].otherSources})</div>
                                            </div>
                                        </div>

                                        <div className='row '>
                                            <div className='col-6'>
                                                <span className='arrow-icon-prisma'>
                                                    {/* <i className='bi bi-arrow-down-short'></i> */}

                                                    ↓
                                                </span>
                                            </div>
                                            <div className='col-6'>
                                                <span className='arrow-icon-prisma'>
                                                    ↓
                                                </span>
                                            </div>
                                        </div>

                                        <div className='row pt-2 mb-3 '>
                                            <div className='col-12'>
                                                <div className='Num2055'>Number of records after duplicates removed (n={prismaValue[0].abstract_articles_count})</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-3'></div>
                                    <div className='col-sm-12 col-md-9 col-lg-10'>
                                        <span className='arrow-icon-prisma'>
                                            ↓
                                        </span>
                                    </div>
                                </div>

                                <div className='row '>
                                    <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                                        <div className='Screening'>
                                            <p className='text'>Screening</p>
                                        </div>
                                        <div className='row pt-2'>
                                            <div className='col-sm-12 col-md-3 col-lg-2 '>
                                                <div className='included'>
                                                    <p className='text'>included</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-col-sm-12 col-md-9 col-lg-10'>
                                        <div className='row mb-3'>
                                            <div className='col-sm-12 col-md-12 col-lg-1 '></div>
                                            <div className='col-sm-12 col-md-12 col-lg-5 mt-2'>
                                                <div className='num_1925'>Number of records screened (n={prismaValue[0].abstract_articles_count})</div>
                                            </div>

                                            <div className='col-sm-12 col-md-12 col-lg-1'>
                                                <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                                                    {/* <i className='bi bi-arrow-down-short'></i> */}
                                                    →
                                                </span>
                                            </div>

                                            <div className='col-sm-12 col-md-12 col-lg-5 mt-2 '>
                                                <div className='num1200'>Number of records excluded (n={prismaValue[0].abstract_articles_count - prismaValue[0].fulltext_articles_count})</div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-12 col-lg-7'>
                                                <span className='arrow-icon-prisma'>
                                                    {/* <i className='bi bi-arrow-down-short'></i> */}
                                                    ↓
                                                </span>
                                            </div>
                                        </div>

                                        <div className='row '>
                                            <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '></div>
                                            <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5 '>
                                                <div className='row mb-3'>
                                                    <div className='col'>
                                                        <div className='num685'>Number of full-text articles assessed for eligibility (n={prismaValue[0].fulltext_articles_count})</div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 col-md-12 col-lg-12'>
                                                    <span className='arrow-icon-prisma'>
                                                        {/* <i className='bi bi-arrow-down-short'></i> */}

                                                        ↓
                                                    </span>
                                                </div>
                                                <div className='row '>
                                                    <div className='col'>
                                                        <div className='mt-2 yelllowbox'>Number of full-text articles included in qualitative synthesis (n={prismaValue[0].included_articles_count})</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '>
                                                <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                                                    {/* <i className='bi bi-arrow-down-short'></i> */}
                                                    →
                                                </span>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5'>
                                                <div className='num432'>Number of full-text articles excluded (n={prismaValue[0].fulltext_articles_count - prismaValue[0].included_articles_count}) <br></br> Reasons:

                                                    {
                                                        reasons && reasons.filter(exclusion => exclusion.stage === 3).map(({ purpose, total }, index) => {
                                                            return (
                                                                <small>
                                                                    <br></br>
                                                                    {purpose.label} (n = {total})
                                                                </small>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* For Doc file  */}
                            <div id='docx' className='prisma-content'>
                                <div className='WordSection1'>
                                    <table className='export-prisma-table'>
                                        <tr>
                                            <td rowSpan={2} className="box-blue">
                                                <div> Identification</div>
                                            </td>
                                            <td className='box-gray'>
                                                <div> Number of records identified through electronic databases searching (n={prismaValue[0].onlineSearch})</div>
                                            </td>
                                            <td className='box-gray'>
                                                <div>Number of additional records identified from other sources (n={prismaValue[0].otherSources})</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="box-gray">
                                                <div> Number of records after duplicates removed (n={prismaValue[0].abstract_articles_count})</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2} className="box-blue">
                                                <div> Screening</div>
                                            </td>
                                            <td className='box-gray'>
                                                <div> Number of records screened (n={prismaValue[0].abstract_articles_count})</div>
                                            </td>
                                            <td className='box-gray'>
                                                <div>Number of records excluded (n={prismaValue[0].abstract_articles_count - prismaValue[0].fulltext_articles_count})</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='box-gray'>
                                                <div> Number of full-text articles assessed for eligibility (n={prismaValue[0].fulltext_articles_count})</div>
                                            </td>
                                            <td className='box-gray'>
                                                <div>
                                                    Number of full-text articles excluded (n={prismaValue[0].fulltext_articles_count - prismaValue[0].included_articles_count})
                                                    Reasons:
                                                    {
                                                        reasons && reasons.filter(exclusion => exclusion.stage === 3).map(({ purpose, total }, index) => {
                                                            return (
                                                                <small>
                                                                    <br></br>
                                                                    {purpose.label} (n = {total})
                                                                </small>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={2} className="box-blue">
                                                <div> included</div>
                                            </td>
                                            <td rowSpan={2} className='box-yellow'>
                                                <div> Number of full-text articles included in qualitative synthesis (n={prismaValue[0].included_articles_count})</div>
                                            </td>
                                        </tr>

                                    </table>
                                </div>
                            </div>
                            {/* For Doc file */}
                        </>
                    )
                })
            }
        </div >
    )
}

export default PrismaComponent