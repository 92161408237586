import React, { useEffect, useState } from 'react';
import { json, Link, useNavigate } from 'react-router-dom';
import {
  FaEye,
  FaMinus,
  FaPlus,
  FaSearch,
  FaStickyNote,
  FaTimes,
} from 'react-icons/fa';
import { GrRotateLeft } from 'react-icons/gr';
import { API_Link, MD5hash } from '../commons/Constants';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './../assets/sass/style.css';
import './../assets/sass/totalreview.css';
import gif from './../assets/img/spin.gif';
import axios from 'axios';
import { Tab, Tabs } from 'react-bootstrap';
import ReviewPie from './dashboardComponent/ReviewPie';
import LineChart from './dashboardComponent/LineChart';
import AreaSpline from './dashboardComponent/AreaSpline';
import { useSelector, useDispatch } from 'react-redux';
import { getPermission } from '../features/setting/permissionSlice';

function TotalReviewsNew(props) {
  const [articles, setArticles] = useState([]);
  const [isActive, setIsActive] = useState(null);
  const [processedArticles, setProcessedArticles] = useState([]);
  const [mayBe, setmayBe] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [excluded, setExcluded] = useState([]);
  const [isGreen, setIsGreen] = useState(
    new Array(articles.length).fill(false)
  );
  const [isRed, setIsRed] = useState(new Array(articles.length).fill(false));
  const [isMoveNextLoading, setIsMoveNextLoading] = useState(false);
  // modal and tab states
  const [tabKey, initTabKey] = useState('three');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  // call for get reviewer permissions
  const [getPermission, setGetPermission] = useState([]);

  const getPermissionCall = () => {
    var config = {
      method: 'get',
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem(
        'studyId'
      )}`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGetPermission(response.data.permissions[0]);

        // console.log('after api call in search inclusion', getPermission);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const navigate = useNavigate();
  let review_id = localStorage.getItem('studyId');

  const [permission1] = useSelector((state) => state.getPermission.data);

  const getReviewArticles = async () => {
    var config = {
      method: 'get',
      url: `${API_Link}getReviewArticles`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
      params: { review_id: review_id, included: 'Yes' },
    };

    await axios(config)
      .then(function (response) {
        const resData = response;

        setExcluded(resData.data.excluded);

        const abstarctScreeningStatus = resData.data.articles.map(
          (article) => ({ ...article, reason: null })
        );
        const setInclusionExclusion = abstarctScreeningStatus.map((article) => {
          if (article.current_stage > 2) {
            return { ...article, abstract_screening: 1 };
          } else if (
            article.current_stage === 2 &&
            resData.data.excluded.includes(article.id)
          ) {
            return { ...article, abstract_screening: 0 };
          } else if (article.journal === null || article.journal === '') {
            return { ...article, journal: '?' };
          } else if (article.authors === null || article.authors === '') {
            return { ...article, authors: '?' };
          } else {
            return article;
          }
        });

        console.log('set inclusion exclusion', setInclusionExclusion);
        const checkLabeledArticles = setInclusionExclusion.map((article) => {
          if (
            article.abstract_screening === 1 ||
            article.abstract_screening === 0
          ) {
            return { ...article, labeled: true };
          } else {
            return { ...article, labeled: false };
          }
        });

        console.log('Label Checker', checkLabeledArticles);
        setArticles(
          checkLabeledArticles
            .filter(function (article) {
              return (
                article.abstract !== '' &&
                article.abstract !== null &&
                article.title !== '' &&
                article.title !== null
              );
            })
            .map(function (article) {
              return article;
            })
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // console.log('Checking labels', articles);

  // const checkLabeledArticles = articles.map((article) => {
  //   if (article.abstract_screening === 1 || article.abstract_screening === 0) {
  //     return { ...article, labeled: true };
  //   } else {
  //     return { ...article, labeled: false };
  //   }
  // });
  // const LabelChecker = checkNonLabel.filter(
  //   (article) => article.labeled === false
  // );
  // const [dataLabeled, setDataLabled] = useState(checkNonLabel);
  console.log(articles);
  useEffect(() => {
    review_id = localStorage.getItem('studyId');

    getPermissionCall();

    getReviewArticles();
  }, []);

  // console.log('abstract Status', articles);
  //
  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  // code for capitalize
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1) return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) =>
        shouldBeUppercase(word, index, length)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }
  // handle Include
  const handleInclude = (id, abstract_screening) => {

    let result = [...isGreen];
    result[id] = !result[id];
    setIsGreen(result);

    abstract_screening = 1;
    setArticles(
      articles.map((item) => {
        if (item.id === id) {
          return { ...item, abstract_screening, labeled: true };
        } else {
          return item;
        }
      })
    );
  };

  const countIncluded = (type = 1) => {
    const countTypes = articles.filter(
      (article) => article.abstract_screening === type
    );
    return countTypes.length;
  };

  // handle Exclude
  const handleExclude = (id, abstract_screening) => {
    let result = [...isRed];
    result[id] = !result[id];
    setIsRed(result);

    abstract_screening = 0;
    setArticles(
      articles.map((item) => {
        if (item.id === id) {
          return { ...item, abstract_screening, labeled: true };
        } else {
          return item;
        }
      })
    );
  };
  const countExcluded = (type = 0) => {
    const countTypes = articles.filter(
      (article) => article.abstract_screening === type
    );
    return countTypes.length;
  };

  const LabeledRecords = countExcluded() + countIncluded();
  // handle Exclude

  const handleMayBe = (index) => {
    if (mayBe === index) {
      return setmayBe(null);
    }
    setmayBe(index);
  };

  //handle Undo
  const handleUndo = async (id, current_stage) => {
    if (current_stage > 2) {
      setArticles(
        articles.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              current_stage: 2,
              abstract_screening: null,
              labeled: false,
            };
          } else {
            return item;
          }
        })
      );
    }

    if (excluded.includes(id)) {
      setExcluded(excluded.filter((item) => item != id));

      setArticles(
        articles.map((item) => {
          if (item.id === id) {
            return { ...item, abstract_screening: null, labeled: false };
          } else {
            return item;
          }
        })
      );
    }

    let abstract_screening = null;

    if (isGreen[id]) {
      handleInclude(id);
      setArticles(
        articles.map((item) => {
          if (item.id === id) {
            return { ...item, abstract_screening: null, labeled: false };
          } else {
            return item;
          }
        })
      );
    } else if (isRed[id]) {
      handleExclude(id);
      setArticles(
        articles.map((item) => {
          if (item.id === id) {
            return { ...item, abstract_screening, labeled: false };
          } else {
            return item;
          }
        })
      );

      setExcluded(excluded.filter((item) => item !== id));
    } else {
    }
  };
  // console.log('exclude', excluded);

  /*This Function is for including Artices from Modal after start of active learning
   if Articles Finish it will Goes to Include page*/
  const handleModalInclude = async (id, abstract_screening) => {

    const result = articles.map((article, index) => {
      const result1 = processedArticles.filter(
        (article2) => article2.id == article.id
      );
      if (result1.length > 0) {
        const result = [...isGreen];
        result[id] = !result[id];

        setIsGreen(result);
      }
      return article;
    });

    abstract_screening = 1;
    const IncludeArticle = articles.map(function (article) {
      var result = processedArticles.filter(
        (article2) => article2.id == article.id
      );
      if (result.length > 0) {
        article.abstract_screening = 1;
        article.labeled = true;
      }
      return article;
    });
    const countTypes = articles.filter(
      (article) => article.labeled === false
    );

    // const countTypes = articles.filter(
    //   (article) => !article.hasOwnProperty('abstract_screening')
    // );

    console.log('countypes', countTypes);
    if (countTypes.length > 0) {
      const data = { articles: IncludeArticle };
      setIsLoading(true);
      try {
        await axios
          .post('http://misr.pacemis.com:92/learning', data)
          .then((res) => {
            //  console.log('Response from server include -- :', res.data);
            setProcessedArticles(res.data);
          });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setShow(false);
    }
    // if (countTypes.length === 0) {
    //   setIsLoading(true);
    //   setShow(false);
    // const excludedArticleIds = articles
    //   .filter((item) => item.abstract_screening === 0)
    //   .map((item) => item.id);

    // const labeledArticleStatus = articles
    //   .filter(
    //     (item) =>
    //       item.abstract_screening === 0 ||
    //       item.abstract_screening === 1 ||
    //       item.abstract_screening === null
    //   )
    //   .map(({ id, abstract_screening, reason }) => {
    //     return { id, abstract_screening, reason: 9 };
    //   });

    // const data = {
    //   review_id: review_id,
    //   stage: '2',
    //   exclusion_purpose: '9',
    //   articles: labeledArticleStatus,
    // };
    // let requestError = null;
    // try {
    //   await axios
    //     .post(`${API_Link}excludeArticles`, data, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: 'Bearer ' + token,
    //         'misr-auth': MD5hash,
    //       },
    //     })
    //     .then((res) => res.data);
    // } catch (err) {
    //   requestError = err;
    //   alert('Something Went Wrong!, Please Try Again');
    //   // Handle specific error conditions if needed
    // } finally {
    //   if (!requestError) {
    //     navigate('/included');
    //   } else {
    //     setIsLoading(false);
    //   }
    // }
    // } else {
    //   const data = { articles: IncludeArticle };
    //   setIsLoading(true);
    //   try {
    //     await axios
    //       .post('http://misr.pacemis.com:78/learning', data)
    //       .then((res) => {
    //         // console.log('Response from server:', res.data);
    //         setProcessedArticles(res.data);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // }
  };

  const handleModalExclude = async (id, abstract_screening) => {
    articles.map((article, index) => {
      const result1 = processedArticles.filter(
        (article2) => article2.id == article.id
      );
      if (result1.length > 0) {
        const result = [...isRed];
        result[id] = !result[id];
        setIsRed(result);
      }
      return article;
    });

    abstract_screening = 0;

    const excludeArticle = articles.map(function (article) {
      var result = processedArticles.filter(
        (article2) => article2.id == article.id
      );
      if (result.length > 0) {
        article.abstract_screening = 0;
        article.labeled = true;
      }
      return article;
    });
    console.log('Exclude Articles', excludeArticle);
    // const countTypes = articles.filter(
    //   (article) => article.abstract_screening === null
    // );

    const countTypes = articles.filter(
      (article) => article.labeled === false
    );

    console.log('countTypes-exclude countTypes', countTypes)
    if (countTypes.length > 0) {
      const data = { articles: excludeArticle };
      setIsLoading(true);
      try {
        await axios
          .post('http://misr.pacemis.com:92/learning', data)
          .then((res) => {
            console.log('Response from server:', res.data);
            setProcessedArticles(res.data);
          });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setShow(false);
    }

    // if (countTypes.length === 0) {
    //   setIsLoading(true);
    //   setShow(false);
    // const excludedArticleIds = articles
    //   .filter((item) => item.abstract_screening === 0)
    //   .map((item) => item.id);

    // const labeledArticleStatus = articles
    //   .filter(
    //     (item) =>
    //       item.abstract_screening === 0 ||
    //       item.abstract_screening === 1 ||
    //       item.abstract_screening === null
    //   )
    //   .map(({ id, abstract_screening, reason }) => {
    //     return { id, abstract_screening, reason: 9 };
    //   });

    // const data = {
    //   review_id: review_id,
    //   stage: '2',
    //   exclusion_purpose: '9',
    //   articles: labeledArticleStatus,
    // };
    // // console.log(data);
    // let requestError = null;
    // try {
    //   await axios
    //     .post(`${API_Link}excludeArticles`, data, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: 'Bearer ' + token,
    //         'misr-auth': MD5hash,
    //       },
    //     })
    //     .then((res) => res.data);
    // } catch (err) {
    //   if (err) {
    //     requestError = err;
    //     alert('Something Went Wrong!, Please Try Again');
    //   }
    // } finally {
    //   if (!requestError) {
    //     navigate('/included');
    //   } else {
    //     setIsLoading(false);
    //   }
    // }
    // } else {
    // const data = { articles: excludeArticle };
    // setIsLoading(true);
    // await axios
    //   .post('http://misr.pacemis.com:78/learning', data)
    //   .then((res) => {
    //     console.log('Response from server:', res.data);
    //     setProcessedArticles(res.data);
    //   })
    //   .finally(() => setIsLoading(false));
    // }
    // console.log('id', excludeArticle);
  };
  // Active Learning API Call

  let data = { articles: articles };
  // console.log('data', data);
  const handleActiveLearning = async () => {
    setShow(true);
    setIsLoading(true);

    try {
      await axios
        .post('http://misr.pacemis.com:92/learning', data)
        .then((res) => {
          // console.log("Response from server:", res.data);
          setProcessedArticles(res.data);
        });
    } catch (error) {
      alert('Something Went Wrong!, Please Try Again');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFinish = async () => {
    setIsLoading(true);
    setShow(false);
    // const excludedArticleIds = articles
    //   .filter(
    //     (item) =>
    //       item.abstract_screening === 0 || item.abstract_screening === null
    //   )
    //   .map((item) => item.id);

    // //This Array is for creating Payload for excludedArticles  by this we get [{id: 1, abstract_screening: null, reason: null}
    // const labeledArticleStatus = articles
    //   .filter(
    //     (item) =>
    //       item.abstract_screening === 0 ||
    //       item.abstract_screening === 1 ||
    //       item.abstract_screening === null
    //   )
    //   .map(({ id, abstract_screening, reason }) => {
    //     return { id, abstract_screening, reason: 9 };
    //   });

    // const data = {
    //   review_id: review_id,
    //   stage: '2',
    //   exclusion_purpose: '9',
    //   articles: labeledArticleStatus,
    // };
    // console.log('dataddd', data);
    // let requestError = null;
    // try {
    //   await axios
    //     .post(`${API_Link}excludeArticles`, data, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: 'Bearer ' + token,
    //         'misr-auth': MD5hash,
    //       },
    //     })
    //     .then((res) => res.data);
    // } catch (error) {
    //   if (error) {
    //     requestError = error;
    //     alert('Something Went Wrong Please Try again');
    //   }
    // } finally {
    //   if (!requestError) {
    //     navigate('/included');
    //   } else {
    //     setIsLoading(false);
    //   }
    // }

    // console.log('finish Excluded Article', excludedArticleIds);
  };

  const handleMoveNext = async () => {
    setIsLoading(true);
    setIsMoveNextLoading(true);
    const excludedArticleIds = articles
      .filter(
        (item) =>
          item.abstract_screening === 0 || item.abstract_screening === null
      )
      .map((item) => item.id);

    //This Array is for creating Payload for excludedArticles  by this we get [{id: 1, abstract_screening: null, reason: null}
    const labeledArticleStatus = articles
      .filter(
        (item) =>
          item.abstract_screening === 0 ||
          item.abstract_screening === 1 ||
          item.abstract_screening === null
      )
      .map(({ id, abstract_screening, reason }) => {
        return { id, abstract_screening, reason: 9 };
      });

      console.log('labeledArticleStatus', labeledArticleStatus)
    const data = {
      review_id: review_id,
      stage: '2',
      exclusion_purpose: '9',
      articles: labeledArticleStatus,
    };
    console.log('dataddd', data);
    let requestError = null;
    try {
      await axios
        .post(`${API_Link}excludeArticles`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            'misr-auth': MD5hash,
          },
        })
        .then((res) => res.data);
    } catch (error) {
      if (error) {
        requestError = error;
        alert('Something Went Wrong Please Try again');
      }
    } finally {
      if (!requestError) {
        navigate('/included');
      } else {
        setIsLoading(false);
        setIsMoveNextLoading(false);
      }
    }

    // console.log('finish Excluded Article', excludedArticleIds);
  };
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    // console.log('input', searchInput);
  };

  const searchArticles = (searchInput) => (item) =>
    item.title.toLowerCase().includes(searchInput.toLowerCase()) ||
    item.abstract.toLowerCase().includes(searchInput.toLowerCase()) ||
    item.authors.toLowerCase().includes(searchInput.toLowerCase());

  const handleSearchSubmit = (e) => {
    e.preventDefault();
  };

  const handleAddNote = (e) => {
    e.preventDefault();
    setShowNoteModal((current) => !current);
  };
  const countUnLabeled = articles
    .filter((article) => article.labeled === false)
    .map((article2) => article2.id).length;

  // console.log(countUnLabeled);

  if (isMoveNextLoading)
    return (
      <div className='preload'>
        <img src={gif} alt='' />
      </div>
    );

  return (
    <div className='page--content--abstract'>
      <div className=''>
        <div className='row'>
          <div className='col-lg-6'>
            <h1 className='page--heading'>Abstract Screening</h1>
          </div>
          <div className='col-lg-6 abstract-ai-move-next-btn-container'>
            {getPermission && getPermission.includes(2) ? (
              <>
                {countIncluded() > 0 &&
                  countExcluded() > 0 &&
                  countUnLabeled > 0 ? (
                  <Button
                    variant='success'
                    onClick={handleActiveLearning}
                    className='modal--positioning ai-btn'
                  >
                    Auto Abstract Screening (AI)
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant='secondary'
                    className='modal--positioning ai-btn'
                  >
                    Auto Abstract Screening (AI)
                  </Button>
                )}
              </>
            ) : (
              ''
            )}

            {countIncluded() > 0 ? (
              <button
                className='btn btn-warning move-next-btn'
                onClick={handleMoveNext}
              >
                Move To Next
              </button>
            ) : (
              <button
                title='Disabled'
                className='btn btn-secondary move-next-btn'
                disabled
              >
                Move To Next
              </button>
            )}

            {/* {permission1 && permission1.includes(2) || permission1 == 2 ? <ul className='ul-export ms-2'>
              {countIncluded() > 0 && countExcluded() > 0 ? (
                <Button variant='success' onClick={handleActiveLearning} className='modal--positioning'>
                  Auto Abstract Screening (AI)
                </Button>
              ) : (
                <Button disabled variant='success' className='modal--positioning'>
                  Auto Abstract Screening (AI)
                </Button>
              )}
            </ul> : ""} */}
          </div>
        </div>
        <div className='row mb-1 '>
          <div className='col-lg-6'>
            <span className='search-bar-wrapper'>
              <form onSubmit={(e) => handleSearchSubmit(e)}>
                <input
                  type='text'
                  name='searchbar'
                  placeholder='Search here...'
                  value={searchInput}
                  className='search-input form-control'
                  id=''
                  onChange={(e) => handleSearch(e)}
                />
              </form>
              {!searchInput ? (
                <FaSearch className='search-clear-icon' />
              ) : searchInput ? (
                <FaTimes
                  className='search-clear-icon'
                  onClick={(e) => setSearchInput('')}
                />
              ) : (
                ''
              )}
            </span>
          </div>
          <div className='col-lg-6 text-right-flex'>
            <span className='count-wrapper-abstract'>
              <span>
                Total <strong>{articles.length}</strong>
              </span>
              &nbsp; &nbsp; &nbsp;
              <span>
                Included <strong>{countIncluded()}</strong>
              </span>
              &nbsp; &nbsp; &nbsp;
              <span>
                Excluded <strong>{countExcluded()}</strong>
              </span>
            </span>
          </div>
        </div>
        {/*  Modal*/}
        <>
          {/* Tabs */}

          <Modal
            className='modal-xl abstract--modal modal-dialog-centered '
            show={show}
            onHide={handleClose}
            backdrop='static'
            keyboard={false}
          >
            <Modal.Header className=''>
              <h4 className='mb-2'>
                {/* <strong>Most Relevant Abstract</strong> */}
              </h4>
              {isLoading ? (
                <button
                  className='btn btn-danger'
                  disabled
                  style={{ cursor: 'not-allowed' }}
                >
                  Stop Active Learning
                </button>
              ) : (
                <button className='btn btn-danger' onClick={handleFinish}>
                  Stop Active Learning
                </button>
              )}
            </Modal.Header>
            {/* activeKey={tabKey} onSelect={(e) => initTabKey(e)} */}
            <div>
              <Tabs
                className='text-dark'
                activeKey={tabKey}
                onSelect={(e) => initTabKey(e)}
              >
                <Tab
                  eventKey='three'
                  title='Most Relevant'
                  className='modal--tab-buttons'
                >
                  {isLoading ? (
                    <div className='preload--modal'>
                      <img src={gif} alt='' />
                    </div>
                  ) : (
                    <>
                      {processedArticles &&
                        processedArticles.length > 0 &&
                        processedArticles.map(
                          (
                            { id, title, abstract, authors, journal },
                            index
                          ) => {
                            return (
                              <div className='col-lg-12' key={index}>
                                <div className='auto--screening--wrapper pt-1 pl-1'>
                                  <h1 className='heading3 auto-screening-heading'>
                                    {convertFirstLetterToUpperCase(title)}
                                  </h1>
                                  <p className='small text-muted auto-screening-title'>
                                    {authors}
                                  </p>
                                  <div className='inner--box--row mb-1'>
                                    {/* <span className='index--numbering'>{id}</span> */}

                                    <div className='width--100--included-modal active-learning'>
                                      <label className='form-check-label'>
                                        {' '}
                                        {journal}{' '}
                                      </label>
                                      <p className='Increase-height--of--paragraph'>
                                        {abstract}
                                      </p>
                                      <span className='d-flex justify-content-end'>
                                        <button
                                          className='btn add-note-btn'
                                          onClick={(e) => handleAddNote(e, id)}
                                        >
                                          Add Note
                                        </button>
                                      </span>
                                      {showNoteModal ? (
                                        <div className='container'>
                                          <fieldset className='scheduler-border'>
                                            <legend className='scheduler-border'>
                                              Add Note
                                            </legend>
                                            <textarea
                                              name=''
                                              id=''
                                              cols='20'
                                              rows='7'
                                              className='form-control'
                                            ></textarea>
                                            <span className='d-flex justify-content-end'>
                                              <button
                                                className='btn btn-primary mt-1'
                                                onClick={() =>
                                                  setShowNoteModal(false)
                                                }
                                              >
                                                Add Note
                                              </button>
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        // <fieldset>
                                        //   <textarea name='' id='' cols='25' rows='10' className='form-control'></textarea>
                                        //
                                        // </fieldset>
                                        ''
                                      )}

                                      <div className='d-flex justify-content-center buttons-wrapper'>
                                        <button
                                          onClick={() => handleModalExclude(id)}
                                          className='btn  mx-2 exclude-btn'
                                        >
                                          Exclude
                                        </button>
                                        <button
                                          onClick={() => handleModalInclude(id)}
                                          className='btn  mx-2 include-btn'
                                        >
                                          Include
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </>
                  )}
                </Tab>
                <Tab
                  eventKey='four'
                  title='Analytics'
                  className='modal--tab-buttons'
                >
                  {isLoading ? (
                    <div className='preload--modal'>
                      <img src={gif} alt='' />
                    </div>
                  ) : (
                    <div className='page--content'>
                      <div className='row text-center'>
                        <div className='col-md-5 col'>
                          <div className='card tab-pie-card '>
                            <ReviewPie
                              LabeledRecords={LabeledRecords}
                              relevantData={countIncluded()}
                              totalRecords={articles.length}
                            />
                          </div>
                        </div>
                        <div className='col-md-7 col'>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='card py-5 label-card'>
                                <h4>Labeled Records</h4>
                                <h4>{LabeledRecords}</h4>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='card py-5 label-card'>
                                <h4>Relevant Records</h4>
                                <h4>{countIncluded()}</h4>
                              </div>
                            </div>
                          </div>
                          <div className='row mt-3'>
                            <div className='col'>
                              <div className='card py-5 label-card'>
                                <h4>Irrelevant Records Since Last Relevant</h4>
                                <h4>{countExcluded()}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mt-2'>
                        <div className='col'>
                          <div className='card line-chart p-2'>
                            <LineChart
                              numberOfRelevantRecords={countIncluded()}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='row mt-2'>
                        <div className='col'>
                          <div className='card line-chart p-2'>
                            <AreaSpline
                              numberOfReviewedRecords={articles.length}
                              numberOfRelevantRecords={countIncluded()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Tab>
              </Tabs>
            </div>

            <Modal.Footer>
              <button
                className='btn btn-success footer-button'
              // onClick={handleFinish}
              >
                Finish
              </button>
            </Modal.Footer>
          </Modal>
        </>
        {/* Modal */}
        <div className='row boxes-row'>
          {React.Children.toArray(
            articles && articles.length > 0 ? (
              articles
                .filter(searchArticles(searchInput))
                .map(
                  (
                    { id, title, abstract, authors, journal, current_stage },
                    index
                  ) => (
                    <div className='col-lg-12' key={index}>
                      {/* Handle Background Color Change On Include, Exclude  */}
                      <div
                        className={
                          current_stage > 2 || isGreen[id]
                            ? 'inner--box--row green'
                            : excluded.includes(id) || isRed[id]
                              ? 'inner--box--row red'
                              : 'inner--box--row'
                        }
                      >
                        {/* <div className='inner--box--row' style={{ backgroundColor: isGreen[index] || current_stage > 2 ? "rgba(0,255,0,0.2)" : isRed[id] || excluded.includes(id) ? "rgba(255,0,0,0.2)" : "" }}> */}
                        <span className='index--numbering'>{index + 1}</span>

                        <div className='width--70'>
                          {/* <label className='form-check-label' htmlFor='flexCheckDefault2'>
                    {Title}
                  </label> */}
                          <h1 className='heading3'>
                            {convertFirstLetterToUpperCase(title)}
                          </h1>

                          {authors && authors !== '?' ? (
                            <p className='small text-muted'>{authors}</p>
                          ) : (
                            ''
                          )}
                          {/* <h1 className='heading3'>{Journal}</h1> */}
                          <label
                            className='form-check-label'
                            htmlFor='flexCheckDefault2'
                          >
                            {/* Journal:{journal} */}
                            {journal && journal !== '?' ? (
                              <label className='form-check-label'>
                                {' '}
                                Journal {journal}{' '}
                              </label>
                            ) : (
                              ''
                            )}
                          </label>
                          {/* Handle Hight of Paragraph Here */}
                          <p
                            className={
                              isActive === index
                                ? 'Increase-height--of--paragraph'
                                : 'Reduce-height--of--paragraph'
                            }
                          >
                            {abstract}
                          </p>
                        </div>
                        <div className='width-30'>
                          <div className=''>
                            <div className='btn--new--ul'>
                              <Link
                                to='#'
                                data-bs-toggle='tooltip'
                                className='link'
                                title='View'
                                onClick={() => handleClick(index)}
                              >
                                <FaEye />
                              </Link>

                              {getPermission && getPermission.includes(2) ? (
                                <>
                                  {' '}
                                  <Link
                                    to='#'
                                    data-bs-toggle='tooltip'
                                    className='link'
                                    title='Add Note'
                                  >
                                    <FaStickyNote />
                                  </Link>
                                  {isGreen[id] ||
                                    isRed[id] ||
                                    current_stage > 2 ||
                                    excluded.includes(id) ? (
                                    <Link
                                      to='#'
                                      data-bs-toggle='tooltip'
                                      className='link'
                                      title='Undo'
                                      onClick={() =>
                                        handleUndo(id, current_stage)
                                      }
                                    >
                                      <GrRotateLeft />
                                    </Link>
                                  ) : (
                                    <span>
                                      <Link
                                        to='#'
                                        data-bs-toggle='tooltip'
                                        className='link'
                                        title='Include'
                                        onClick={() => handleInclude(id)}
                                      >
                                        <FaPlus />
                                      </Link>
                                      <Link
                                        to='#'
                                        data-bs-toggle='tooltip'
                                        className='link'
                                        title='Exclude'
                                        onClick={() => handleExclude(id)}
                                      >
                                        <FaMinus />
                                      </Link>
                                    </span>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                            {/* {isActive === index ? (
                          ""
                        ) : (
                          <p>
                            <small className='circle--warning'>
                              <BsFillCircleFill />
                              &nbsp; You last view 1 hour ago 
                            </small>
                          </p>
                        )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
            ) : (
              <p className='p-3 text-muted'>There are no abstract screening.</p>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default TotalReviewsNew;
