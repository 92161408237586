import React from "react";
import AddReview from "../../Components/addreviews";
import EditReview from "../../Components/editreviews";

function EditReviewPage() {
  return (
    <div>
      <EditReview />
    </div>
  );
}

export default EditReviewPage;
