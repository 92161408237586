import React from 'react'
import "./../../assets/sass/_login.scss"
import "./../FontAwesome/FontAwesome"
import BGV1 from "./../../assets/img/loginBGV1.png"
import Mahtas from "./../../assets/img/mahtas.png"
import WHO from "./../../assets/img/WHO-logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Login2() {
  return (
    <div>
       <div className='login--content'>
      <div className="left--side">
                <img src={BGV1}  alt="" />
            </div>
            <div className="right--side">
                <div className="login--box">
                    <h1 className="login--box--heading">
                        LOGIN
                    </h1>
                    <div className="welcome">
                        Welcome to MISR Sysmetic Review
                    </div>

                    <form>
                        <div className="form-group">
                            <label for="username">{<FontAwesomeIcon icon="user" style={{color: '#044f75'}}/>}Username</label><br/>
                            <input type="email" className="form-control custom--form--control"
                                placeholder="Enter your username" />
                        </div>
                        <div className="form-group">
                            <label for="password">{ <FontAwesomeIcon icon="key" style={{color: '#044f75'}}/>}Password</label><br/>
                            <input type="password" className="form-control custom--form--control" placeholder="Password" />
                        </div>
                        <div className="form-group text-end">
                            <a href="#" className="forget--password">Forget password?</a>
                        </div>
                        <div className="form-group text-center">
                            <span className="position-relative">
                                <input type="submit" value="LOGIN" className="submit--btn" />
                                <FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
                            </span>
                        </div>
                    </form>
                    <div className="box--logo text-center mt-4">
                        <img src={Mahtas}  alt="" />
                        <img src={WHO}  alt="" />
                    </div>
                </div>
              </div>
      </div>
    </div>
 
  )
}

export default Login2
