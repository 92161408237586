import React from 'react'
import InputComponent from '../InputComponent'

const TopbarChildComponent = () => {
    return (
        <>
            <div className='row top_bar_first'>
                <h2 className='col-12'>
                    <InputComponent for={"top_bar"} type={"text"} placeholder={"MaHTAS Intelligent Systematic Review System"} disabled />
                </h2>
            </div>
        </>
    )
}

export default TopbarChildComponent