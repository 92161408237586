import React from "react";
import Review from "./../../Components/reviews"

function ReviewsListingPage() {
  return (
    <div>
      <Review/>
    </div>
  )
}

export default ReviewsListingPage

