import React from "react";
import Aside from "../../Components/aside";
import AsideDuplicate from "../../Components/asideduplicate";
import TopBar from "../../Components/topBar";
import ScreeningDuplicate from "./../../Components/screeningComponent/screeningduplicate";

function ScreeningDuplicatePage() {
  return (
    <>
      <ScreeningDuplicate />
    </>
  );
}

export default ScreeningDuplicatePage;
