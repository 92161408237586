import React from 'react'
import Login3 from './../../Components/loginComponent/login3'
import Header from "./../../Components/header"
import Footer from "./../../Components/footer";


function LoginPage3() {
  return (
    <div className='loginPage'>
    <Header/>
    <Login3 />
    <Footer/>
</div>
  )
}

export default LoginPage3
