import React from "react";
import EvidenceTable from "../../Components/evidencetable";

const Evidencetablepage = () => {
  return (
    <div>
      <EvidenceTable />
    </div>
  );
};

export default Evidencetablepage;
