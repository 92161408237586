import React from "react";

export default function ButtonComponent(props) {
    function handleSubmit(e) {
        e.preventDefault();
        props.handleSubmit(e);
    }
    return (
        <>
            {props.size === "large" ? (
                <div className="d-grid mt-5">
                    <button
                        onClick={handleSubmit}
                        className={`d-block btn ${
                            props.size === "large" ? "form-btn" : ""
                        } text-uppercase mb-5`}
                    >
                        {props.icon ? (
                            <span className="p-2">{props.icon}</span>
                        ) : (
                            ""
                        )}
                        {props.text}
                    </button>
                </div>
            ) : (
                <button
                    type="submit"
                    onClick={props.handleSubmit && handleSubmit}
                    style={
                        props.button_type === "rounded"
                            ? {
                                  ...stylesheet.round_button,
                                  background: props.color,
                              }
                            : {
                                  ...stylesheet.small_button,
                                  background: props.color,
                              }
                    }
                    disabled={props.disabled}
                    className="new--add--button--review"
                >
                    {props.icon && props.icon_position !== "after" ? (
                        <span className="p-2">{props.icon}</span>
                    ) : (
                        ""
                    )}
                    {props.text}
                    {props.icon && props.icon_position === "after" ? (
                        <span className="p-2">{props.icon}</span>
                    ) : (
                        ""
                    )}
                </button>
            )}
        </>
    );
}

const stylesheet = {
   
};
