import React from "react";
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";

exporting(Highcharts);
exportdata(Highcharts);
function Teamgraph() {
  const newoption = {
    chart: {
      type: "column",
    },
    title: {
      text: "Team Progress",
    },
    xAxis: {
      categories: ["Team 1", "Team 2", "Team 3", "Team 4", "Team 5", "Team 6", "Team 7", "Team 8", "Team 9", "Team 10", "Team 11", "Team 12"],
      crosshair: true,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Done",
        data: [13, 13, 13, 13, 14, 14, 14, 14, 13, 93, 21, 16],
        color: "#023047",
      },
      {
        name: "Conflicts",
        data: [24, 12, 11, 12, 65, 96, 59, 94, 96, 59, 42, 76],
        color: "#D3E3EB",
      },
      {
        name: "one Vote",
        data: [10, 93, 97, 13, 10, 26, 14, 12, 36, 72, 38, 69],
        color: "#219EBC",
      },
      {
        name: "no Vote",
        data: [35, 32, 34, 39, 46, 52, 58, 55, 53, 51, 49, 57],
        color: "#8ECAE6",
      },
    ],
  };

  return (
    <div style={{ border: "1px solid lightgray", marginTop: "30px", borderRadius: "10px", overflow: "hidden", maxWidth: "95%", marginLeft: "50px" }}>
      <HighchartsReact highcharts={Highcharts} options={newoption} />
    </div>
  );
}

export default Teamgraph;
