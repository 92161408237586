import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userOld from './../../services/user/userService'


export const setting = createAsyncThunk(
  'setting',
  async () => {
    const response = await userOld.userSettings()
    return response.data.settings
    
  } 
)

const createInitialState = () => {
    return {
      data: []
    }
  }
  const initialState = createInitialState()
  const settingSlice = createSlice({
    name: 'setting',
    initialState,
    reducers: {},
    extraReducers: {
      [setting.fulfilled](state, action) {
        state.data = action.payload
      },     
    },
  })


  export default settingSlice.reducer