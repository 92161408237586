import React, { useEffect, useState } from "react";
import "./../../assets/sass/_login.scss";
import "./../FontAwesome/FontAwesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loginAuth } from "../../features/setting/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import loginvideo from "./../../assets/img/MISR-Login-Page Simulation.mp4";

function Login({ login_page_bg, short_title, sponsor_logo }) {
  const users = useSelector((state) => state.user);
  const { error, status } = useSelector((state) => state.user);
  //   console.log(error);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setErrorMessage] = useState([]);
  let navigate = useNavigate;
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     setErrorMessage([]);

  //     if (status === "succeeded") {
  //       setErrorMessage([]);
  //     } else if (status === "failed") {
  //       setErrorMessage(error.error_message);
  //     } else {
  //       setErrorMessage([]);
  //     }
  //   }, [status]);

  const [loginError, setLoginError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage([]);
    const user = { email, password };
    dispatch(loginAuth(user));

    // if (status === "failed") {
    //   setErrorMessage(error.error_message);
    // } else {
    //   setErrorMessage([]);
    // }

    const interval = setInterval(() => {
      setLoginError(localStorage.getItem("loginError"));
    }, 1000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    localStorage.removeItem("loginError");
    localStorage.removeItem("user");
    localStorage.removeItem("pageId");
    localStorage.removeItem("subPageId");
    localStorage.removeItem("search");
    localStorage.removeItem("studyId");
    localStorage.removeItem("studyTitle");
    localStorage.removeItem("invitation");
  }, []);

  return (
    <div>
      <div className='login--content'>
        <div className='left--side   d-flex justyfy-content:center;'>
          <div className='video-border-lef'></div>
          <div>
            <video autoPlay muted loop className='img-fluid2 video' style={{ boxShadow: "2px 2px 10px #00000029" }}>
              <source src={loginvideo} type='video/mp4' />
            </video>
          </div>
          <div className='video-border-righ--1'></div>
          <div className='video-border-righ--2'></div>
        </div>
        <div className='right--side'>
          <div className='login--box'>
            <h1 className='login--box--heading'>LOGIN</h1>
            <div className='welcome'>Welcome to {short_title}</div>

            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label htmlFor='email'>
                  {<FontAwesomeIcon icon='user' style={{ color: "#044f75" }} />}
                  Email
                </label>
                <br />
                <input type='email' value={email} className='form-control custom--form--control' placeholder='Enter your username' onChange={(e) => setEmail(e.target.value)} required />
              </div>
              <div className='form-group'>
                <label htmlFor='password'>
                  {<FontAwesomeIcon icon='key' style={{ color: "#044f75" }} />}
                  Password
                </label>
                <br />
                <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} className='form-control custom--form--control' placeholder='Password' required />
              </div>
              {/* {loginError ? (
                <p className='alert-danger p-2 rounded text-center text-danger'>
                  {loginError}
                </p>
              ) : (
                ""
              )} */}
              {loginError ? <p className='alert-danger p-2 rounded text-center text-danger'>{loginError}</p> : ""}

              <div class='form-group text-end'>
                <Link to='/forgetPassword' className='forget--password'>
                  Forget password?
                </Link>
              </div>
              <div class='form-group text-start' style={{ marginTop: "-28px" }}>
                <Link to='/signup' className='Sign--up'>
                  Create Your Account ?
                </Link>
              </div>
              <div className='form-group text-center'>
                <span className='position-relative'>
                  <input type='submit' value='LOGIN' className='submit--btn' />
                  <FontAwesomeIcon icon='fa-solid fa-arrow-right' />
                </span>
              </div>
            </form>
            <div className='box--logo text-center mt-4'>
              <img src={sponsor_logo} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
