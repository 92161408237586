import { createSlice } from "@reduxjs/toolkit";

export const invitationSlice = createSlice({
  name: "invitation",
  initialState: {
    value: localStorage.getItem("invitation") ? JSON.parse(localStorage.getItem("invitation")) : [],
  },
  reducers: {
    setInvitation: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInvitation, setCount } = invitationSlice.actions;

export default invitationSlice.reducer;
