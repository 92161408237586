import React, { useEffect, useState } from "react";
import "./../assets/sass/style.css";
import "./FontAwesome/FontAwesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { loginAuth } from "../../features/setting/userSlice";
// import { useSelector, useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Avatar from "./../assets/img/Avatar.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { API_Link, MD5hash } from "./../commons/Constants";
import axios from "axios";
import Aside from "./aside";
import TopBar from "./topBar";
import { useDispatch, useSelector } from "react-redux";
// import { invitation } from "./../features/setting/invitationSlice";
import gif from "./../assets/img/spin.gif";
import { FaCheck, FaSpinner, FaTimes } from "react-icons/fa";
function Invitation() {
  const invitation1 = useSelector((state) => state.invitation.value);
  // console.log("reducer", invitation1);

  const navigate = useNavigate();
  const [invitations, setInvitations] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(new Array(invitations.length).fill(false));
  const [hiding, setHiding] = useState(new Array(invitations.length).fill(false));

  const [tick, setTick] = useState(new Array(invitations.length).fill(false));
  const [cross, setCross] = useState(new Array(invitations.length).fill(false));
  const [cardId, setCardId] = useState();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  const dispatch = useDispatch();
  useEffect(() => {
    getInvitation();
    // if (invitations.length < 1) {
    //   navigate("/dashboard");
    // }
  }, []);

  //GET INVITATIONS API CALL
  const getInvitation = async () => {
    await axios
      .get(`${API_Link}getInvitations`, {
        params: {
          status: "pending",
        },
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setInvitations(response.data.invitations);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //ACCEPT INVITATIONS API CALL
  const handleAcceptInvitation = async (event, id) => {
    let result = [];
    result[id] = !result[id];
    setIsLoading(result);
    const data = {
      id: event.target.value,
      status: "accept",
    };
    await axios
      .post(`${API_Link}setInvitationStatus`, data, {
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        // setErrorMessage(response.data.error_message);
        if (response.data.status === 1) {
          setIsLoading(false);
          let result = [];
          result[id] = !result[id];

          setTick(result);
          setTimeout(() => {
            setHiding(result);
            getInvitation();
          }, 1000);
          localStorage.removeItem("studyId");
          window.location.reload(false);
          // getReviewsList();
        }
        if (response.data.status === 0) {
          setIsLoading(false);
          let result = [];
          result[id] = !result[id];
          setCross(result);
          setTimeout(() => {
            setCross(false);
            getInvitation();
          }, 1000);
        }
      })
      .finally(() => {
        // setIsLoading(false);
        // getInvitation();
      });
  };

  //IGNORE INVITATIONS API CALL
  const handleIgnoreInvitation = async (event, id) => {
    let result = [];
    result[id] = !result[id];
    setIsLoading(result);
    const data = {
      id: event.target.value,
      status: "reject",
    };
    await axios
      .post(`${API_Link}setInvitationStatus`, data, {
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response.data);
        if (response.data.status === 1) {
          setIsLoading(false);
          let result = [];
          result[id] = !result[id];
          setTick(result);
          setTimeout(() => {
            setHiding(result);
            getInvitation();
          }, 1000);
        }
        if (response.data.status === 0) {
          setIsLoading(false);

          let result = [];
          result[id] = !result[id];
          setCross(result);
          setTimeout(() => {
            setCross(false);
            getInvitation();
          }, 1000);
        }
      })
      .finally(() => {});
  };
  //

  // if (invitations.length < 1) {
  //   navigate("/dashboard");
  // }
  // let countdata = invitations.invitations.length;
  // localStorage.setItem("invitation", invitations.length);
  // console.log("count", invitations.length);

  return (
    <div>
      <div className='main-wrapper'>
        <Aside extractname={"Extract Data"} extracttitle={"Prisma FlowChart"} extractpath={"Evidence Table"} invitations={invitations.length} />

        <div className='content-wrapper'>
          <TopBar />
          {user ? (
            <div className='page--content duplicate--percentage--page'>
              <div className='row'>
                <div className='col-lg-6'>
                  <h2 className='page--heading'>Invitation</h2>
                </div>
                <div className='col-lg-6 text-end'>
                  <p>
                    {" "}
                    PENDING INVITATION <span className='invitationCircle'>{invitations.length}</span>
                  </p>
                </div>
              </div>

              {/*  */}

              <div className='invitataion--div'>
                {invitations &&
                  invitations.length > 0 &&
                  invitations.map(({ id, review_id, stage, name, review }, index) => {
                    return (
                      <div className={hiding[id] ? "row  pending--invitation--row mb-1 invitation-loader" : "row  pending--invitation--row mb-1"}>
                        {errorMessage ? <span className='invitation--error-message'>{errorMessage}</span> : ""}
                        <div className='col-md-2 col-sm-12 text-center pe-2 '>
                          <img src={Avatar} alt='' />
                        </div>
                        <div className='col-md-10 col-sm-12   ps-3'>
                          <div className='row p-2'>
                            <div className='col-md-8 col-sm-12 invitation-name'>
                              <div className='row'>
                                <div className='col'>{name}</div>
                              </div>

                              <div className='row'>
                                <div className='col want--friend ms-1'>
                                  Study Title: <strong>{review.title}</strong>
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col want--friend ms-1'>
                                  Reviewer Stage:{" "}
                                  <strong>
                                    {stage === 1 && "Information Specialists for Data Searching"} {stage === 2 && "Reviewer for Data Screening"} {stage === 3 && "Reviewer for Full Text Review"} {stage === 4 && "Reviewer for Data Extraction"}
                                  </strong>
                                </div>
                              </div>
                            </div>
                            <div className='col-md-4 col-sm-12  text-center'>
                              <div>
                                {isLoading[id] ? (
                                  // <div>
                                  //   <img src={gif} alt='' style={{ width: "110px" }} />
                                  // </div>
                                  <span className='invitation-spinner-icon'>
                                    <img src={gif} alt='' style={{}} />
                                  </span>
                                ) : tick[id] ? (
                                  <span className='invitation-tick-icon'>
                                    <FaCheck />
                                  </span>
                                ) : cross[id] ? (
                                  <span className='invitation-cross-icon'>
                                    <FaTimes />
                                  </span>
                                ) : (
                                  <div>
                                    <div className='row'>
                                      <div className='col-sm-12  text-center '>
                                        <button id='Decline1' value={id} onClick={(e) => handleIgnoreInvitation(e, id)}>
                                          REJECT
                                        </button>
                                      </div>
                                    </div>
                                    <div className='row  mt-2 '>
                                      <div className='col-sm-12  text-center   '>
                                        <button id='Accept2' value={id} onClick={(e) => handleAcceptInvitation(e, id)}>
                                          ACCEPT
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* {tick[id] ? <span>tick</span> : cross[id] ? <span>cross</span> : ""} */}
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-1'></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/*  */}
            </div>
          ) : (
            navigate("/")
          )}
        </div>
      </div>
    </div>
  );
}

export default Invitation;
