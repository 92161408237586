import React from 'react'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'

const JudgementtablePage = () => {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
       <div>JudgementTable</div>
      </div>
    </div>
  )
}

export default JudgementtablePage