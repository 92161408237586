import React from 'react'
import Aside from '../../Components/aside'
import MaybeAbstractComponent from '../../Components/maybeAbstractComponent'
import TopBar from '../../Components/topBar'

const MaybeAbstractPage = () => {
    return (

        <div className='main-wrapper'>
            <Aside />
            <div className="content-wrapper">
                <TopBar />
                <MaybeAbstractComponent />
            </div>
        </div>

    )
}

export default MaybeAbstractPage