import React from "react";
import Extraction from "./../../Components/extraction"

function ExtractionPage() {
  return (
    <div>
      <Extraction/>
    </div>
  )
}

export default ExtractionPage