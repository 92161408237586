import React from 'react';
import logo from './../../assets/img/Template-logo.png';
import mark from './../../assets/img/mark.jpg';
import { useState, useEffect } from 'react';
import { MD5hash, API_Link } from '../../commons/Constants';
import axios from 'axios';
import pdficon from './../../assets/img/pdficon.png';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import 'react-quill/dist/quill.snow.css';
import ExecutiveSummary from './EditableReportComponents/ExecutiveSummary';
import Background from './EditableReportComponents/Background';
import Feature from './EditableReportComponents/Feature';
import Objective from './EditableReportComponents/Objective';
import Method from './EditableReportComponents/Method';
import Result from './EditableReportComponents/Result';
import Conclusion from './EditableReportComponents/Conclusion';
import EvidenceTableSingle from '../evidenceTableSingle';
function HTA() {
  const [isEditable, setIsEditable] = useState(false);
  const [data, setData] = useState([]);
  const [reviewData, setreviewData] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [punchline, setPunchline] = useState([]);
  const [picoSearchs, setPicoSearches] = useState([]);
  const [abstract, setAbstract] = useState([]);
  const [prismaValue, setPrismaValue] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [n, setn] = useState([]);
  const [year, setYear] = useState([]);
  const [title, setTitle] = useState([]);
  const [pages, setPages] = useState([]);
  const [pmid, setPmid] = useState([]);
  const [judgement, setJudgment] = useState([]);
  const [rct, setRct] = useState([]);
  const [findings, setFindings] = useState([]);
  const [multidata, setMultidata] = useState([]);
  const [maintitle, setMaintitle] = useState([]);
  const [title1, setTitle1] = useState([]);
  const [biasesData, setBiasesData] = useState([]);
  const [judgementData, setJudgmentData] = useState([]);
  const [picoData, setPicoData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [finalData, setFinalData] = useState([]);

  const navigate = useNavigate();
  // styling
  const styles = {
    tdStyling: {
      backgroundColor: '#eaefef',
      padding: '5px',
      border: '1px solid #000',
      verticalAlign: 'top',
    },
    thStyling: {
      border: '1px solid black',
      textAlign: 'center',
      color: '#219ebc',
      backgroundColor: '#d9e2f3',
    },
    tableStyling: {
      borderCollapse: 'collapse',
      width: '100%',
      padding: '10px',
      height: 'auto',
      backgroundColor: '#00b0f0',
      color: 'white',
    },
  };
  //
  const getReviews = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;
    const studyId = localStorage.getItem('studyId');
    var config = {
      method: 'get',
      url: `${API_Link}getReviews`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
      params: {
        id: studyId,
      },
    };
    axios(config)
      .then(function (response) {
        const APIresponse = response.data.reviews;
        setMaintitle(APIresponse);
        const title1 = APIresponse[0].title;
        setTitle1(title1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getReviewData = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;
    const studyId = localStorage.getItem('studyId');

    var config = {
      method: 'get',
      url: `${API_Link}getExtractedReportJson?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    axios(config)
      .then(function (response) {

        const Response1 = response.data.data[0].report_json;
        const Response2 = JSON.parse(Response1);
        const ResponseData = Response2.article_data[0];
        setreviewData(ResponseData);
        const abstract = ResponseData.dubious.abstract;
        setAbstract(abstract);
        // const Authors = ResponseData.dubious.authors;
        // setAuthors(Authors)
        const interventions = ResponseData.ml.pico_span.interventions;
        setInterventions(interventions);
        const outcomes = ResponseData.ml.pico_span.outcomes;
        setOutcomes(outcomes);
        // const participants = ResponseData.ml.pico_span.population;
        // setParticipants(participants);

        const participants = articleData.articles;
        setParticipants(participants);
        const punchline = ResponseData.ml.punchlines;
        setPunchline(punchline);
        const n = ResponseData.ml.sample_size;
        setn(n);
        // const Author = ResponseData.dubious.authors[0].forename;
        // setAuthor(Author)
        const year = ResponseData.dubious.year;
        setYear(year);
        const title = ResponseData.dubious.title;
        setTitle(title);
        const pages = ResponseData.dubious.pages;
        setPages(pages);
        const pmid = ResponseData.dubious.pmid;
        setPmid(pmid);
        const judgement = ResponseData.ml.bias;
        setJudgment(judgement);
        const rct = ResponseData.ml.rct;
        // console.log("RCT:", rct)
        setRct(rct);
        const findings = ResponseData.ml.finding_direction;
        setFindings(findings);
        const multidata = Response2.article_data;
        setMultidata(multidata);
        // console.log("AUTHOES:  ", multidata[1].dubious.authors[0].forename)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getReviewEvidence = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;
    const studyId = localStorage.getItem('studyId');

    var config = {
      method: 'get',
      url: `${API_Link}getReviewEvidence?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const APIresponse = response.data.evidences;
        setData(APIresponse);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    const updatedData = articleData.map((item, i) => Object.assign({}, item, data[i]));
    setFinalData(updatedData);
  }, [articleData, data]);


  const getPicoSearches = () => {
    let review_id = localStorage.getItem('studyId');
    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;
    var config = {
      method: 'get',
      url: `${API_Link}getPicoSearches?review_id=${review_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        setPicoSearches(
          response.data.picoSearches.filter(
            (item) => item.database !== 'RISimport'
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const PrismaComponent = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const studyId = localStorage.getItem('studyId');
    const token = user.token;
    var config = {
      method: 'get',
      url: `${API_Link}getPrismaCounts?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        setPrismaValue(resData.data.counts);
        // console.warn('reasons', resData.data.counts[0].excluded);
        setReasons(resData.data.counts[0].excluded);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getReviewBiases = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const studyId = localStorage.getItem('studyId');
    const token = user.token;
    var config = {
      method: 'get',
      url: `${API_Link}getReviewBiases?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        setBiasesData(resData.data.biases);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getReviewArticles = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    const studyId = localStorage.getItem('studyId')

    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles?review_id=${studyId}&fulltext=Yes&exclusion=No`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash
      },

    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        console.warn('resData', resData)
        const notNull = resData.data.articles.filter((article) => article.current_stage > 3 && article.file_path !== null || article.source !== null);
        const result = notNull.map(({ id, title, authors, journal }) => ({ id, title, authors, journal }));
        setArticleData(result);
        // const finalData1 = articleData.map((item, i) => Object.assign({}, item, data[i]));


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getReviewJudgements = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const studyId = localStorage.getItem('studyId');
    const token = user.token;
    var config = {
      method: 'get',
      url: `${API_Link}getReviewJudgements?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;

        // console.warn('warn', resData.data);
        setJudgmentData(resData.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getReviewPico = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const studyId = localStorage.getItem('studyId');
    const token = user.token;

    var config = {
      method: 'get',
      url: `${API_Link}getReviewPicoSentences?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    localStorage.setItem('pdfAId', '');
    axios(config)
      .then(function (response) {
        const resData = response;
        setPicoData(resData.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'color',
    'background',
    'link',
    'image',
  ];
  //
  useEffect(() => {
    // get Review Biases
    getReviewBiases();
    // get Judgements
    getReviewJudgements();
    getReviewPico();
    getReviewEvidence();
    getReviewArticles();
    getReviewData();
    getPicoSearches();
    PrismaComponent();
    getReviews();
    getReportJson();
  }, []);
  const judgements = judgementData.judgements;
  const picos = picoData.picos;
  // console.log('picosss', judgements);

  const headings1 = [
    { id: 1, headingText: 'Random sequence generation' },
    { id: 2, headingText: 'Allocation concealment' },
    { id: 3, headingText: 'Blinding of participants and personnel' },
    { id: 4, headingText: 'Blinding of outcome assessment' },
    { id: 5, headingText: 'Incomplete outcome data' },
    { id: 6, headingText: 'Selective reporting' },
  ];
  const headings2 = [
    { id: 1, headingText: 'Participants' },
    { id: 2, headingText: 'Intervention' },
    { id: 3, headingText: 'OutComes' },
  ];
  const output = [];
  if (judgementData.judgements) {
    var topLevelKeys = Object.keys(judgementData.judgements);
  }
  if (picoData.picos) {
    var picoIds = Object.keys(picoData.picos);
    // console.log('pico ids', picoIds);
  }
  //
  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleSaveClick = () => {
    setIsEditable(false);
    console.log(title1);
  };

  const handleEditorChange = (value) => {
    setTitle1(value);
  };
  ///////////////////Report Editing Section\\\\\\\\\\\\|
  const [jsonForReportEditing, setJsonForReportEditing] = useState({
    executive_summary: [],
    background: [],
    objective: [],
    feature: [],
    method: [],
    result: [],
    conclusion: [],
  });

  const getReportJson = () => {
    axios.post('http://misr.pacemis.com:78/final_report').then((response) => {
      const jsonData = response.data;
      const newJson = jsonData[0];
      setJsonForReportEditing(newJson);
    });
  };
  // console.log('json for report editing', jsonForReportEditing);
  //

  console.log('datadata', data)
  return (
    <div className='hta-report-continer'>
      {output}
      <p style={{ textAlign: 'right' }}>
        <img src={logo} />{' '}
      </p>
      <div style={{ paddingLeft: '10%' }}>
        <div class='hta-report-title'>HEALTH TECHNOLOGY ASSESSMENT REPORT</div>

        <div className='hta-study-title'>{title1}</div>
        <div
          style={{
            textAlign: 'left',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#767171',
            marginBottom: '30px',
          }}
        >
          Malaysian Health Technology Assessment Section (MaHTAS)<br></br>
          Medical Development Division
          <br />
          Ministry of Health Malaysia
          <br />
          006/2020
        </div>
      </div>
      <p style={{ textAlign: 'right' }}>
        <img src={mark} />{' '}
      </p>
      <div
        style={{
          textAlign: 'start',
          backgroundColor: 'lightgray',
          padding: '20px',
          paddingBottom: '20px',
        }}
      >
        <strong>DISCLAIMER</strong>
        <br />
        This Health Technology Assessment has been developed from analysis,
        interpretation and synthesis of scientific research and/or technology
        assessment conducted by other organizations available at the time of
        development. It also incorporates, where available, Malaysian data, and
        information provided by experts to the Ministry of Health Malaysia.
        While effort has been made to do so, this document may not fully reflect
        all scientific research available. Other relevant scientific findings
        may have been reported since completion of the review. MaHTAS is not
        responsible for any errors, injury, loss or damage arising or relating
        to the use (or misuse) of any information, statement or content of this
        document or any of the source materials..
        <br />
        <p>
          Please contact htamalaysia@moh.gov.my if further information is
          required.{' '}
        </p>
      </div>
      <div
        style={{ textAlign: 'left', marginTop: '20px', marginBottom: '30px' }}
      >
        <p>
          <b>Published by:</b>
        </p>
        <p>Malaysian Health Technology Assessment Section (MaHTAS)</p>
        <p>Medical Development Division </p>
        <p>Ministry of Health Malaysia</p>
        <p>Level 4, Block E1, Precinct 1</p>
        <p>Government Office Complex</p>
        <p>62590, Putrajaya</p>
        <p>Tel: 603 8883 1229</p>
        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          Available online via the official Ministry of Health Malaysia website:
          http://www.moh.gov.my
        </div>
        <div style={{ padding: '10px', height: 'auto' }}>
          <strong>ISBN:</strong>
        </div>
        <div style={{ padding: '10px', height: 'auto' }}>
          <strong>eISBN:</strong>
        </div>
        <div style={{ padding: '10px', height: 'auto' }}>
          <strong>Copyright:</strong> The copyright owner of this publication is
          the Malaysian Health Technology Assessment Section (MaHTAS). Content
          may be reproduced in any number of copies and in any format or medium
          provided that a copyright acknowledgement to MaHTAS is included and
          the content is not changed, not sold, nor used to promote or endorse
          any product or service and not used in inappropriate or misleading
          context.
        </div>
        <br />
        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          This HTA report was approved in HTA&CPG Council Meeting Bil.2/2018 (4
          November 2018).
        </div>
        <div
          style={{
            padding: '10px',
            height: '120px',
            backgroundColor: '#8bc6fc',
          }}
        >
          <strong>SUGGESTED CITATION:</strong> Atikah S, Hanin Farhana K,
          Aidatul Azura AR, Syful Azlie MF and Junainah S. Bone targeting agents
          in preventing skeletal related events for metastatic cancers of solid
          tumours and economic evaluation: Health Technology Assessment.
          Malaysia: Malaysian Health Technology Assessment Section (MaHTAS);
          2019. 179 p. Report No.: 02/2018. eISBN: ……..
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: '100px',
            backgroundColor: '#8bc6fc',
          }}
        >
          <strong>DISCLOSURE:</strong> The author of this report has no
          competing interest in this subject and the preparation of this report
          is entirely funded by the Ministry of Health Malaysia
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
            marginTop: '20px',
          }}
        >
          <strong>Abstract</strong>{' '}
        </div>{' '}
        <br />
        <p
          style={{
            textAlign: 'justify',
            fontFamily: 'sans-serif',
            fontSize: '16px',
          }}
        >
          {abstract}
        </p>
        <br />
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>AUTHORS</strong>{' '}
        </div>
        <br />
       
        {multidata.map((item) => {
          return (
            <>
              <p>
                {/* <b>
                  {item.dubious.length === 0 ? (
                    <>
                      {item.pubmed.authors[0].forename} {item.pubmed.year}
                    </>
                  ) : (
                    item.dubious.authors[0].forename
                  )}
                  {item.dubious.year}
                </b> */}
                {item.pubmed.length === 0 ? "" : item.pubmed.authors[0].forename} {item.pubmed.year}
                {item.dubious.length === 0 ? "" : item.dubious.authors[0].forename} {item.dubious.year}
              </p>
              <p>Senior Principle Assistant Director</p>
              <p>Malaysian Health Technology Assessment Section (MaHTAS)</p>
              <p>Medical Development Division, Ministry of Health Malaysia</p>
            </>
          );
        })}
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>INFORMATION SPECIALIST</strong>
        </div>
        <br />
        <p>Information Specialist 1</p>
        <p>Medical Assistant Officer</p>
        <p>Malaysian Health Technology Assessment Section (MaHTAS)</p>
        <p>Medical Development Division, Ministry of Health Malaysia</p>
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> EXPERT COMMITTEE (alphabetical order)</strong>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> CHARACTERISTICS OF STUDIES</strong>
        </div>
        <br />
        {biasesData.map((item) => {
          return (
            <>
              <p>
                <strong>{item.author_year}</strong>
              </p>
              <table style={{ border: '1px solid black', padding: '2px' }}>
                <tr style={{ border: '1px solid black', padding: '2px' }}>
                  <td
                    style={{
                      color: '#219ebc',
                      backgroundColor: '#d9e2f3',
                      padding: '5px',
                    }}
                  >
                    Participants
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textAlign: 'justify',
                      padding: '5px',
                    }}
                  >
                    {item.population}
                  </td>
                </tr>
                <tr style={{ border: '1px solid black', padding: '2px' }}>
                  <td
                    style={{
                      color: '#219ebc',
                      backgroundColor: '#d9e2f3',
                      padding: '5px',
                    }}
                  >
                    Interventions
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textAlign: 'justify',
                      padding: '5px',
                    }}
                  >
                    {item.interventions}
                  </td>
                </tr>
                <tr style={{ border: '1px solid black', padding: '2px' }}>
                  <td
                    style={{
                      color: '#219ebc',
                      backgroundColor: '#d9e2f3',
                      padding: '5px',
                    }}
                  >
                    Outcomes
                  </td>
                  <td
                    style={{
                      border: '1px solid black',
                      textAlign: 'justify',
                      padding: '5px',
                    }}
                  >
                    {item.outcomes}
                  </td>
                </tr>
              </table>
            </>
          );
        })}
        {topLevelKeys?.map((articleId) => {
          return (
            <table className='table table-bordered table-striped mt-4 judgement-table'>
              <thead>
                <tr>
                  <th style={styles.thStyling}>Bias</th>
                  <th style={styles.thStyling}>Judgment</th>
                  <th style={styles.thStyling}>Support for judgement</th>
                </tr>
              </thead>
              <tbody>
                {headings1?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.headingText}</td>
                      <td>High/Unclear</td>
                      <td>
                        {judgements[articleId][index + 1]?.map(
                          (judgement, idx) => {
                            return (
                              <div className=''>
                                <span>{judgement.support_text}</span>
                                <Link
                                  className='Editpdf-icon'
                                  to={`/biasbot/${articleId}`}
                                >
                                  <img src={pdficon} alt='' height='17px' />
                                  <span className='tooltiptextannotation'>
                                    Jump to Annotation
                                  </span>
                                </Link>
                              </div>
                            );
                          }
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
        {/* Pico Bot */}
        {picoIds?.map((articleId) => {
          return (
            <table className='table table-bordered table-striped mt-4 judgement-table'>
              <thead>
                <tr>
                  <th style={styles.thStyling}>PICO</th>
                  <th style={styles.thStyling}>Judgment</th>
                  <th style={styles.thStyling}>Support for Pico</th>
                </tr>
              </thead>
              <tbody>
                {headings2?.map((item, index) => {
                  return (
                    <tr key={item.headingText}>
                      <td>{item.headingText}</td>
                      <td>High/Unclear</td>
                      <td>
                        {picos[articleId][index + 1]?.map((pico, idx) => {
                          return (
                            <div key={idx}>
                              <span>{pico.pico_text}</span>
                              <Link
                                to={`/picobot/${articleId}`}
                                className='Editpdf-icon'
                              >
                                <img src={pdficon} alt='' height='17px' />
                                <span className='tooltiptextannotation'>
                                  Jump to Annotation
                                </span>
                              </Link>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        })}
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>EXTERNAL REVIEWERS (alphabetical order)</strong>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> ACKNOWLEDGEMENT </strong>
        </div>
        <br />
        <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          The authors of this Health Technology Assessment Report would like to
          express their gratitude and appreciation to the following for their
          contribution and assistance:
        </div>
        <ul>
          <li>
            Health Technology Assessment and Clinical Practice Guidelines
            Council
          </li>
          <li>Technical Advisory Committee for Health Technology Assessment</li>
        </ul>
        {/* Editable Report Section */}
        <ExecutiveSummary
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Background
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Objective
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Feature
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Method
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Result
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <Conclusion
          jsonForReportEditing={jsonForReportEditing}
          setJsonForReportEditing={setJsonForReportEditing}
        />
        <div style={{ padding: '10px', height: 'auto' }}>
          <strong>Recommendation</strong>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>Trail Summaries</strong>
        </div>
        <br />
        <table
          className='table table-striped blue-bg-th-table'
          style={{
            borderCollapse: 'collapse',
            width: '100%',
            border: '1px solid black',
            marginTop: '15px',
          }}
        >
          <tr style={{ border: '1px solid black' }}>
            <th style={styles.thStyling}> n </th>
            <th style={styles.thStyling}> Participants </th>
            <th style={styles.thStyling}> Interventions </th>
            <th style={styles.thStyling}> Outcomes </th>
            <th style={styles.thStyling}> punchline </th>
            <th style={styles.thStyling}> finding </th>
          </tr>
          {
          interventions?.length !== 0 &&
            participants?.length !== 0 &&
            outcomes?.length !== 0 &&
            punchline?.length !== 0 ? (
            <td colspan='9' className='text-center bg-danger text-light'>
              {' '}
              <strong>You don't have any Trail Summaries</strong>{' '}
            </td>
          ) : (
            articleData.map((item) => {
              return (
                // <tr>
                //   <td style={styles.tdStyling}>{item.ml.sample_size} </td>
                //   <td style={styles.tdStyling}>
                //     {item.ml.pico_span.population.map((inter) => inter)}
                //   </td>
                //   <td style={styles.tdStyling}>
                //     {item.ml.pico_span.interventions.map((inter) => inter)}
                //   </td>
                //   <td style={styles.tdStyling}>
                //     {item.ml.pico_span.outcomes.map((inter) => inter)}
                //   </td>
                //   <td style={styles.tdStyling}>{item.ml.punchlines}</td>
                //   <td style={styles.tdStyling}>{item.ml.finding_direction}</td>
                // </tr>
                 <tr>
                 <td style={styles.tdStyling}><strong>Title:</strong> {item.title} <br></br> <strong>Authors:</strong> {item.authors}</td>
                 <td style={styles.tdStyling}>
                
                 </td>
                 <td style={styles.tdStyling}>
                   
                 </td>
                 <td style={styles.tdStyling}>
                  
                 </td>
                 <td style={styles.tdStyling}></td>
                 <td style={styles.tdStyling}></td>
               </tr>
              );
            })
          )}
        </table>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> 1.0 BACKGROUND</strong>
        </div>
        <br />
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> 2.0 TECHNICAL FEATURES</strong>
        </div>
        <br />
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> 3.0 POLICY QUESTION</strong>
        </div>
        <br />
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> 4.0 OBJECTIVE</strong>
        </div>
        <br />
        <br />
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              5.0
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              METHODS
            </th>
          </tr>
        </table>
        <br />
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tr style={{ textAlign: 'left', padding: ' 8px', width: '100px' }}>
            <th>5.1 </th>
            <th>SEARCHING</th>
          </tr>
          <br />
          <tr>
            <th> 5.2 </th>
            <th style={{ width: '95%', textAlign: 'left' }}>SELECTION </th>
          </tr>
          <br />
        </table>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              6.0
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              RESULTS
            </th>
          </tr>
        </table>
        <br />
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tr style={{ textAlign: 'left', padding: ' 8px', width: '100px' }}>
            <th>6.1 </th>
            <th>EFFICACY/ EFFECTIVENESS</th>
          </tr>
          <br />
          <tr>
            <th> 6.2 </th>
            <th style={{ width: '95%', textAlign: 'left' }}>SAFETY </th>
          </tr>
          <br />
          <tr>
            <th> 6.3 </th>
            <th style={{ width: '95%', textAlign: 'left' }}>
              ECONOMIC IMPLICATION{' '}
            </th>
          </tr>
          <br />
          <tr>
            <th> 6.4 </th>
            <th style={{ width: '95%', textAlign: 'left' }}>ORGANISATIONAL </th>
          </tr>
          <br />
          <tr>
            <th> 6.5 </th>
            <th style={{ width: '95%', textAlign: 'left' }}>
              SOCIAL/ ETHICAL / LEGAL{' '}
            </th>
          </tr>
        </table>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              {' '}
              7.0{' '}
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              DISCUSSION
            </th>
          </tr>
        </table>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              {' '}
              8.0
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              CONCLUSION
            </th>
          </tr>
        </table>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              {' '}
              9.0{' '}
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              RECOMMENDATION
            </th>
          </tr>
        </table>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              {' '}
              10.0{' '}
            </th>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              REFERENCES{' '}
            </th>
          </tr>
        </table>
        <br />
        {/* <div >{Author}, {title} {year}, {pages} PMID: {pmid}</div><br /> */}
        <div>
          {multidata.map((item, index) => {
            return (
              <>
                {item.dubious.length === 0 ? (
                  <p
                    style={{ justifyContent: 'space-between', padding: '2px' }}
                  >
                    {index + 1}. <span style={{ marginLeft: '5px' }}></span>
                    {item.pubmed.length === 0
                      ? ''
                      : item.pubmed.authors[0].forename}{' '}
                    ,
                    {item.pubmed.length === 0
                      ? ''
                      : item.pubmed.authors[0].lastname}
                    ,{item.pubmed.title} , {item.pubmed.pages}, PMID:{' '}
                    {item.pubmed.pmid}
                  </p>
                ) : (
                  <p
                    style={{ justifyContent: 'space-between', padding: '2px' }}
                  >
                    {index + 1}. <span style={{ marginLeft: '5px' }}></span>
                    {item.dubious.length === 0
                      ? ''
                      : item.dubious.authors[0].forename}{' '}
                    ,
                    {item.dubious.length === 0
                      ? ''
                      : item.dubious.authors[0].lastname}
                    ,{item.dubious.title} , {item.dubious.pages}, PMID:{' '}
                    {item.dubious.pmid}
                  </p>
                )}
              </>
            );
          })}
        </div>
        <br />
        <table style={styles.tableStyling}>
          <tr>
            <th
              style={{
                width: '95%',
                textAlign: 'left',
                backgroundColor: '#00b0f0',
                padding: '10px',
              }}
            >
              Risk of bias table{' '}
            </th>
          </tr>
        </table>
        <table
          style={{
            width: '800px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderCollapse: 'collapse',
            border: 'none',
            padding: 0,
            marginTop: '10px',
          }}
        >
          <tr class='bias-header-row'>
            <th
              style={{
                verticalAlign: 'bottom',
                textAlign: 'left',
                margin: '20px',
              }}
            >
              trial
            </th>
            <th style={{ verticalAlign: 'bottom', textAlign: 'left' }}>
              design
            </th>

            {judgement.map((item) => {
              return (
                <th style={{ height: '70px', whiteSpace: 'normal' }}>
                  <div
                    style={{
                      width: '100px',
                      fontSize: '14px',
                      padding: '10px',
                    }}
                  >
                    <span>{item.domain}</span>
                  </div>
                </th>
              );
            })}
          </tr>
          {multidata.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td>
                    {/* {item.dubious.length === 0 ? (
                      <p>
                        {item.pubmed.authors[0].forename} {item.pubmed.year}
                      </p>
                    ) : (
                      item.dubious.authors[0].forename
                    )}{' '}
                    {item.dubious.year} */}
                    {item.pubmed.length === 0 ? "" : item.pubmed.authors[0].forename} {item.pubmed.year}
                    {item.dubious.length === 0 ? "" : item.dubious.authors[0].forename} {item.dubious.year}
                  </td>
                  <td style={{ padding: '2px' }}>
                    {item.ml.rct.is_rct === false ? <p>Not Rct</p> : <p>Rct</p>}
                  </td>
                  {item.ml.bias.map((bi) => {
                    return bi.judgement === 'high/unclear' ? (
                      <td
                        style={{
                          backgroundColor: 'red',
                          color: 'white',
                          textAlign: 'center',
                          width: '40px',
                        }}
                      >
                        ?
                      </td>
                    ) : (
                      <td
                        style={{
                          backgroundColor: 'green',
                          color: 'white',
                          textAlign: 'center',
                          width: '40px',
                        }}
                      >
                        +
                      </td>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </table>
        <br />
        <div>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th>Bias</th>
                <th>Judgement</th>
                <th>Support for judgement</th>
              </tr>
            </thead>
          </table>
        </div>
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>
            APPENDIX 1: HIERARCHY OF EVIDENCE FOR EFFECTIVENESS STUDIES/
            DIAGNOSTIC STUDIES{' '}
          </strong>
        </div>
        <br />
        <div style={{ backgroundColor: 'lightgray', padding: '20px' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tr>
              <th></th>
              <th style={{ width: '95%', textAlign: 'center' }}>
                {' '}
                DESIGNATION OF LEVELS OF EVIDENCE
              </th>
            </tr>
            <br />
            <tr>
              <th>1</th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                Evidence obtained from at least one properly designed randomised
                controlled trial.
              </th>
            </tr>
            <br />
            <tr>
              <th>II-I</th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                Evidence obtained from well-designed controlled trials without
                randomisation.
              </th>
            </tr>
            <br />
            <tr>
              <th>II-2 </th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                Evidence obtained from well-designed cohort or case-control
                analytic studies, preferably from more than one centre or
                research group.
              </th>
            </tr>
            <br />

            <tr>
              <th>II-3 </th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                Evidence obtained from multiple time series with or without the
                intervention. Dramatic results in uncontrolled experiments (such
                as the results of the introduction of penicillin treatment in
                the 1940s) could also be regarded as this type of evidence.
              </th>
            </tr>
            <br />

            <tr>
              <th>III</th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                Opinions or respected authorities, based on clinical experience;
                descriptive studies and case reports; or reports of expert
                committees.
              </th>
            </tr>
            <br />
            <br />
            <tr>
              <th></th>
              <th style={{ width: '95%', textAlign: 'left' }}>
                {' '}
                SOURCE: US/CANADIAN PREVENTIVE SERVICES TASK FORCE (Harris 2001)
              </th>
            </tr>
            <br />
          </table>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> APPENDIX 2: HEALTH TECHNOLOGY ASSESSMENT PROTOCOL </strong>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> APPENDIX 3: SEARCH STRATEGY </strong>
        </div>
        <br />
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>APPENDIX 4: EVIDENCE TABLE </strong>
        </div>
        <br />
      
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong> APPENDIX 5: LIST OF EXCLUDED STUDIES </strong>
        </div>
        <br />
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
          <tr>
            <th></th>
            <th
              style={{ width: '97%', textAlign: 'left', fontWeight: 'normal' }}
            >
              {/* 1. Liu L, Huang QM, Liu QG, et al. Evidence for dry needling in
              the management of myofascial trigger points associated with low
              back pain: a systematic review and meta-analysis. Arch Phys Med
              Rehabil. 2018;99(1):144-152. */}
            </th>
          </tr>
          <br />
        </table>
        <div
          style={{
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
          }}
        >
          <strong>PICO Result </strong>
        </div>
        <div>
          <table>
            {picoSearchs.length > 0 ? (
              picoSearchs.map((search, index) => (
                <tr key={index}>
                  {' '}
                  <strong>
                    <br />
                    <td>
                      {index + 1} {search.database}
                    </td>
                    <td>{search.population}</td>
                    <td>{search.intervention}</td>
                    <td>{search.comparison}</td>
                    <td>{search.outcome}</td>
                    <td>{search.included}</td>
                    <td>{search.total_records}</td>
                  </strong>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className='text-center text-muted'>
                  You have no pico search History
                </td>
              </tr>
            )}
          </table>
          <p>
          <b>Evidence Table :</b>
        </p>
        <p>
          <b>Question :</b>
        </p>
        <EvidenceTableSingle />
        <br />
        </div>
        <br />
        <div>
          {prismaValue &&
            prismaValue.length > 0 &&
            prismaValue.map(() => {
              return (
                <>
                  <div
                    style={{
                      padding: '10px',
                      height: 'auto',
                      backgroundColor: '#00b0f0',
                      color: 'white',
                    }}
                  >
                    <strong>Prisma Flowchart</strong>
                  </div>
                  <div className='container'>
                    <div className='row pt-5'>
                      <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                        <div className='identification'>
                          <p className='text'>Identification</p>
                        </div>
                      </div>
                      <div className='col-col-sm-12 col-md-9 col-lg-10'>
                        <div className='row mb-3'>
                          <div className='col-sm-12 col-md-12 col-lg-6 mt-2 '>
                            <div className='Number1972'>
                              Number of records identified through electronic
                              databases searching (n=
                              {prismaValue[0].onlineSearch})
                            </div>
                          </div>

                          <div className='col-sm-12 col-md-12 col-lg-6 mt-2'>
                            <div className='Number53'>
                              Number of additional records identified from other
                              sources (n={prismaValue[0].otherSources})
                            </div>
                          </div>
                        </div>

                        <div className='row '>
                          <div className='col-6'>
                            <span className='arrow-icon-prisma'>
                              {/* <i className='bi bi-arrow-down-short'></i> */}
                              ↓
                            </span>
                          </div>
                          <div className='col-6'>
                            <span className='arrow-icon-prisma'>↓</span>
                          </div>
                        </div>

                        <div className='row pt-2 mb-3 '>
                          <div className='col-12'>
                            <div className='Num2055'>
                              Number of records after duplicates removed (n=
                              {prismaValue[0].abstract_articles_count})
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12 col-md-3'></div>
                      <div className='col-sm-12 col-md-9 col-lg-10'>
                        <span className='arrow-icon-prisma'>↓</span>
                      </div>
                    </div>

                    <div className='row '>
                      <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                        <div className='Screening'>
                          <p className='text'>Screening</p>
                        </div>
                        <div className='row pt-2'>
                          <div className='col-sm-12 col-md-3 col-lg-2 '>
                            <div className='included'>
                              <p className='text'>included</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='col-col-sm-12 col-md-9 col-lg-10'>
                        <div className='row mb-3'>
                          <div className='col-sm-12 col-md-12 col-lg-1 '></div>
                          <div className='col-sm-12 col-md-12 col-lg-5 mt-2'>
                            <div className='num_1925'>
                              Number of records screened (n=
                              {prismaValue[0].abstract_articles_count})
                            </div>
                          </div>

                          <div className='col-sm-12 col-md-12 col-lg-1'>
                            <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                              {/* <i className='bi bi-arrow-down-short'></i> */}
                              →
                            </span>
                          </div>

                          <div className='col-sm-12 col-md-12 col-lg-5 mt-2 '>
                            <div className='num1200'>
                              Number of records excluded (n=
                              {prismaValue[0].abstract_articles_count -
                                prismaValue[0].fulltext_articles_count}
                              )
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-7'>
                            <span className='arrow-icon-prisma'>
                              {/* <i className='bi bi-arrow-down-short'></i> */}
                              ↓
                            </span>
                          </div>
                        </div>

                        <div className='row '>
                          <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '></div>
                          <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5 '>
                            <div className='row mb-3'>
                              <div className='col'>
                                <div className='num685'>
                                  Number of full-text articles assessed for
                                  eligibility (n=
                                  {prismaValue[0].fulltext_articles_count})
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                              <span className='arrow-icon-prisma'>↓</span>
                            </div>
                            <div className='row '>
                              <div className='col'>
                                <div className='mt-2 yelllowbox'>
                                  Number of full-text articles included in
                                  qualitative synthesis (n=
                                  {prismaValue[0].included_articles_count})
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '>
                            <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                              →
                            </span>
                          </div>
                          <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5'>
                            <div className='num432'>
                              Number of full-text articles excluded (n=
                              {prismaValue[0].fulltext_articles_count -
                                prismaValue[0].included_articles_count}
                              ) <br></br> Reasons:
                              {reasons &&
                                reasons
                                  .filter((exclusion) => exclusion.stage === 3)
                                  .map(({ purpose, total }, index) => {
                                    return (
                                      <small>
                                        <br></br>
                                        {purpose.label} (n = {total})
                                      </small>
                                    );
                                  })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default HTA;
