import React from 'react'
import AddUserComponent from './addUserComponent'
import Aside from './aside'
import TopBar from './topBar'

const AddUser = () => {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <AddUserComponent />
      </div>
    </div>
  )
}

export default AddUser