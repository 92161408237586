import React from "react";
import Aside from "../../Components/aside";
import TopBar from "../../Components/topBar";
import IncludedDuplicateComponent from "../../Components/includedDuplicateComponent";

const IncludedDuplicatePage = () => {
  return <IncludedDuplicateComponent />;
};

export default IncludedDuplicatePage;
