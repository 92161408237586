import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { API_Link, MD5hash } from "../commons/Constants";
import ButtonComponent from "./ButtonComponent";

const AddLevelComponent = () => {
  const [levels, setLevels] = useState({
    level: "",
    description: "",
  });
  const { level, description } = levels;
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;

  const handleChange = (e) => {
    setLevels({ ...levels, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log("data", levels);
  };
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-4'>
          <h1 className='page--heading'>Add Level</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form--body max-width-1200'>
            <form className='input--form' autocomplete='off'>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Title </label>
                <div className='col-sm-9'>
                  <input type='text' className='form-control' placeholder='' name='level' value={level} onChange={(event) => handleChange(event)} />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Description</label>
                <div className='col-sm-9'>
                  <input type='text' className='form-control' placeholder='' name='description' value={description} onChange={(event) => handleChange(event)} />
                </div>
              </div>

              <div className='text-end row'>
                <div className='col-sm-12'>
                  <ButtonComponent text={"Create new level"} handleSubmit={handleSubmit} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLevelComponent;
