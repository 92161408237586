import React from "react";
import PrismaComponent from './../../Components/prismaComponent'

import "./../../assets/sass/style.css";
import Aside from "../../Components/aside";
import TopBar from "../../Components/topBar";


const PrismaPage = () => {
  return <>
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <PrismaComponent />
      </div>
    </div>
  </>
};

export default PrismaPage;
