import React from 'react'
import Logo from "./../assets/img/logo.png"

function Header({mainHeading,subHeading,logo}) {
  return (
    <div>
      <div className='main--logo'>
        <img src={logo} className="img-fluid" alt='' />
      </div>
      <div className='main--text'>
        <h1 className='main--heading'> {mainHeading}</h1>
        <div className='sub--heading'> {subHeading}</div>
      </div>
    </div>
  )
}

export default Header
