import React from 'react'
import Aside from '../../Components/aside'
import EditUserComponent from '../../Components/editUserComponent'
import TopBar from '../../Components/topBar'

const EditUser = () => {
    return (
        <div className="main-wrapper">
            <Aside />
            <div className="content-wrapper">
                <TopBar />
                <EditUserComponent/>
            </div>
        </div>
    )
}

export default EditUser