import React from 'react'

function Footer({associated_link,associated_name,associated_verb,copyright_text,}) {
  return (
    <div>
       <div className="bottom">
        <p>{associated_verb} <a href={associated_link} target="_blank">{associated_name}</a></p>
        <p>&copy; {copyright_text} All Rights Reserved.</p>
    </div>
    </div>
  )
}

export default Footer
