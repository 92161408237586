import React from 'react'
import AdminActionsComponent from '../../Components/adminActionsComponent'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'


const AdminActions = () => {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <AdminActionsComponent/>
      </div>
    </div>

  )
}

export default AdminActions