import React from 'react'

const MaybeComponent = () => {
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-4'>
          <h1 className='page--heading'>
            Maybe Abstracts
          </h1>
        </div>
      </div>
    </div>
  )
}

export default MaybeComponent