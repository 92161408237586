import React, { useEffect, useState } from 'react'
import { API_Link, MD5hash } from '../../commons/Constants'
import icon1 from "./../../assets/img/icon/icon1.svg"
import icon2 from "./../../assets/img/icon/icon2.svg"
import icon3 from "./../../assets/img/icon/icon3.svg"
import icon4 from "./../../assets/img/icon/icon4.svg"
import icon5 from "./../../assets/img/icon/icon5.svg"
import icon6 from "./../../assets/img/icon/icon6.svg"
import icon7 from "./../../assets/img/icon/icon7.svg"
import icon8 from "./../../assets/img/icon/icon8.svg"
import axios from 'axios'
import DashboardCard from '../childComponents/DashboardCard.js'

function Reviewcard() {

    const [hta, setHta] = useState();
    const [TR, setTR] = useState();
    const [IB, setIB] = useState();
    const [EE, setEE] = useState();
    const [TB, setTB] = useState();
    const [TS, setTS] = useState();
    const [CPG, setCPG] = useState();
    const [Other, setOther] = useState();
    const [loader, setLoader] = useState(true)


    const getReviewTypesCount = () => {

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;

        var config = {
            method: "get",
            url: `${API_Link}getReviewTypesCount`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "misr-auth": MD5hash
            }
        }
        axios(config)
            .then(function (response) {
                const APIresponse = response.data.reviews;
                console.log('response', APIresponse[0])
                setHta(APIresponse[0]);
                setTR(APIresponse[1]);
                setIB(APIresponse[2]);
                setEE(APIresponse[3]);
                setTB(APIresponse[4]);
                setTS(APIresponse[5]);
                setCPG(APIresponse[6]);
                setOther(APIresponse[7]);

                setLoader(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    useEffect(() => {
        getReviewTypesCount();
    }, [])

    if (loader) return ""
    return (
        <div className="dashboard--page">
            <h1 className="page--heading">Dashboard</h1>
            <div className='row'>
                <div className='col-lg-2 padding-top-15'>
                    <div className="inner--card--total">
                        <div className="top--part">
                            <span className="title heading--font">
                                <strong>Total Reviews</strong>
                            </span>
                        </div>
                        <div className="bottom--part">
                            <span>
                                <h1 className="fig__number mt-2 mb-0">{hta.completed_reviews + TR.completed_reviews + IB.completed_reviews + EE.completed_reviews + TB.completed_reviews + TS.completed_reviews + CPG.completed_reviews + Other.completed_reviews}</h1>
                                <small>Completed</small>
                            </span>
                            <span>
                                <h1 className="fig__number mt-2 mb-0">{hta.inprogress_reviews + TR.inprogress_reviews + IB.inprogress_reviews + EE.inprogress_reviews + TB.inprogress_reviews + TS.inprogress_reviews + CPG.inprogress_reviews + Other.inprogress_reviews}</h1>
                                <small>In Progress</small>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='col-lg-10'>
                    <div className="row card--dashboard">
                        <DashboardCard title={hta.description} figNumber={hta.completed_reviews} figNumber_progress={hta.inprogress_reviews} src={icon1} />
                        <DashboardCard title={TR.description} figNumber={TR.completed_reviews} figNumber_progress={TR.inprogress_reviews} src={icon2} />
                        <DashboardCard title={IB.description} figNumber={IB.completed_reviews} figNumber_progress={IB.inprogress_reviews} src={icon3} />
                        <DashboardCard title={EE.description} figNumber={EE.completed_reviews} figNumber_progress={EE.inprogress_reviews} src={icon4} />
                        <DashboardCard title={TB.description} figNumber={TB.completed_reviews} figNumber_progress={TB.inprogress_reviews} src={icon5} />
                        <DashboardCard title={TS.description} figNumber={TS.completed_reviews} figNumber_progress={TS.inprogress_reviews} src={icon6} />
                        <DashboardCard title={CPG.description} figNumber={CPG.completed_reviews} figNumber_progress={CPG.inprogress_reviews} src={icon7} />
                        <DashboardCard title={Other.description} figNumber={Other.completed_reviews} figNumber_progress={Other.inprogress_reviews} src={icon8} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Reviewcard
