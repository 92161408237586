import React from 'react'

const AdminActivityLogComponent = () => {
  return (
    <div className="page--content ">
    <div className="row">
        <div className="col-md-6 col-lg-6">
            <h1 className="page--heading ">
                 Activity log Listing
            </h1>
        </div>

    </div>
</div>
  )
}

export default AdminActivityLogComponent