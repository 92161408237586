import React from "react";
import Setting from "./../Components/setting"

function SettingPage() {
  return (
    <div>
      <Setting/>
    </div>
  )
}

export default SettingPage