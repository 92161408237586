import React from 'react';
import Aside from '../../Components/aside';
import ReportComponentOne from '../../Components/ReportsComponent/ReportComponentOne';
import TopBar from '../../Components/topBar';

const RobotReviewerPage = () => {
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <ReportComponentOne />
      </div>
    </div>
  );
};

export default RobotReviewerPage;
