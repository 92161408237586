import React from 'react'
import InProgress from './../../Components/screeningComponent/inProgress'
import Aside from "./../../Components/aside";
import TopBar from "./../../Components/topBar";
import "./../../assets/sass/style.css";
import "./../../assets/sass/_screening.scss"
import "./../../assets/sass/_topbar.scss"
import "./../../assets/css/topbar.css"

function InprogressPage() {
  return (
    <div className="main-wrapper">
      <Aside />

      <div className="content-wrapper">
        <TopBar />
        <InProgress />

      </div>
    </div>
  )
}

export default InprogressPage
