import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { MD5hash, API_Link } from "../commons/Constants";
import { FaEye, FaMinus, FaPlus, FaStickyNote } from "react-icons/fa";
import { GrRotateLeft } from "react-icons/gr";
import { BsFillCircleFill, BsQuestionLg } from "react-icons/bs";
import "./../assets/sass/style.css";
import "./../assets/sass/totalreview.css";
import IncludedFulltext from "../Pages/user/includedfulltext";
import Aside from "./aside";
import TopBar from "./topBar";

const ExcludedDuplicatedComponent = () => {
  const [arrayData, setArrayData] = useState([]);
  const [countArticles, setCountArticles] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const studyLocalId = JSON.parse(localStorage.getItem("studyId"));
  const token = user.token;
  useEffect(() => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles`,
      params: {
        review_id: studyLocalId,
        duplicates: "Yes",
        exclusion: "Yes",
      },
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, "misr-auth": MD5hash },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        setArrayData(resData.data.articles);
        setCountArticles(resData.data.articles.length);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("Included Yes Api responce lenght", arrayData.length);

  const [isActive, setIsActive] = useState(null);
  const [isInclude, setIsInclude] = useState(null);
  const [isExclude, setIsExclude] = useState(null);
  const [mayBe, setmayBe] = useState(null);
  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };
  // handle Include
  const handleInclude = (index) => {
    if (isInclude === index) {
      return setIsInclude(null);
    }
    setIsInclude(index);
  };
  // handle Exclude
  const handleExclude = (index) => {
    if (isExclude === index) {
      return setIsExclude(null);
    }
    setIsExclude(index);
  };
  // handle Exclude
  const handleMayBe = (index) => {
    if (mayBe === index) {
      return setmayBe(null);
    }
    setmayBe(index);
  };

  return (
    <div className='main-wrapper'>
      <Aside countExcluded={countArticles} />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content'>
          <div className='row'>
            <div className='col-lg-6'>
              <h1 className='page--heading'>Excluded Duplicate</h1>
            </div>
            <div className='col-lg-6 text-end'>
              {/* <ul className='ul-export'>
                <li style={{ display: "inline-block" }}>
                  <div style={{ display: "inline-flex" }}>
                    <select style={{ backgroundColor: "#023047", border: "none", color: "white", borderRadius: "100px", padding: "7px 22px", zIndex: "9" }}>
                      <option value=''>Author </option>
                      <option value=''>Author </option>
                      <option value=''>Author </option>
                    </select>
                    <ul className='dropdown-menu' aria-labelledby='dropdownMenuButton1'>
                      <li>
                        <Link className='dropdown-item' to='#'>
                          Action
                        </Link>
                      </li>
                      <li>
                        <Link className='dropdown-item' to='#'>
                          Another action
                        </Link>
                      </li>
                      <li>
                        <Link className='dropdown-item' to='#'>
                          Something else here
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <input type='text' name='' placeholder='Search by Author' className='input--new form-control' id='' style={{ display: "inline-block", width: "250px", position: "relative", left: "-50px", zIndex: "1", paddingLeft: "50px", borderRadius: "50px" }} />
                </li>
                <li style={{ display: "inline-block" }}>
                  <Link to='#' style={{ backgroundColor: "#023047", textDecoration: "none", padding: "7px 25px", borderRadius: "100px", color: "white" }}>
                    {" "}
                    <i className='fa-solid fa-file-arrow-down'></i> Export
                  </Link>
                </li>
              </ul> */}
              <p>Total Aricles Count <strong>({countArticles})</strong></p>
            </div>
          </div>
          <div className='row boxes-row'>
            {React.Children.toArray(
              arrayData &&
                arrayData.length > 0 ?
                arrayData.map(({ title, authors, journal, abstract }, index) => (
                  <div className='col-lg-12' key={index}>
                    {/* Handle Background Color Change On Include, Exclude  */}
                    <div className='inner--box--row' style={{ backgroundColor: isInclude === index ? " rgba(0,255,0,0.1)" : isExclude === index ? "rgba(255,0,0,0.1)" : mayBe === index ? " rgba(255,255,0,0.1)" : "" }}>
                      <span className='index--numbering'>{index + 1}</span>

                      <div className='width--70'>
                        {/* <label className='form-check-label' htmlFor='flexCheckDefault2'>
                    {Title}
                  </label> */}
                        <h1 className='heading3'>{title}</h1>
                        <p className='small text-muted'>{authors}</p>
                        {/* <h1 className='heading3'>{Journal}</h1> */}

                        {journal ? <label className='form-check-label'> Journal {journal} </label> : ""}
                        {/* Handle Hight of Paragraph Here */}
                        <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{abstract}</p>
                      </div>
                      <div className='width-30'>
                        <div className=''>
                          <div className='btn--new--ul'>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleClick(index)}>
                              <FaEye />
                            </Link>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Add Note'>
                              <FaStickyNote />
                            </Link>
                            {/* Handle Include ,Exclue and Maybe Buttons Here */}
                            {/* {isInclude === index || isExclude === index || mayBe === index ? (
                          <Link to='#' data-bs-toggle='tooltip' className='link' title='Undo' onClick={isInclude === index ? () => handleInclude(index) : isExclude === index ? () => handleExclude(index) : mayBe === index ? () => handleMayBe(index) : ""}>
                            <GrRotateLeft />
                          </Link>
                        ) : (
                          <span>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Include' onClick={() => handleInclude(index)}>
                              <FaPlus />
                            </Link>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Exclude' onClick={() => handleExclude(index)}>
                              <FaMinus />
                            </Link>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Maybe' onClick={() => handleMayBe(index)}>
                              <BsQuestionLg />
                            </Link>
                          </span>
                        )} */}
                          </div>
                          {isActive === index ? (
                            ""
                          ) : (
                            <p>
                              
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )) 
                : 
                <p className="p-3 text-muted">There are no excluded duplicate articles.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExcludedDuplicatedComponent;
