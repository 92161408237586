import axios from 'axios';
import { API_Link, MD5hash } from '../../commons/Constants';

const getReviewId = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;

    return axios.get(`${API_Link}getReviewsList`, {
        headers: {
            "misr-auth": MD5hash,
            Authorization: `Bearer ${token}`,
        },
    })
}

const articleReviews = {
    getReviewId,
   
};

export default articleReviews;