import React from 'react'
import Footer from '../../Components/footer'
import Header from '../../Components/header'
import Login2 from '../../Components/loginComponent/login2'



function LoginPage2() {
  return (
    <div className='loginPage'>
       <Header />
        <Login2/>
        <Footer />
    </div>
  )
}

export default LoginPage2
