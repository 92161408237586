import { configureStore } from "@reduxjs/toolkit";
import invitationSlice from "./features/setting/invitationSlice";
import settingSlice from "./features/setting/settingSlice";
import userSlice from "./features/setting/userSlice";
import reviewSlice from "./features/reviews/reviewSlice";
import permissionSlice from "./features/setting/permissionSlice";

//import errorSlice from "./features/setting/errorSlice";

export default configureStore({
  reducer: {
    setting: settingSlice,
    user: userSlice,
    invitation: invitationSlice,
    reviews: reviewSlice,
    getPermission: permissionSlice
    //error: errorSlice,
  },
});
