import React from 'react'
import AdminLookupsListingComponent from '../../Components/adminLookupsListingComponent'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'

const LookUpListingPage = () => {
    return (

        <div className="main-wrapper">
            <Aside />
            <div className="content-wrapper">
                <TopBar />
               <AdminLookupsListingComponent/>
            </div>
        </div>
    )
}

export default LookUpListingPage