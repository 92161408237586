import React, { useEffect, useState } from "react";
import "./../assets/sass/_login.scss";
import "./../assets/sass/style.css";
import "./FontAwesome/FontAwesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loginAuth } from "../features/setting/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setting } from "../features/setting/settingSlice";
import signup from "./../assets/img/loginBGV1.png";
import logo from "./../assets/img/mahtas.png";
import axios from "axios";
import { API_Link, MD5hash } from "../commons/Constants";

import loginvideo from "./../assets/img/MISR-Login-Page Simulation.mp4";
function SignUp({ login_page_bg, short_title, sponsor_logo }) {
  // const [name,setName] =useState()
  // const[email,setEmail] =useState('')
  // const[password,setPassword] =useState('')
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cPassError, setCPassError] = useState("");
  const [error_message, setMessage] = useState("");

  const [user, setUser] = useState({
    name: "",
    lastname: "",
    email: "",
    password: "",
  });

  const { name, lastname, email, password } = user;

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    // console.log(user);
  };
  let navigate = useNavigate;

  const [loginError, setLoginError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    signup(user);
    // console.log(user);
  };

  const signup = (data) => {
    axios
      .post(`${API_Link}register`, data, {
        headers: {
          "misr-auth": MD5hash,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("Response from server:", response);
        localStorage.setItem("user", JSON.stringify(response.data));
        window.location.href = response.data.role.landingpage;

        // res.data.status === 1 &&
        //   setMessage({
        //     code: 200,
        //     message: "Data Has been added successfully.",
        //   });
        // res.data.status === 0 && setMessage({ code: 400, message: res.data.error_message });

        // console.log(res.data.error_message);
      })
      .catch(function (error) {
        setMessage(error.response.data.error_message);
        // alert(error.response.data.error_message);
        console.log(error.response.data.error_message);
      });
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    if (password != e.target.value) {
      setCPassError(" Confirm Password should be match with Password");
      console.log("password not matcch");
    } else {
      setCPassError("");
      console.log("");
    }
  };

  useEffect(() => {
    localStorage.removeItem("loginError");
    localStorage.setItem("pageId", "1001");
  }, []);

  return (
    <div>
      <div className='login--content'>
        <div className='left--side d-flex justify-content-center'>
          {/* <img src={signup} className='img-fluid img-fluid2' alt='' /> */}
          <div>
            <video autoPlay muted loop className='img-fluid2 video' style={{ boxShadow: "2px 2px 10px #00000029" }}>
              <source src={loginvideo} type='video/mp4' />
            </video>
          </div>
        </div>
        <div className='right--side d-flex justify-content-center'>
          <div className='right--side'>
            <div className='login--box'>
              <h1 className='login--box--heading'>SIGN UP</h1>
              <span className='welcome'>Welcome to {short_title}</span>

              <form onSubmit={(e) => handleSubmit(e)}>
                <div className='form-group'>
                  <div className='row'>
                    <span className='text-danger'>{error_message ? error_message : ""}</span>
                    <div className='col'>
                      <input type='text' className='form-control custom--form--control' name='name' value={name} onChange={(e) => handleChange(e)} placeholder=' Name' />
                    </div>
                    {/* <div className='col '>
                      <input type='text' className='form-control custom--form--control' name='lastname' value={lastname} onChange={(e) => handleChange(e)} placeholder='Last Name' />
                    </div> */}
                  </div>
                </div>
                <div className='form-group'>
                  <input type='email' className='form-control custom--form--control' name='email' value={email} onChange={(e) => handleChange(e)} placeholder='Email' />
                  <i className='fas fa-user'></i>
                </div>

                <div className='form-group'>
                  <input type='password' className='form-control custom--form--control' name='password' value={password} onChange={(e) => handleChange(e)} placeholder='Password' />
                  <i className='fas fa-key'></i>
                </div>

                <div className='form-group'>
                  <input type='password' className='form-control custom--form--control' name='confirmpassword' value={confirmPassword} onChange={(e) => handleConfirmPassword(e)} placeholder='Confirm Password' />
                  <i className='fas fa-key'></i>
                </div>
                <span className='text-danger'>{cPassError}</span>

                <div className='hint-text form-group'>
                  Already have an account?
                  <Link to='/' className=''>
                    Login here
                  </Link>
                </div>

                <div className='form-group text-center'>
                  <span className='position-relative'>
                    <input type='submit' value='SIGN UP' className='submit--btn' />
                    <i className='fa-solid fa-arrow-right-from-bracket'></i>
                  </span>
                </div>
              </form>
              <div className='box--logo text-center mt-4'>
                <img src={sponsor_logo} className='img-fluid' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
