import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import "./../../assets/sass/style.css";
import userImg from "./../../assets/img/user.png";
import { useSelector } from "react-redux";

function Sidedropdown({ invitations }) {
  // const invitation = useSelector((state) => state.invitation.value);
  // console.log("reducer", invitation);

  const sidebars = JSON.parse(localStorage.getItem("user"));

  const username = sidebars.user.name;

  const [value, setValue] = useState("");
  const [invitataionCount, setInvitationCount] = useState(0);

  const handleSelect = (e) => {
    console.log(e);
    setValue(e);
  };

  useEffect(() => {
    setInvitationCount(localStorage.getItem("invitation"));
  }, []);
  function logout() {
    window.location.href = "/";
    localStorage.setItem("user", null);
    localStorage.setItem("subPageId", null);
    localStorage.setItem("search", null);
    localStorage.setItem("pageId", null);
    localStorage.setItem("studyId", null);

    // if(localStorage.getItem('user') === null){

    // }
  }

  return (
    <div className='App container'>
      <Dropdown onSelect={handleSelect}>
        <img src={userImg} alt='' />
        <Dropdown.Toggle variant='custom' style={{ color: "white" }} className='bottom--profile' id='dropdownMenuButton1'>
          {/* <FaRegUserCircle/>  */}

          <span className='user_name'> {username}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href='/invitation'>Invitations {invitataionCount ? <span className='invitationCircle'>{invitataionCount}</span> : ""}</Dropdown.Item>
          <Dropdown.Item href='#'>Profile</Dropdown.Item>
          <Dropdown.Item href='#'>Settings</Dropdown.Item>
          <Dropdown.Item>
            <Link onClick={logout}>Logout</Link>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default Sidedropdown;
