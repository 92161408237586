import React from 'react'
import Aside from '../../Components/aside'
import ExcludedFulltextComponent from '../../Components/ExcludedFulltextComponent'
import TopBar from '../../Components/topBar'

const ExcludedFulltext = () => {
    return (
        <div className="main-wrapper">
        <Aside />
        <div className="content-wrapper">
            <TopBar />
            <ExcludedFulltextComponent />
        </div>
    </div>
      )
}

export default ExcludedFulltext