import React from "react";
import { API_Link, MD5hash } from "../commons/Constants";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";
const AdminLevelListingComponent = () => {
  const [levels, setLevels] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;
  const getLevels = () => {
    var config = {
      method: "get",
      url: `${API_Link}getLevels`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setLevels(resData.data.levels);

        //  console.log('Level APIs',resData.data.levels)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getLevels();
  }, []);
  return (
    <div className='page--content '>
      <div className='row'>
        <div className='col-md-6 col-lg-6'>
          <h1 className='page--heading '>Level Listing</h1>
        </div>
        <div className='col-sm-6 col-xs-12 text--align--right mt-1'>
          <Link to='/admin/addLevel' className='tem--add--btn'>
            <IoIosAdd /> Add New Level
          </Link>
        </div>
      </div>
      <div className='row'>
        <div className='padding-table'>
          <div className='table--template'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Level</th>
                  <th>Description</th>
                  <th className="max-width-action">Action</th>
                </tr>
              </thead>
              <tbody>
                {levels &&
                  levels.map((level, index) => (
                    <tr>
                      <td>{index + 1} </td>
                      <td>{level.level.charAt(0).toUpperCase() + level.level.slice(1)}</td>
                      <td> {level.description}</td>
                      <td className="max-width-action">
                        <Link to='#' className='tem--add--btn'>
                          Edit
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLevelListingComponent;
