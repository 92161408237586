import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoutes = () => {
  const getUser = useSelector((state) => state.user);

  console.log("getUser", getUser);
  const users = JSON.parse(localStorage.getItem("user"));

  const TOKEN = () => {
    if (JSON.parse(localStorage.getItem("user"))) {
      return JSON.parse(localStorage.getItem("user"));
    } else {
      return "";
    }
  };

  // let token = "";
  // token = users.token;
  // let auth = { token: true };
  return !TOKEN() ? <Navigate to='/' /> : <Outlet />;
};

export default PrivateRoutes;
