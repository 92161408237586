import React from "react";
import AddRoleComponent from "./addRoleComponent";
import Aside from "./aside";
import TopBar from "./topBar";

const AddRole = () => {
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <AddRoleComponent />
      </div>
    </div>
  );
};

export default AddRole;
