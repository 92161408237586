import React from "react";
import Aside from "./aside";
import TopBar from "./topBar";
import { BiArrowToBottom } from "react-icons/bi";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaPlus } from "react-icons/fa";
import "./../assets/sass/_extract.scss";
const ExtractionPrisma = () => {
  return (
    <div className='main-wrapper'>
      <Aside extractname={"Extract Data"} extracttitle={"Prisma FlowChart"} extractpath={"Evidence Table"} />

      <div className='content-wrapper'>
        <TopBar />
        {/*  */}
        <div className='page--content'>
          <h1 className='page--heading'>Prisma Flowchart</h1>

          <div className='container'>
            <div className='row pt-5'>
              <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                <div className='identification'>
                  <p className='text'>Identification</p>
                </div>
              </div>
              <div className='col-col-sm-12 col-md-9 col-lg-10'>
                <div className='row mb-3'>
                  <div className='col-sm-12 col-md-12 col-lg-6 mt-2 '>
                    <div className='Number1972'>Number of records identified through electronic databases searching (n=1972)</div>
                  </div>

                  <div className='col-sm-12 col-md-12 col-lg-6 mt-2'>
                    <div className='Number53'>Number of additional records identified from other sources (n=53)</div>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-6'>
                    <span className='arrow-icon-prisma'>
                      {/* <i className='bi bi-arrow-down-short'></i> */}

                      <FaArrowDown />
                    </span>
                  </div>
                  <div className='col-6'>
                    <span className='arrow-icon-prisma'>
                      <FaArrowDown />
                    </span>
                  </div>
                </div>

                <div className='row pt-2 mb-3 '>
                  <div className='col-12'>
                    <div className='Num2055'>Number of records after duplicates removed (n=2025)</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-3'></div>
              <div className='col-sm-12 col-md-9 col-lg-10'>
                <span className='arrow-icon-prisma'>
                  <FaArrowDown />
                </span>
              </div>
            </div>

            <div className='row '>
              <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                <div className='Screening'>
                  <p className='text'>Screening</p>
                </div>
                <div className='row pt-2'>
                  <div className='col-sm-12 col-md-3 col-lg-2 '>
                    <div className='included'>
                      <p className='text'>included</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-col-sm-12 col-md-9 col-lg-10'>
                <div className='row mb-3'>
                  <div className='col-sm-12 col-md-12 col-lg-1 '></div>
                  <div className='col-sm-12 col-md-12 col-lg-5 mt-2'>
                    <div className='num_1925'>Number of records screened (n=1925)</div>
                  </div>

                  <div className='col-sm-12 col-md-12 col-lg-1'>
                    <span className='arrow-icon-prisma text-icon'>
                      {/* <i className='bi bi-arrow-down-short'></i> */}

                      <FaArrowRight />
                    </span>
                  </div>

                  <div className='col-sm-12 col-md-12 col-lg-5 mt-2 '>
                    <div className='num1200'>Number of records excluded (n=1240)</div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-7'>
                    <span className='arrow-icon-prisma'>
                      {/* <i className='bi bi-arrow-down-short'></i> */}
                      <FaArrowDown />
                    </span>
                  </div>
                </div>

                <div className='row '>
                  <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '></div>
                  <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5 '>
                    <div className='row mb-3'>
                      <div className='col'>
                        <div className='num685'>Number of full-text articles assessed for eligibility (n=685)</div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <span className='arrow-icon-prisma'>
                        {/* <i className='bi bi-arrow-down-short'></i> */}
                        <FaArrowDown />
                      </span>
                    </div>
                    <div className='row '>
                      <div className='col'>
                        <div className='mt-2 yelllowbox'>Number of full-text articles included in qualitative synthesis (n=253)</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '>
                    <span className='arrow-icon-prisma text-icon'>
                      {/* <i className='bi bi-arrow-down-short'></i> */}
                      <FaArrowRight />
                    </span>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5'>
                    <div className='num432'>Number of full-text articles excluded (n=432): Reason 1 (n=102) Reason 2 (n=157) Reason 3 (n=173)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractionPrisma;
