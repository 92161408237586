export const testHighlights = {
  "http://misr.pacemis.com:83/assets/articlesdata/article1124_datafile.pdf": [
    {
      "content": {
        "text": "Percutaneous Injuries Among Health-Care WorkersDuring"
      },
      "position": {
        "boundingRect": {
          "x1": 39.4525146484375,
          "y1": 237.52499389648438,
          "x2": 608.0936889648438,
          "y2": 303.8625183105469,
          "width": 635.9999999999999,
          "height": 875.5571808510637,
          "pageNumber": 1
        },
        "rects": [
          {
            "x1": 200.4375,
            "y1": 237.52499389648438,
            "x2": 374.988525390625,
            "y2": 276.7250061035156,
            "width": 635.9999999999999,
            "height": 875.5571808510637,
            "pageNumber": 1
          },
          {
            "x1": 374.9375,
            "y1": 238.8125,
            "x2": 608.0936889648438,
            "y2": 270.01251220703125,
            "width": 635.9999999999999,
            "height": 875.5571808510637,
            "pageNumber": 1
          },
          {
            "x1": 39.4525146484375,
            "y1": 238.83749389648438,
            "x2": 197.94952392578125,
            "y2": 269.2375183105469,
            "width": 635.9999999999999,
            "height": 875.5571808510637,
            "pageNumber": 1
          },
          {
            "x1": 39.46246337890625,
            "y1": 265.4624938964844,
            "x2": 108.8203125,
            "y2": 303.8625183105469,
            "width": 635.9999999999999,
            "height": 875.5571808510637,
            "pageNumber": 1
          }
        ],
        "pageNumber": 1
      },
      "comment": {
        "text": "",
        "emoji": ""
      },
      id: "900",
    },
    {
      "content": {
        "text": "Evaluation"
      },
      "position": {
        "boundingRect": {
          "x1": 304.55999755859375,
          "y1": 246.84811401367188,
          "x2": 340.8001708984375,
          "y2": 279.45587158203125,
          "width": 541,
          "height": 745,
          "pageNumber": 1
        },
        "rects": [
          {
          "x1": 304.55999755859375,
          "y1": 246.84811401367188,
          "x2": 340.8001708984375,
          "y2": 279.45587158203125,
          "width": 541,
          "height": 745,
          "pageNumber": 1
          },
          {
          "x1": 340.8001708984375,
          "y1": 243.25439453125,
          "x2": 439.2419128417969,
          "y2": 282.6954345703125,
          "width": 541,
          "height": 745,
          "pageNumber": 1
          }
        ],
        "pageNumber": 1
      },
      "comment": {
        "text": "",
        "emoji": ""
      }
    },
    



    // {
    //   id: "46570518065661616",
    //   comment: { text: "Fast type checking?!?!", emoji: "😱" },
    //   content: {
    //     text: "Fast type checking.The key toFlow’s speed is modularity: the ability to break the analysis intofile-sized chunks that can be assembled later. Fortunately, JavaScript is already written using files4Avik Chaudhuri, Panagiotis Vekris, Sam Goldman, Marshall Roch, and Gabriel Levias modules, so we modularize our analysis simply by asking that modules have explicitly typedsignatures. (We still infer types for the vast majority of code “local” to modules.) Coincidentally,developers consider this good software engineering practice anyway.",
    //   },
    //   position: {
    //     boundingRect: {
    //       x1: 76.375,
    //       y1: 1058.4271240234375,
    //       x2: 733.626220703125,
    //       y2: 1097.0208740234375,
    //       width: 809.9999999999999,
    //       height: 1200,
    //       pageNumber: 3,
    //     },
    //     rects: [
    //       {
    //         x1: 75.989501953125,
    //         y1: 96.9896240234375,
    //         x2: 85.2291259765625,
    //         y2: 115.65625,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 4,
    //       },
    //       {
    //         x1: 167.583251953125,
    //         y1: 96.9896240234375,
    //         x2: 733.6123046875,
    //         y2: 115.65625,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 4,
    //       },
    //       {
    //         x1: 76.375,
    //         y1: 142.82293701171875,
    //         x2: 733.613525390625,
    //         y2: 161.4896240234375,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 4,
    //       },
    //       {
    //         x1: 76.375,
    //         y1: 162.7396240234375,
    //         x2: 735.43798828125,
    //         y2: 181.40625,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 4,
    //       },
    //       {
    //         x1: 76.375,
    //         y1: 182.66668701171875,
    //         x2: 544.7579345703125,
    //         y2: 201.3333740234375,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 4,
    //       },
    //       {
    //         x1: 92.9791259765625,
    //         y1: 1058.4271240234375,
    //         x2: 733.619140625,
    //         y2: 1077.09375,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 3,
    //       },
    //       {
    //         x1: 76.375,
    //         y1: 1078.3541870117188,
    //         x2: 733.626220703125,
    //         y2: 1097.0208740234375,
    //         width: 809.9999999999999,
    //         height: 1200,
    //         pageNumber: 3,
    //       },
    //     ],
    //     pageNumber: 3,
    //   },
    // },
  ],
};
