import React from "react";
import AddReview from "../../Components/addreviews";


function ReviewPage() {
  return (
    <div>
      <AddReview/>
    </div>
  )
}

export default ReviewPage

