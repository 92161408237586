import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import pdf from './../../assets/example.pdf';
const ReportComponentOne = () => {
  const [show, setShow] = useState(false);

  const accordionJson = [
    {
      id: 1,
      title: 'Random sequence generation',
      description: [
        { id: 1, title: 'Overall Risk of Bias descrption' },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
    {
      id: 2,
      title: ' Allocation concealment',
      description: [
        { id: 1, title: 'Overall Risk of Bias descrption' },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
    {
      id: 3,
      title: 'Blinding of participants and personnel',
      description: [
        { id: 1, title: 'Overall Risk of Bias descrption' },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
    {
      id: 4,
      title: 'Blinding of outcome assessment',
      description: [
        {
          id: 1,
          title:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, laudantium modi necessitatibus corrupti veniam',
        },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
    {
      id: 5,
      title: 'Incomplete outcome data',
      description: [
        {
          id: 1,
          title:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, laudantium modi necessitatibus corrupti veniam',
        },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
    {
      id: 6,
      title: 'Selective reporting',
      description: [
        {
          id: 1,
          title:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet, laudantium modi necessitatibus corrupti veniam',
        },
        { id: 2, title: 'Random sequence generation' },
        { id: 3, title: 'Random sequence generation' },
      ],
    },
  ];

  const [isShow, setIsShow] = useState(
    new Array(accordionJson.length).fill(false)
  );
  const [showRiskofBias, setShowRiskofBias] = useState(
    new Array(accordionJson.length).fill(false)
  );
  const openAccordion = (id) => {
    let result = [...isShow];
    result[id] = !result[id];
    console.warn(result);
    setIsShow(result);
  };
  const editRiskOfBiasPrediction = (id) => {
    let result = [...showRiskofBias];
    result[id] = !result[id];
    console.warn(result);
    setShowRiskofBias(result);
  };
  console.log(accordionJson);
  return (
    <div className='page--content--abstract'>
      <div className='row'>
        <div className='col-md-9  '>
          <iframe
            src={pdf}
            frameborder='0'
            width='100%'
            height='1000px'
          ></iframe>
        </div>
        <div className='col-md-3 mt-1'>
          <h2 className='risk-of-bias'>Risk of Bias</h2>

          <div id='accordion'>
            {accordionJson.map((item) => {
              return (
                <div className='card mb-2'>
                  <div
                    className={
                      isShow[item.id]
                        ? 'card-header robot-reviewer-card-header robot-reviewer-card-header-font'
                        : 'card-header robot-reviewer-card-header'
                    }
                    style={{
                      backgroundColor:
                        isShow[item.id] === true && item.id === 1
                          ? 'rgb(168, 191, 18)'
                          : isShow[item.id] === true && item.id === 2
                          ? 'rgb(0,191,181)'
                          : isShow[item.id] === true && item.id === 3
                          ? 'rgb(255, 159, 0)'
                          : isShow[item.id] === true && item.id === 4
                          ? 'rgb(244, 28, 84)'
                          : isShow[item.id] === true && item.id === 5
                          ? 'rgb(0, 170, 181)'
                          : isShow[item.id] === true && item.id === 6
                          ? 'rgb(0, 170, 181)'
                          : '',
                    }}
                  >
                    <a
                      className='btn d-flex justify-content-between align-items-center'
                      onClick={() => openAccordion(item.id)}
                    >
                      <span className='text-start'> {item.title}</span>
                      <span>
                        {isShow[item.id] ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </a>
                  </div>
                  <div
                    className={
                      isShow[item.id] ? 'display-accordion' : 'hide-accordion'
                    }
                  >
                    <div className='card-body robot-reviewer-card-body'>
                      {showRiskofBias[item.id] ? (
                        <div class='input-group mb-1'>
                          <input
                            type='text'
                            class='form-control'
                            placeholder='Overall risk of Bias Prediction'
                            aria-label="Recipient's username"
                            aria-describedby='basic-addon2'
                          />
                          <span
                            class='input-group-text editBiasRiskBtn'
                            id='basic-addon2'
                            onClick={() => editRiskOfBiasPrediction(item.id)}
                          >
                            Edit
                          </span>
                        </div>
                      ) : (
                        <p
                          className='mb-1 bias-prediction-p'
                          onClick={() => editRiskOfBiasPrediction(item.id)}
                        >
                          Overall risk of Bias Prediction{' '}
                        </p>
                      )}

                      <ul>
                        {item.description.map((item1) => {
                          return (
                            <>
                              <li>
                                <a href='#'>{item1.title}</a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportComponentOne;
