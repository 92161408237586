import CryptoJS from "crypto-js";

const API_KEY = process.env.APP_KEY;
const API_PATH = process.env.MIX_API_PATH;

// pacemis server

// const API_Link = "http://misr.pacemis.com:83/api/";
// const API_SRC = "http://misr.pacemis.com:83/";

const API_Link = "http://misr.pacemis.com:76/api/";
const API_SRC = "http://misr.pacemis.com:76/";


// local
// const API_Link = "http://localhost/mirs/backend/public/api/";
// const API_SRC = "http://localhost/mirs/backend/public";
var moment = require("moment");
var MD5hash = CryptoJS.MD5("P@ce-" + moment().format("YYYY-MM-DD") + "-T3c4").toString();

export { API_KEY, API_PATH, MD5hash, API_Link, API_SRC };