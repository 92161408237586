import React, { Component } from 'react';
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from 'react-pdf-highlighter';
import type { IHighlight, NewHighlight } from 'react-pdf-highlighter';
import { testHighlights as _testHighlights } from './test-highlights.ts';
import { Spinner } from './Spinner.js';
import { Sidebar } from './Sidebar.tsx';
import Tip from './Tip.tsx';
import './style/Spinner.css';
import axios from 'axios';
import { API_Link, MD5hash } from '../commons/Constants';
import { PicoSidebar } from './PicoSideBar.tsx';
const testHighlights: Record<string, Array<IHighlight>> = _testHighlights;
interface State {
  url: string;
  highlights: Array<IHighlight>;
  defaultHighlight?: Record<string, Array<IHighlight>>;
  testObj?: any;
  picoHeadings: Array<{ id: number; headingText: string; biasType: number }>;
  isShow: boolean[];
  biastype: any;
  activeAccordion: any;
  finalPayLoad: any;
}
interface Props {
  judgements: Record<string, any>;
  pdfAId: number | string;
}

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  document.location.hash = '';
};
const picoHeadings1 = [
  { id: 1, headingText: 'Population', picoType: 1 },
  { id: 2, headingText: 'Intervention', picoType: 2 },
  { id: 3, headingText: 'OutComes', picoType: 3 },
];
const HighlightPopup = ({
  comment,
}: {
  comment: { text: string; emoji: string };
}) =>
  comment.text ? (
    <div className='Highlight__popup'>
      {comment.emoji} {comment.text}
    </div>
  ) : null;
const getId = localStorage.getItem('pdfAId');
console.log('pdfidddd', getId);
let PRIMARY_PDF_URL;
PRIMARY_PDF_URL = `http://misr.pacemis.com:83/assets/articlesdata/article${getId}_datafile.pdf`;
const SECONDARY_PDF_URL = 'https://arxiv.org/pdf/1604.02480.pdf';
// const searchParams = new URLSearchParams(document.location.search);
// const initialUrl = searchParams.get('url') || PRIMARY_PDF_URL;
const user = JSON.parse(localStorage.getItem('user'));
const studyId = localStorage.getItem('studyId');

let token;
if (user !== null) {
  token = user.token;
}
// console.log('initalurl', initialUrl);
// function
async function processDefaultHighlight(defaultHighlight, id) {
  const testHighlights1 = {};
  for (let articleId in defaultHighlight) {
    let article = defaultHighlight[articleId];
    for (let biasType in article) {
      let judgments = article[biasType] && article[biasType];
      let filterJudgements = judgments.filter(
        // (judgment) => judgment.position !== []
        (judgment) => judgment.position !== '[]'
      );
      for (let judgment of filterJudgements) {
        let url = `http://misr.pacemis.com:83/assets/articlesdata/article${id}_datafile.pdf`;
        let content = { text: judgment.pico_text };
        // let comment = { text: `` };
        let comment = { text: `${judgment.pico_type}` };
        let biasType = judgment.pico_type;
        let position = judgment.position ? JSON.parse(judgment.position) : {};
        let extraction_type = judgment.extraction_type;
        // Create a new test highlight object
        let testHighlight = {
          content: content,
          position: position,
          comment: comment,
          biasType: biasType,
          id: judgment.id.toString(),
          extraction_type: extraction_type ? extraction_type : '',
        };
        // Push the test highlight to the corresponding URL in the testHighlights object
        if (!testHighlights1[url]) {
          testHighlights1[url] = [];
        }
        testHighlights1[url].push(testHighlight);
      }
    }
  }
  return testHighlights1;
}
//
let filteredArticles = {};
let testHighlights1 = {};
console.log('testing...', testHighlights1);

class PicoBotComponent extends Component<Props, State> {
  constructor(props) {
    super(props);
    const { pdfAId } = this.props;
    this.state = {
      url: `http://misr.pacemis.com:83/assets/articlesdata/article${pdfAId}_datafile.pdf`,
      highlights: [],
      defaultHighlight: {},
      picoHeadings: picoHeadings1, // Assign `picoHeadings1` to `picoHeadings`
      isShow: new Array(picoHeadings1.length).fill(false),
      biastype: [],
      activeAccordion: null,
      finalPayLoad: [],
    };
    this.openAccordion = this.openAccordion.bind(this);
    this.saveHighlights = this.saveHighlights.bind(this);
  }
  async fetchReviewJudgements() {
    const { url } = this.state;
    const { pdfAId } = this.props;
    var config = {
      method: 'get',
      url: `${API_Link}getReviewPicoSentences?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    try {
      const res = await axios(config);
      const defaultHighlight = res.data.picos;
      this.setState({ defaultHighlight });
      console.log('judgements in newtest', defaultHighlight);

      for (let articleId in defaultHighlight) {
        if (articleId === this.props.pdfAId.toString()) {
          filteredArticles[articleId] = defaultHighlight[articleId];
        }
      }
      console.log('filter', filteredArticles);
      console.log('default', defaultHighlight);
      const testHighlights1 = await processDefaultHighlight(
        filteredArticles,
        this.props.pdfAId
      );
      console.log('result of highlight', testHighlights1);
      this.setState({
        highlights: testHighlights1[url] ? [...testHighlights1[url]] : [],
      });

      const previousHighlight = testHighlights1[url]
        ? [...testHighlights1[url]].map((highlight) => ({
            id: highlight.id,
            article_id: pdfAId,
            review_id: studyId,
            pico_type: highlight.biasType,
            extraction_type: highlight.extraction_type,
            pico_text: highlight.content.text,
            position: highlight.position,
          }))
        : [];

      console.log('previous', previousHighlight);

      this.setState(
        {
          finalPayLoad: [...this.state.finalPayLoad, ...previousHighlight],
        },
        () => {
          console.log('finalpayload', this.state.finalPayLoad);
        }
      );
    } catch (error) {
      console.log('Error fetching review judgements:', error);
    }
  }
  componentDidMount() {
    localStorage.setItem('pdfAId', '');
    window.addEventListener(
      'hashchange',
      this.scrollToHighlightFromHash,
      false
    );
    this.fetchReviewJudgements.bind(this)();
  }

  //   state = {
  //     url: initialUrl,
  //     highlights: [],
  //     defaultHighlight: {},
  //     biasType: [],
  //   };

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  toggleDocument = () => {
    const newUrl =
      this.state.url === PRIMARY_PDF_URL ? SECONDARY_PDF_URL : PRIMARY_PDF_URL;

    this.setState({
      url: newUrl,
      highlights: testHighlights[newUrl] ? [...testHighlights[newUrl]] : [],
    });
    const { judgements, pdfAId } = this.props;
    console.log('get from props', judgements);
    console.log('get from props', pdfAId);
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };
  openAccordion(id, biasType) {
    const { isShow, biastype, activeAccordion } = this.state;
    const updatedIsShow = [...isShow];
    updatedIsShow[id] = !isShow[id];
    const updatedBiasType = [...biastype];
    const index = updatedBiasType.indexOf(biasType);
    if (index === -1) {
      updatedBiasType.push(biasType);
    } else {
      updatedBiasType.splice(index, 1);
    }
    const updatedActiveAccordion = id === activeAccordion ? null : id;
    this.setState({
      isShow: updatedIsShow,
      biastype: updatedBiasType,
      activeAccordion: updatedActiveAccordion,
    });
  }
  getHighlightById(id: string) {
    const { highlights } = this.state;
    return highlights.find((highlight) => highlight.id === id);
  }
  addHighlight(highlight: NewHighlight) {
    const { highlights, biastype, picoHeadings, finalPayLoad } = this.state;
    const { pdfAId } = this.props;
    console.log('Saving highlight', highlight);
    console.log('biastype', biastype);
    let biasType = highlight.biasType;
    if (biastype.includes(1)) {
      biasType = 1;
    } else if (biastype.includes(2)) {
      biasType = 2;
    } else if (biastype.includes(3)) {
      biasType = 3;
    } else if (biastype.includes(4)) {
      biasType = 4;
    } else if (biastype.includes(5)) {
      biasType = 5;
    } else if (biastype.includes(6)) {
      biasType = 6;
    }
    const newHighlights = biastype.map((type) => {
      return { ...highlight, id: getNextId(), biasType: type };
    });
    // const previousHighlight = highlights.map((highlight, index) => {
    //   return {
    //     id: highlight.id,
    //     article_id: pdfAId,
    //     review_id: studyId,
    //     pico_type: highlight.biasType,
    //     extraction_type: 'auto',
    //     pico_text: highlight.content.text,
    //     position: highlight.position,
    //   };
    // });
    // console.log('prvious highlight', previousHighlight);
    this.setState({
      highlights: [...newHighlights, ...highlights],
    });

    const highlightPayLoad = newHighlights.map((highlight, index) => {
      return {
        id: highlight.id,
        pico_type: highlight.biasType,
        extraction_type: 'manual',
        pico_text: highlight.content.text,
        position: highlight.position,
      };
    });

    this.setState(
      (prevState) => {
        return {
          finalPayLoad: [...prevState.finalPayLoad, ...highlightPayLoad],
        };
      },
      () => {
        console.log('finalpayload', this.state.finalPayLoad);
      }
    );
    console.log('highlights all', highlights);
    console.log('payload', highlightPayLoad);
    console.log('finalpayload', finalPayLoad);
  }

  removeHighlight = (id) => {
    console.log(id);
    const { highlights, finalPayLoad } = this.state;

    let newPayload = finalPayLoad.filter((payload) => payload.id !== id);
    let newHighlights = highlights.filter((highlight) => highlight.id !== id);

    this.setState(
      {
        highlights: newHighlights,
        finalPayLoad: newPayload,
      },
      () => {
        console.log(
          'after delete',
          this.state.highlights,
          this.state.finalPayLoad
        );
      }
    );
  };

  //   send highlights to api
  saveHighlights() {
    const { finalPayLoad } = this.state;
    const { pdfAId } = this.props;

    const payLoadWithoutIds = finalPayLoad.map(({ id, ...rest }) => ({
      ...rest,
    }));
    console.log('rmove ids', payLoadWithoutIds);
    const data = {
      article_id: pdfAId,
      review_id: studyId,
      sentences: payLoadWithoutIds,
    };
    console.log(finalPayLoad);
    console.log(data);
    const config = {
      method: 'post',
      url: `${API_Link}setReviewPicoSentences?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
      data: data,
    };

    try {
      axios(config).then((res) => {
        console.log(res.data);
        alert(res.data.message);
      });
    } catch (error) {
      console.log('Error Posting highlight judgements:', error);
      alert('Error Posting highlight judgements');
    }
  }
  updateHighlight(highlightId: string, position: Object, content: Object) {
    console.log('Updating highlight', highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  render() {
    const { url, highlights } = this.state;
    return (
      <div className='App' style={{ display: 'flex', height: '100vh' }}>
        <div
          style={{
            height: '100vh',
            width: '75vw',
            position: 'relative',
          }}
        >
          <PdfLoader url={url} beforeLoad={<Spinner />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                onScrollChange={resetHash}
                // pdfScaleValue="page-width"
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => (
                  <Tip
                    onOpen={transformSelection}
                    onConfirm={(comment) => {
                      this.addHighlight({ content, position, comment });

                      hideTipAndSelection();
                    }}
                  />
                )}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      isScrolledTo={isScrolledTo}
                      highlight={highlight}
                      onChange={(boundingRect) => {
                        this.updateHighlight(
                          highlight.id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) }
                        );
                      }}
                    />
                  );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={(popupContent) =>
                        setTip(highlight, (highlight) => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>

        <PicoSidebar
          highlights={highlights}
          picoHeadings={this.state.picoHeadings}
          isShow={this.state.isShow}
          biasType={this.state.biastype}
          filteredricles={filteredArticles}
          resetHighlights={this.resetHighlights}
          toggleDocument={this.toggleDocument}
          removeHighlight={this.removeHighlight}
          openAccordion={this.openAccordion}
          saveHighlights={this.saveHighlights}
        />
      </div>
    );
  }
}

export default PicoBotComponent;
