import React from "react";

export default function TableRowComponent({
    rowAttributes,
    cellData,
    rowClick,
    status
}) {
    return (
        <tr
            className={rowAttributes ? rowAttributes.class : ""}
            onClick={ rowClick }
            style={StyleSheet[status]}
            // key={rowAttributes?.key??''}
        >
            {cellData.map((row, index) => (
                <td
                    key={index}
                    style={row.style}
                    rowSpan={row.rowSpan}
                    colSpan={row.colSpan}
                    className={row.class}
                    onClick={row.onClick}
                >
                    {row.icon && row.icon} {row.data}
                </td>
            ))}
        </tr>
    );
}
// pistachio #93C572
// Celadon #AFE1AF
// Fern Green #4F7942
const StyleSheet={
    approved:{background:"#AFE1AF",color:'#4F7942',border:'1px solid #fff'}
}
