import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MD5hash, API_Link } from '../commons/Constants';
import { FaEye, FaMinus, FaPlus, FaStickyNote } from 'react-icons/fa';
import { GrRotateLeft } from 'react-icons/gr';
import { BsFillCircleFill, BsQuestionLg } from 'react-icons/bs';
import './../assets/sass/style.css';
import './../assets/sass/totalreview.css';

const ExcludedComponent = () => {
  const [arrayData, setArrayData] = useState([]);
  const studyLocalId = JSON.parse(localStorage.getItem('studyId'));
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;
  useEffect(() => {
    var config = {
      method: 'get',
      url: `${API_Link}getReviewArticles`,
      params: {
        review_id: studyLocalId,
        included: 'Yes',
        exclusion: 'Yes',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        const excludedArticles = resData.data.articles.filter(
          (article) => article.current_stage === 2
        );
        console.log(excludedArticles);
        setArrayData(excludedArticles);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log('arrayData', arrayData);

  // code for capitalize
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1) return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) =>
        shouldBeUppercase(word, index, length)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }
  const [isActive, setIsActive] = useState(null);
  const [isInclude, setIsInclude] = useState(null);
  const [isExclude, setIsExclude] = useState(null);
  const [mayBe, setmayBe] = useState(null);
  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };
  // handle Include
  const handleInclude = (index) => {
    if (isInclude === index) {
      return setIsInclude(null);
    }
    setIsInclude(index);
  };
  // handle Exclude
  const handleExclude = (index) => {
    if (isExclude === index) {
      return setIsExclude(null);
    }
    setIsExclude(index);
  };
  // handle Exclude
  const handleMayBe = (index) => {
    if (mayBe === index) {
      return setmayBe(null);
    }
    setmayBe(index);
  };

  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-8'>
          <h1 className='page--heading'>Abstracts Excluded</h1>
        </div>
        <div className='col-lg-4 text-right-flex'>
          <p>
            Total Articles Count <strong>({arrayData.length})</strong>
          </p>
        </div>
      </div>
      <div className='row boxes-row'>
        {React.Children.toArray(
          arrayData && arrayData.length > 0 ? (
            arrayData.map(({ title, authors, journal, abstract }, index) => (
              <div className='col-lg-12' key={index}>
                {/* Handle Background Color Change On Include, Exclude  */}
                <div
                  className='inner--box--row'
                  style={{
                    backgroundColor:
                      isInclude === index
                        ? ' rgba(0,255,0,0.1)'
                        : isExclude === index
                        ? 'rgba(255,0,0,0.1)'
                        : mayBe === index
                        ? ' rgba(255,255,0,0.1)'
                        : '',
                  }}
                >
                  <span className='index--numbering'>{index + 1}</span>

                  <div className='width--70'>
                    {/* <label className='form-check-label' htmlFor='flexCheckDefault2'>
                    {Title}
                  </label> */}
                    <h1 className='heading3'>
                      {convertFirstLetterToUpperCase(title)}
                    </h1>
                    <p className='small text-muted'>{authors}</p>
                    {journal ? (
                      <label className='form-check-label'>
                        {' '}
                        Journal {journal}{' '}
                      </label>
                    ) : (
                      ''
                    )}
                    {/* Handle Hight of Paragraph Here */}
                    <p
                      className={
                        isActive === index
                          ? 'Increase-height--of--paragraph'
                          : 'Reduce-height--of--paragraph'
                      }
                    >
                      {abstract}
                    </p>
                  </div>
                  <div className='width-30'>
                    <div className=''>
                      <div className='btn--new--ul'>
                        <Link
                          to='#'
                          data-bs-toggle='tooltip'
                          className='link'
                          title='View'
                          onClick={() => handleClick(index)}
                        >
                          <FaEye />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className='p-3 text-muted'>
              There are no excluded abstracts articles.
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default ExcludedComponent;
