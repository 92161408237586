import React from "react";
import Aside from "./../../Components/aside";
import TopBar from "./../../Components/topBar";
import "./../../assets/css/topbar.css";
import "./../../assets/sass/_dashboard.scss";
import "./../../assets/sass/_topbar.scss";
import Reviewcard from "./../../Components/dashboardComponent/reviewcard";
import Teamgraph from "./../../Components/dashboardComponent/teamProgress";
import Progressgraph from "./../../Components/dashboardComponent/ReviewerProgress";
import PieChart from "./../../Components/dashboardComponent/pieChart";
import "./../../Components/FontAwesome/FontAwesome";
import { Col } from "react-bootstrap";
import "./../../assets/sass/style.css";
import "./../../assets/sass/style.scss";
import { useState } from "react";
import { API_Link, MD5hash } from "../../commons/Constants";
import axios from "axios";
import { useEffect } from "react";
import RecentlyCompleted from "../../Components/dashboardComponent/RecentlyCompleted";

function Dashboard(props) {
  const [data, setData] = useState([]);
  const [completed, setCompleted] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;

  const getReviewArticlesCount = () => {
    var config = {
      method: "get",
      url: `${API_Link}getInProgressReviewArticlesCount`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const APIresponse = response.data.reviews;
        console.log("response", APIresponse);
        setData(APIresponse);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getCompletedArticlesCount = () => {
    var config = {
      method: "get",
      url: `${API_Link}getCompletedReviewArticlesCount`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const APIresponse = response.data.reviews;
        console.log("response completed", APIresponse);
        setCompleted(APIresponse);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getReviewArticlesCount();
    getCompletedArticlesCount();
  }, []);
  // Inprogress Reviews Count
  const studies = data.map((study) => study.title);
  const articlesCount = data.map((study) => study.articles_count);
  const abstractArticlesCount = data.map((study) => study.abstract_articles_count);
  const fullTextArticlesCount = data.map((study) => study.fulltext_articles_count);
  const includedArticlesCount = data.map((study) => study.included_articles_count);
  // Completed Reviews Count
  const completedStudies = completed.map((study) => study.title);
  const completedArticlesCount = completed.map((study) => study.articles_count);
  const completedAbstractArticlesCount = completed.map((study) => study.abstract_articles_count);
  const completedFulltextArticlesCount = completed.map((study) => study.fulltext_articles_count);
  const completedIncludeArticlesCount = completed.map((study) => study.included_articles_count);

  console.log("article count", articlesCount);
  return (
    <div className='main-wrapper'>
      <Aside />

      <div className='content-wrapper'>
        <TopBar />

        <div className='container-fluid'>
          <div className='page--content'>
            <div className='row'>
              <Col>
                <Reviewcard />
              </Col>
            </div>
            <div className='row'>
              <Col>
                <Progressgraph studies={studies} articlesCount={articlesCount} abstractArticlesCount={abstractArticlesCount} fullTextAriclesCount={fullTextArticlesCount} includedArticlesCount={includedArticlesCount} />
              </Col>
            </div>
            <div className='row'>
              <Col>
                <RecentlyCompleted studies={completedStudies} articlesCount={completedArticlesCount} abstractArticlesCount={completedAbstractArticlesCount} fullTextAriclesCount={completedFulltextArticlesCount} includedArticlesCount={completedIncludeArticlesCount} />
              </Col>
            </div>
            {/*
            <div className='row pie-chart'>
              <Col>
                <PieChart />
              </Col>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
