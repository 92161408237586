import React from "react";
import Completed from "./../../Components/screeningComponent/Completed";
import Aside from "./../../Components/aside";
import TopBar from "./../../Components/topBar";
import { useSelector } from "react-redux";
import "./../../assets/sass/style.css";
import "./../../assets/sass/_screening.scss";
import "./../../assets/sass/_topbar.scss";
import "./../../assets/css/topbar.css";

function CompletedPage() {
  const invitation = useSelector((state) => state.invitation.value);
  console.log("reducer", invitation);
  return (
    <div className='main-wrapper'>
      <Aside />

      <div className='content-wrapper'>
        <TopBar />
        <Completed />
      </div>
    </div>
  );
}

export default CompletedPage;
