import React from "react";
import logo from "./../../assets/img/Template-logo.png"
import mahtas from "./../../assets/img/mahtas-logo.png"
import { useState, useEffect } from "react";
import { API_Link } from "../../commons/Constants";
import { MD5hash } from "../../commons/Constants";
import axios from "axios";
import { Link } from 'react-router-dom';
import pdficon from './../../assets/img/pdficon.png';
import EvidenceTable from "../evidencetable";
import EvidenceTableSingle from "../evidenceTableSingle";

function TechBrief() {
    const [data, setData] = useState([]);
    const [reviewData, setreviewData] = useState([]);
    // const [Authors, setAuthors] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [outcomes, setOutcomes] = useState([])
    const [participants, setParticipants] = useState([])
    const [punchline, setPunchline] = useState([])
    const [picoSearchs, setPicoSearches] = useState([]);
    const [abstract, setAbstract] = useState([])
    const [prismaValue, setPrismaValue] = useState([])
    const [reasons, setReasons] = useState([])
    const [n, setn] = useState([])
    // const [Author, setAuthor] = useState([]);
    const [year, setYear] = useState([])
    const [title, setTitle] = useState([])
    const [pages, setPages] = useState([])
    const [pmid, setPmid] = useState([])
    const [judgement, setJudgment] = useState([])
    const [rct, setRct] = useState([])
    const [findings, setFindings] = useState([])
    const [multidata, setMultidata] = useState([])
    const [maintitle, setMaintitle] = useState([])
    const [title1, setTitle1] = useState([])
    const [biasesData, setBiasesData] = useState([]);
    const [judgementData, setJudgmentData] = useState([]);
    const [picoData, setPicoData] = useState([]);

    // 
    const [articleData, setArticleData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [finalData, setFinalData] = useState([]);
    const [studyDesign, setStudyDesign] = useState([]);
    const [objectives, setObjectives] = useState([]);
    const [methodologies, setMethodologies] = useState([]);
    const [results, setResults] = useState([]);
    const [conclusions, setConclusions] = useState([]);
    const [loe, setLoe] = useState([]);
    // 

    const styles = {
        tdStyling: {
            backgroundColor: '#eaefef',
            padding: '5px',
            border: '1px solid #000',
            verticalAlign: 'top',
        },
        thStyling: {
            border: '1px solid black',
            textAlign: 'center',
            color: '#219ebc',
            backgroundColor: '#d9e2f3',
        },
        tableStyling: {
            borderCollapse: 'collapse',
            width: '100%',
            padding: '10px',
            height: 'auto',
            backgroundColor: '#00b0f0',
            color: 'white',
        },
    };

    const getReviews = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;
        const studyId = localStorage.getItem('studyId')
        var config = {
            method: "get",
            url: `${API_Link}getReviews`,
            headers: {
                "misr-auth": MD5hash,
                Authorization: `Bearer ${token}`,
            },
            params: {
                id: studyId,
            },
        };
        axios(config)
            .then(function (response) {
                const APIresponse = response.data.reviews;
                console.log('DATA is here :', APIresponse)
                setMaintitle(APIresponse)
                const title1 = APIresponse[0].title
                console.log("TITLE:", title1)
                setTitle1(title1)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getReviewData = () => {

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;
        const studyId = localStorage.getItem('studyId')

        var config = {
            method: "get",
            url: `${API_Link}getExtractedReportJson?review_id=${studyId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "misr-auth": MD5hash
            },

        }
        axios(config)
            .then(function (response) {
                const Response1 = response.data.data[0].report_json;
                const Response2 = JSON.parse(Response1)
                const ResponseData = Response2.article_data[0]
                setreviewData(ResponseData)
                // const Authors = ResponseData.dubious.authors;
                // setAuthors(Authors)
                const interventions = ResponseData.ml.pico_span.interventions;
                setInterventions(interventions)
                const abstract = ResponseData.dubious.abstract;
                setAbstract(abstract)
                const outcomes = ResponseData.ml.pico_span.outcomes;
                setOutcomes(outcomes)
                const participants = ResponseData.ml.pico_span.population;
                setParticipants(participants)
                const punchline = ResponseData.ml.punchlines;
                setPunchline(punchline)
                const n = ResponseData.ml.sample_size;
                setn(n)
                // const Author = ResponseData.dubious.authors[0].forename;
                // setAuthor(Author)
                const year = ResponseData.dubious.year;
                setYear(year)
                const title = ResponseData.dubious.title;
                setTitle(title)
                const pages = ResponseData.dubious.pages;
                setPages(pages)
                const pmid = ResponseData.dubious.pmid;
                setPmid(pmid)
                const judgement = ResponseData.ml.bias;
                setJudgment(judgement)
                const rct = ResponseData.ml.rct;
                setRct(rct)
                const findings = ResponseData.ml.finding_direction;
                setFindings(findings)
                const multidata = Response2.article_data
                console.log("MultiDATA: ", multidata)
                setMultidata(multidata)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getReviewEvidence = () => {

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;
        const studyId = localStorage.getItem('studyId')

        var config = {
            method: "get",
            url: `${API_Link}getReviewEvidence?review_id=${studyId}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "misr-auth": MD5hash
            },

        }
        axios(config)
            .then(function (response) {
                const APIresponse = response.data.evidences;
                console.log('DATA is here :', APIresponse)
                setData(APIresponse)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getPicoSearches = () => {
        let review_id = localStorage.getItem("studyId")

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;

        var config = {
            method: "get",
            url: `${API_Link}getPicoSearches?review_id=${review_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "misr-auth": MD5hash
            }
        }
        axios(config)
            .then(function (response) {
                setPicoSearches(response.data.picoSearches.filter((item) => item.database !== 'RISimport'))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        articleData.forEach(article => {
          const evidenceJson = article.evidence_json;
          if (evidenceJson) {
            try {
              const evidence = JSON.parse(evidenceJson);
              console.warn('evidence', evidence)
              if (evidence) {
                const study_Design = evidence[0].study_design;
                const e_objectives = evidence[0].objectives;
                const e_methodologies = evidence[0].methodologies;
                const e_outcomes = evidence[0].outcomes;
                const e_results = evidence[0].results;
                const e_conclusions = evidence[0].conclusions;
                const e_loe = evidence[0].loe;
    
                // Do something with the variables
    
                console.warn('studyDesign', study_Design);
    
                if (studyDesign) {
                  setStudyDesign(study_Design);
                }
                e_objectives && setObjectives(e_objectives);
                e_methodologies && setMethodologies(e_methodologies);
                e_outcomes && setOutcomes(e_outcomes);
                e_results && setResults(e_results);
                e_conclusions && setConclusions(e_conclusions);
                e_loe && setLoe(e_loe);
              }
            } catch (error) {
              console.error(`Failed to parse JSON for article ${article.article_id}: ${error}`);
            }
          } else {
            console.error(`evidence_json is undefined for article ${article.article_id}`);
          }
        });
      }, [finalData]);

    const PrismaComponent = () => {

        const user = JSON.parse(localStorage.getItem('user'))
        const studyId = localStorage.getItem('studyId')
        const token = user.token

        var config = {
            method: 'get',
            url: `${API_Link}getPrismaCounts?review_id=${studyId}`, headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token,
                'misr-auth': MD5hash
            }
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                setPrismaValue(resData.data.counts);
                console.log('getPrisma', prismaValue)
                console.warn('reasons', resData.data.counts[0].excluded)
                setReasons(resData.data.counts[0].excluded)
            })
            .catch(function (error) {
                console.log(error);
            });


    }

    const getReviewArticles = () => {

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;
        const studyId = localStorage.getItem('studyId')
    
        var config = {
          method: "get",
          url: `${API_Link}getReviewArticles?review_id=${studyId}&fulltext=Yes&exclusion=No`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            "misr-auth": MD5hash
          },
    
        }
        setLoader(true)
        axios(config)
          .then(function (response) {
            const resData = response;
            // document.querySelector(".preload").style.display = "none"//stop the load
            console.warn('resData', resData)
            const notNull = resData.data.articles.filter((article) => article.current_stage > 3 && article.file_path !== null || article.source !== null);
            const result = notNull.map(({ id, title, authors, journal }) => ({ id, title, authors, journal }));
            setArticleData(result);
            // const finalData1 = articleData.map((item, i) => Object.assign({}, item, data[i]));
    
    
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    
    // 
    const getReviewBiases = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const studyId = localStorage.getItem('studyId');
        const token = user.token;

        var config = {
            method: 'get',
            url: `${API_Link}getReviewBiases?review_id=${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'misr-auth': MD5hash,
            },
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                setBiasesData(resData.data.biases);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const getReviewJudgements = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const studyId = localStorage.getItem('studyId');
        const token = user.token;

        var config = {
            method: 'get',
            url: `${API_Link}getReviewJudgements?review_id=${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'misr-auth': MD5hash,
            },
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                setJudgmentData(resData.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const getReviewPico = () => {
        const user = JSON.parse(localStorage.getItem('user'));
        const studyId = localStorage.getItem('studyId');
        const token = user.token;

        var config = {
            method: 'get',
            url: `${API_Link}getReviewPicoSentences?review_id=${studyId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
                'misr-auth': MD5hash,
            },
        };
        localStorage.setItem('pdfAId', '');
        axios(config)
            .then(function (response) {
                const resData = response;
                setPicoData(resData.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    // 
    const headings1 = [
        { id: 1, headingText: 'Random sequence generation' },
        { id: 2, headingText: 'Allocation concealment' },
        { id: 3, headingText: 'Blinding of participants and personnel' },
        { id: 4, headingText: 'Blinding of outcome assessment' },
        { id: 5, headingText: 'Incomplete outcome data' },
        { id: 6, headingText: 'Selective reporting' },
    ];
    const headings2 = [
        { id: 1, headingText: 'Participants' },
        { id: 2, headingText: 'Intervention' },
        { id: 3, headingText: 'OutComes' },
    ];
    const output = [];

    const tableRows = [];
    if (judgementData.judgements) {
        var topLevelKeys = Object.keys(judgementData.judgements);
        console.log('output', topLevelKeys);
    }
    if (picoData.picos) {
        var picoIds = Object.keys(picoData.picos);
        console.log('pico ids', picoIds);
    }
    useEffect(() => {
        getReviewEvidence();
        getReviewData();
        getPicoSearches();
        PrismaComponent();
        getReviews();
        getReviewBiases();
        getReviewJudgements();
        getReviewArticles();
    }, [])

    const judgements = judgementData.judgements;
    const picos = picoData.picos;
    return (
        <div>
            <div style={{ paddingLeft: "20px", paddingRight: "20px", marginBottom: "50px" }}>
                <div style={{ display: "flex", }}>
                    <span>
                        <p style={{ textAlign: "left", height: "100px", width: "150px", marginRight: "100px" }}><img src={logo} style={{ height: "50px" }} /> </p>
                    </span>
                    <span>
                        <p style={{ textAlign: "left", height: "100px", width: "350px" }}><img src={mahtas} style={{ height: "50px" }} /> </p>
                    </span>
                </div>

                <table style={{ width: "100%", fontWeight: 'bold' }}>
                    <tr>
                        <td style={{ width: "70%", backgroundColor: "white", textAlign: "left", fontSize: "28px", border: "1px solid black", padding: '5px' }}>
                            <span style={{ color: '#365F91' }}>TechBrief</span> Horizon Scanning</td>
                        <td style={{ border: "1px solid black", fontSize: "20px", padding: '5px' }}>
                            Report No.: 000/year</td>
                    </tr>
                </table>
                <div style={{ textAlign: "center", padding: "5px" }}><p style={{ fontWeight: "bold", fontSize: "26px" }}>{title1}</p></div>
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>Abstract </strong></div> <br />
                <p style={{ textAlign: 'justify', fontFamily: 'sans-serif', fontSize: '16px' }}>{abstract}</p>
                <br /><br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>EXECUTIVE SUMMARY </strong></div>
                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>•	Short summary of report</li>
                    </ul>
                    <p style={{ fontStyle: "italic", textAlign: "left", marginTop: "10px" }}> Keywords: </p>
                </div>
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>INTRODUCTION</strong></div><br />

                <p style={{ textAlign: "left" }}>Briefly on the technology and the related disease  </p>
                <br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>THE TECHNOLOGY </strong></div><br />
                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>    •Photo of the technology (if available )</li>
                        <li>	•	What is it (the technology)?</li>
                        <li>	•	Type of technology</li>
                        <li>	•	How does it work / mechanism of action?</li>
                        <li>	•	What is the expected outcome?</li>
                        <li>	•	Who is the developer?</li>
                        <li>	•	In what the current stage of development?</li>
                        <li>	•	What is the regulatory status/approval status?</li><br />
                        <p>         <b>*Pharmaceutical</b></p>
                        <li>	•	What is it (the technology)?</li>
                        <li>	•	What is its place in the treatment pathway (e.g. first line, second lines)?</li>
                        <li>	•	What class of drug or pharmacological action of this product</li>
                        <li>	•	Is it a new class of drug for the patient group?</li>
                        <li>	•	What other indication is the product licensed for?</li>
                        <li>	•	What is the route of administration? (e.g., oral, subcutaneous, intravenous)</li>
                        <li>	•	(short or infusion)?</li>
                        <li>	•	What is/are the treatment schedule and/or combination (e.g., once a day,	twice a day, day 1 - 5 in a 28 days’ cycle)?
                        </li>
                    </ul>
                </div>
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>PATIENT GROUP AND INDICATION</strong></div>
                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>	•	Intended to be used in which group of patients?</li>
                        <li>	•	What is the clinical need and burden of the disease?</li></ul></div><br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>CURRENT PRACTICE </strong></div>
                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>	•	What is the current practice/existing technology for the disease/patient group?</li>
                        <li>	•	Compare existing technology with advantages/benefits of the new Technology </li></ul></div><br />

                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>SAFETY AND EFFICACY</strong></div>
                <p style={{ padding: '5px' }}>Evidence on safety and efficacy </p>
                <br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>ESTIMATED COST </strong></div><br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>OTHER ISSUES </strong></div>
                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>•	Organisational</li>
                        <li>•	Societal/ethical</li></ul></div><br />
                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>POTENTIAL IMPACT </strong></div><br />

                <div style={{ textAlign: "left", padding: "10px " }}>
                    <ul>
                        <li>•	Clinical - mortality, morbidity, quality of life, diagnosis</li>
                        <li>•	Costs</li>
                        <li>•	Organisation - health services, infrastructure, human resources</li>
                        <li>•	Social, ethical, legal concerns</li></ul></div><br />
                <br />

                <table style={{ borderCollapse: "collapse", width: "100%", padding: "10px", height: "auto", backgroundColor: '#365F91', color: 'white' }}>
                    <tr>
                        <th style={{ width: "95%", textAlign: "left", backgroundColor: '#365F91', padding: "10px", }}>Risk of bias table </th>
                    </tr>
                </table>

                <table style={{ width: "800px", marginLeft: 'auto', marginRight: 'auto', borderCollapse: 'collapse', border: 'none', padding: 0, marginTop: '10px' }}>
                    <tr class='bias-header-row'>
                        <th style={{ verticalAlign: 'bottom', textAlign: 'left', margin: '20px' }}>trial</th>
                        <th style={{ verticalAlign: 'bottom', textAlign: 'left' }}>design</th>

                        {
                            judgement.map((item) => {
                                return (
                                    <th style={{ height: '70px', whiteSpace: 'normal' }}><div style={{ width: '100px', fontSize: '14px', padding: '10px' }}><span>{item.domain}</span></div></th>
                                )
                            })
                        }
                    </tr>
                    {
                        multidata.map((item, index) => {
                            return <>
                                <tr key={index}>
                                    <td>{item.dubious.length === 0 ? <p>{item.pubmed.authors && item.pubmed.authors[0].forename} {item.pubmed.year}</p> :
                                        item.dubious.authors[0].forename} {item.dubious.year}</td>
                                    <td style={{ padding: '2px' }}>
                                        {
                                            item.ml.rct.is_rct === false ? <p>Not Rct</p> : <p>Rct</p>
                                        }
                                    </td>
                                    {
                                        item.ml.bias.map((bi) => {
                                            return (
                                                bi.judgement === 'high/unclear' ? <td style={{ backgroundColor: "red", color: 'white', textAlign: 'center', width: '40px', }}>?</td> :

                                                    <td style={{ backgroundColor: "green", color: 'white', textAlign: 'center', width: '40px', }}>+</td>

                                            )
                                        })}
                                </tr>
                            </>

                        })
                    }


                    {/* <tr>
                        <td class="risk-studyid">

                            {Author}, {year}

                        </td>
                        <td class="risk-studyid">


                            {rct.is_rct === false ? <p>Not Rct</p> : <p>Rct</p>}


                        </td>

                        {
                            judgement.length > 0 &&
                            judgement.map((item) => {
                                return (
                                    item.judgement === 'high/unclear' ?
                                        <td style={{ backgroundColor: "red", color: 'white', textAlign: 'center' }}>?</td> :

                                        <td style={{ backgroundColor: "green", color: 'white', textAlign: 'center' }}>+</td>
                                )
                            })
                        }


                    </tr> */}

                </table>
                {topLevelKeys &&
                    topLevelKeys.map((articleId) => {
                        return (
                            <table className='table table-bordered table-striped mt-4 judgement-table'>
                                <thead>
                                    <tr>
                                        <th style={styles.thStyling}>Bias</th>
                                        <th style={styles.thStyling}>Judgment</th>
                                        <th style={styles.thStyling}>Support for judgement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {headings1 &&
                                        headings1.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>{item.headingText}</td>
                                                    <td>High/Unclear</td>
                                                    <td>
                                                        {judgements &&
                                                            judgements[articleId][index + 1] &&
                                                            judgements[articleId][index + 1].map(
                                                                (judgement, idx) => {
                                                                    return (
                                                                        <div className=''>
                                                                            <span>{judgement.support_text}</span>
                                                                            <Link
                                                                                className='Editpdf-icon'
                                                                                to={`/biasbot/${articleId}`}
                                                                            >
                                                                                <img
                                                                                    src={pdficon}
                                                                                    alt=''
                                                                                    height='17px'
                                                                                />
                                                                                <span className='tooltiptextannotation'>
                                                                                    Jump to Annotation
                                                                                </span>
                                                                            </Link>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        );
                    })}

                {picoIds &&
                    picoIds.map((articleId) => {
                        return (
                            <table className='table table-bordered table-striped mt-4 judgement-table'>
                                <thead>
                                    <tr>
                                        <th style={styles.thStyling}>PICO</th>
                                        <th style={styles.thStyling}>Judgment</th>
                                        <th style={styles.thStyling}>Support for Pico</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {headings2 &&
                                        headings2.map((item, index) => {
                                            return (
                                                <tr key={item.headingText}>
                                                    <td>{item.headingText}</td>
                                                    <td>High/Unclear</td>
                                                    <td>
                                                        {picos &&
                                                            picos[articleId][index + 1] &&
                                                            picos[articleId][index + 1].map((pico, idx) => {
                                                                return (
                                                                    <div key={idx}>
                                                                        <span>{pico.pico_text}</span>
                                                                        <Link
                                                                            to={`/picobot/${articleId}`}
                                                                            className='Editpdf-icon'
                                                                        >
                                                                            <img src={pdficon} alt='' height='17px' />
                                                                            <span className='tooltiptextannotation'>
                                                                                Jump to Annotation
                                                                            </span>
                                                                        </Link>
                                                                    </div>
                                                                );
                                                            })}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        );
                    })}
                {/* Pico Bot */}
                <div style={{ padding: "10px", height: "auto", backgroundColor: '#365F91', color: 'white' }}><strong>  CHARACTERISTICS OF STUDIES</strong></div><br />
                {/* {
                    multidata.map((item) => {
                        return (
                            <>
                                <p style={{ fontSize: '20px' }}><b>{item.grobid.length === 0 ? '' : item.grobid.authors[0].forename}, {item.grobid.year}</b></p><br />
                                <table style={{ border: "1px solid black", padding: "2px" }}>
                                    <>
                                        <tr style={{ border: "1px solid black", padding: "2px" }}>
                                            <td style={{ color: "#219ebc", backgroundColor: "#d9e2f3", padding: '5px' }}>Participants</td>
                                            <td style={{ border: "1px solid black", textAlign: 'justify', padding: '5px' }}>{
                                                item.ml.pico_span.population.map((inter) => inter)
                                            }
                                            </td>
                                        </tr>
                                        <tr style={{ border: "1px solid black", padding: "2px" }}>
                                            <td style={{ color: "#219ebc", backgroundColor: "#d9e2f3", padding: '5px' }}>Interventions</td>
                                            <td style={{ border: "1px solid black", textAlign: 'justify', padding: '5px' }}>{
                                                item.ml.pico_span.interventions.map((inter) => inter)
                                            }</td>
                                        </tr>
                                        <tr style={{ border: "1px solid black", padding: "2px" }}>
                                            <td style={{ color: "#219ebc", backgroundColor: "#d9e2f3", padding: '5px' }}>Outcomes</td>
                                            <td style={{ border: "1px solid black", textAlign: 'justify', padding: '5px' }}>{
                                                item.ml.pico_span.outcomes.map((inter) => inter)
                                            }</td>
                                        </tr>
                                    </>
                                </table>

                                <table className='table table-striped blue-bg-th-table' style={{ borderCollapse: "collapse", width: "100%", border: "1px solid black", marginTop: '15px' }}>
                                    <tr style={{ border: "1px solid black", }}>

                                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Bias </th>
                                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Judgment </th>

                                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }} > Support for judgement </th>



                                    </tr>



                                    {item.ml.bias.map((li) => {
                                        return (
                                            <tr>
                                                <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{li.domain}</td>
                                                <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{li.judgement}</td>
                                                <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                                    {
                                                        li.annotations.map((sup, index) => {
                                                            return (
                                                                <p><b>{index + 1}</b> {sup.content}</p>
                                                            )
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                        )

                                    })}
                                </table></>
                        )

                    })
                } */}
                <br />

                {biasesData && biasesData.map((item) => {
                    return (
                        <>
                            <p>
                                <strong>{item.author_year}</strong>
                            </p>
                            <table style={{ border: '1px solid black', padding: '2px' }}>
                                <tr style={{ border: '1px solid black', padding: '2px' }}>
                                    <td
                                        style={{
                                            color: '#219ebc',
                                            backgroundColor: '#d9e2f3',
                                            padding: '5px',
                                        }}
                                    >
                                        Participants
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid black',
                                            textAlign: 'justify',
                                            padding: '5px',
                                        }}
                                    >
                                        {item.population}
                                    </td>
                                </tr>
                                <tr style={{ border: '1px solid black', padding: '2px' }}>
                                    <td
                                        style={{
                                            color: '#219ebc',
                                            backgroundColor: '#d9e2f3',
                                            padding: '5px',
                                        }}
                                    >
                                        Interventions
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid black',
                                            textAlign: 'justify',
                                            padding: '5px',
                                        }}
                                    >
                                        {item.interventions}
                                    </td>
                                </tr>
                                <tr style={{ border: '1px solid black', padding: '2px' }}>
                                    <td
                                        style={{
                                            color: '#219ebc',
                                            backgroundColor: '#d9e2f3',
                                            padding: '5px',
                                        }}
                                    >
                                        Outcomes
                                    </td>
                                    <td
                                        style={{
                                            border: '1px solid black',
                                            textAlign: 'justify',
                                            padding: '5px',
                                        }}
                                    >
                                        {item.outcomes}
                                    </td>
                                </tr>
                            </table>
                        </>
                    );
                })}



                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>EVIDENCE Table </strong></div>
                <p style={{ textAlign: "left" }}>List of studies conducted on the technology (published papers / abstracts / unpublished paper / ongoing studies / conference paper presentation) - Based on available evidence up to (date)</p>
                {/* <table className='table table-striped blue-bg-th-table' style={{ borderCollapse: "collapse", width: "100%", border: "1px solid black", marginTop: '15px' }}>
                    <tr style={{ border: "1px solid black", }}>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Bibliographic citation </th>
                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Study type / Methodology </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }} > LE </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Number of Patients & Patient Characteristic </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Intervention </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Comparison </th>
                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Length of Follow Up (If Applicable) </th>
                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Outcome Measures/Effect Size </th>
                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> General Comments </th>
                    </tr>

                    {
                        data.length === 0 ? <td colspan="9" className="text-center bg-danger text-light"> <strong>You don't have any Evidence Data</strong> </td> : ''
                    }
                    {
                        data && data.map((item, { biblographic_citation, study_type, le, patient_characteristics, intervention, comparison, length_of_followup, outcome_measure_and_effect_size, comments }) => {
                            return (
                                <tr>
                                    
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{item.study_design}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{study_type}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{le}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{patient_characteristics}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{intervention}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{comparison}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{length_of_followup}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{outcome_measure_and_effect_size}</td>
                                    <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>{comments}</td>
                                </tr>
                            )
                        })
                    }

                </table> */}
                <EvidenceTableSingle />
               
                <br />
                <div style={{ padding: "10px", height: "auto", backgroundColor: '#365F91', color: 'white', marginTop: '10px' }}><strong>Trail Summaries </strong></div>
                <table className='table table-striped blue-bg-th-table' style={{ borderCollapse: "collapse", width: "100%", border: "1px solid black", marginTop: '15px' }}>
                    <tr style={{ border: "1px solid black", }}>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> n </th>
                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Participants </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }} > Interventions </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> Outcomes </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> punchline </th>

                        <th style={{ border: "1px solid black", textAlign: 'center', color: "#219ebc", backgroundColor: "#d9e2f3" }}> finding </th>
                    </tr>
                    {
                        interventions.length !== 0 && participants.length !== 0 && outcomes.length !== 0 && punchline.length !== 0 ? <td colspan="9" className="text-center bg-danger text-light"> <strong>You don't have any Trail Summaries</strong> </td> :


                            articleData.map((item) => {
                                return (
                                    <tr>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}><strong>Title: </strong>{item.title} <br></br> <strong>Authors:</strong> {item.authors}</td>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                            {/* {
                                                item.ml.pico_span.population.map((inter) => inter)
                                            } */}

                                        </td>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                            {/* {
                                                item.ml.pico_span.interventions.map((inter) => inter)
                                            } */}

                                        </td>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                            {/* {
                                                item.ml.pico_span.outcomes.map((inter) => inter)
                                            } */}

                                        </td>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                            {/* {item.ml.punchlines} */}
                                            </td>
                                        <td style={{ border: "1px solid black", verticalAlign: 'top', padding: '5px' }}>
                                            {/* {item.ml.finding_direction} */}

                                        </td>
                                    </tr>
                                )
                            })

                    }
                </table>


                <div style={{ padding: "1px", textAlign: "left", backgroundColor: '#365F91', color: "white", fontWeight: "bold" }}><strong>REFERENCES </strong></div>       <br />
                <div>
                    {multidata.map((item, index) => {

                        return <>
                            {
                                item.dubious.length === 0 ? <p style={{ justifyContent: 'space-between' }}>
                                    {index + 1}.  <span style={{ marginLeft: '5px' }}></span>
                                    {item.pubmed.length === 0 ? '' :
                                        item.pubmed.authors[0].forename} ,
                                    {item.pubmed.length === 0 ? '' :
                                        item.pubmed.authors[0].lastname},
                                    {item.pubmed.title} ,     {item.pubmed.pages}, PMID: {item.pubmed.pmid}</p> :
                                    <p style={{ justifyContent: 'space-between' }}>
                                        {index + 1}.  <span style={{ marginLeft: '5px' }}></span>
                                        {item.dubious.length === 0 ? '' :
                                            item.dubious.authors[0].forename} ,
                                        {item.dubious.length === 0 ? '' :
                                            item.dubious.authors[0].lastname},
                                        {item.dubious.title} ,     {item.dubious.pages}, PMID: {item.dubious.pmid}</p>
                            }
                        </>

                    })}
                </div>
                <br />
                <p style={{ textAlign: "left" }}><strong>Prepared by:</strong></p><br />
                <p style={{ textAlign: "left" }}><strong>Reviewed by:</strong></p><br />

                <div style={{ border: "1px solid black", textAlign: "left", paddingLeft: "10px " }}>
                    <p >
                        <strong>Disclosure: </strong> The author of this report has no competing interest in this subject and the preparation of this report is totally funded by the Ministry of Health, Malaysia.</p>
                    <p> <strong>Disclaimer:</strong> TechBrief report is prepared based on information available at the time of research and a limited literature. It is not a definitive statement on the safety, effectiveness or cost effectiveness of the health technology covered. Additionally, other relevant scientific findings may have been reported since completion of this report.</p>
                    <p></p>
                    <div style={{ textAlign: "left", paddingLeft: "10px " }}>
                        Horizon Scanning Unit, MaHTAS, <br />
                        Medical Development Division, <br />
                        Ministry of Health, Malaysia<br />
                        Email:  horizonscanningunit.cptk@moh.gov.my<br />
                        Web: http://www.moh.gov.my<br />

                    </div>
                </div>


            </div>
            <div style={{ padding: "10px", height: "auto", backgroundColor: '#365F91', color: 'white', marginTop: '10px' }}><strong>PICO Result </strong></div>
            <div>
                {
                    picoSearchs?.length > 0 ? picoSearchs.map((search, index) => (
                        <tr key={index}> <strong><br />
                            <td>{index + 1}  {search.database}</td>
                            <td>{search.population}</td>
                            <td>{search.intervention}</td>
                            <td>{search.comparison}</td>
                            <td>{search.outcome}</td>
                            <td>{search.included}</td>
                            <td>{search.total_records}</td>

                        </strong></tr>
                    )) :

                        <tr>
                            <td colSpan={7} className="text-center text-muted">You have no pico search History</td>
                        </tr>
                }
            </div>
            <br />
            <div >
                {
                    prismaValue && prismaValue.length > 0 && prismaValue.map(() => {
                        return (
                            <>
                                <div style={{ padding: "10px", height: "auto", backgroundColor: '#365F91', color: 'white' }}><strong>Prisma Flowchart</strong></div>
                                <div className='container'>
                                    <div className='row pt-5'>
                                        <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                                            <div className='identification'>
                                                <p className='text'>Identification</p>
                                            </div>
                                        </div>
                                        <div className='col-col-sm-12 col-md-9 col-lg-10'>
                                            <div className='row mb-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-6 mt-2 '>
                                                    <div className='Number1972'>Number of records identified through electronic databases searching (n={prismaValue[0].onlineSearch})</div>
                                                </div>

                                                <div className='col-sm-12 col-md-12 col-lg-6 mt-2'>
                                                    <div className='Number53'>Number of additional records identified from other sources (n={prismaValue[0].otherSources})</div>
                                                </div>
                                            </div>

                                            <div className='row '>
                                                <div className='col-6'>
                                                    <span className='arrow-icon-prisma'>
                                                        {/* <i className='bi bi-arrow-down-short'></i> */}

                                                        ↓
                                                    </span>
                                                </div>
                                                <div className='col-6'>
                                                    <span className='arrow-icon-prisma'>
                                                        ↓
                                                    </span>
                                                </div>
                                            </div>

                                            <div className='row pt-2 mb-3 '>
                                                <div className='col-12'>
                                                    <div className='Num2055'>Number of records after duplicates removed (n={prismaValue[0].abstract_articles_count})</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-3'></div>
                                        <div className='col-sm-12 col-md-9 col-lg-10'>
                                            <span className='arrow-icon-prisma'>
                                                ↓
                                            </span>
                                        </div>
                                    </div>

                                    <div className='row '>
                                        <div className='col-sm-12 col-md-3 col-lg-2 mt-2'>
                                            <div className='Screening'>
                                                <p className='text'>Screening</p>
                                            </div>
                                            <div className='row pt-2'>
                                                <div className='col-sm-12 col-md-3 col-lg-2 '>
                                                    <div className='included'>
                                                        <p className='text'>included</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-col-sm-12 col-md-9 col-lg-10'>
                                            <div className='row mb-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-1 '></div>
                                                <div className='col-sm-12 col-md-12 col-lg-5 mt-2'>
                                                    <div className='num_1925'>Number of records screened (n={prismaValue[0].abstract_articles_count})</div>
                                                </div>

                                                <div className='col-sm-12 col-md-12 col-lg-1'>
                                                    <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                                                        {/* <i className='bi bi-arrow-down-short'></i> */}
                                                        →
                                                    </span>
                                                </div>

                                                <div className='col-sm-12 col-md-12 col-lg-5 mt-2 '>
                                                    <div className='num1200'>Number of records excluded (n={prismaValue[0].abstract_articles_count - prismaValue[0].fulltext_articles_count})</div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-7'>
                                                    <span className='arrow-icon-prisma'>
                                                        {/* <i className='bi bi-arrow-down-short'></i> */}
                                                        ↓
                                                    </span>
                                                </div>
                                            </div>

                                            <div className='row '>
                                                <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '></div>
                                                <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5 '>
                                                    <div className='row mb-3'>
                                                        <div className='col'>
                                                            <div className='num685'>Number of full-text articles assessed for eligibility (n={prismaValue[0].fulltext_articles_count})</div>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-12 col-md-12 col-lg-12'>
                                                        <span className='arrow-icon-prisma'>
                                                            {/* <i className='bi bi-arrow-down-short'></i> */}

                                                            ↓
                                                        </span>
                                                    </div>
                                                    <div className='row '>
                                                        <div className='col'>
                                                            <div className='mt-2 yelllowbox'>Number of full-text articles included in qualitative synthesis (n={prismaValue[0].included_articles_count})</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 col-md-12 col-lg-1 col-xxl-1 '>
                                                    <span className='arrow-icon-prisma text-icon arrow-icon-prisma-right'>
                                                        {/* <i className='bi bi-arrow-down-short'></i> */}
                                                        →
                                                    </span>
                                                </div>
                                                <div className='col-sm-12 col-md-12 col-lg-5 col-xxl-5'>
                                                    <div className='num432'>Number of full-text articles excluded (n={prismaValue[0].fulltext_articles_count - prismaValue[0].included_articles_count}) <br></br> Reasons:

                                                        {
                                                            reasons && reasons.filter(exclusion => exclusion.stage === 3).map(({ purpose, total }, index) => {
                                                                return (
                                                                    <small>
                                                                        <br></br>
                                                                        {purpose.label} (n = {total})
                                                                    </small>
                                                                )
                                                            })
                                                        }

                                                        {/* <br></br> <small> Irrelevant study design (n=2)
                                                        <br></br>Irrelevant intervention (n=1)
                                                        <br></br>  Irrelevant outcome (n=1)</small> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )

                    })
                }

            </div>
        </div>
    )
}
export default TechBrief;