import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Screentopbar from "../screeningComponent/screentopbar";
import { FaEye, FaMinus, FaPlus, FaStickyNote } from "react-icons/fa";
import { GrRotateLeft } from "react-icons/gr";
import { BsFillCircleFill, BsQuestionLg } from "react-icons/bs";
import { API_Link, MD5hash, API_SRC } from "../../commons/Constants";
// import ReactTimeAgo from "react-time-ago";
import "./../../assets/sass/style.css";
import "./../../assets/sass/totalreview.css";
//import  "./../../assets/sass/_screening.scss"
import axios from "axios";
import { parseExpression } from "@babel/parser";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import paperclip from "./../../assets/img/icon/paperclip.svg";
import { useSelector, useDispatch } from 'react-redux'
import { getPermission } from "../../features/setting/permissionSlice";

function TotalReviews(props) {
  //
  // const options = JSON.parse(localStorage.getItem("search"));

  //
  const [permission1] = useSelector((state) => state.getPermission.data)
  const [options, setOptions] = useState([]);
  const [exclusionPurpose, setexclusionPurpose] = useState([]);
  const [isGreen, setIsGreen] = useState(new Array(options.length).fill(false));
  // const [isRed, setIsRed] = useState(new Array(options.length).fill(false));
  const [isRed, setIsRed] = useState([]);
  const [show, setShow] = useState(false);
  const [tabKey, initTabKey] = useState("one");
  const [excluded, setExcluded] = useState([]);
  const ref = React.useRef();
  const [height, setHeight] = React.useState("0px");
  const user = JSON.parse(localStorage.getItem("user"));
  const studyId = localStorage.getItem("studyId");
  const token = user.token;
  const [selectArticle, setSelectArticle] = useState([]);

  // call for get reviewer permissions
  const [getPermission, setGetPermission] = useState([]);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [dataSetUrl, setDataSetUrl] = useState('');

  const [article, setArticle] = useState([]);
  const [articleId, setArticleId] = useState([]);


  const handleOpenModal2 = (index, id) => {
    setShowModal2(true);

    options.find((article) => {
      if (article.id === id) {
        setArticle([article]);
        setArticleId(article.id);
        setDataSetUrl(article.source);
      } else {
        // return article;
      }
    });
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const getPermissionCall = () => {
    var config = {
      method: "get",
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem("studyId")}`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {

        setGetPermission(response.data.permissions[0])

        console.log('after api call in search inclusion', getPermission)
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  // code for capitalize
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1)
      return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words 
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) => shouldBeUppercase(word, index, length) ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())
      .join(' ');
  }
  const getlookup = () => {
    var config = {
      method: "get",
      url: `${API_Link}getLookupsList?options=true&title=exclusion_purpose`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        console.log("This is lookup call", resData.data.lookups);
        const purpose = resData.data.lookups[0].options;
        console.warn(purpose);
        const filterExclusionPurpose = purpose.filter((item) => item.value !== 1 && item.value !== 9);
        setexclusionPurpose(filterExclusionPurpose);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [payloadArray, setPayloadArray] = useState([]);
  console.log("exclusion", exclusionPurpose);

  useEffect(() => {
    getPermissionCall();
    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles?review_id=${studyId}&fulltext=Yes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load

        // const resDataArray = resData.data.articles.filter((article) => article.source !== null || article.file_path !== null);
        const resDataArray = resData.data.articles.filter((article) => article);


        // const newArr1 = resDataArray.map(v => ({ ...v, reason: null }))

        console.warn('resDataArray', resDataArray);

        let currentRecord = resData.data.articles.filter((option) => option.current_stage > 3);

        let partialStudentDetails = currentRecord.map(({ id, abstract_screening, reason }) => ({ id, abstract_screening: 1 }));

        let excludedAPI = resData.data.articles.filter((option) => option.reason !== null);

        let letExcluded = excludedAPI.map(({ id, abstract_screening, reason }) => ({ id, reason, abstract_screening: 0 }));

        console.log("Included", currentRecord);

        setPayloadArray(partialStudentDetails);

        setPayloadArray((current) => [...current, ...letExcluded]);

        setExcluded(resData.data.excluded);
        // setOptions(resData.data.articles);

        //setPayloadArray(newArr1)

        setOptions(
          resDataArray.map(function (obj) {
            if (obj.reaon === null) {
              var newObj = { ...obj };
              delete newObj.reason;
              return newObj;
            }
            return obj;
          })
        );

        setSelectArticle(resData.data.articles.filter((option) => option.reason !== null));

        // const filtered = resData.data.articles.filter(article => {
        //   return article.id == Array.prototype.some.call(excluded, (x) => x.id == article.id);
        // });

        // const filtered = resData.data.articles.filter(article => {
        //   return Array.prototype.some.call(excluded, (x) => x.id == article.id)
        // });

        //  console.log('testing_______________', filtered)
      })
      .catch(function (error) {
        console.log(error);
      });

    getlookup();
  }, []);

  console.log("optiosdfasdfasdfns", payloadArray);

  const checkKey = (obj, keyName) => {
    if (Object.keys(obj).indexOf(keyName) !== -1) {
      console.log("This key exists");
    } else {
      console.log("This key does not exist");
    }
  };

  console.log("Full text API response excluded", excluded);
  //

  console.log("options", options);
  const [isActive, setIsActive] = useState(null);
  const [isInclude, setIsInclude] = useState(null);
  const [isExclude, setIsExclude] = useState(null);

  const navigate = useNavigate();
  //handle increase Decrease Height
  const handleClick = (index) => {
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  // handle Include
  const handleInclude = (index, id) => {
    let result = [...isGreen];
    result[index] = !result[index];
    console.warn(result);
    setIsGreen(result);

    //

    // setOptions(
    //   options.map(function(obj) {
    //     if( obj.id ===id && obj.reaon === null) {
    //       var newObj = {...obj};
    //       delete newObj.reason;
    //       return newObj;
    //     }
    //     return obj;
    //   })
    // )
    let currentRecord = options.filter((option) => option.id === id);

    let partialStudentDetails = currentRecord.map(({ id, abstract_screening, reason }) => ({ id, abstract_screening: 1 }));
    console.log(partialStudentDetails);

    setPayloadArray((current) => [...current, partialStudentDetails[0]]);
  };

  // handle Exclude

  const handleExclude = (index, id, abstract_screening, reason) => {
    let result = [...isRed];
    result[index] = !result[index];
    setIsRed(result);

    let currentRecord = options.filter((option) => option.id === id);

    let partialStudentDetails = currentRecord.map(({ id, abstract_screening, reason }) => ({ id, abstract_screening: 0, reason: 2 }));

    setPayloadArray((current) => [...current, partialStudentDetails[0]]);

    console.log("payloadArray------------------", payloadArray);

    options.map((article) => {
      let result = [...selectArticle];
      result[index] = article.id;
      if (article.id === id) {
        setSelectArticle(result);
      } else {
        // return article;
      }
    });
  };

  //handle Undo

  const handleUndo = (index, id, current_stage, reason) => {
    let newid = id;

    if (current_stage > 3) {
      const newData = options.map(({ id, ...datum }) => (id === newid ? { id, ...datum, current_stage: 3 } : { id, ...datum }));

      const newData1 = payloadArray.map(({ id, ...datum }) => (id === newid ? { id, ...datum, current_stage: 3, abstract_screening: null } : { id, ...datum }));

      console.log("newData, ", newData);
      setOptions(newData);
      setPayloadArray(newData1);
    }

    // const updatedArray = payloadArray.map((item) => {
    //   if(item.id == id){
    //     return {...item,current_stage:3}
    //   }
    // });

    if (current_stage === 3 && reason !== null) {
      const newData = options.map(({ id, ...datum }) => (id === newid ? { id, ...datum, reason: null, abstract_screening: null } : { id, ...datum }));

      const newData1 = payloadArray.map(({ id, ...datum }) => (id === newid ? { id, ...datum, abstract_screening: null } : { id, ...datum }));

      console.log("newData, ", newData1);
      setOptions(newData);
      setPayloadArray(newData1);
    }
    // const filteredArray = payloadArray.filter((item) => item.id !== id);
    // setPayloadArray(setUndo);
    // setSelectArticle(result);

    if (isGreen[index]) {
      handleInclude(index, id);
      let result = payloadArray.filter((article) => article.id !== id);
      setPayloadArray(result);
    } else if (isRed[index]) {
      handleExclude(index, id);
      let result = payloadArray.filter((article) => article.id !== id);
      setPayloadArray(result);

      console.log("undo exclude");
    } else {
    }
  };

  const onLoad = () => {
    setHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
  };
  const handleCloseModal = (index) => {
    setShow(false);
  };

  const [file, setFile] = useState();
  const [source, setSource] = useState();
  const handleOpenModal = (index, id) => {
    options.map((article) => {
      if (article.id === id) {
        setFile(article.file_path);
        setSource(article.source);
      }
    });
    setShow(true);
  };

  //

  const [selectedClient, setSelectedClient] = useState(new Array(exclusionPurpose.length).fill([]));

  function handleSelectChange(event, index, id) {
    event.preventDefault();

    // setSelectedClient(event.target.value);

    let newid = id;

    console.log("check handle select change value ", newid);

    const newArray = payloadArray.map((item, id) => {
      if (item.id === newid) {
        return { ...item, reason: event.target.value };
      } else {
        return item;
      }
    });

    console.log("newArraynewArraynewArray", newArray);


    const uniqueDataPayload = [];
const ids = new Set();

// Iterate through the array in reverse
for (let i = newArray.length - 1; i >= 0; i--) {
  const item = newArray[i];
  if (!ids.has(item.id)) {
    ids.add(item.id);
    uniqueDataPayload.push(item);
  }
}


    setPayloadArray(uniqueDataPayload);

    console.log("updated after change", payloadArray);

    exclusionPurpose.map((value) => {
      let result = [...selectedClient];
      result[index] = event.target.value;

      setSelectedClient(result);
    });
  }

  console.log(
    "seleceted reason",
    selectedClient.filter((item) => item !== undefined)
  );
  //
  const handleFinalExclusion = async () => {
    // const excludedArticleIds = selectArticle.filter((item) => item.abstract_screening === 0 || item.abstract_screening === null).map((item) => item.id);
    // const unlabeledArticleIds = selectArticle.filter((item) => item.abstract_screening === null).map((item) => item.id);
    // const labelelArticleIds = selectArticle.filter((item) => item.abstract_screening === 0 || item.abstract_screening === 1).map((item) => item.id);

    // const excludedArticleIds = options.filter((item) => Array.prototype.some.call(excluded, (x) => x.id == item.id));

    // console.log('excludedArticles', excludedArticleIds)

    //  const labeledArticleStatus = selectArticle
    // .map(({ id, abstract_screening, reason }) => {
    //   return { id, abstract_screening, reason };
    // });

    // let reasons = selectedClient.filter((item) => parseInt(item) !== undefined);
    let data = {
      review_id: studyId,
      stage: "3",
      // exclusion_purpose: "9",
      articles: payloadArray,
      // reasons: reasons,
    };

    //console.log("labeledArticleStatus", labeledArticleStatus);

    await axios
      .post(`${API_Link}excludeArticles`, data, {
        headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, "misr-auth": MD5hash },
      })
      .then((res) => res.data)
      .finally(() => navigate("/includedfulltext"));
  };

  const countExcluded = (type = 0) => {
    const countTypes = payloadArray.filter((article) => article.abstract_screening === type);
    return countTypes.length;
  };
  console.log("payload array", payloadArray);


  // 
  const [showDelete, setShowDelete] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [getFileName, setgetFileName] = useState('');

  const handleDeleteClose = () => {
    setShowDelete(false);
  };


  const handleFileUpload = (index, id) => {
    console.log(id);
    setShow(true);

    options.find((article) => {
      if (article.id === id) {
        setArticle([article]);
        setArticleId(article.id);
        setDataSetUrl(article.source);
      } else {
        // return article;
      }
    });
  };

  const handleFileRemoved = (index, id) => {
    console.log(id);
    setShowRemove(true);
    options.find((article) => {
      if (article.id === id) {
        setArticle([article]);
        setArticleId(article.id);
      } else {
        // return article;
      }
    });
  };

  const handleDeleteArticle = () => {
    // getId
  };



  // Handle File Upload
  const handleSubmit = async (event, index) => {
    event.preventDefault();

    const result = options.map((article, index) => {
      console.log('before filter', article);
      if (articleId === article.id) {
        const result = [...isGreen];
        result[index] = !result[index];
        console.warn('state', result);
        setIsGreen(result);
      }
      return article;
    });
    // console.warn("result submit", result);
    var formData = new FormData();
    formData.append('review_id', localStorage.getItem('studyId') || 1);
    formData.append('file', selectedFile);
    formData.append('id', articleId);
    console.log('file data-----------', ...formData);
    // const fileUrl = article.map((article) => ({ ...article, url: null }));
    // console.log("url", fileUrl);
    console.log('Selected Article', articleId);

    await axios
      .post(`${API_Link}uploadFulltextData`, formData, {
        headers: {
          'misr-auth': MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Response status is not 200');
        }
        console.log('Response From Server', res.data);
      })
      .catch((error) => {
        console.error(error.message);
        // handle the error appropriately
      })
      .finally(() => setShow(false));

    window.location.reload(false);
  };

  const handleFileSelect = (event) => {
    //
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // convert bytes to MB

    if (fileSizeInMB > 3) {
      console.error('File size exceeds the limit of 3 MB');
      // handle the error appropriately
      return alert('File size exceeds the limit of 3 MB');
    }

    setSelectedFile(event.target.files[0]);

    setgetFileName(event.target.files[0].name);

    console.log('setSelectedFile', selectedFile)
    console.log('setgetFileName', getFileName)
  };

  const handleUrlSubmit = async (e) => {
    e.preventDefault();

    const result = options.map((article, index) => {
      console.log('before filter', [article]);
      if (articleId === article.id) {
        const result = [...isGreen];
        result[index] = !result[index];
        console.warn('state', result);
        setIsGreen(result);
      }
      return article;
    });

    var formData = new FormData();
    formData.append('review_id', localStorage.getItem('studyId') || 1);
    formData.append('source', dataSetUrl);
    formData.append('id', articleId);
    console.log(...formData);

    await axios
      .post(`${API_Link}uploadFulltextData`, formData, {
        headers: {
          'misr-auth': MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log('Response From Server', res.data);
      })
      .finally(() => setShow(false));
    window.location.reload(false);
  };

  const handleClose = () => setShow(false);
  return (
    <>
      <div className='page--content'>
        <div className=''>
          <div className='row'>
            <div className='col-lg-6'>
              <h1 className='page--heading'>Fulltext Screening</h1>
            </div>
            <div className='col-lg-6 text-right-flex'>
              <p>
                Total Articles Count <strong>({options.length})</strong>
              </p>
              {

                getPermission && getPermission.includes(3) ? <ul className='ul-export ms-2'>
                  <button className='btn btn-danger' onClick={handleFinalExclusion}>
                    Excluded article {countExcluded()}
                  </button>
                </ul> : ''

              }

            </div>
          </div>
          {/* <select class='form-select' value="">
            {exclusionPurpose &&
              exclusionPurpose.map(({ label, id }, index) => {
                return (
                  <option value={id}>
                    {label}
                  </option>
                );
              })}
          </select> */}
          <div className='row boxes-row'>
            {React.Children.toArray(
              options && options.length > 0 ? (
                options.map(({ title, authors, journal, abstract, id, file_path, abstract_screening, current_stage, reason }, index) => (
                  <div className='col-lg-12' key={index}>
                    <div className=''>
                      {/* Handle Background Color Change On Include, Exclude  */}
                      {/* <div className='inner--box--row'  style={{ backgroundColor: isGreen[index] ? "rgba(0,255,0,0.2)" : isRed[index] ? "rgba(255,0,0,0.2)" : "" }}></div> */}
                      {/* <div className={(excluded.includes(id) ? 'inner--box--row red' : 'inner--box--row')} style={{ backgroundColor: isGreen[index] ? "rgba(0,255,0,0.2)" : isRed[index] ? "rgba(255,0,0,0.2)" : "" }}> */}
                      <div className={current_stage > 3 ? "inner--box--row green" : reason !== null ? "inner--box--row red" : "inner--box--row"} style={{ backgroundColor: isGreen[index] ? "rgba(0,255,0,0.2)" : isRed[index] ? "rgba(255,0,0,0.2)" : "" }}>
                        <span className='index--numbering'>{index + 1}</span>

                        <div className='width--70'>
                          {/* <label className='form-check-label' htmlFor='flexCheckDefault2'>
                        {Title}
                      </label> */}
                          <h1 className='heading3'>{convertFirstLetterToUpperCase(title)}</h1>
                          <p className='small text-muted'>{authors}</p>
                          {/* <h1 className='heading3'>{Journal}</h1> */}

                          <label className='form-check-label' htmlFor='flexCheckDefault2'>
                            {/* Journal:{journal} */}
                            {journal ? <label className='form-check-label'> Journal {journal} </label> : ""}
                          </label>
                          {/* Handle Hight of Paragraph Here */}
                          <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"} onClick={() => handleClick(index)}>
                            {abstract}
                          </p>
                        </div>
                        {showModal2 && (

                          <Modal
                            className='modal-lg abstract--modal file-upload-modal modal-dialog-centered vertical-align-center'
                            show={showModal2}
                            onHide={handleCloseModal2}
                            backdrop='static'
                            keyboard={false}
                          >
                            <Modal.Header className='pt-5'>
                              <h2 className='mb-2 Active--learning--heading'>
                                Add Link or Upload File
                              </h2>
                              <button className='btn-close'
                                onClick={handleCloseModal2}
                              ></button>
                            </Modal.Header>

                            <Tabs
                              className='text-dark included-file-upload-tabs'
                              activeKey={tabKey}
                              onSelect={(e) => initTabKey(e)}
                            >
                              <Tab eventKey='one' title='File' className='modal--tab-buttons'>
                                <div className='row'>
                                  <div className='col-lg-12'>
                                    <h1>{ }</h1>
                                    <div className='mt-2 data-set-upload'>
                                      <form
                                        onSubmit={handleSubmit}
                                        className='form--ris-modal'>
                                        {!getFileName ? (
                                          <span
                                            className='text-info drag--drop-font'
                                            style={{ fontSize: '22px' }}
                                          >
                                            Drag and drop file here, or click to select file
                                          </span>
                                        ) : (
                                          <span
                                            id='fileName'
                                            className='text-info'
                                            style={{ fontSize: '22px' }}
                                          >
                                            {/* {getFileName} */}
                                          </span>
                                        )}
                                        <input
                                          id='fileUpload'
                                          type='file'
                                          name='myFile'
                                          required
                                          className='inputFileRis'
                                          onChange={handleFileSelect}
                                        />
                                        <input
                                          type='submit'
                                          value='Upload File'
                                          className='btn btn-lg btn-success upload-file-ris '
                                        />
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                              <Tab eventKey='two' title='URL' className='modal--tab-buttons'>
                                <div className='row'>
                                  <div className='col-lg-12'>
                                    <div className='mt-2 data-set-upload'>
                                      <form
                                        onSubmit={handleUrlSubmit}
                                        className='form--ris-modal'
                                      >
                                        <div>
                                          <span className='d-flex justify-content-start mb-3'>
                                            <span
                                              className='text-info '
                                              style={{ fontSize: '22px' }}
                                            >
                                              Dataset URL
                                            </span>
                                          </span>
                                          <input
                                            id='fileUpload'
                                            style={{ height: '60px' }}
                                            type='text'
                                            // value={dataSetUrl}
                                            name='myFile'
                                            required
                                            className='form-control mb-3'
                                          // onChange={(e) => handleChange(e)}
                                          />
                                          <input
                                            type='submit'
                                            value='Add URL'
                                            className='btn btn-lg btn-success upload-file-ris '
                                          />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </Tab>
                            </Tabs>
                          </Modal>

                        )}
                        <div className='width-30'>
                          <div className='d-flex flex-column'>
                            <div className=''>
                              <div className='btn--new--ul'>

                                {file_path === null ? <span style={{ "display": "inline-block" }}>

                                  <Link
                                    to='#'
                                    data-bs-toggle='tooltip'
                                    className='link'
                                    title='Upload or Link File'
                                    onClick={() => handleOpenModal2(index, id)}
                                  >
                                    <img src={paperclip} alt='' />
                                  </Link>
                                  <br />
                                  <span className='upload-link-file' ></span>
                                  <Modal
                                    className='modal-dialog-centered '
                                    show={showDelete}
                                    onHide={handleDeleteClose}
                                    backdrop='static'
                                    keyboard={false}
                                  >
                                    <Modal.Header closeButton>
                                      <Modal.Title>Confirm!</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                      This Article is Without File Would You Like to
                                      Delete it?
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <Button
                                        variant='secondary'
                                        onClick={handleDeleteClose}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant='danger'
                                        onClick={handleDeleteArticle}
                                      >
                                        Delete
                                      </Button>
                                    </Modal.Footer>
                                  </Modal>

                                  {/*  */}
                                </span> : <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleOpenModal(index, id)}>
                                  {/* <img src={paperclip} /> */}
                                  <FaEye />
                                </Link>}



                                {
                                  getPermission && getPermission.includes(3) ? <>
                                    <Link to='#' data-bs-toggle='tooltip' className='link' title='Add Note'>
                                      <FaStickyNote />
                                    </Link>


                                    {
                                      isRed[index] || isGreen[index] || current_stage > 3 || reason !== null ? (
                                        <Link to='#' data-bs-toggle='tooltip' className='link' title='Undo' onClick={() => handleUndo(index, id, current_stage, reason)}>
                                          <GrRotateLeft />
                                        </Link>
                                      ) : (
                                        <span>
                                          <Link to='#' data-bs-toggle='tooltip' className='link' title='Include' onClick={() => handleInclude(index, id)}>
                                            <FaPlus />
                                          </Link>
                                          <Link to='#' data-bs-toggle='tooltip' className='link' title='Exclude' onClick={() => handleExclude(index, id, current_stage, abstract_screening)}>
                                            <FaMinus />
                                          </Link>
                                        </span>
                                      )
                                    }
                                  </> : ''
                                }

                              </div>
                              {isActive === index ? "" : <p></p>}
                            </div>
                            <div className=''>
                              {isRed[index] || reason !== null ? (
                                <div className='w-75 ms-auto'>
                                  <label className='mt-2 text-danger'>Reason for Exclusion?</label>
                                  <select class='form-select' defaultValue={reason} onChange={(event) => handleSelectChange(event, index, id)}>
                                    <option value='0' selected disabled>
                                      Please select option
                                    </option>
                                    {exclusionPurpose &&
                                      exclusionPurpose.map(({ label, value }, index) => {
                                        return <option value={value}>{label}</option>;
                                      })}
                                  </select>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      show && (

                        <>
                          <div className='template_modal'>
                            <div className='template_modal-wrapper'>
                              <div className='modal--header'>
                                <div className='text-end'>
                                  <button
                                    className='btn-danger'
                                    onClick={() => {
                                      handleCloseModal();
                                    }}
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                              <div className='modal--body'>
                                {source && <iframe width='100%' title='attached file' src={`${source}`}></iframe>}
                                {file && <iframe width='100%' title='attached file' src={`${API_SRC + "/" + file}`}></iframe>}
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    }

                  </div>
                ))
              ) : (
                <p className='p-3 text-muted'>There are no fulltext screening articles with dataset.</p>
              )
            )}
          </div>
        </div>
      </div>


      {/*  */}

      {/*  */}
    </>
  );
}

export default TotalReviews;
