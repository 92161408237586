import React, { useEffect } from "react";
import { useState } from "react";
import ButtonComponent from "./ButtonComponent";
import { API_Link, MD5hash } from "../commons/Constants";
import axios from "axios";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const AddUserComponent = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "4",
    level: "",
    department: "",
    is_active: "1",
  });

  const { name, email, password, role, level, department, is_active } = user;

  const [getRole, setGetRole] = useState([]);
  const [getLevel, setGetLevel] = useState([]);
  const [getDepartmentType, setGetDepartmentType] = useState([]);
  const [getEditUser, setGetEditUser] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;

  const { id } = useParams();
  console.log("Param id", { id });

  // getReviewTypes
  const getReviewTypesApi = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewTypes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setGetDepartmentType(resData.data.reviewTypes);

        //   console.log(resData.data.reviewTypes)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // getLevel
  const getLevelApi = () => {
    var config = {
      method: "get",
      url: `${API_Link}getLevels`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setGetLevel(resData.data.levels);

        //  console.log('Level APIs',resData.data.levels)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // getRole
  const getRoleApi = () => {
    var config = {
      method: "get",
      url: `${API_Link}getRoles`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setGetRole(resData.data.roles);

        //  console.log('Level APIs',resData.data.levels)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.warn("warn", user);
  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log("payload", user);
    // let data = user;
    var config = {
      method: "post",
      url: `${API_Link}setUser`,
      data: user,
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, "misr-auth": MD5hash },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        console.warn(resData);
        if (resData.status === 200) {
          navigate("/admin/users");
        }
      })
      .catch(function (error) {
        // console.log(error);
        alert(JSON.stringify(error.response.data.error_message));
      });
  };

  // const getUser = async () => {
  //   // api Call
  //   await axios
  //     .get(`${API_Link}getUsers?id=${id}`, {
  //       headers: {
  //         "misr-auth": MD5hash,
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       setGetEditUser(res.data);
  //     });
  //   // .finally(() => setIsLoading(false));
  // };
  console.log("getDepartmentType", getDepartmentType);
  const handleChange = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };
  console.log("user", user);
  useEffect(() => {
    getReviewTypesApi();
    getLevelApi();
    getRoleApi();
    // getUser();
  }, []);
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-4'>
          <h1 className='page--heading'>Add User</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='form--body max-width-1200'>
            <form className='input--form' autocomplete='off'>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Full Name</label>
                <div className='col-sm-9'>
                  <input type='text' className='form-control' placeholder='' name='name' value={name} onChange={(event) => handleChange(event)} />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Email</label>
                <div className='col-sm-9'>
                  <input type='email' className='form-control' name='email' value={email} onChange={(event) => handleChange(event)} autoComplete='new-password' />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Password</label>
                <div className='col-sm-9'>
                  <input type='password' className='form-control' name='password' value={password} onChange={(event) => handleChange(event)} autoComplete='new-password' />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Role</label>
                <div className='col-sm-9'>
                  <select className='form-select' name='role' value={role} onChange={(event) => handleChange(event)}>
                    {getRole.map(({ id, role }, index) => {
                      return <option value={id}>{role.charAt(0).toUpperCase() + role.slice(1)}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Level </label>
                <div className='col-sm-9'>
                  <select className='form-select' name='level' value={level} onChange={(event) => handleChange(event)}>
                    <option value=''>Select Level</option>
                    {getLevel.map(({ id, level, description }, index) => {
                      return (
                        <option key={index} value={id}>
                          {description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Department Type</label>
                <div className='col-sm-9'>
                  <select className='form-select' name='department' value={department} onChange={(event) => handleChange(event)}>
                    <option value=''>Select Department</option>
                    {getDepartmentType.map(({ id, type, description }, index) => {
                      return (
                        <option key={index} value={id}>
                          {description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='mb-3 row'>
                <div className='col-sm-3'>Is Active ?</div>
                <div className='col-sm-9'>
                  <div className='' onChange={(event) => handleChange(event)}>
                    <input class='form-check-input me-1' type='radio' checked={is_active === "1"} name='is_active' value='1' id='inlineRadio1' />
                    Yes
                    <input class='form-check-input ms-3 me-1' checked={is_active === "0"} type='radio' name='is_active' value='0' id='inlineRadio2' />
                    No
                  </div>
                </div>
              </div>
              <div className='text-end row'>
                <div className='col-sm-12'>
                  <ButtonComponent text={"Create new user"} handleSubmit={handleSubmit} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserComponent;
