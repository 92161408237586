import React from 'react'
import Aside from '../../Components/aside'
import MaybeFulltextComponent from '../../Components/maybeFulltextComponent'
import TopBar from '../../Components/topBar'

const MaybeFulltextPage = () => {
    return (
        <div className='main-wrapper'>
            <Aside/>
            <div className="content-wrapper">
               <TopBar/>
                <MaybeFulltextComponent/>
            </div>
        </div>

    )
}

export default MaybeFulltextPage