import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { API_Link, MD5hash } from '../commons/Constants'

const AdminLookupsListingComponent = () => {

    // set useState
    const [isLoading, setIsLoading] = useState(false)
    const [lookupsListing, setlookupsListing] = useState([])

    // get Token
    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;

    const getLookupsList = async () => {

        // api Call
        await axios
            .get(`${API_Link}getLookups?options=true`, {
                headers: {
                    "misr-auth": MD5hash,
                    Authorization: `Bearer ${token}`,
                }
            }).then((res) => {

                setlookupsListing(res.data.lookups)
                setIsLoading(false)
            })
        // .finally(() => setIsLoading(false));
    }




    useEffect(() => {
        getLookupsList();
    }, [])

    return (
        <div className="page--content ">
            <div className="row">
                <div className="col-md-6 col-lg-6">
                    <h1 className="page--heading ">
                        Lookups Listing
                    </h1>
                </div>
            </div>
            <div className="row">
                <div className="padding-table">
                    <div className="table--template">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Sr#</th>
                                    <th>Title</th>
                                    <th>Options</th>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    lookupsListing && lookupsListing.map((lookups, index) =>
                                        <tr>
                                            <td>{index + 1} </td>
                                            <td>{lookups.title}</td>
                                            <td><select className='form-select'>
                                                {
                                                    lookups.options.map((option) => {
                                                        return (
                                                            <option>{option.label}</option>
                                                        )
                                                    })
                                                }
                                            </select> </td>
                                            <td><button className='btn btn-primary'>Edit</button></td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="row">
                            <div className="col-lg-12 text-center">
                                <nav aria-label="...">
                                    <ul className="pagination">
                                        <li className="page-item disabled">
                                            <a className="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">1</a></li>
                                        <li className="page-item active" aria-current="page">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div> */}
            </div>
        </div>
    )
}

export default AdminLookupsListingComponent