import React from 'react'
import Consensus from './../../Components/screeningComponent/Consensus'
import Aside from "./../../Components/aside";
import TopBar from "./../../Components/topBar";
import "./../../assets/sass/style.css";
import "./../../assets/sass/_screening.scss"
import "./../../assets/sass/_topbar.scss"
import "./../../assets/css/topbar.css"

function ConsensusPage() {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <Consensus />
      </div>
    </div>
  )
}

export default ConsensusPage
