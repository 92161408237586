import React from 'react'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'
import SearchAbstractsComponent from '../../Components/searchAbstractsComponent'
import AsidebarSearchResult from '../../Components/asidebarSearchResult'

const SearchAbstracts = () => {
  return (
    <div className="main-wrapper">
    {/* <AsidebarSearchResult /> */}
    <Aside/>
    <div className="content-wrapper">
      <TopBar />
      <SearchAbstractsComponent/>
    </div>
  </div>
  )
}

export default SearchAbstracts