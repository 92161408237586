import React from "react";
import TopBar from "./../../Components/topBar";
import Aside from "./../../Components/aside";
import TotalReviewsNew from "../../Components/TotalReviewsNew";
import { useSelector } from "react-redux";
const ReviewsAbstractPage = () => {
  const invitation = useSelector((state) => state.invitation.value);
  console.log("reducer", invitation);
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <TotalReviewsNew />
      </div>
    </div>
  );
};

export default ReviewsAbstractPage;
