import React from 'react'

const DashboardCard = (props) => {
    return (
        <div className="col-lg-3 col-md-4 col-sm-6 col-12" id="new">
            <div className="inner--card">
                <div className="top--part">
                    <span className="title heading--font">{props.title}</span>
                </div>
                <div className="bottom--part">
                    <span>
                        <h1 className="fig__number">{props.figNumber}</h1>
                        <small>Completed</small>
                    </span>
                    <span>
                        <h1 className="fig__number">{props.figNumber_progress}</h1>
                        <small>In Progress</small>
                    </span>
                    <span className="icon">
                        <img src={props.src} alt="icon" className="img-fluid" />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard