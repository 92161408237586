import React from "react";
import Invitation from "./../../Components/invitation";
// import Header from "./../../Components/header"
// import Footer from "./../../Components/footer";

function InvitationPage() {
  return (
    <div>
      <Invitation />
    </div>
  );
}

export default InvitationPage;
