import React, { useState, useEffect } from "react";
import TableRowComponent from "./../Components/TableRowComponent";
import TableHeadComponent from "./../Components/TableHeadComponent";
import DropDownComponent from "./DropDownComponent";
import axios from "axios";
import "./../assets/sass/_login.scss";
import "./../assets/sass/_review.scss";
import "./../assets/sass/style.css";
import { IoIosAdd } from "react-icons/io";
import Aside from "./aside";
import TopBar from "./topBar";
import { Link } from "react-router-dom";
import { API_Link, MD5hash } from "./../commons/Constants";
import AsidebarNew from "./asidebarNew";
import { DropdownButton, Dropdown, Tabs, Tab } from "react-bootstrap";
import { FaDownload, FaEllipsisV, FaEye } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import HTA from "./FileTemplateComponent/HTA";
import TechBrief from "./FileTemplateComponent/TechBrief";
import IB from "./FileTemplateComponent/IB";
import TechScan from "./FileTemplateComponent/TechScan";
import TR from "./FileTemplateComponent/TR";
import ButtonComponent from "./ButtonComponent";
import Avatar from "./../assets/img/Avatar.png";
import InvitationCard from "./invitationCard";
import { useCallback } from "react";
import gif from "./../assets/img/spin.gif";
function Review() {
  const [review, setReview] = useState("");
  const [filtered_review, setFilteredReview] = useState();
  const [value, setValue] = useState();
  const [showEditModal, setShowEditModal] = useState(false);
  const [reviewTypes, setReviewTypes] = useState([]);
  const [reviewId, setReviewId] = useState();
  const [reviewList, setReviewList] = useState([]);
  const [studyId, setStudyId] = useState(localStorage.getItem("studyId"));
  const [invitations, setInvitations] = useState([]);
  const [showNewInvitationModal, setShowNewInvitationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //
  const [emailSuggestion, setEmailSuggestion] = useState([]);
  const [text, setText] = useState("");
  // edit review
  const [reviewById, setReviewByID] = useState({
    title: "",
    review_type: "",
    tentative_completion_date: "",
    is_followup: "",
  });
  const { title, review_type, tentative_completion_date, is_followup } = reviewById;
  const [inviteUser, setInviteUser] = useState({
    name: "",
    email: "",
  });
  const { name, email } = inviteUser;
  const [loader, setLoader] = useState(false);

  const [selectValue, setSelectValue] = useState("");
  const studyCheck = (event) => {
    setSelectValue(event.target.value);

    localStorage.setItem("studyId", event.target.value);

    setStudyId(localStorage.getItem("studyId"));
  };
  console.log("REVIEW:", review);

  //

  // manage invitations
  const [tabKey, initTabKey] = useState("one");
  const [showEditInvitation, setShowEditInvitation] = useState(false);
  //stages for new invitation
  let stage = tabKey === "one" ? "1" : tabKey === "two" ? "2" : tabKey === "three" ? "3" : tabKey === "four" ? "4" : "";
  console.log("stages on review page", stage);
  const Export2Word = (element, filename = "") => {
    console.log("REACHEDDDD: ");
    var preHtml = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    var postHtml = "</body></html>";
    var html = preHtml + document.getElementById(element).innerHTML + postHtml;

    var blob = new Blob(["\ufeff", html], {
      type: "application/msword",
    });

    // Specify link url
    var url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

    // Specify file name
    filename = filename ? filename + ".doc" : "document.doc";

    // Create download link element
    var downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = filename;

      //triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  };
  //
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;
  console.log("token", token);

  const getReviews = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviews`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const reviewdata = JSON.stringify(response.data.reviews);
        //onsole.log('reviewdata', reviewdata);
        const valuesArray = JSON.parse(reviewdata);
        console.log("parsedata", valuesArray);
        setFilteredReview(valuesArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editReview = (id) => {
    console.log(id);
    setShowEditModal(true);
    setReviewId(id);
    getReviewsByID(id);
  };
  const getReviewsByID = async (id) => {
    //
    await axios
      .get(`${API_Link}getReviews`, {
        params: {
          id: id,
        },
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log("invitations", response.data);
        setReviewByID({
          title: response.data.reviews[0].title,
          review_type: response.data.reviews[0].review_type.id,
          tentative_completion_date: response.data.reviews[0].tentative_completion_date,
          is_followup: response.data.reviews[0].is_followup === true ? "1" : "0",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //
  const manageInvitation = (id) => {
    console.log(id);
    setShowEditInvitation(true);
    setReviewId(id);
    getInvitationById(id);
  };
  const getInvitationById = async (id) => {
    //
    await axios
      .get(`${API_Link}getInvitations`, {
        params: {
          review_id: id,
        },
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log("invitations", response.data);
        setInvitations(response.data.invitations);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getReviewsList = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewsList`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const reviewdata = JSON.stringify(response.data.reviews);
        //onsole.log('reviewdata', reviewdata);
        const valuesArray = JSON.parse(reviewdata);
        //console.log('parsedata', valuesArray);
        setReviewList(valuesArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  console.log("reviewtype", reviewById.review_type);
  const getReviewTypes = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewTypes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setReviewTypes(resData.data.reviewTypes);

        //   console.log(resData.data.reviewTypes)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    getReviews();
    getReviewTypes();
    getReviewsByID(reviewId);
    getInvitationById(reviewId);
    getReviewsList();
  }, []);
  useState(() => {
    localStorage.setItem("pageId", "2222");
    localStorage.setItem("subPageId", "2223");
  }, []);

  const handleClick = (event) => {
    console.log(event.currentTarget.id);
    localStorage.setItem("Study Name", event.currentTarget.id);
    window.location.href = "/searchabstracts";
  };
  //

  const [show, setShow] = useState(false);
  const ViewReport = (id) => {
    setShow(true);
    console.log(id);
    setReview(id);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleCloseEdit = () => {
    setShowEditModal(false);
  };
  const handleSelect = (e) => {
    // console.log(e);
    setValue(e);
  };
  const [message, setMessage] = useState("");
  const handleChange = (e) => {
    console.log("e.target.name", e.target.name, "e.target.value", e.target.value);
    setReviewByID({ ...reviewById, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    console.log("update payload", reviewById);
    let data = reviewById;
    axios
      .post(`${API_Link}addReview?id=${reviewId}`, data, {
        headers: {
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("Response from server:", res.data);
        res.data.status === 1 &&
          setMessage({
            code: 200,
            message: "Data Has been added successfully.",
          });
        //  localStorage.removeItem("studyId");
        //  setLoader(false);
        // navigate("/review");
        res.data.status === 0 && setMessage({ code: 400, message: res.data.error_message });
      })
      .catch((error) => {
        alert(JSON.stringify(error.response.data.error_message));
      })
      .finally(() => {
        setShowEditModal(false);
        window.location.reload(false);
      });
  };
  const deleteReview = (e, id) => {
    //  axios.delete(`${API_Link}addReview${id}`, {
    //    headers: {
    //      "misr-auth": MD5hash,
    //      Authorization: `Bearer ${token}`,
    //    },
    //  });
    console.log(id);
  };
  // Manage invitations methods
  //
  const handleCloseInvitation = () => {
    setShowEditInvitation(false);
    setInvitations([]);
  };
  // New Invitation
  const openNewInvitationModal = () => {
    setShowNewInvitationModal(true);
  };

  const handleChangeNewInvite = (e) => {
    setInviteUser({ ...inviteUser, [e.target.name]: e.target.value });
  };
  const handleSubmitInvitation = () => {
    console.log("userrrr", inviteUser);

    var config = {
      method: "post",
      url: `${API_Link}addInvitation`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
      data: {
        review_id: reviewId,
        stage: stage,
        name: inviteUser.name,
        email: text,
      },
    };
    setIsLoading(true);
    axios(config)
      .then((response) => {
        if (response.data.status === 1) {
          setShowNewInvitationModal(false);
          getInvitationById(reviewId);
          setIsLoading(false);
          setInviteUser({});
          setText("");
        }
        if (response.data.status === 0) {
          alert(response.data.error_message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  const handleCloseNewInvite = () => {
    setShowNewInvitationModal(false);
    // reset states when close mdal
    setInviteUser([]);
    setText("");
  };
  //
  const [invitataionId, setInvitationId] = useState();
  const [showCofirmDelete, setShowCofirmDelete] = useState(false);
  const deleteInvitation = (id) => {
    // console.log('delete id',id)
    setInvitationId(id);
    setShowCofirmDelete(true);
  };

  const handleCloseCofirmDelete = () => {
    setShowCofirmDelete(false);
  };
  const handleDelete = () => {
    console.log("invitation id", invitataionId);
    console.log("Review id", reviewId);
    var config = {
      method: "post",
      url: `${API_Link}deleteInvitation`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
      data: {
        id: invitataionId,
        review_id: reviewId,
      },
    };

    axios(config)
      .then((response) => {
        if (response.data.status === 1) {
          getInvitationById(reviewId);
          setShowCofirmDelete(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  console.log("delete id invite", invitataionId);
  // Email Suggesions
  // Debounce to limit number of requests

  const [timer, setTimer] = useState(null);
  const handleChangeEmail = (e) => {
    const { value } = e.target;

    setText(value);
    clearTimeout(timer);
    var config = {
      method: "get",
      url: `${API_Link}getEmailsList`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
      params: {
        keyword: text,
      },
    };

    setTimer(
      setTimeout(async () => {
        if (value.length > 1) {
          axios(config)
            .then((res) => {
              setEmailSuggestion(res.data.emails);
              console.log("emailsuggestion", res.data.emails);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }, 500)
    );
  };

  //
  const suggestionHandler = (text) => {
    setText(text);
    console.log("onclick text", text);
    setEmailSuggestion([]);
  };

  console.log("emailsuggestion", emailSuggestion);
  console.log("text", text);
  return (
    <div className='main-wrapper'>
      {/* <Aside
        reviewname={'Add Reviews'}
        reviewtitle={'Reviews'}
      /> */}
      <AsidebarNew />
      <div className='content-wrapper '>
        <TopBar />
        <div className='container page--content'>
          <div className='row' style={{ marginTop: "20px" }}>
            <div className='col-sm-6 '>
              <h2 className='page--heading'>Review Listing</h2>
            </div>
            <div className='col-sm-6 col-xs-12 text--align--right '>
              {/* <a href="addreview" className="tem--add--btn"></a> */}
              <Link to='/addreview' className='tem--add--btn'>
                <IoIosAdd /> Add New
              </Link>
            </div>
          </div>
          <div className='row'>
            <div className='padding-table'>
              <div className='table--template  review-table'>
                <table className='table table-striped'>
                  <thead className='table-head'>
                    <TableHeadComponent options={[{ title: "Name" }, { title: "Type" }, { title: "Stage" }, { title: "Start Date" }, { title: "End Date" }, { title: "Report" }, { title: "Action" }]} />
                  </thead>
                  <tbody>
                    {filtered_review &&
                      filtered_review.map(
                        (review, index) => (
                          <tr key={index}>
                            <td>{review.title}</td>
                            <td>
                              {review.review_type.description === "Information Brief (IB)"
                                ? "IB"
                                : review.review_type.description === "Health Technology Assessment (HTA)"
                                ? "HTA"
                                : review.review_type.description === "Technology Review (TR)"
                                ? "TR"
                                : review.review_type.description === "Economic Evaluation (EE)"
                                ? "EE"
                                : review.review_type.description === "Clinical Practice Guidelines (CPG)"
                                ? "CPG"
                                : review.review_type.description}
                            </td>
                            {/* <td>{review.screening_type}</td> */}
                            {/* <td>{review.owner.name}</td> */}
                            <td>{review.final_completion_date !== null ? <span className='bg-success px-2 py-1 text-light rounded'>Completed</span> : `${review.current_stage.label}`}</td>
                            <td> {review.created_at.slice(0, 10)}</td>
                            <td> {review.final_completion_date === "" ? review.final_completion_date : review.tentative_completion_date}</td>
                            {/* <td>{review.is_active}</td>                             */}
                            <td>
                              <span className='d-flex justify-content-center'>
                                {review.final_completion_date !== null ? (
                                  <span>
                                    <FaEye onClick={() => ViewReport(review.review_type_id)} style={{ cursor: "pointer" }} />
                                  </span>
                                ) : (
                                  ""
                                )}

                                {/* <span><FaDownload style={{ cursor: 'pointer' }} onclick={() => Export2Word('exportContent')} /></span> */}
                              </span>
                            </td>
                            <td>
                              <div className='Actions--container'>
                                <Dropdown onSelect={handleSelect}>
                                  <Dropdown.Toggle variant='custom' id='dropdownMenuButton2'>
                                    <span>
                                      <FaEllipsisV />
                                    </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='actions--dropdown--menu'>
                                    <Dropdown.Item onClick={() => editReview(review.id)}>Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => manageInvitation(review.id)}>Edit Invitation</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => deleteReview(e, review.id)}>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </td>
                          </tr>
                        )

                        // <TableRowComponent key={index} cellData={[{ data: review.title }, { data: review.screening_type }, { data: review.owner.name }, { data: review.tentative_completion_date }, { data: "<Sidedropdown />" }]} />
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="backdrop report-modal"onClick={handleClose}> */}
            <Modal className='modal-xl abstract--modal modal-dialog-centered ' show={show} onHide={handleClose}>
              <Modal.Header className=''>
                <h4 className='mb-2'>{/* <strong>Most Relevant Abstract</strong> */}</h4>
                <button className='btn btn-danger' onClick={handleClose}>
                  X
                </button>
              </Modal.Header>

              <div id='exportContent'>
                {review === 2 ? <HTA /> : review === 3 ? <TR /> : review === 4 ? <IB /> : review === 6 ? <TechBrief /> : <TechScan />}

                {/* <h1>{review}</h1> */}
              </div>
              <Modal.Footer>
                <button
                  className='btn btn-success footer-button'
                  // onClick={handleFinish}
                >
                  Finish
                </button>
              </Modal.Footer>
            </Modal>

            <Modal className='modal-lg modal-dialog-centered edit-review-modal' show={showEditModal} onHide={handleClose}>
              <Modal.Header className=''>
                <h2>Edit Review</h2>
                <button className='btn btn-danger' onClick={handleCloseEdit}>
                  X
                </button>
              </Modal.Header>

              <div className='container'>
                <div className='row p-2'>
                  <div className='card  py-4 px-4 edit-review-modal-card'>
                    <form action=''>
                      <div className='mb-3 row'>
                        <label className='col-sm-4 col-form-label'>Review Name</label>
                        <div className='col-sm-8'>
                          <input type='text' className='form-control' name='title' value={title} onChange={(e) => handleChange(e)} />
                        </div>
                      </div>
                      <div className='mb-3 row'>
                        <label className='col-sm-4 col-form-label'>Review Type</label>
                        <div className='col-sm-8'>
                          {console.log("review_type", review_type)}
                          <select className='form-select' value={review_type} name='review_type' onChange={(e) => handleChange(e)}>
                            {reviewTypes &&
                              reviewTypes.map(({ id, description }, index) => {
                                return (
                                  <option key={index} value={id} {...(index === id ? "selected" : "")}>
                                    {description}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      <div className='mb-3 row'>
                        <label className='col-sm-4 col-form-label'>Tentative Date to complete</label>
                        <div className='col-sm-8'>
                          <input className='form-control' type='date' name='tentative_completion_date' value={tentative_completion_date} placeholder='DD/MM/YYYY' onChange={handleChange} />
                        </div>
                      </div>

                      <div className='mb-3 row'>
                        <label className='col-sm-4 col-form-label'>Update Previous Review</label>
                        <div className='col-sm-8 custom--logo'>
                          <div className='' onChange={handleChange}>
                            <input class='form-check-input me-1' type='radio' checked={is_followup === "1"} name='is_followup' value='1' id='inlineRadio1' />
                            Yes
                            <input class='form-check-input ms-3 me-1' checked={is_followup === "0"} type='radio' name='is_followup' value='0' id='inlineRadio2' />
                            No
                          </div>
                        </div>
                      </div>
                      <div className='mb-3 row'>
                        <label className='col-sm-4 col-form-label'>Review List</label>
                        <div className='col-sm-8'>
                          {is_followup === "1" ? (
                            <select class='form-select' value={studyId} onChange={studyCheck}>
                              {reviewList &&
                                reviewList.sort((a, b) => (a.id < b.id ? 1 : -1)) &&
                                reviewList.map(({ title, id }, index) => {
                                  return (
                                    <option value={id} {...(index === studyId ? "selected" : "unSelected")}>
                                      {title}
                                    </option>
                                  );
                                })}
                            </select>
                          ) : (
                            <select className='form-select' disabled></select>
                          )}
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-12'>
                          <div className='text-end mt-3'>
                            <ButtonComponent text={"Update Review"} handleSubmit={handleSubmit} />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>

            {/* Manage Invitation Modal */}

            <Modal className='modal-xl modal-lg  modal-dialog-centered edit-invitation-modal' show={showEditInvitation} onHide={handleClose}>
              <Modal.Header className=''>
                <h2>Manage Invitations</h2>
                <button className='btn btn-primary invite-new-user-btn' onClick={openNewInvitationModal}>
                  Invite New Reviewer
                </button>
                <button className='btn btn-danger' onClick={handleCloseInvitation}>
                  X
                </button>
              </Modal.Header>
              <div className='row edit-invitation-padding'>
                <Tabs className='text-dark' activeKey={tabKey} onSelect={(e) => initTabKey(e)}>
                  <Tab eventKey='one' title='Searching' className='modal--tab-buttons'>
                    <InvitationCard invitations={invitations} reviewStage={1} tabKey={tabKey} deleteInvitation={deleteInvitation} />
                  </Tab>
                  <Tab eventKey='two' title='Abstract Screening' className='modal--tab-buttons'>
                    <InvitationCard invitations={invitations} reviewStage={2} tabKey={tabKey} deleteInvitation={deleteInvitation} />
                  </Tab>
                  <Tab eventKey='three' title='Fulltext Screening' className='modal--tab-buttons'>
                    <InvitationCard invitations={invitations} reviewStage={3} tabKey={tabKey} deleteInvitation={deleteInvitation} />
                  </Tab>
                  <Tab eventKey='four' title='Extraction' className='modal--tab-buttons'>
                    <InvitationCard invitations={invitations} reviewStage={4} tabKey={tabKey} deleteInvitation={deleteInvitation} />
                  </Tab>
                </Tabs>
              </div>
            </Modal>

            {/* New Invitation */}
            <Modal className='modal-md modal-dialog-centered edit-invitation-form' show={showNewInvitationModal} onHide={handleClose}>
              <Modal.Header className=''>
                <h4>New Invitation</h4>

                <button className='btn btn-danger' onClick={handleCloseNewInvite}>
                  X
                </button>
              </Modal.Header>

              {isLoading ? (
                <div className='card d-flex justify-content-center align-items-center'>
                  <img src={gif} alt='' className='edit-new-reviewr-loader' />
                </div>
              ) : (
                <div className='card'>
                  {tabKey === "one" ? (
                    <p className='text-center text-muted py-2'>
                      <strong>Invite Reviewer for Data Searching</strong>
                    </p>
                  ) : tabKey === "two" ? (
                    <p className='text-center text-muted py-2'>
                      <strong>Invite Reviewer for Data Screening</strong>
                    </p>
                  ) : tabKey === "three" ? (
                    <p className='text-center text-muted py-2'>
                      <strong>Invite Reviewer for Fulltext Screening</strong>
                    </p>
                  ) : tabKey === "four" ? (
                    <p className='text-center text-muted py-2'>
                      <strong>Invite Reviewer for Data Extraction</strong>
                    </p>
                  ) : (
                    ""
                  )}
                  <form action='' className='p-2'>
                    <div className='mb-3 row'>
                      <label className='col-sm-2 col-form-label'>Name</label>
                      <div className='col-sm-10'>
                        <input type='text' className='form-control' name='name' value={name} onChange={(e) => handleChangeNewInvite(e)} />
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <label className='col-sm-2 col-form-label'>Email</label>
                      <div className='col-sm-10'>
                        <input type='email' className='form-control' value={text} onChange={handleChangeEmail} />
                        {emailSuggestion && emailSuggestion.length > 0 && (
                          <div className='search--bar'>
                            {emailSuggestion.map((item, index) => {
                              return (
                                <div className='p-2' key={index}>
                                  <div className='suggestion py-2' onClick={() => suggestionHandler(item.email)}>
                                    {item.email}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-3 row'>
                      <div className='col-sm-12'>
                        <div className='text-end mt-3'>
                          {!isLoading ? (
                            <button className='new--add--button--review' onClick={handleSubmitInvitation}>
                              Invite User
                            </button>
                          ) : (
                            <button className='new--add--button--review' disabled>
                              Invite User
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Modal>

            <Modal show={showCofirmDelete} onHide={handleCloseCofirmDelete}>
              <Modal.Header>
                <Modal.Title>Confirm Delete Invitation</Modal.Title>
                <button className='btn btn-danger' onClick={handleCloseCofirmDelete}>
                  X
                </button>
              </Modal.Header>
              <Modal.Body>
                <p>Are you sur you want to delete Invitation ?</p>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-primary' onClick={handleCloseCofirmDelete}>
                  Cancel
                </button>
                <button className='btn btn-danger' onClick={handleDelete}>
                  Delete
                </button>
              </Modal.Footer>
            </Modal>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
{
  /* <ButtonComponent text={"Invite User"} handleSubmit={handleSubmitInvitation} /> */
}
export default Review;
