import React from "react";
import AddLevelComponent from "./addLevelComponent";
import Aside from "./aside";
import TopBar from "./topBar";

const AddLevel = () => {
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <AddLevelComponent />
      </div>
    </div>
  );
};

export default AddLevel;
