import React, { useEffect, useState } from "react";
import "./../assets/sass/style.css";
import Aside from "./aside";
import TableHeadComponent from "./TableHeadComponent";
import TopBar from "./topBar";
import axios from "axios";
import { API_Link, MD5hash } from "../commons/Constants";
import { useRef } from "react";
import Loader from "./loader/loader";
import { Link } from "react-router-dom";

// import { Link } from "react-router-dom"

function EvidenceTable({ elementId, filename = '' }) {

  const [data, setData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [studyDesign, setStudyDesign] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [methodologies, setMethodologies] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [results, setResults] = useState([]);
  const [conclusions, setConclusions] = useState([]);
  const [loe, setLoe] = useState([]);
  const [loader, setLoader] = useState(false);

  const getReviewArticles = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    const studyId = localStorage.getItem('studyId')

    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles?review_id=${studyId}&fulltext=Yes&exclusion=No`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash
      },

    }
    setLoader(true)
    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        console.warn('resData', resData)
        const notNull = resData.data.articles.filter((article) => article.current_stage > 3 && article.file_path !== null || article.source !== null);
        const result = notNull.map(({ id, title, authors, journal }) => ({ id, title, authors, journal }));
        setArticleData(result);
        // const finalData1 = articleData.map((item, i) => Object.assign({}, item, data[i]));


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getReviewEvidence = () => {

    const user = JSON.parse(localStorage.getItem("user"));
    const token = user.token;
    const studyId = localStorage.getItem('studyId')

    var config = {
      method: "get",
      url: `${API_Link}getReviewEvidence?review_id=${studyId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash
      },

    }

    axios(config)
      .then(function (response) {

        const APIresponse = response.data.evidences;
        setData(APIresponse)


        setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  console.log('finalData', finalData)


  useEffect(() => {
    getReviewEvidence();
    getReviewArticles();


  }, [])

  useEffect(() => {
    const updatedData = articleData.map((item, i) => Object.assign({}, item, data[i]));
    setFinalData(updatedData);


  }, [articleData, data]);


  useEffect(() => {
    finalData.forEach(article => {
      const evidenceJson = article.evidence_json;
      if (evidenceJson) {
        try {
          const evidence = JSON.parse(evidenceJson);
          console.warn('evidence', evidence)
          if (evidence) {
            const study_Design = evidence[0].study_design;
            const e_objectives = evidence[0].objectives;
            const e_methodologies = evidence[0].methodologies;
            const e_outcomes = evidence[0].outcomes;
            const e_results = evidence[0].results;
            const e_conclusions = evidence[0].conclusions;
            const e_loe = evidence[0].loe;

            // Do something with the variables

            console.warn('studyDesign', study_Design);

            if (studyDesign) {
              setStudyDesign(study_Design);
            }
            e_objectives && setObjectives(e_objectives);
            e_methodologies && setMethodologies(e_methodologies);
            e_outcomes && setOutcomes(e_outcomes);
            e_results && setResults(e_results);
            e_conclusions && setConclusions(e_conclusions);
            e_loe && setLoe(e_loe);
          }
        } catch (error) {
          console.error(`Failed to parse JSON for article ${article.article_id}: ${error}`);
        }
      } else {
        console.error(`evidence_json is undefined for article ${article.article_id}`);
      }
    });
  }, [finalData]);



  // 
  const ExportDocx = () => {

    if (!window.Blob) {
      alert('Your legacy browser does not support this action.');
      return;
    }

    var html, link, blob, url, css;

    // EU A4 use: size: 841.95pt 595.35pt;
    // US Letter use: size:11.0in 8.5in;

    css = (
      '<style>' +
      '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
      'div.WordSection1 {page: WordSection1;}' +
      'table{border-collapse:collapse;}td{border:1px gray solid;width:5em;padding:2px;}' +
      '</style>'
    );

    html = window.docx.innerHTML;
    blob = new Blob(['\ufeff', css + html], {
      type: 'application/msword'
    });
    url = URL.createObjectURL(blob);
    link = document.createElement('A');
    link.href = url;
    // Set default file name. 
    // Word will append file extension - do not add an extension here.
    link.download = 'Document';
    document.body.appendChild(link);
    if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, 'Document.doc'); // IE10-11
    else link.click();  // other browsers
    document.body.removeChild(link);
  };
  // 

  console.log('loader', loader)
  //  
  //
  // if (loader) return 'test'
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content' id="exportContent">
          <div className="row">
            <div className="col-lg-8">
              <h1 className='page--heading'>Evidence Data</h1>
            </div>
            <div className="col-lg-4 text-end">
              <a onClick={ExportDocx} className="export-btn"><i className="bi bi-file-earmark-word"></i> Download</a>
            </div>
          </div>
          <div id="docx">
            <div class="WordSection1">
              <div className='row'>
                <div className=''>
                  <div className='table--template'>
                    <table className='table table-striped blue-bg-th-table'>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Author, Title, Journal, Year, Volume, Page Number</th>
                          <th>Study Design, Sample Size, Follow up</th>
                          <th>Outcome & Characteristics</th>
                          <th>LEVEL</th>
                          <th colSpan={2}>COMMENT (your own’s)</th>
                        </tr>
                      </thead>
                      <tbody>

                        {
                          loader ? <td colSpan="9">  <Loader /> </td> : (data.length === 0 ? <td colSpan="9" className="text-center bg-danger text-light"> <strong>You don't have any Evidence Data</strong> </td> : null)
                        }


{
  finalData && finalData.length > 0 ? (
    finalData.map(({ authors, title, journal, article_id, comments, evidence_json }, index) => {
      try {
        const evidence = evidence_json ? JSON.parse(evidence_json)[0] : {};

        return (
          <tr key={article_id}>
            <td>{index + 1}</td>
            <td>
              {title && <p><strong>Title: </strong> {title}</p>}
              {authors && <p><strong>Authors: </strong> {authors}</p>}
              {journal && <p><strong>Journal: </strong> {journal}</p>}
            </td>
            <td>
              {evidence.study_design && evidence.study_design.length > 0 && evidence.studyDesign !== undefined && (
                <>
                  <strong>Study Design:</strong>
                  {evidence.study_design.map(({ content }, innerIndex) => (
                    <p key={`study_design-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
              <br />
              {evidence.objectives && evidence.objectives.length > 0 && (
                <>
                  <strong>Objectives:</strong>
                  {evidence.objectives.map(({ content }, innerIndex) => (
                    <p key={`objectives-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
              <br />
              {evidence.methodologies && evidence.methodologies.length > 0 && (
                <>
                  <strong>Methodologies:</strong>
                  {evidence.methodologies.map(({ content }, innerIndex) => (
                    <p key={`methodologies-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
              <br />
              {evidence.results && evidence.results.length > 0 && (
                <>
                  <strong>Results:</strong>
                  {evidence.results.map(({ content }, innerIndex) => (
                    <p key={`results-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
              <br />
              {evidence.conclusions && evidence.conclusions.length > 0 && (
                <>
                  <strong>Conclusions:</strong>
                  {evidence.conclusions.map(({ content }, innerIndex) => (
                    <p key={`conclusions-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
              <br />
              {evidence.loe && evidence.loe.length > 0 && (
                <>
                  <strong>LOE:</strong>
                  {evidence.loe.map(({ content }, innerIndex) => (
                    <p key={`loe-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
            </td>
            <td>
              {evidence.outcomes && evidence.outcomes.length > 0 && (
                <>
                  <strong>Outcomes:</strong>
                  {evidence.outcomes.map(({ content }, innerIndex) => (
                    <p key={`outcomes-${innerIndex}`}>{content || 'N/A'}</p>
                  ))}
                </>
              )}
            </td>
            <td></td>
            <td></td>
            <td>
              {comments || 'N/A'}
              <Link to={`/evidencebot/${article_id}`} className='Editpdf-icon'>Edit</Link>
            </td>
          </tr>
        );
      } catch (error) {
        console.error('Error parsing evidence_json:', error);
        return (
          <tr key={article_id}>
            <td colSpan="7">Error parsing evidence data</td>
          </tr>
        );
      }
    })
  ) : (
    <tr>
      <td colSpan="7">No data available</td>
    </tr>
  )
}


                      </tbody>
                    </table>
                    {/* <div className="row">
                      <div className="col-lg-12">
                       <p className="text-center">
                       <strong>Clinical Question 5:</strong> <span className="text-muted">What are the effective interventions to promote adherence to TB treatment?</span>
                       </p>
                      </div>
                    </div> */}

                    <table className='table table-striped blue-bg-th-table'>
                      {/* <thead>
                        <tr>
                          <th>Author, Title, Journal, Year, Volume, Page Number</th>
                          <th>Study Design, Sample Size, Follow up</th>
                          <th>Outcome & Characteristics</th>
                          <th>LEVEL</th>
                          <th>COMMENT (your own’s)</th>
                        </tr>
                      </thead> */}
                      {/* <tbody>
                       
                      {
                        loader ? <td colSpan="9">  <Loader /> </td> : (data.length === 0 ? <td colSpan="9" className="text-center bg-danger text-light"> <strong>You don't have any Evidence Data</strong> </td> : null)
                      }


                        {

                          data && data.map(({ biblographic_citation, study_type, le,article_id, patient_characteristics, intervention, comparison, length_of_followup, outcome_measure_and_effect_size, comments }) => {
                            return (
                              <tr>
                                <td>{biblographic_citation}</td>
                                <td>{study_type}</td>
                                <td>{le}</td>
                                <td>{patient_characteristics}</td>
                                <td>{intervention}</td>
                                <td>{comparison}</td>
                                <td>{length_of_followup}</td>
                                <td>{outcome_measure_and_effect_size}</td>
                                <td>{comments}  
                                <Link
                                      to={`/picobot/${article_id}`}
                                      className='Editpdf-icon'
                                    >Edit</Link>
                                  </td>
                              </tr>
                            )
                          })
                        }

                      </tbody> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default EvidenceTable;
