import axios from 'axios';
import CryptoJS from 'crypto-js';
import {API_Link, MD5hash} from '../../commons/Constants';
var moment = require('moment');


//var MD5hash = CryptoJS.MD5('P@ce-' + (moment().format(("YYYY-MM-DD"))) + '-T3c4').toString();
// Get Common Settings



const userSettings = () => {
  // return HTTPCommon.get("getSettings");
 return axios.get(`${API_Link}getSettings`, {
    headers: {
      "misr-auth" : MD5hash,
    },
  })
}

// Login Api
const userLogin = (user) => {
  return axios.post(`${API_Link}login`,user,{
    headers: {
      "misr-auth" : MD5hash,
    }
  })
}


const userOld = {
  userSettings,
  userLogin
};

export default userOld;