import React, { useState } from 'react';
import { useEffect } from 'react';
import PdfView from './pdfView';
import { FaEye, FaEyeSlash, FaTimes } from 'react-icons/fa';
import { API_Link, MD5hash } from '../commons/Constants';
import axios from 'axios';
import { useLocation, useParams } from 'react-router';
import NewTest from './newTest.tsx';

export default function TestRobotComponent() {
  const [data1, setData1] = useState([]);
  const [show, setShow] = useState(false);
  const [array1, setArray1] = useState([]);
  const [judgmentData, setJudgmentData] = useState([]);
  const location = useLocation();
  const [pdfAId, setPdfAId] = useState(location.state?.id || 1124);
  const [getJudgments, setGetJudgements] = useState(
    location.state?.judgements || []
  );
  const { id } = useParams();
  useEffect(() => {
    localStorage.setItem('pdfAId', '');
    if (id) {
      localStorage.setItem('pdfAId', id);
    }
  }, []);

  console.log('pdfidprm', id);
  const [picoData, setPicoData] = useState(location.state?.picos || []);
  const getReviewJudgements = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const studyId = localStorage.getItem('studyId');
    const token = user.token;

    var config = {
      method: 'get',
      url: `${API_Link}getReviewJudgements?review_id=${studyId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    try {
      const response = await axios(config);
      setJudgmentData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getReviewJudgements();
  }, []);

  const headings1 = [
    { id: 1, headingText: 'Random sequence generation' },
    { id: 2, headingText: 'Allocation concealment' },
    { id: 3, headingText: 'Blinding of participants and personnel' },
    { id: 4, headingText: 'Blinding of outcome assessment' },
    { id: 5, headingText: 'Incomplete outcome data' },
    { id: 6, headingText: 'Selective reporting' },
  ];

  const picoHeadings = [
    { id: 1, headingText: 'Population' },
    { id: 2, headingText: 'Intervention' },
    { id: 3, headingText: 'OutComes' },
  ];
  localStorage.setItem('judgements', JSON.stringify(getJudgments[pdfAId]));

  return (
    <div className='page--content--abstract'>
      <div className='row'>
        <div className='col-md-12'>
          <NewTest pdfAId={id} />
        </div>
      </div>
    </div>
  );
}
{
  /* <div className='col-md-3'>
          {getJudgments ? (
            <h2 className='risk-of-bias'>Risk of Bias</h2>
          ) : (
            <h2 className='risk-of-bias'>PICO</h2>
          )}
          {getJudgments
            ? headings1 &&
              headings1.map((item, index) => {
                return (
                  <div className='card mb-2'>
                    <div
                      className={
                        isShow[item.id]
                          ? 'card-header robot-reviewer-card-header robot-reviewer-card-header-font'
                          : 'card-header robot-reviewer-card-header'
                      }
                      style={{
                        backgroundColor:
                          isShow[item.id] === true && item.id === 1
                            ? 'rgb(168, 191, 18)'
                            : isShow[item.id] === true && item.id === 2
                            ? 'rgb(0,191,181)'
                            : isShow[item.id] === true && item.id === 3
                            ? 'rgb(255, 159, 0)'
                            : isShow[item.id] === true && item.id === 4
                            ? 'rgb(244, 28, 84)'
                            : isShow[item.id] === true && item.id === 5
                            ? '#004358'
                            : isShow[item.id] === true && item.id === 6
                            ? '#BF0426'
                            : '',
                      }}
                    >
                      <a
                        className='btn d-flex justify-content-between align-items-center'
                        onClick={() => openAccordion(item.id)}
                      >
                        <span className='text-start'> {item.headingText}</span>
                        <span>
                          {isShow[item.id] ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </a>
                    </div>
                    <div
                      className={
                        isShow[item.id] ? 'display-accordion' : 'hide-accordion'
                      }
                    >
                      <div className='card-body robot-reviewer-card-body p-2'>
                        <ul>
                          {judgements &&
                            judgements[pdfAId][index + 1].map((judgement) => {
                              return (
                                <div className='d-flex justify-content-between accordion-list-style'>
                                  <li
                                    key={judgement.id}
                                    className='flex-3 acc-list-text mb-2'
                                  >
                                    {judgement.support_text}
                                  </li>
                                  <span className='flex-1 acc-list-icon'>
                                    <FaTimes />
                                  </span>
                                </div>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })
            : picoData &&
              picoHeadings &&
              picoHeadings.map((item, index) => {
                return (
                  <div className='card mb-2'>
                    <div
                      className={
                        isShow[item.id]
                          ? 'card-header robot-reviewer-card-header robot-reviewer-card-header-font'
                          : 'card-header robot-reviewer-card-header'
                      }
                      style={{
                        backgroundColor:
                          isShow[item.id] === true && item.id === 1
                            ? 'rgb(168, 191, 18)'
                            : isShow[item.id] === true && item.id === 2
                            ? 'rgb(0,191,181)'
                            : isShow[item.id] === true && item.id === 3
                            ? 'rgb(255, 159, 0)'
                            : isShow[item.id] === true && item.id === 4
                            ? 'rgb(244, 28, 84)'
                            : '',
                      }}
                    >
                      <a
                        className='btn d-flex justify-content-between align-items-center'
                        onClick={() => openAccordion(item.id)}
                      >
                        <span className='text-start'> {item.headingText}</span>
                        <span>
                          {isShow[item.id] ? <FaEyeSlash /> : <FaEye />}
                        </span>
                      </a>
                    </div>
                    <div
                      className={
                        isShow[item.id] ? 'display-accordion' : 'hide-accordion'
                      }
                    >
                      <div className='card-body robot-reviewer-card-body p-2'>
                        <ul>
                          {picoData &&
                            picoData[pdfAId][index + 1] &&
                            picoData[pdfAId][index + 1].map((pico) => {
                              return (
                                <div className='d-flex justify-content-between accordion-list-style'>
                                  <li
                                    key={pico.id}
                                    className='flex-3 acc-list-text mb-2'
                                  >
                                    {pico.pico_text}
                                  </li>
                                  <span className='flex-1 acc-list-icon'>
                                    <FaTimes />
                                  </span>
                                </div>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div> */
}
