import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import Logo from "./../assets/img/logo.png"
import "./../assets/sass/style.css"
import { IoHomeOutline } from 'react-icons/io5'
import Sidedropdown from './childComponents/dropDown'
import { FaBars } from 'react-icons/fa'
import { BsCloudArrowDown } from 'react-icons/bs'
import { API_SRC } from './../commons/Constants'
import { useSelector } from 'react-redux'

function AsidebarSearchResult(props) {






    const sidebars = JSON.parse(localStorage.getItem('user'))
    const currentPageId = JSON.parse(localStorage.getItem('pageId'))
    const menus = sidebars.menu
    // console.log (menu)
    // const newmenu = menu.map((menus) => menus.modules === 'topBar')

    const topBarMenu = menus.filter(menu => menu.module === 'topBar')
    const topRightMenu = menus.filter(menu => menu.module === 'topRight')
    const sideBottomMenu = menus.filter(menu => menu.module === 'sideBottom')
    const sideTopMenu = menus.filter(menu => menu.module === 'sideTop')


    const [isActivedupplicate, setIsActiveduplicate] = useState(false);
    const [isActivedabstract, setIsActiveabstract] = useState(false);
    const [isActivefulltext, setIsActivefulltext] = useState(false);

    const handleClickMenuDuplicate = () => {
        setIsActiveabstract(false)
        setIsActivefulltext(false)
        setIsActiveduplicate(current => !current)
    };

    const handleClickMenuAbstract = () => {
        setIsActiveduplicate(false)
        setIsActivefulltext(false)
        setIsActiveabstract(current => !current)
    };

    const handleClickMenuFulltext = () => {
        setIsActiveduplicate(false)
        setIsActiveabstract(false)
        setIsActivefulltext(current => !current)
    };
    const [newSidebarMenu, setNewSidebarMenu] = useState([])

    // console.log('pageId', sideBarMenu[0].parent_id)


    const sidebarnewmenu = () => {



    }


    useEffect(() => {
        sidebarnewmenu()
    }, [])


    console.log('newSidebarMenu', newSidebarMenu)
    // const abc = () => {
    //   if (sideBarMenu[0].parent_id == 1301) {



    //     const newSidebarMenuvalue = [...sideBarMenu, {
    //       "id": 5555,
    //       "description": "Testing",
    //       "title": "Extract Data",
    //       "link": null,
    //       "icon": null,
    //       "parent_id": 1301,
    //       "category": "web",
    //       "module": "sideBar",
    //       "target": "_self",
    //       "actions": [
    //         {
    //           "role_id": 4,
    //           "menu_id": 1311,
    //           "action_id": null
    //         }
    //       ]
    //     }]
    //     return setNewSidebarMenu(newSidebarMenuvalue)
    //   }


    // }

    // useEffect(() => {
    //   abc()
    //   console.log('smile', newSidebarMenu)
    // }, [])


    const handleClick = event => {
        console.log(event.currentTarget.id);
        localStorage.setItem('subPageId', event.currentTarget.id)
    };
    const currentSubPage = localStorage.getItem('subPageId')
    return (
        <div>
            <div className="aside-top-bar">
                <img src={Logo} alt="MISR Logo" className="img-fluid" />
            </div>
            <div className='aside'>
                <div className='aside-div'>
                    <div className="d-flex aside__icon__bar">
                        {
                            sideTopMenu.map((topRight, index) => {

                                return (
                                    <Link to={"/" + topRight.link}> <img src={API_SRC + topRight.icon} alt='' /> </Link>
                                )

                            })
                        }

                    </div>

                </div>
                <div className="sidebar__menu">
                   
                    <div id="myUL"><a id="1111" class="li" href="/searchinginclusion"> <li class="bg--active--link"> Online Search </li> </a><a id="1112" class="li" href="/risimport"> <li class="bg--active--link"> Import Abstracts </li> </a><a id="1114" class="li " href="/searchabstracts"> <li class="bg--active--link"> Searched &amp; Imported Results </li> </a></div>
                
                </div >
                <Sidedropdown />
            </div >
        </div >
    );
}

export default AsidebarSearchResult;
