import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userOld from "./../../services/user/userService";

const createInitialState = () => {
  return {
    data: [],
    error: null,
    status: "idle",
  };
};

export const loginAuth = createAsyncThunk("loginAuth", async (initialPost, { rejectWithValue }) => {
  try {
    const response = await userOld.userLogin(initialPost);
    localStorage.removeItem("loginError");
    localStorage.setItem("user", JSON.stringify(response.data));
    if (response.data.role.role === 'admin') {
      localStorage.setItem('pageId', 2)
    }
    else if (response.data.role.role === 'teamlead') {
      localStorage.setItem('pageId', 1001)
    }
    window.location.href = response.data.role.landingpage;

  } catch (err) {
    rejectWithValue(err.response.data);
    localStorage.setItem("loginError", "The provided credentials are incorrect.");
  }
});

const initialState = createInitialState();
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [loginAuth.rejected](state, action) {
      state.status = "failed";
      state.error = action.payload;
    },
    [loginAuth.fulfilled](state, action) {
      state.status = "succeeded";
      state.data = action.payload;
    },
  },
});

export default userSlice.reducer;
