import React from 'react'
import Aside from '../../Components/aside'
import IncludedfulltextComponent from '../../Components/IncludedFulltextComponent'
import TopBar from '../../Components/topBar'


const IncludedFulltext = () => {
  return (

        <div className="main-wrapper">
        <Aside />
        <div className="content-wrapper">
            <TopBar />
            <IncludedfulltextComponent/>
        </div>
    </div>
      
  )
}

export default IncludedFulltext