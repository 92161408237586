import React from 'react'

const AdminActionsComponent = () => {
    return (
        <div className="page--content ">
            <div className="row">
                <div className="col-md-6 col-lg-6">
                    <h1 className="page--heading ">
                        Actions
                    </h1>
                </div>

            </div>
        </div>
    )
}

export default AdminActionsComponent