import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import './../assets/sass/totalreview.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MD5hash, API_Link } from '../commons/Constants';
import gif from './../assets/img/spin.gif';
import { useSelector, useDispatch } from 'react-redux';
import { getPermission } from '../features/setting/permissionSlice';
// const userData = [{ name: "name1" }, { name: "name2" }, { name: "name3" }, { name: "name4" }, { name: "name5" }]

// const indexed = JSON.parse(localStorage.getItem("search"));
// const searchStudy = indexed && indexed.map((item, index) => Object.assign(item, { index }))
// console.log(indexed)
// console.log(searchStudy)

const ListingSelectionsComponent = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState('');
  const [study, setStudy] = useState(
    JSON.parse(localStorage.getItem('search'))
  );
  const [indexed, setIndexed] = useState('');
  const [show, setShow] = useState(false);
  const [searchStudy, setSearchStudy] = useState('');
  const [checkButton, setCheckButton] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCount, setShowCount] = useState(false);
  const [info, setInfo] = useState([]);
  const [count, setCount] = useState(0);
  // call for get reviewer permissions
  const [getPermission, setGetPermission] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  const getPermissionCall = () => {
    var config = {
      method: 'get',
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem(
        'studyId'
      )}`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGetPermission(response.data.permissions[0]);

        console.log('after api call in search inclusion', getPermission);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const picoData =
    localStorage.getItem('picoData') !== undefined &&
    localStorage.getItem('picoData') !== ''
      ? JSON.parse(localStorage.getItem('picoData'))
      : 1;

  console.log(
    'some data',
    picoData && picoData.length > 1 ? picoData.Intervention : ''
  );
  //

  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1) return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) =>
        shouldBeUppercase(word, index, length)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }

  useEffect(() => {
    getPermissionCall();
    const indexed = JSON.parse(localStorage.getItem('search'));
    // localStorage.getItem("info") !== undefined ? JSON.parse(localStorage.getItem("info")) : "";
    const info =
      localStorage.getItem('info') !== undefined &&
      localStorage.getItem('info') !== ''
        ? JSON.parse(localStorage.getItem('info'))
        : '';
    setIndexed(indexed);
    setInfo(info);
    const searchStudy = (indexed &&
      indexed.length > 0 &&
      indexed.map((item, index) => Object.assign(item, { index }))) || [
      {
        ID: '1',
        title: 'No Data',
        authors: {
          1: 'No Data',
        },
        year: 'No Data',
        abstract: 'No abstract data',
        place: 'No Data',
        'abstract-link': 'No Data',
        'fulltext-link': 'No Data',
      },
    ];

    if (searchStudy.length > 0) {
      setSearchStudy(searchStudy);
      // setStudy(searchStudy);
      setStudy(searchStudy);
    }
  }, []);

  // console.log("searched articles", indexed.length);

  let startINdex = 0;
  if (indexed) {
    startINdex = indexed.length;
  }
  // if (startINdex > info && info[0].Count) return;

  picoData.start = startINdex;
  const getSearchAPI = () => {
    var config = {
      method: 'post',
      url: 'http://misr.pacemis.com:78/search',
      headers: {
        'Content-Type': 'application/json',
      },
      data: picoData,
    };

    if (picoData.Database !== 'RISimport' && indexed && indexed.length >= 999) {
      axios(config).then((response) => {
        if (response.data.articles.length === 0) {
          return;
        }

        if (
          response.data.articles.length < 999 &&
          response.data.articles.length > 1
        ) {
          localStorage.setItem('PICO', 1);
          setIndexed([...indexed, ...response.data.articles]);
        }
        if (response.data.articles.length >= 999) {
          localStorage.setItem('PICO', 1);
          console.log('before', indexed);
          setIndexed([...indexed, ...response.data.articles]);

          console.log('after', indexed.length);
          console.log('combined', indexed);
          console.log('response.data', response.data.articles);
        }
        if (response.data === 'Internal Server Error') {
          return;
        }
      });
    }

    const searchStudy = (indexed &&
      indexed.length > 0 &&
      indexed.map((item, index) => Object.assign(item, { index }))) || [
      {
        ID: '1',
        title: 'No Data',
        authors: {
          1: 'No Data',
        },
        year: 'No Data',
        abstract: 'No abstract data',
        place: 'No Data',
        'abstract-link': 'No Data',
        'fulltext-link': 'No Data',
      },
    ];

    console.log('after API call', searchStudy);
    setSearchStudy(searchStudy);
    setStudy(searchStudy);
  };
  let countInfo = 0;
  if (info && info.length > 0) {
    let count = info[0].Count;
    countInfo = Math.ceil(parseInt(count) / 1000);
    console.log('123count info', countInfo);
  }

  useEffect(() => {
    getSearchAPI();
    // let count1 = 0;
    // if (info && info.length > 0) {
    //   count1 = parseInt(info[0].Count);
    // }
    // if (indexed && indexed.length == count1) return;

    // console.log("match", indexed && indexed.length == count1, typeof startINdex);
  }, [indexed]);

  const review_idLocal = JSON.parse(localStorage.getItem('studyId'));
  // handle change for selected check box
  const handleChange = (event) => {
    const { name, checked } = event.target;

    if (name == 'allSelect') {
      let tempUser = study.map((user) => {
        return { ...user, isChecked: checked };
      });
      setStudy(tempUser);
    } else {
      let tempUser = study.map((user) =>
        user.index == name ? { ...user, isChecked: checked } : user
      );
      setStudy(tempUser);
    }
  };

  // handle Click for paragraph hide show
  const handleClick = (index) => {
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  //console.log('newStudy', study && study.length > 0 && study.filter(user => user.isChecked === true))

  // Post API call

  const postAddArticles = () => {
    //e.preventDefault();

    setIsLoading(true);

    let newData =
      study &&
      study.length > 0 &&
      study
        .filter((user) => user.isChecked === true)
        .map((x) =>
          Object.fromEntries(
            Object.entries(x).map(([key, value]) => [
              key.toLowerCase(),
              typeof value == 'string' ? value.toLowerCase() : value,
            ])
          )
        );

    let newstringData = newData.map((x) =>
      Object.fromEntries(
        Object.entries(x).map(([key, value]) => [
          key.toLowerCase(),
          typeof value !== 'string' ? value.toString() : value,
        ])
      )
    );

    console.log('This is new study', study);
    console.log('this is new and newStringData', newstringData);

    const filteredArticles = newstringData
      .filter(function (article) {
        return article.abstract !== '' && article.title !== '';
      })
      .map(function (article) {
        return article;
      });
    console.log('This is new filtered', filteredArticles);

    const countFiltered = (type = '') => {
      const countTypes = newstringData.filter(
        (article) => article.abstract === type || article.title === type
      );
      return countTypes.length;
    };

    console.log('filtere stringdata', countFiltered());
    if (countFiltered() > 0) {
      alert(
        `${countFiltered()} Articles''s have Empty abstracts or Titles so they are Removed. Would You Like to Continue?`
      );
    }

    setCheckButton(newstringData);

    var config = {
      method: 'post',
      url: `${API_Link}addArticles`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
      data: {
        review_id: review_idLocal,
        database: picoData.Database,
        population: picoData.Population,
        intervention: picoData.Intervention,
        comparison: picoData.Comparison,
        outcome: picoData.Outcomes,
        exclusion: picoData.Exclusion,
        total_records: indexed.length,
        included:
          study &&
          study.length > 0 &&
          study.filter((user) => user.isChecked === true).length,
        articles: filteredArticles,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = JSON.stringify(response.data);
        console.log('resData', resData);

        // Navigate to next page
        navigate('/searchabstracts');
      })
      .catch(function (error) {
        // console.log(error);
        alert(error);
      })
      .finally(() => setIsLoading(false));
  };

  // Use Effect
  // useEffect(() => {
  //     setStudy(searchStudy)
  // }, [])

  //
  const goToBack = () => {
    // localStorage.setItem("picoData", "");
    navigate('/searchinginclusion');
  };

  if (isLoading)
    return (
      <div className='preload'>
        <img src={gif} alt='' />
      </div>
    );

  return (
    <>
      <div className='page--content'>
        <div className='row'>
          <div className='col bg-secondary-template'>
            <p>
              <span className='ms-1'>
                <strong>Database:</strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Database} </span>
              <span className='ms-1'>
                <strong>Population: </strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Population} </span>
              <span className='ms-1'>
                <strong>Intervention: </strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Intervention} </span>
              <span className='ms-1'>
                <strong>Comparison: </strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Comparison} </span>
              <span className='ms-1'>
                <strong>Outcome: </strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Outcomes} </span>
              <span className='ms-1'>
                <strong>Exclude: </strong>
              </span>
              <span className='text-muted ms-1'>{picoData.Exclusion}</span>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col bg-secondary-template'>
            <p>
              <span className=' ms-1'>
                {' '}
                <strong>KeyWords:</strong>{' '}
              </span>{' '}
              <span className='text-muted ms-1'>
                {info && info.length > 0 && info[0].Query}
              </span>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {/* <h1 className='page--heading'>Search Results</h1> */}
            <h1 className='page--heading'>Search Results</h1>
          </div>

          <div className='col text-end'>
            <button
              className='btn btn-danger me-2 add-in-review'
              onClick={() => {
                goToBack();
              }}
            >
              ← Go Back {}
            </button>
            {getPermission.includes(1) ? (
              study &&
              study.length > 0 &&
              study.filter((user) => user.isChecked === true).length > 0 ? (
                (picoData.Database !== 'RISimport' &&
                  study.length == info[0].Count - 1) ||
                (picoData.Database !== 'RISimport' &&
                  study.length == info[0].Count) ||
                (picoData.Database !== 'RISimport' && study.length == 9999) ? (
                  <button
                    className='btn btn-warning add-in-review'
                    onClick={() => postAddArticles()}
                  >
                    Include in Review
                  </button>
                ) : (
                  picoData.Database === 'RISimport' && (
                    <button
                      className='btn btn-warning add-in-review'
                      onClick={() => postAddArticles()}
                    >
                      Include in Review
                    </button>
                  )
                )
              ) : (picoData.Database !== 'RISimport' &&
                  study.length == info[0].Count - 1) ||
                (picoData.Database !== 'RISimport' &&
                  study.length == info[0].Count) ||
                (picoData.Database !== 'RISimport' && study.length == 9999) ? (
                <button className='btn btn-warning add-in-review' disabled>
                  Include in Review
                </button>
              ) : (
                picoData.Database === 'RISimport' && (
                  <button className='btn btn-warning add-in-review' disabled>
                    Include in Review
                  </button>
                )
              )
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            {getPermission.includes(1) ? (
              (picoData.Database !== 'RISimport' &&
                study.length == info[0].Count - 1) ||
              (picoData.Database !== 'RISimport' &&
                study.length == info[0].Count) ||
              (picoData.Database !== 'RISimport' && study.length == 9999) ? (
                <div className='form-check'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    name='allSelect'
                    checked={
                      study.filter((user) => user?.isChecked !== true).length <
                      1
                    }
                    onChange={handleChange}
                  />
                  <label className='form-check-label'>Select All</label>
                </div>
              ) : (
                picoData.Database === 'RISimport' && (
                  <div className='form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      name='allSelect'
                      checked={
                        study.filter((user) => user?.isChecked !== true)
                          .length < 1
                      }
                      onChange={handleChange}
                    />
                    <label className='form-check-label'>Select All</label>
                  </div>
                )
              )
            ) : (
              ''
            )}
          </div>
          <div className='col'>
            <label>
              Total articles count{' '}
              <strong>
                {indexed && indexed.length > 0 ? indexed.length : 0}
              </strong>
            </label>
            <label className='ms-3'>
              Selected articles{' '}
              <strong>
                {study &&
                  study.length > 0 &&
                  study.filter((user) => user.isChecked === true).length}
              </strong>
            </label>
          </div>
        </div>

        <div>
          {/* {
                        study && study.length > 0 && study.map(user =>
                            <div className=''>
                                <h1>{user.abstract}</h1>
                            </div>
                        )
                    } */}

          {study &&
            study.length > 0 &&
            study.map((user, index) => (
              <div className='form-check col-lg-12' key={index}>
                {getPermission.includes(1) ? (
                  <input
                    type='checkbox'
                    className='form-check-input'
                    name={user.index}
                    onChange={handleChange}
                    checked={user?.isChecked || false}
                  />
                ) : (
                  ''
                )}
                <div className='inner--box--row'>
                  <span className='index--numbering'>{user.index + 1}</span>

                  <div>
                    <h1 className='heading3' key={index}>
                      {' '}
                      {convertFirstLetterToUpperCase(
                        user.title || user.Title || ''
                      )}
                    </h1>
                    {/* <h1 className='heading3'>{user.Title || user.title}</h1> */}
                    {/* <h1 className='heading3'>{(user.Title || user.title).replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}</h1> */}
                    {/* <p className='small text-muted'>{user.Authors ? user.Authors || user.authors[0] : ""}</p> */}
                    <label
                      className='form-check-label'
                      htmlFor='flexCheckDefault2'
                    >
                      Journal:{user.Journal || user.journal}
                    </label>
                    <p
                      onClick={() => handleClick(user.index)}
                      className={
                        isActive === user.index
                          ? 'Increase-height--of--paragraph user-select-none'
                          : 'Reduce-height--of--paragraph user-select-none'
                      }
                    >
                      {user.abstract}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ListingSelectionsComponent;
