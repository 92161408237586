import React from "react";
import { Link } from "react-router-dom";
import { FaEye, FaPlus, FaStickyNote } from "react-icons/fa";
import { GrRotateRight } from "react-icons/gr";
import { BsFillCircleFill } from "react-icons/bs";
import Aside from "./../aside";
import TopBar from "./../topBar";
import "./../../assets/sass/style.css";
import  "./../../assets/sass/_screening.scss"
import  "./../../assets/sass/_topbar.scss"
import "./../../assets/css/topbar.css"

function InProgress() {
  const options = [
    {
      label: "#4816 - Alish 2007",
      p: "Alish, Yaakov; Birger, Moshe; Manor, Nira; Kertzman, Simion; Zerzion, Moshe; Kotler, Moshe; Strous, Real D.",
      h1: "Schizophrenia sex offenders: A clinical and epidemiological comparison study",
      pa: "International Journal of Law & Psychiatry 2007;30(6):459-466",
      par: "2007",
      ls: "Your last edit was about 1 hour ago",
    },
    {
      label: "#5241 - Ash 2015",
      p: "Curabitur sodales placerat leo, vitae fringilla ex molestie sit amet",
      h1: "Mauris dapibus porttitor neque eget varius Fusce aliquam efficitur vehicula",
      pa: "International Journal of Law & Psychiatry 2007;30(6):459-466",
      par: "2015",
      ls: "Your last edit was about 4 hour ago",
    },
  ];
  return (
    <div>
      <div className="page--content3">
        <div className="row">
          <div className="col-lg-4">
            <h1 className="page--heading">Pending</h1>
          </div>
          <div className="col-lg-8 text-right">
            <ul className="ul-export">
              <li>
                <div className="dropdown cst-dropdown">
                  <select name="" id="">
                    <option value="">Author </option>
                    <option value="">Year </option>
                    <option value="">Author </option>
                  </select>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Another action
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div>
                <input
                  type="text"
                  name=""
                  placeholder="Search by Author"
                  className="input--new form-control"
                  id=""
                />
              </li>
              <li>
                <a href="#" className="btn--export">
                  {" "}
                  <i className="fa-solid fa-file-arrow-down"></i> Export
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row boxes-row">
          {React.Children.toArray(
            options.map(({ label, h1, p, pa, par }) => (
              <div className="col-lg-12">
                <div className="inner--box--row">
                  <div className="width--70">
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault2"
                    >
                      {label}
                    </label>
                    <p className="small text-muted">{h1}</p>
                    <h1 className="heading3">{p}</h1>
                    <p className="small text-muted">{pa}</p>
                    <p>{par}</p>
                  </div>
                  <div className="width-30">
                    <div className="">
                      <Link
                        to="#"
                        data-bs-toggle="tooltip"
                        className="link"
                        title="View Full Text"
                      >
                        <FaEye />
                      </Link>
                      <Link
                        to="#"
                        data-bs-toggle="tooltip"
                        className="link"
                        title="View Full Text"
                      >
                        <FaPlus />
                      </Link>
                      <Link
                        to="#"
                        data-bs-toggle="tooltip"
                        className="link"
                        title="View Full Text"
                      >
                        <FaStickyNote />
                      </Link>
                      <Link
                        to="#"
                        data-bs-toggle="tooltip"
                        className="link"
                        title="View Full Text"
                      >
                        <GrRotateRight />
                      </Link>
                      <p>
                        <small className="circle--warning">
                          <BsFillCircleFill />
                          Your last edit was about 1 hour ago
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default InProgress;
