import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { IoIosAdd } from "react-icons/io";
import { Link } from "react-router-dom";
import { MD5hash, API_Link } from "../commons/Constants";
import "./../assets/sass/style.css";
import Aside from "./aside";
import TopBar from "./topBar";

function User() {
  // set useState
  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  // get Token
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;

  const getUserList = async () => {
    // api Call
    await axios
      .get(`${API_Link}getUsers`, {
        headers: {
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUserList(res.data.users);
        setIsLoading(false);
      });
    // .finally(() => setIsLoading(false));
  };

  const editUser = (user) => {};

  // useEffect

  useEffect(() => {
    getUserList();
  }, []);

  if (isLoading) return "";
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content '>
          <div className='row'>
            <div className='col-md-6 col-lg-6'>
              <h1 className='page--heading '>User Listing</h1>
            </div>
            <div className='col-sm-6 col-xs-12 text--align--right '>
              {/* <a href="addreview" className="tem--add--btn"></a> */}
              <Link to='/admin/adduser' className='tem--add--btn'>
                <IoIosAdd /> Add New User
              </Link>
            </div>
          </div>
          <div className='row'>
            <div className='padding-table'>
              <div className='table--template'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Sr#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Department</th>
                      <th>Role</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList &&
                      userList.map((user, index) => (
                        <tr>
                          <td>{index + 1} </td>
                          <td>{user.name}</td>
                          <td> {user.email}</td>
                          <td>{user.department !== null ? user.department.description : ""}</td>
                          <td>{user.role !== null ? user.role.role.charAt(0).toUpperCase() + user.role.role.slice(1) : ""}</td>
                          <td>{user.is_active === true ? <span className="text-success">Active</span> : <span className="text-danger">Inactive</span>}</td>
                          <td>
                            <Link to={`/admin/edituser/${user.id}`} className='tem--add--btn'>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
