import React from 'react'
import Aside from '../../Components/aside'
import TestRobotComponent from '../../Components/testRobotComponent'
import TopBar from '../../Components/topBar'

const TestRobotReviewerPage = () => {
    return (
        <div className='main-wrapper'>
            <Aside />
            <div className='content-wrapper'>
                <TopBar />
               <TestRobotComponent />
            </div>
        </div>
    )
}

export default TestRobotReviewerPage