import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { MD5hash, API_Link } from '../commons/Constants';
import {
  FaEye,
  FaFileUpload,
  FaMinus,
  FaPaperclip,
  FaPlus,
  FaStickyNote,
  FaTimes,
} from 'react-icons/fa';
import './../assets/sass/style.css';
import './../assets/sass/totalreview.css';
import { Modal, Tab, Tabs, Button } from 'react-bootstrap';
import { BiPaperclip } from 'react-icons/bi';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import paperclip from './../assets/img/icon/paperclip.svg';
import { BiTrash } from 'react-icons/bi';

const IncludedComponent = () => {
  const [arrayData, setArrayData] = useState([]);
  const [getPermission, setGetPermission] = useState([]);
  const [getIdForRemoveFile, setGetIdForRemoveFile] = useState('');
  const user = JSON.parse(localStorage.getItem('user'));
  const studyLocalId = JSON.parse(localStorage.getItem('studyId'));
  const token = user.token;

  const getPermissionCall = () => {
    var config = {
      method: 'get',
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem(
        'studyId'
      )}`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGetPermission(response.data.permissions[0]);

        // console.log('after api call in search inclusion', getPermission);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var config = {
      method: 'get',
      url: `${API_Link}getReviewArticles`,
      params: {
        review_id: studyLocalId,
        included: 'Yes',
        exclusion: 'No',
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        setArrayData(
          resData.data.articles.filter((array) => array.current_stage >= 3)
        );
      })
      .catch(function (error) {
        console.log(error);
      });

    getPermissionCall();
  }, []);

  const [isActive, setIsActive] = useState(null);

  const [getFileName, setgetFileName] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [dataSetUrl, setDataSetUrl] = useState('');
  const [show, setShow] = useState(false);
  const [tabKey, initTabKey] = useState('one');
  const [article, setArticle] = useState([]);
  const [articleId, setArticleId] = useState([]);

  const [isGreen, setIsGreen] = useState(
    new Array(arrayData.length).fill(false)
  );
  const [isRed, setIsRed] = useState(new Array(arrayData.length).fill(false));
  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };
  // handle Cahnge
  const handleChange = (e) => {
    setDataSetUrl(e.target.value);
    // console.log(dataSetUrl);
  };

  const handleFileUpload = (index, id) => {
    console.log(id);
    setShow(true);

    arrayData.find((article) => {
      if (article.id === id) {
        setArticle([article]);
        setArticleId(article.id);
        setDataSetUrl(article.source);
      } else {
        // return article;
      }
    });
  };

  const handleFileRemoved = (index, id) => {
    console.log(id);
    setShowRemove(true);
    arrayData.find((article) => {
      if (article.id === id) {
        setArticle([article]);
        setArticleId(article.id);
      } else {
        // return article;
      }
    });
  };
  // console.log('Selected Article', article);
  //
  const handleFileSelect = (event) => {
    //
    const selectedFile = event.target.files[0];
    const fileSizeInBytes = selectedFile.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // convert bytes to MB

    if (fileSizeInMB > 3) {
      console.error('File size exceeds the limit of 3 MB');
      // handle the error appropriately
      return alert('File size exceeds the limit of 3 MB');
    }

    setSelectedFile(event.target.files[0]);
    setgetFileName(event.target.files[0].name);
  };
  // Handle File Upload
  const handleSubmit = async (event, index) => {
    event.preventDefault();

    const result = arrayData.map((article, index) => {
      console.log('before filter', [article]);
      if (articleId === article.id) {
        const result = [...isGreen];
        result[index] = !result[index];
        console.warn('state', result);
        setIsGreen(result);
      }
      return article;
    });
    // console.warn("result submit", result);
    var formData = new FormData();
    formData.append('review_id', localStorage.getItem('studyId') || 1);
    formData.append('file', selectedFile);
    formData.append('id', articleId);
    // console.log('file data =-----=====',...formData);
    // const fileUrl = article.map((article) => ({ ...article, url: null }));
    // console.log("url", fileUrl);
    // console.log('Selected Article', articleId);

    await axios
      .post(`${API_Link}uploadFulltextData`, formData, {
        headers: {
          'misr-auth': MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Response status is not 200');
        }
        console.log('Response From Server', res.data);
      })
      .catch((error) => {
        console.error(error.message);
        // handle the error appropriately
      })
      .finally(() => setShow(false));

    window.location.reload(false);
  };

  //

  const countFilesNotAdded = (type = null) => {
    const countTypes = arrayData.filter(
      (article) => article.file_path === type && article.source === type
    );
    return countTypes.length;
  };
  const countFilesAdded = (type = null) => {
    const countTypes = arrayData.filter(
      (article) => article.file_path !== type || article.source !== type
    );
    return countTypes.length;
  };
  const filesArticleIds = arrayData
    .filter((item) => item.file_path !== null || item.source !== null)
    .map((item) => item.id);

  const index = arrayData.map((item, index) => {
    return index;
  });

  console.log('file  Added1111', filesArticleIds.length);
  console.log('file  Added', countFilesAdded());
  console.log('file not Added', countFilesNotAdded());
  // console.log("file not Added 1", article);
  //
  //Url
  const handleUrlSubmit = async (e) => {
    e.preventDefault();

    const result = arrayData.map((article, index) => {
      console.log('before filter', [article]);
      if (articleId === article.id) {
        const result = [...isGreen];
        result[index] = !result[index];
        console.warn('state', result);
        setIsGreen(result);
      }
      return article;
    });

    var formData = new FormData();
    formData.append('review_id', localStorage.getItem('studyId') || 1);
    formData.append('source', dataSetUrl);
    formData.append('id', articleId);
    console.log(...formData);

    await axios
      .post(`${API_Link}uploadFulltextData`, formData, {
        headers: {
          'misr-auth': MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log('Response From Server', res.data);
      })
      .finally(() => setShow(false));
    window.location.reload(false);
  };
  // Extension

  // Handle Move To Full Text
  const handleMoveToFullText = () => {
    navigate('/screeningFullText');
  };

  // delete modal code starts here
  const [showDelete, setShowDelete] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  const [getId, setGetId] = useState('');
  const handleDelete = (index, id) => {
    setShowDelete(true);
    let result = arrayData
      .filter((article) => article.id === id)
      .map((item) => item.id);
    console.log('get111 id', result);
    setGetId(result);
    // alert(result);
  };
  console.log('getId', getId);
  const handleDeleteClose = () => {
    setShowDelete(false);
  };
  const handleDeleteArticle = () => {
    // getId
  };

  const hideRemoveModal = () => {
    setShowRemove(false);
  };
  // code for capitalize
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1) return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) =>
        shouldBeUppercase(word, index, length)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }

  const handleRemoveFile = () => {
    var config = {
      method: 'post',
      url: `${API_Link}deleteFulltextData`,
      data: {
        review_id: studyLocalId,
        id: articleId,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        //  console.log('response', response.data)
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-8'>
          <h1 className='page--heading'>Abstracts Included</h1>
          <span>
            Files : &nbsp; &nbsp; &nbsp; Total Abstracts{' '}
            <strong>({arrayData.length})</strong> &nbsp; &nbsp; &nbsp; Full Text
            Available <strong>{countFilesAdded()}</strong> &nbsp; &nbsp; Full
            Text Not Available <strong>{countFilesNotAdded()}</strong>
          </span>
        </div>

        {getPermission && getPermission.includes(2) ? (
          <div className='col-lg-4 text-right'>
            <span className='d-flex justify-content-end'>
              {countFilesAdded() > 0 ? (
                <button
                  className='btn btn-primary'
                  onClick={handleMoveToFullText}
                >
                  Move to Full Text Screening
                </button>
              ) : (
                <button className='btn btn-primary' disabled>
                  Move to Full Text Screening
                </button>
              )}
            </span>
          </div>
        ) : (
          ''
        )}

        <Modal
          className='modal-lg abstract--modal file-upload-modal modal-dialog-centered vertical-align-center'
          show={show}
          onHide={handleClose}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header className='pt-5'>
            <h2 className='mb-2 Active--learning--heading'>
              Add Link or Upload File
            </h2>
            <button className='btn-close' onClick={handleClose}></button>
          </Modal.Header>

          <Tabs
            className='text-dark included-file-upload-tabs'
            activeKey={tabKey}
            onSelect={(e) => initTabKey(e)}
          >
            <Tab eventKey='one' title='File' className='modal--tab-buttons'>
              <div className='row'>
                <div className='col-lg-12'>
                  <h1>{}</h1>
                  <div className='mt-2 data-set-upload'>
                    <form onSubmit={handleSubmit} className='form--ris-modal'>
                      {!getFileName ? (
                        <span
                          className='text-info drag--drop-font'
                          style={{ fontSize: '22px' }}
                        >
                          Drag and drop file here, or click to select file
                        </span>
                      ) : (
                        <span
                          id='fileName'
                          className='text-info'
                          style={{ fontSize: '22px' }}
                        >
                          {getFileName}
                        </span>
                      )}
                      <input
                        id='fileUpload'
                        type='file'
                        name='myFile'
                        required
                        className='inputFileRis'
                        onChange={handleFileSelect}
                      />
                      <input
                        type='submit'
                        value='Upload File'
                        className='btn btn-lg btn-success upload-file-ris '
                      />
                    </form>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey='two' title='URL' className='modal--tab-buttons'>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='mt-2 data-set-upload'>
                    <form
                      onSubmit={handleUrlSubmit}
                      className='form--ris-modal'
                    >
                      <div>
                        <span className='d-flex justify-content-start mb-3'>
                          <span
                            className='text-info '
                            style={{ fontSize: '22px' }}
                          >
                            Dataset URL
                          </span>
                        </span>
                        <input
                          id='fileUpload'
                          style={{ height: '60px' }}
                          type='text'
                          value={dataSetUrl}
                          name='myFile'
                          required
                          className='form-control mb-3'
                          onChange={(e) => handleChange(e)}
                        />
                        <input
                          type='submit'
                          value='Add URL'
                          className='btn btn-lg btn-success upload-file-ris '
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal>
      </div>
      <div className='row boxes-row'>
        {React.Children.toArray(
          arrayData && arrayData.length > 0 ? (
            arrayData.map(
              ({ id, title, authors, journal, abstract }, index) => (
                <div className='col-lg-12' key={index}>
                  {/* Handle Background Color Change On Include, Exclude  */}
                  <div
                    className='inner--box--row'
                    style={{
                      backgroundColor:
                        isGreen[index] || filesArticleIds.includes(id)
                          ? ' rgba(0,255,0,0.2)'
                          : '',
                    }}
                  >
                    <span className='index--numbering'>{index + 1}</span>

                    <div className='width--80'>
                      <h1 className='heading3'>
                        {convertFirstLetterToUpperCase(title)}
                      </h1>
                      <p className='small text-muted'>{authors}</p>
                      {journal ? (
                        <label className='form-check-label'>
                          {' '}
                          Journal {journal}{' '}
                        </label>
                      ) : (
                        ''
                      )}
                      <p
                        className={
                          isActive === index
                            ? 'Increase-height--of--paragraph'
                            : 'Reduce-height--of--paragraph'
                        }
                      >
                        {abstract}
                      </p>
                    </div>
                    <div className='width-20'>
                      <div className=''>
                        <div className='btn--new--ul'>
                          {/* <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleClick(index)}>
                          <FaEye />
                        </Link> */}

                          {getPermission && getPermission.includes(2) ? (
                            <span>
                              {isGreen[index] ||
                              filesArticleIds.includes(id) ? (
                                <Link
                                  to='#'
                                  className='link bg-danger'
                                  title='Remove File'
                                  onClick={() => handleFileRemoved(index, id)}
                                >
                                  <BiTrash />
                                </Link>
                              ) : (
                                ''
                              )}
                              <Link
                                to='#'
                                data-bs-toggle='tooltip'
                                className='link'
                                title='Upload or Link File'
                                onClick={() => handleFileUpload(index, id)}
                              >
                                <img src={paperclip} alt='' />
                              </Link>
                              <br />
                              <span className='upload-link-file'></span>
                              <Modal
                                className='modal-dialog-centered '
                                show={showDelete}
                                onHide={handleDeleteClose}
                                backdrop='static'
                                keyboard={false}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>Confirm!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  This Article is Without File Would You Like to
                                  Delete it?
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button
                                    variant='secondary'
                                    onClick={handleDeleteClose}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    variant='danger'
                                    onClick={handleDeleteArticle}
                                  >
                                    Delete
                                  </Button>
                                </Modal.Footer>
                              </Modal>

                              {/*  */}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <p className='p-3 text-muted'>There are no included abstracts.</p>
          )
        )}
      </div>

      <Modal
        className='modal-dialog-centered '
        show={showRemove}
        onHide={hideRemoveModal}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove attached file !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please confirm do you want to remove file ?</Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={hideRemoveModal}>
            Cancel
          </Button>
          <Button variant='danger' onClick={handleRemoveFile}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default IncludedComponent;
