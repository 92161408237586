import React, { useEffect, useState } from "react";
import SignUp from "./../../Components/signup";
import Header from "./../../Components/header";
import Footer from "./../../Components/footer";
import { useSelector, useDispatch } from "react-redux";
import { setting } from "./../../features/setting/settingSlice";
function SignUpPage() {
  const userSetting = useSelector((state) => state.setting.data);

  // console.log("Slice", userSetting);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setting());
  }, []);
  return (
    <div className='loginPage'>
      <Header logo={userSetting.logo} mainHeading={userSetting.full_title} subHeading={userSetting.sub_title} />
      <SignUp login_page_bg={userSetting.login_page_bg} short_title={userSetting.short_title} sponsor_logo={userSetting.sponsor_logo} />
      <Footer associated_link={userSetting.associated_link} associated_name={userSetting.associated_name} associated_verb={userSetting.associated_verb} copyright_text={userSetting.copyright_text} />
    </div>
  );
}

export default SignUpPage;
