import React from 'react'
import TopBar from './../../Components/topBar'
import Aside from './../../Components/aside'
import TotalReviews from './../../Components/screeningComponent/totalReviews'
import "./../../assets/sass/_screening.scss"
import TotalReviewsNew from '../../Components/TotalReviewsNew'

function ReviewsPage() {
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <TotalReviews/> 
      </div>
    </div>
  )
}

export default ReviewsPage
