import React from "react";
import BGV1 from "./../assets/img/loginBGV1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Card, CardGroup } from "react-bootstrap";
import { BsImages} from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import ListGroup from "react-bootstrap/ListGroup";
import Aside from "./aside";
import TopBar from "./topBar";
import "./../assets/sass/style.css"
import { Link } from "react-router-dom";

function Setting() {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />

        <div className="page--content ">
          <div className="row">
            <div className="col-md-12 col-lg-11">
              <h1 className="page--heading text-center">
                GENERAL SETTINGS
              </h1>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-5 ">
                <form className="general--setting--page">
                  <div className="mb-3">
                    <label className="form-label">
                      Application name
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Application Full Title
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                </form>
              </div>
              <div className="col-1"></div>
              <div className="col-sm-12 col-md-12 col-lg-5">
                <form className="general--setting--page">
                  <div className="mb-3">
                    <label className="form-label">
                      Application Short Title
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Application Sub Title
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-2">
              <div className=" col-md-12 col-lg-11">
                <label htmlFor="file-input" > Application Logo</label>
                <div className="image-upload general--setting--page--img--upload mt-3">
                  <label >
                    <BsImages/>
                    <p className="upload">Upload logo</p>
                    <p className="size">Size (90x90)</p>
                  </label>
                  <input id="file-input" type="file" />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <form className="general--setting--page">
                  <div className="mb-3">
                    <label className="form-label">
                      Sponsored Title
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                    />
                      {" "}
                    
                  </div>
                </form>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-11 ">
                <label>Sponsored Logo</label>
                <div className="image-upload general--setting--page--img--upload mt-3">
                  <label>
                    <BsImages/>
                    <p className="upload">Upload logo</p>
                    <p className="size">Max (5)</p>
                  </label>
                  <input id="file-input" type="file" />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg-5">
                <form className="general--setting--page">
                  <div className="mb-3">
                    <label className="form-label">
                      Powered by Decription
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </form>
              </div>
              <div className="col-1"></div>
              <div className="col-sm-12 col-md-12 col-lg-5">
                <form className="general--setting--page">
                  <div className="mb-3">
                    <label className="form-label">
                      Copy Right Decription
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-12 col-lg-11 ">
                <label>
                  Login page background image{" "}
                </label>
                <div className="image-upload general--setting--page--img--upload mt-3">
                  <label>
                       <BsImages className="icon"/>
                    <p className="upload">Upload Login background image</p>
                    <p className="size">Size (750x1366)</p>
                  </label>
                  <input id="file-input" type="file" />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className=" col-md-12 col-lg-11">
                <label>Mobile splash screen </label>
                <div className="image-upload general--setting--page--img--upload mt-3">
                  <label >
                      <BsImages/>
                    <p className="upload">Upload mobile splash image</p>
                    <p className="size">Size (800x800)</p>
                  </label>
                  <input id="file-input" type="file" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;
