import React from "react";
import { API_Link, MD5hash } from "../commons/Constants";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosAdd } from "react-icons/io";

const AdminRoleListingComponent = () => {
  const [roles, setRoles] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;

  const getRoles = () => {
    var config = {
      method: "get",
      url: `${API_Link}getRoles`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setRoles(resData.data.roles);

        //  console.log('Level APIs',resData.data.levels)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className='page--content '>
      <div className='row'>
        <div className='col-md-6 col-lg-6'>
          <h1 className='page--heading '>Role Listing</h1>
        </div>
        <div className='col-sm-6 col-xs-12 text--align--right '>
          {/* <a href="addreview" className="tem--add--btn"></a> */}
          <Link to='/admin/addrole' className='tem--add--btn'>
            <IoIosAdd /> Add New Role
          </Link>
        </div>
      </div>

      <div className='row'>
        <div className='padding-table'>
          <div className='table--template'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Role</th>
                  <th>Description</th>
                  <th>Landing Page</th>
                  {/* <td>Action</td> */}
                </tr>
              </thead>
              <tbody>
                {roles &&
                  roles.map((role, index) => (
                    <tr>
                      <td>{index + 1} </td>
                      <td>{role.role.charAt(0).toUpperCase() + role.role.slice(1)}</td>
                      <td> {role.description}</td>
                      <td> {role.landingpage}</td>

                      {/* <td>
                        <Link to={`/admin/edituser/${user.id}`} className='tem--add--btn'>
                          Edit
                        </Link>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminRoleListingComponent;
