import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./../assets/img/logo.png";
import "./../assets/sass/style.css";
import { IoHomeOutline } from "react-icons/io5";
import Sidedropdown from "./childComponents/dropDown";
import { FaBars } from "react-icons/fa";
import { BsCloudArrowDown } from "react-icons/bs";
import { API_Link, API_SRC } from "./../commons/Constants";
import { useSelector } from "react-redux";

function Aside(props) {
  // const invitation = useSelector((state) => state.invitation.value);
  // console.log("reducer", invitation);
  const sidebars = JSON.parse(localStorage.getItem("user"));
  const currentPageId = JSON.parse(localStorage.getItem("pageId"));
  const menus = sidebars.menu;
  // console.log (menu)
  // const newmenu = menu.map((menus) => menus.modules === 'topBar')

  // const sideBarMenu = menus.filter((menu) => menu.module === "sideBar" && menu.parent_id === currentPageId);
  const sideBarMenu = menus.filter((menu) => menu.module === "sideBar" && menu.parent_id === currentPageId);
  const topBarMenu = menus.filter((menu) => menu.module === "topBar");
  const topRightMenu = menus.filter((menu) => menu.module === "topRight");
  const sideBottomMenu = menus.filter((menu) => menu.module === "sideBottom");
  const sideTopMenu = menus.filter((menu) => menu.module === "sideTop");
  const subMenusideBarMenu = menus.filter((menu) => menu.parent_id === 1211 || menu.parent_id === 1212 || menu.parent_id === 1213);

  const [isActivedupplicate, setIsActiveduplicate] = useState(null);
  const [isActivedabstract, setIsActiveabstract] = useState(false);
  const [isActivefulltext, setIsActivefulltext] = useState(false);

  const handleClickMenuDuplicate = (index) => {
    console.log("this is console Index", index);
    if (isActivedupplicate === index) {
      setIsActiveduplicate(null);
    }

    setIsActiveduplicate(index);
  };

  const handleClickMenuAbstract = () => {
    setIsActiveduplicate(false);
    setIsActivefulltext(false);
    setIsActiveabstract((current) => !current);
  };

  const handleClickMenuFulltext = () => {
    setIsActiveduplicate(false);
    setIsActiveabstract(false);
    setIsActivefulltext((current) => !current);
  };

  const [newSidebarMenu, setNewSidebarMenu] = useState([]);

  // console.log('pageId', sideBarMenu[0].parent_id)

  const sidebarnewmenu = () => { };

  useEffect(() => {
    sidebarnewmenu();
  }, []);

  console.log("newSidebarMenu", subMenusideBarMenu);
  // const abc = () => {
  //   if (sideBarMenu[0].parent_id == 1301) {

  //     const newSidebarMenuvalue = [...sideBarMenu, {
  //       "id": 5555,
  //       "description": "Testing",
  //       "title": "Extract Data",
  //       "link": null,
  //       "icon": null,
  //       "parent_id": 1301,
  //       "category": "web",
  //       "module": "sideBar",
  //       "target": "_self",
  //       "actions": [
  //         {
  //           "role_id": 4,
  //           "menu_id": 1311,
  //           "action_id": null
  //         }
  //       ]
  //     }]
  //     return setNewSidebarMenu(newSidebarMenuvalue)
  //   }

  // }

  // useEffect(() => {
  //   abc()
  //   console.log('smile', newSidebarMenu)
  // }, [])

  const handleClick = (event) => {
    console.log(event.currentTarget.id);
    localStorage.setItem("subPageId", event.currentTarget.id);
  };
  const currentSubPage = localStorage.getItem("subPageId");
  return (
    <div>
      <div className='aside-top-bar'>
        <img src={Logo} alt='MISR Logo' className='img-fluid' />
      </div>
      <div className='aside'>
        <div className='aside-div'>
          <div className='d-flex aside__icon__bar'>
            {sideTopMenu.map((topRight, index) => {
              return (
                <Link to={"/" + topRight.link}>
                  {" "}
                  <img src={API_SRC + topRight.icon} alt='' />{" "}
                </Link>
              );
            })}
          </div>
        </div>
        <div className='sidebar__menu'>
          <div id='myUL'>
            {sideBarMenu.map((sideBarLink, index) => {
              return sideBarLink.link === null && sideBarLink.parent_id === 1201 ? (
                <ul className='nav flex-column' id='nav_accordion'>
                  <li className='nav-item has-submenu'>
                    <a className='nav-link bg--active--link'  onClick={() => handleClickMenuDuplicate(index)}>
                      {sideBarLink.title}
                      <span className={isActivedupplicate === index ? "right--menu--collapse test" : "right--menu--collapse "}>➜ </span>
                    </a>
                    <ul className={isActivedupplicate === index ? "submenu" : "submenu collapse"}>
                      {subMenusideBarMenu
                        .filter((menu) => menu.parent_id === sideBarLink.id)
                        .map(({ title, id, link }, index) => {
                          return (
                            <>
                              <li className={"/" + link == window.location.pathname ? 'li active' : 'li'} >
                                {/* <a className={"/" + link == window.location.pathname ? "active nav-link inner--nav--link" : "nav-link inner--nav--link"} href={"/" + link}>
                                  {title}

                                  {id == "1241" ? (
                                    <span className='countCircle'> {props.countTotal}</span>
                                  ) : id == "1242" ? (
                                    <span className='countCircle'> {props.countIncluded}</span>
                                  ) : id == "1243" ? (
                                    <span className='countCircle'> {props.countExcluded}</span>
                                  ) : (
                                    <span className='countCircle'>0</span>
                                  )}
                                </a> */}

                                <Link className={"/" + link == window.location.pathname ? "active nav-link inner--nav--link" : "nav-link inner--nav--link"} to={"/" + link}>
                                  <span className='menu--link--for--count'>
                                    <span> {title}</span>

                                    {id == "1241" ? (
                                      <span className='countCircle'> {props.countTotal}</span>
                                    ) : id == "1242" ? (
                                      <span className='countCircle'> {props.countIncluded}</span>
                                    ) : id == "1243" ? (
                                      <span className='countCircle'> {props.countExcluded}</span>
                                    ) : (
                                      // <span className='countCircle'></span>
                                      <span></span>
                                    )}
                                  </span>
                                </Link>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  </li>
                </ul>
              ) : (
                <Link onClick={handleClick} to={"/" + sideBarLink.link} key={index} id={sideBarLink.id} className={"/" + sideBarLink.link == window.location.pathname ? "li active" : "li"}>
                  {" "}
                  <li className='bg--active--link'> {sideBarLink.title} </li>{" "}
                </Link>
              );
            })}
          </div>
        </div>
        <Sidedropdown invitataionCount={props.invitaions} />
      </div>
    </div>
  );
}

export default Aside;
