import { useRef } from "react";

export default function InputComponent(props) {
    let error_message = props.error ? props.error : "";
    let show_label = !props.no_label;
    function handleChange(e) {
        // console.log(reference.current.value);
        props.onChange(props.for, e, "InputComponent");
    }
    return (
        <div className="form-group">
            {props.label && show_label && (
                <label htmlFor={props.for} className="login__label-text">
                    {props.label}
                    {props.is_optional === false && "*"}
                </label>
            )}
            <div className="input-group" style={props.error?stylesheet.error:{}} >
                <input
                    ref={props.reference}
                    type={props.type}
                    id={props.for}
                    list={props.list}
                    autoComplete="off"
                    className="form-control"
                    placeholder={props.placeholder}
                    value={props.value&&props.value}
                    disabled={props.disabled}
                    onChange={handleChange}
                    multiple={props.multiple}
                    required={!props.is_optional}
                    maxLength={props.max}
                    min={props.minValue}
                    max={props.maxValue}
                    readOnly={props.readOnly}
                />
                {props.icon&&
                    <div className="icon__input-div">
                        <span className="icon__span">
                            <i className={props.icon}></i>
                        </span>
                    </div>
                }
            </div>
            {props.error
                &&
            <p style={stylesheet.error_text}>
                Please fill this field.
            </p>}
        </div>
    );
}

const stylesheet={
    error:{
        border:'1px solid tomato',
        // borderColor:'red !important'
    },
    error_text:{
        fontSize:'14px',
        color:'tomato'
    }
}