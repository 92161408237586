import React from 'react'
import "./../assets/sass/_login.scss"
import "./../assets/sass/_search.scss"
import "./../assets/sass/style.css"
import BGV1 from "./../assets/img/loginBGV1.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'react-bootstrap/Button';
import {Card, CardGroup} from 'react-bootstrap';
import { BiMessageSquareAdd, BiLoaderCircle } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import ListGroup from 'react-bootstrap/ListGroup';
import Aside from './aside'
import TopBar from './topBar'

function Searching() {
  return (
       <div className="main-wrapper" >
	    
		<Aside />
		<TopBar />
	   <div className="containernew">
		<h2 className="page--heading">Evidence</h2>
	 
	  
	<CardGroup  style={{ width: '50rem' }}>

      <Card>
      <Card.Body>
        <Card.Header style={{ backgroundColor: '#023047', color: 'white', fontSize: '20px' }}>Keywords for exclude<BiMessageSquareAdd style={{ marginLeft: '100px' }} /></Card.Header>
   <ListGroup variant="">
        <ListGroup.Item>trials<AiFillDelete style={{ marginLeft: '80px' }}/></ListGroup.Item>
        <ListGroup.Item>cohort<AiFillDelete style={{ marginLeft: '70px' }}/></ListGroup.Item>
        <ListGroup.Item>cells<AiFillDelete style={{ marginLeft: '80px' }}/></ListGroup.Item>
        <ListGroup.Item>prevalence<AiFillDelete style={{ marginLeft: '30px' }}/></ListGroup.Item>
        <ListGroup.Item>retrospectively<AiFillDelete style={{ marginLeft: '20px' }}/></ListGroup.Item>
        <ListGroup.Item>observational<AiFillDelete style={{ marginLeft: '30px' }}/></ListGroup.Item>
      </ListGroup>
        <Button style={{ backgroundColor: '#023047'}}><BiLoaderCircle/>Load more...</Button>
      </Card.Body>
    </Card>
	
	  <Card style={{ width: '18rem' }}>
      <Card.Body>
      <Card.Header style={{ backgroundColor: '#023047', color: 'white', fontSize: '20px' }}>Keywords for exclude<BiMessageSquareAdd style={{ marginLeft: '100px' }} /></Card.Header>
   <ListGroup variant="">
        <ListGroup.Item>patient </ListGroup.Item>
        <ListGroup.Item>year</ListGroup.Item>
        <ListGroup.Item>cells</ListGroup.Item>
        <ListGroup.Item>prevalence</ListGroup.Item>
        <ListGroup.Item>retrospectively</ListGroup.Item>
        <ListGroup.Item>observational</ListGroup.Item>
      </ListGroup>
        <Button style={{ backgroundColor: '#023047'}}><BiLoaderCircle/>Load more...</Button>
      </Card.Body>
    </Card>
	</CardGroup>
	</div>
	</div>


  )
}

export default Searching
