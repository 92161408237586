import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/sass/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './Pages/public/loginPage';
import InvitationPage from './Pages/user/invitationPage';
import LoginPage2 from './Pages/public/loginPage2';
import LoginPage3 from './Pages/public/loginPage3';
import Dashboard from './Pages/user/dashboard';
import SignupPage from './Pages/user/signupPage';
import TextReviews from './Pages/user/reviewsPageScreening';
import CompletedPage from './Pages/user/completedPage';
import ConsensusPage from './Pages/user/consensusPage';
import InprogressPage from './Pages/user/inprogressPage';
import ConflictPage from './Pages/user/conflictPage';
import SettingPage from './Pages/settingPage';
import SearchingPage from './Pages/user/searchingPage';
import SearchingInclusionPage from './Pages/user/searchingInclusionPage';
import ExtractionPage from './Pages/user/extractionPage';
import ExtractionprismaPage from './Pages/user/extractionprismaPage';
import ExtractiontablePage from './Pages/user/extractiontablePage';
import ReviewPage from './Pages/user/addreviewsPage';
import RolePage from './Pages/user/rolePage';
import ReviewsListingPage from './Pages/user/reviewsPage';
import ScreeningDuplicatePage from './Pages/user/screeningduplicatePage';
import UserPage from './Pages/user/userPage';
import PageNotFound from './Pages/user/Page-404';
import PrismaPage from './Pages/user/PrismaPage';
import IncludedPage from './Pages/user/IncludedPage';
import ExcludedPage from './Pages/user/ExcludedPage';
import MaybePage from './Pages/user/MaybePage';
import SearchAbstracts from './Pages/user/searchabstracts';
import RisImportPage from './Pages/user/RisImportPage';
import Evidencetablepage from './Pages/user/evidencetablepage';
import IncludedFulltext from './Pages/user/includedfulltext';
import ExcludedFulltext from './Pages/user/excludedfulltext';
import ReviewsAbstractPage from './Pages/user/reviewsAbstractPage';
import IncludedDuplicatePage from './Pages/user/includedDuplicatesPage';
import ExcludedDuplicatePage from './Pages/user/excludedDuplicatesPage';
import ListingSelections from './Pages/user/listingSelections';
import PrivateRoutes from './utils/PrivateRoutes';
import { useSelector } from 'react-redux';
import EditReviewPage from './Pages/user/editreviewsPage';
import AddUser from './Components/addUser';
import MaybeFulltextPage from './Pages/user/maybeFulltextPage';
import MaybeAbstractPage from './Pages/user/maybeAbstractPage';
import LookUpListingPage from './Pages/admin/LookUpListingPage';
import ReviewTypeListingPage from './Pages/admin/ReviewTypeListingPage';
import MenuListingPage from './Pages/admin/MenuListingPage';
import RoleListingPage from './Pages/admin/RoleListingPage';
import LevelListingPage from './Pages/admin/LevelListingPage';
import Activitylog from './Pages/admin/activitylog';
import AdminActions from './Pages/admin/actions';
import EditUser from './Pages/admin/editUser';
import AddLevel from './Components/addLevel';
import AddRole from './Components/addRole';
import RobotReviewerPage from './Pages/user/robotReviewerPage';
import TestRobotReviewerPage from './Pages/user/testRobotReviewerPage';
import PicoBot from './Components/picoBot';
import JudgementtablePage from './Pages/user/judgementtablePage';
import Evidencebot from './Components/evidencebot';
;


function App() {

  
  // let token = localStorage.setItem("token", null);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/invitation' element={<InvitationPage />} />
          <Route path='/loginV2' element={<LoginPage2 />} />
          <Route path='/loginV3' element={<LoginPage3 />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/screeningFullText' element={<TextReviews />} />
          <Route
            path='/screeningAbstractCompleted'
            element={<CompletedPage />}
          />
          <Route
            path='/screeningAbstractConsensus'
            element={<ConsensusPage />}
          />
          <Route
            path='/screeningAbstractInProgress'
            element={<InprogressPage />}
          />
          <Route path='/screeningAbstractconflict' element={<ConflictPage />} />
          <Route
            path='/screeningAbstractTotal'
            element={<ReviewsAbstractPage />}
          />
          <Route path='/searching' element={<SearchingPage />} />
          <Route
            path='/searchinginclusion'
            element={<SearchingInclusionPage />}
          />
          <Route path='/extraction' element={<ExtractionPage />} />
          <Route path='/extractionprisma' element={<ExtractionprismaPage />} />
          <Route path='/extractiontable' element={<ExtractiontablePage />} />
          <Route path='/evidencetable' element={<Evidencetablepage />} />
          <Route path='/judgementtable' element={<JudgementtablePage />} />
          <Route path='/addreview' element={<ReviewPage />} />
          <Route path='/editreview/:id' element={<EditReviewPage />} />
          <Route path='/review' element={<ReviewsListingPage />} />
          <Route
            path='/screeningduplicate'
            element={<ScreeningDuplicatePage />}
          />
          <Route path='/setting' element={<SettingPage />} />
          <Route path='/role' element={<RolePage />} />
          <Route path='/prisma' element={<PrismaPage />} />
          <Route path='/included' element={<IncludedPage />} />
          <Route path='/includedfulltext' element={<IncludedFulltext />} />
          <Route path='/excluded' element={<ExcludedPage />} />
          <Route path='/excludedfulltext' element={<ExcludedFulltext />} />
          <Route path='/maybe' element={<MaybePage />} />
          <Route path='/admin/users' element={<UserPage />} />
          <Route path='/searchabstracts' element={<SearchAbstracts />} />
          <Route path='/risimport' element={<RisImportPage />} />
          <Route
            path='/includedduplicates'
            element={<IncludedDuplicatePage />}
          />
          <Route
            path='/excludedduplicates'
            element={<ExcludedDuplicatePage />}
          />
          <Route path='/listingselections' element={<ListingSelections />} />
          <Route path='/maybefulltext' element={<MaybeFulltextPage />} />
          <Route path='/maybeabstract' element={<MaybeAbstractPage />} />
          <Route path='/robotreviewerpage' element={<RobotReviewerPage />} />
          <Route path='/biasbot/:id' element={<TestRobotReviewerPage />} />
          <Route path='/picobot/:id' element={<PicoBot />} />
          <Route path='/evidencebot/:id' element={<Evidencebot/>} />

          <Route path='/admin/actions' element={<AdminActions />} />
          <Route path='/admin/MenuListingPage' element={<MenuListingPage />} />
          <Route path='/admin/RoleListingPage' element={<RoleListingPage />} />
          <Route
            path='/admin/LevelListingPage'
            element={<LevelListingPage />}
          />
          <Route path='/admin/activitylog' element={<Activitylog />} />

          <Route path='/admin/adduser' element={<AddUser />} />
          <Route path='/admin/edituser/:id' element={<EditUser />} />
          <Route
            path='/admin/LookUpListingPage'
            element={<LookUpListingPage />}
          />
          <Route
            path='/admin/ReviewTypeListingPage'
            element={<ReviewTypeListingPage />}
          />
          <Route path='/admin/addLevel' element={<AddLevel />} />
          <Route path='/admin/addrole' element={<AddRole />} />
          <Route path='/*' element={<PageNotFound />} />
        </Route>
        <Route path='/signup' element={<SignupPage />} />
        <Route path='/' element={<LoginPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
