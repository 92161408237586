import React from 'react'
import AdminMenuListingComponent from '../../Components/adminMenuListingComponent'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'

const MenuListingPage = () => {
  return (

    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <AdminMenuListingComponent/>
      </div>
    </div>

  )
}

export default MenuListingPage