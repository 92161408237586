import React, { useState, useEffect } from "react";
import InputComponent from "./InputComponent";
import ButtonComponent from "./ButtonComponent";
import RadioComponent from "./RadioComponent";
import "./../assets/sass/_login.scss";
import DropDownComponent from "./DropDownComponent";
import TableRowComponent from "./../Components/TableRowComponent";
import "./../assets/sass/_review.scss";
import BGV1 from "./../assets/img/loginBGV1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { Card, CardGroup } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { Form, Col, Row } from "react-bootstrap";
import { MdAddBox, MdClear } from "react-icons/md";
import { BsFillFileExcelFill } from "react-icons/bs";
import { IoIosAdd } from "react-icons/io";
import Aside from "./aside";
import TopBar from "./topBar";
import axios from "axios";
import { API_Link, MD5hash } from "./../commons/Constants";
import { IoExit } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";

function EditReview() {
  const [review, setReview] = useState([]);
  const [user, setUser] = useState();
  const [studyId, setStudyId] = useState(localStorage.getItem("studyId"));
  const [lookupOptions, setLookupOptions] = useState("");
  const [lookupOptions1, setLookupOptions1] = useState("");
  const [lookupOptions2, setLookupOptions2] = useState("");
  const [reviewList, setFilteredReview] = useState();
  const [server_message, setMessage] = useState(false);
  const [reviewTypes, setReviewTypes] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);
  const [selectValue, setSelectValue] = useState("");
  const studyCheck = (event) => {
    setSelectValue(event.target.value);

    localStorage.setItem("studyId", event.target.value);

    setStudyId(localStorage.getItem("studyId"));
  };

  const getReviewTypes = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewTypes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setReviewTypes(resData.data.reviewTypes);

        //   console.log(resData.data.reviewTypes)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const review_type_options = [
    {
      value: "1",
      label: "Health Technology Assessment (HTA)",
    },
    {
      value: "2",
      label: "Technology Review (TR)",
    },
    {
      value: "3",
      label: "Information Brief (IB)",
    },
    {
      value: "4",
      label: "Economic Evaluation (EE)",
    },
    {
      value: "5",
      label: "TechBrief",
    },
    {
      value: "6",
      label: "TechScan",
    },
    {
      value: "7",
      label: "Clinical Practice Guidelines (CPG)",
    },
    {
      value: "8",
      label: "Others",
    },
  ];

  //ist code start
  const cancelSVG = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 36 36'>
      <g id='Icon__minus' transform='translate(-1609 -435)'>
        <circle id='Ellipse_29' data-name='Ellipse 29' cx='18' cy='18' r='18' transform='translate(1609 435)' fill='#ec4949' />
        <line id='Line_69' data-name='Line 69' x2='16' transform='translate(1619.5 453.5)' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='4' />
      </g>
    </svg>
  );
  const addSVG = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 37 37'>
      <g id='icon__plus' transform='translate(-1773 -76)'>
        <circle id='Ellipse_15' data-name='Ellipse 15' cx='18' cy='18' r='18' transform='translate(1773 76)' fill='#64c564' />
        <g id='Group_297' data-name='Group 297' transform='translate(1783.657 86.731)'>
          <line id='Line_50' data-name='Line 50' x2='16.368' transform='translate(0 8.499)' fill='none' stroke='#fff' strokeWidth='4' />
          <line id='Line_51' data-name='Line 51' y2='16.368' transform='translate(8.184)' fill='none' stroke='#fff' strokeWidth='4' />
        </g>
      </g>
    </svg>
  );

  function Delete(id) {
    // alert(id);
    console.log(id);
    setLookupOptions(lookupOptions && lookupOptions.filter((index) => index.id !== id));
  }

  // Function For adding new row object
  function myFunction() {
    setLookupOptions([
      ...lookupOptions,
      {
        id: lookupOptions.length + 1,
        name: "",
        email: "",
      },
    ]);
  }

  //end for ist
  //2nd code start
  const cancelSVG1 = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 36 36'>
      <g id='Icon__minus' transform='translate(-1609 -435)'>
        <circle id='Ellipse_29' data-name='Ellipse 29' cx='18' cy='18' r='18' transform='translate(1609 435)' fill='#ec4949' />
        <line id='Line_69' data-name='Line 69' x2='16' transform='translate(1619.5 453.5)' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='4' />
      </g>
    </svg>
  );
  const addSVG1 = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 37 37'>
      <g id='icon__plus' transform='translate(-1773 -76)'>
        <circle id='Ellipse_15' data-name='Ellipse 15' cx='18' cy='18' r='18' transform='translate(1773 76)' fill='#64c564' />
        <g id='Group_297' data-name='Group 297' transform='translate(1783.657 86.731)'>
          <line id='Line_50' data-name='Line 50' x2='16.368' transform='translate(0 8.499)' fill='none' stroke='#fff' strokeWidth='4' />
          <line id='Line_51' data-name='Line 51' y2='16.368' transform='translate(8.184)' fill='none' stroke='#fff' strokeWidth='4' />
        </g>
      </g>
    </svg>
  );

  function Delete1(id) {
    // alert(id);
    //console.log(id);
    setLookupOptions1(lookupOptions1 && lookupOptions1.filter((index) => index.id !== id));
  }

  // Function For adding new row object
  function myFunction1() {
    setLookupOptions1([
      ...lookupOptions1,
      {
        id: lookupOptions1.length + 1,
        name: "",
        email: "",
      },
    ]);
  }

  const cancelSVG2 = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 36 36'>
      <g id='Icon__minus' transform='translate(-1609 -435)'>
        <circle id='Ellipse_29' data-name='Ellipse 29' cx='18' cy='18' r='18' transform='translate(1609 435)' fill='#ec4949' />
        <line id='Line_69' data-name='Line 69' x2='16' transform='translate(1619.5 453.5)' fill='none' stroke='#fff' strokeLinecap='round' strokeWidth='4' />
      </g>
    </svg>
  );
  const addSVG2 = (
    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 37 37'>
      <g id='icon__plus' transform='translate(-1773 -76)'>
        <circle id='Ellipse_15' data-name='Ellipse 15' cx='18' cy='18' r='18' transform='translate(1773 76)' fill='#64c564' />
        <g id='Group_297' data-name='Group 297' transform='translate(1783.657 86.731)'>
          <line id='Line_50' data-name='Line 50' x2='16.368' transform='translate(0 8.499)' fill='none' stroke='#fff' strokeWidth='4' />
          <line id='Line_51' data-name='Line 51' y2='16.368' transform='translate(8.184)' fill='none' stroke='#fff' strokeWidth='4' />
        </g>
      </g>
    </svg>
  );

  function Delete2(id) {
    // alert(id);
    //console.log(id);
    setLookupOptions2(lookupOptions2 && lookupOptions2.filter((index) => index.id !== id));
  }

  // Function For adding new row object
  function myFunction2() {
    setLookupOptions2([
      ...lookupOptions2,
      {
        id: lookupOptions2.length + 1,
        name: "",
        email: "",
      },
    ]);
  }

  function handleChangeForName(type, value) {
    setLookupOptions(
      lookupOptions.map((option) => {
        return option.id === type ? { ...option, name: value.target.value } : option;
      })
    );
    //console.log(lookupOptions);
  }
  function handleChangeForEmail(type, value) {
    setLookupOptions(lookupOptions.map((option) => (option.id === type ? { ...option, email: value.target.value } : option)));
    //console.log(lookupOptions);
  }
  //
  function handleChangeForName1(type, value) {
    setLookupOptions1(
      lookupOptions1.map((option) => {
        return option.id === type ? { ...option, name: value.target.value } : option;
      })
    );
    // console.log(lookupOptions1);
  }
  function handleChangeForEmail1(type, value) {
    setLookupOptions1(lookupOptions1.map((option) => (option.id === type ? { ...option, email: value.target.value } : option)));
    // console.log(lookupOptions1);
  }
  //
  function handleChangeForName2(type, value) {
    setLookupOptions2(
      lookupOptions2.map((option) => {
        return option.id === type ? { ...option, name: value.target.value } : option;
      })
    );
    // console.log(lookupOptions2);
  }
  function handleChangeForEmail2(type, value) {
    setLookupOptions2(lookupOptions2.map((option) => (option.id === type ? { ...option, email: value.target.value } : option)));
    // console.log(lookupOptions2);
  }

  const [title, setTitle] = useState();
  const [review_type, setReviewType] = useState("");
  const [tentative_completion_date, settentative_completion_date] = useState();

  function handleChange(type, value) {
    switch (type) {
      case "title":
        setTitle(value.target.value);
        break;
      case "review_type":
        setReviewType(value);
        break;
      case "tentative_completion_date":
        settentative_completion_date(value.target.value);
        break;
      default:
        break;
    }
  }
  console.log("review type", review_type);
  const [is_followup, setStatus] = useState("yes");
  function handleChangeForStatus(e) {
    // setStatus({ ...is_followup, is_followup: value });
    setStatus(e.target.value);
  }
  console.log("isfollowup", is_followup);
  //save review data
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;
  function addReview(data) {
    axios
      .post(`${API_Link}addReview`, data, {
        headers: {
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("Response from server:", res.data);
        res.data.status === 1 &&
          setMessage({
            code: 200,
            message: "Data Has been added successfully.",
          });
        navigate("/review");
        res.data.status === 0 && setMessage({ code: 400, message: res.data.error_message });
      });
  }
  function handleSubmit() {
    const screening_reviewers = [];
    lookupOptions.forEach((lookup) => {
      screening_reviewers.push({
        name: lookup.name,
        email: lookup.email,
      });
    });
    const fulltext_reviewers = [];
    lookupOptions1.forEach((lookup1) => {
      fulltext_reviewers.push({
        name: lookup1.name,
        email: lookup1.email,
      });
    });
    const extraction_reviewers = [];
    lookupOptions2.forEach((lookup2) => {
      extraction_reviewers.push({
        name: lookup2.name,
        email: lookup2.email,
      });
    });

    let data = { title, tentative_completion_date, review_type, is_followup, screening_reviewers: screening_reviewers, fulltext_reviewers: fulltext_reviewers, extraction_reviewers: extraction_reviewers };
    console.log("data", data);
    addReview(data);
  }
  useEffect(() => {
    getReviewsList();
    getReviewsByID();
    getReviewTypes();
  }, []);
  const getReviewsList = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewsList`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const reviewdata = JSON.stringify(response.data.reviews);
        //onsole.log('reviewdata', reviewdata);
        const valuesArray = JSON.parse(reviewdata);
        //console.log('parsedata', valuesArray);
        setFilteredReview(valuesArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleChangeS = (e) => {
    setReviewType(e.target.value);
  };
  // Get Review By ID
  const getReviewsByID = async () => {
    //
    await axios
      .get(`${API_Link}getReviews`, {
        params: {
          id: id,
        },
        headers: {
          "Content-Type": "application/json",
          "misr-auth": MD5hash,
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        // setFilteredReview(response.data.reviews);
        setTitle(response.data.reviews[0].title);
        settentative_completion_date(response.data.reviews[0].tentative_completion_date);
        setStatus(response.data.reviews[0].is_followup === true ? "1" : "0");
        setReviewType(response.data.reviews[0].review_type);
        console.log("review 123", response.data.reviews[0].review_type);
        console.log("is_followup", response.data.reviews[0].review_type.description);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  console.log("state", review_type);
  return (
    <div className='main-wrapper'>
      <Aside reviewname={"Add Reviews"} reviewtitle={"Reviews"} />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content'>
          <h2 className='page--heading'>Edit Review</h2>
          <div className='form--body'>
            <form className='input--form'>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Review Name</label>
                <div className='col-sm-5'>
                  <InputComponent for={"title"} value={title} type={"text"} placeholder={""} onChange={handleChange} />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Review Type</label>
                <div className='col-sm-5'>
                  {/* <DropDownComponent id={"review_type"} value={review_type} selected={review_type} options={reviewTypes} onChange={handleChange}></DropDownComponent> */}

                  <select className='form-select' name='review_type' value={review_type.description} onChange={(e) => handleChangeS(e)}>
                    {reviewTypes.map(({ id, description }, index) => {
                      return (
                        <option key={index} value={id}>
                          {description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Tentative Date to complete</label>
                <div className='col-sm-5'>
                  <InputComponent for={"tentative_completion_date"} value={tentative_completion_date} type={"date"} placeholder={"DD/MM/YYYY"} onChange={handleChange} />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Update Previous Review</label>
                <div className='col-sm-6 custom--logo'>
                  <div className='' onChange={handleChangeForStatus}>
                    <input class='form-check-input me-1' type='radio' checked={is_followup === "1"} name='is_followup' value='1' id='inlineRadio1' />
                    Yes
                    <input class='form-check-input ms-3 me-1' checked={is_followup === "0"} type='radio' name='is_followup' value='0' id='inlineRadio2' />
                    No
                  </div>
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-3 col-form-label'>Review List</label>
                <div className='col-sm-5'>
                  {/* <DropDownComponent id={"followup_review_id"} options={filtered_review} handleChange={handleChange} /> */}
                  {is_followup === "1" ? (
                    <select class='form-select' value={studyId} onChange={studyCheck}>
                      {reviewList &&
                        reviewList.sort((a, b) => (a.id < b.id ? 1 : -1)) &&
                        reviewList.map(({ title, id }, index) => {
                          return (
                            <option value={id} {...(index === studyId ? "selected" : "unSelected")}>
                              {title}
                            </option>
                          );
                        })}
                    </select>
                  ) : (
                    <select className='form-select' disabled></select>
                  )}
                </div>
              </div>
              <div className='form--box--row'>
                <div className='col-sm-12'>
                  <h1>Reviewers for Data Screening</h1>
                  <div className='mb-3 row'>
                    {lookupOptions &&
                      lookupOptions.map((lookupOptions, index) => (
                        <TableRowComponent
                          key={index}
                          cellData={[
                            {
                              data: <InputComponent type={"text"} placeholder={"Name"} for={lookupOptions.id} value={lookupOptions.name} onChange={handleChangeForName} />,
                            },
                            {
                              data: <InputComponent type={"email"} placeholder={"Email"} for={lookupOptions.id} value={lookupOptions.email} onChange={handleChangeForEmail} />,
                            },
                            {
                              data: <a onClick={() => Delete(lookupOptions.id)}>{cancelSVG}</a>,
                            },
                          ]}
                        />
                      ))}
                    <TableRowComponent
                      cellData={[
                        {
                          data: "",
                        },
                        {
                          data: "",
                        },
                        {
                          data: <a onClick={myFunction}>{addSVG}</a>,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className='form--box--row'>
                <div className='col-sm-12'>
                  <h1>Reviewers for Full Text Review</h1>
                  <div className='mb-3 row'>
                    {lookupOptions1 &&
                      lookupOptions1.map((lookupOptions1, index) => (
                        <TableRowComponent
                          key={index}
                          cellData={[
                            {
                              data: <InputComponent type={"text"} placeholder={"Name"} for={lookupOptions1.id} value={lookupOptions1.name} onChange={handleChangeForName1} />,
                            },
                            {
                              data: <InputComponent type={"email"} placeholder={"Email"} for={lookupOptions1.id} value={lookupOptions1.email} onChange={handleChangeForEmail1} />,
                            },
                            {
                              data: <a onClick={() => Delete1(lookupOptions1.id)}>{cancelSVG1}</a>,
                            },
                          ]}
                        />
                      ))}
                    <TableRowComponent
                      cellData={[
                        {
                          data: "",
                        },
                        {
                          data: "",
                        },
                        {
                          data: <a onClick={myFunction1}>{addSVG1}</a>,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className='form--box--row'>
                <div className='col-sm-12'>
                  <h1>Reviewers for Data Extraction</h1>
                  <div className='mb-3 row'>
                    {lookupOptions2 &&
                      lookupOptions2.map((lookupOptions2, index) => (
                        <TableRowComponent
                          key={index}
                          cellData={[
                            {
                              data: <InputComponent type={"text"} placeholder={"Name"} for={lookupOptions2.id} value={lookupOptions2.name} onChange={handleChangeForName2} />,
                            },
                            {
                              data: <InputComponent type={"email"} placeholder={"Email"} for={lookupOptions2.id} value={lookupOptions2.email} onChange={handleChangeForEmail2} />,
                            },
                            {
                              data: <a onClick={() => Delete2(lookupOptions2.id)}>{cancelSVG2}</a>,
                            },
                          ]}
                        />
                      ))}
                    <TableRowComponent
                      cellData={[
                        {
                          data: "",
                        },
                        {
                          data: "",
                        },
                        {
                          data: <a onClick={myFunction2}>{addSVG2}</a>,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
              <div className='text-end mt-3'>
                <a href='#' className='reset--btn'>
                  Reset
                </a>
                {/* <a  className="add-new--btn" type="submit">
                                <IoIosAdd/>Submit Review
                            </a> */}
                <ButtonComponent text={"Update Review"} handleSubmit={handleSubmit} />
                {/*<button type="submit" handleSubmit={handleSubmit}>Submit Review</button>*/}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditReview;
