import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect,useRef } from "react";
const RecentlyCompleted = ({ studies, articlesCount, abstractArticlesCount, fullTextAriclesCount, includedArticlesCount }) => {
  const chartRf = useRef()
  const maxArticlesCount = Math.max(...articlesCount)
  const maxabstractArticlesCount = Math.max(...abstractArticlesCount)
  const maxfullTextArticlesCount = Math.max(...fullTextAriclesCount)
  const maxincludedArticlesCount = Math.max(...includedArticlesCount)
  const yAxisMaxValue = maxArticlesCount + maxabstractArticlesCount +maxfullTextArticlesCount+maxincludedArticlesCount
  console.log('yaxismaxvalue',yAxisMaxValue)


  // useEffect(()=>{
  //   if (chartRf.current) {
  //     chartRf.current.addEventListener('mouseover', (e) => {
  //       console.log('1233');
  //     });
  //   }
  // },[chartRf])

  // document.querySelector('g.highcharts-axis-labels.highcharts-xaxis-labels text').addEventListener("click", 
  // (event) => {

  //   console.log(event.trget)
  // })
  const option = {
    chart: {
      type: "column",
    
    },
    title: {
      text: "Recent Completed Reviews",
    },
    xAxis: {
      categories: studies,
     labels:{
      // rotation:0,
      useHTML: true,
      formatter: function () {
        // let classTooltip = this.value.length < 30 ? 'tooltiptext' : 'tooltiptext1'
        let string = (this.value.length < 18) ? this.value : this.value.substring(0, 15) + '...'
        return `<div class="hastip">` + string + 
        '<span class="tooltiptext tooltiptext1 ">' + this.value + '</span>' +
        '</div>';
      }
     }
    },
    yAxis: {
      min: 0,
      title: false,
      tickAmount: 9,
      max:yAxisMaxValue
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
      shared: true,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
    },
    series: [
      {
        name: "Articles",
        data: articlesCount,
        color: "#023047",
      },
      {
        name: "Abstract Articles",
        data: abstractArticlesCount,
        color: "#99c1d5",
      },
      {
        name: "FullText Articles",
        data: fullTextAriclesCount,
        color: "#219EBC",
      },
      {
        name: "Extraction",
        data: includedArticlesCount,
        color: "#8ECAE6",
      },
    ],
  };
  return (
    <div ref={chartRf} style={{ border: "1px solid lightgray", marginTop: "50px", borderRadius: "8px", overflow: "hidden" }} className='reviews-chart recent-completed-chart'>
      <HighchartsReact highcharts={Highcharts} options={option} />
    </div>
  );
};

export default RecentlyCompleted;
