import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Aside from './aside';
import EvidenceBotComponent from './evidenceBotComponent';
import TopBar from './topBar';

const Evidencebot = () => {
    const { id } = useParams();

    useEffect(() => {
        localStorage.setItem('pdfAId', '');
        if (id) {
            localStorage.setItem('pdfAId', id);
        }
    }, []);
    return (
        <div className='main-wrapper'>
            <Aside />
            <div className='content-wrapper'>
                <TopBar />
                <div className='page--content--abstract'>
                    <div className='row'>
                        <div className='col-md-12'>

                            <EvidenceBotComponent pdfAId={id} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Evidencebot;
