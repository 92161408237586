import React from "react";
import ExtractionPrisma from "../../Components/extractionprisma";


function ExtractionprismaPage() {
  return (
    <div>
      <ExtractionPrisma/>
    </div>
  )
}

export default ExtractionprismaPage

