import React from "react";
import Role from "./../../Components/role"

function RolePage() {
  return (
    <div>
      <Role/>
    </div>
  )
}

export default RolePage