import React from 'react';
import { BiChevronDown } from 'react-icons/bi';

export default function DropDownComponent(props) {
  let show_label = !props.no_label;
  function handleChange(e) {
    props.handleChange(props.id, e.target.value, 'DropDownComponent');
  }
  return (
    <div className='form-group'>
      {props.label && show_label && (
        <label htmlFor={props.id} className='text-muted small-heading'>
          {props.label}
          {props.is_optional === false && '*'}
        </label>
      )}
      <div className='input-group'>
        <select
          className='form-select'
          defaultvalue={props.defaultvalue}
          name={props.name}
          id={props.id}
          onChange={handleChange}
          required={!props.is_optional}
          disabled={props.disabled}
        >
          {props.options &&
            props.options.map((option, index) => (
              <option
                key={index}
                value={option.value}
                selected={
                  props.selected
                    ? String(props.selected) === String(option.value)
                      ? true
                      : false
                    : props.selectedOnTitle &&
                      props.selectedOnTitle === option.title
                    ? true
                    : false
                }
              >
                {option.label}
              </option>
            ))}
        </select>
        {/* <div className='icon__input-div misr_icon__input--div'>
          <span className='icon__span'>{props.icon ? <i className={props.icon}></i> : <BiChevronDown/>}</span>
        </div> */}
      </div>
    </div>
  );
}
