import React from "react";
import Aside from "../../Components/aside";
import TopBar from "../../Components/topBar";
import Searching from "./../../Components/searchingInclusion"

function SearchingInclusionPage() {
  return (
    <div>
      <div className="main-wrapper">
        <Aside />
        <div className="content-wrapper">
          <TopBar />
          <Searching />
        </div>
      </div>
     </div>
  )
}

export default SearchingInclusionPage

