import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { MD5hash, API_Link } from "../commons/Constants";
import { FaEye, FaMinus, FaPlus, FaStickyNote } from "react-icons/fa";
import { GrRotateLeft } from "react-icons/gr";
import { BsFillCircleFill, BsQuestionLg } from "react-icons/bs";
import "./../assets/sass/style.css";
import "./../assets/sass/totalreview.css";
import IncludedFulltext from "../Pages/user/includedfulltext";
import { API_SRC } from "../commons/Constants";
import gif from "./../assets/img/spin.gif";

const IncludedFulltextComponent = () => {
  const [arrayData, setArrayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const studyId = localStorage.getItem("studyId");
  const [show, setShow] = useState(false);
  const token = user.token;

  // call for get reviewer permissions
  const [getPermission, setGetPermission] = useState([]);

  // code for capitalize
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1)
      return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words 
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) => shouldBeUppercase(word, index, length) ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())
      .join(' ');
  }

  const getPermissionCall = () => {
    var config = {
      method: "get",
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem("studyId")}`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {

        setGetPermission(response.data.permissions[0])

        console.log('after api call in search inclusion', getPermission)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const addEvidence = () => {
  //   setIsLoading(true);
  //   var config = {
  //     method: "POST",
  //     url: `${API_Link}setReviewEvidences`,
  //     data: {
  //       review_id: studyId,
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //       "misr-auth": MD5hash,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       const resData = response;

  //       console.log("New Evidence API", resData);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     })
  //     .finally(() => setIsLoading(false));
  // };

  useEffect(() => {

    getPermissionCall();

    var config = {
      method: "get",
      url: `${API_Link}getReviewArticles?review_id=${studyId}&fulltext=Yes&exclusion=No `,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        // document.querySelector(".preload").style.display = "none"//stop the load
        console.warn('resData', resData)
        const notNull = resData.data.articles.filter((article) => article.current_stage > 3 && article.file_path !== null || article.source !== null);
        setArrayData(notNull);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const [isActive, setIsActive] = useState(null);
  const [isInclude, setIsInclude] = useState(null);
  const [isExclude, setIsExclude] = useState(null);
  const [mayBe, setmayBe] = useState(null);
  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };
  // handle Include
  const handleInclude = (index) => {
    if (isInclude === index) {
      return setIsInclude(null);
    }
    setIsInclude(index);
  };
  // handle Exclude
  const handleExclude = (index) => {
    if (isExclude === index) {
      return setIsExclude(null);
    }
    setIsExclude(index);
  };
  // handle Exclude
  const handleMayBe = (index) => {
    if (mayBe === index) {
      return setmayBe(null);
    }
    setmayBe(index);
  };
  const navigate = useNavigate();
  const handleMoveToExtraction = () => {
    // const pageId = localStorage.getItem("pageId");

    setIsLoading(true);
    var config = {
      method: "POST",
      url: `${API_Link}setReviewEvidences`,
      data: {
        review_id: studyId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;

        console.log("New Evidence API", resData);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
        localStorage.setItem("pageId", 1301);
        navigate("/extraction");
      });
  };
  const [file, setFile] = useState();
  const [source, setSource] = useState();
  const handleOpenModal = (index, id) => {
    arrayData.map((article) => {
      if (article.id === id) {
        setFile(article.file_path);
        setSource(article.source);
      }
    });
    setShow(true);
  };
  const handleCloseModal = (index) => {
    setShow(false);
  };

  if (isLoading)
    return (
      <div className='preload'>
        <img src={gif} alt='' />
      </div>
    );
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-6'>
          <h1 className='page--heading'>Fulltext Included </h1>
        </div>
        <div className='col-lg-6 text-right-flex'>
          <p>
            Total Articles Count <strong>({arrayData.length})</strong>
          </p>

          {
            getPermission && getPermission.includes(3) ? <button className='btn btn-primary ms-2' onClick={handleMoveToExtraction}>
              Move to Extraction
            </button> : ''

          }

        </div>
      </div>

      <div className='row boxes-row'>
        {React.Children.toArray(
          arrayData && arrayData.length > 0 ? (
            arrayData.map(({ id, title, authors, journal, abstract }, index) => (
              <div className='col-lg-12' key={index}>
                {/* Handle Background Color Change On Include, Exclude  */}
                <div className='inner--box--row' style={{ backgroundColor: isInclude === index ? " rgba(0,255,0,0.1)" : isExclude === index ? "rgba(255,0,0,0.1)" : mayBe === index ? " rgba(255,255,0,0.1)" : "" }}>
                  <span className='index--numbering'>{index + 1}</span>

                  <div className='width--80'>
                    {/* <label className='form-check-label' htmlFor='flexCheckDefault2'>
                    {Title}
                  </label> */}
                    <h1 className='heading3'>{convertFirstLetterToUpperCase(title)}</h1>
                    <p className='small text-muted'>{authors}</p>
                    {/* <h1 className='heading3'>{Journal}</h1> */}

                    {journal ? <label className='form-check-label'> Journal {journal} </label> : ""}
                    {/* Handle Hight of Paragraph Here */}
                    <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"} onClick={() => handleClick(index)}>
                      {abstract}
                    </p>
                  </div>
                  <div className='width-20'>
                    <div className=''>
                      <div className='btn--new--ul'>
                        {/* <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleClick(index)}>
                          <FaEye />
                        </Link> */}
                        <Link to='#' data-bs-toggle='tooltip' className='link' title='View' onClick={() => handleOpenModal(index, id)}>
                          <FaEye />
                        </Link>
                        {/* <Link to='#' data-bs-toggle='tooltip' className='link' title='Add Note'>
                          <FaStickyNote />
                        </Link> */}
                        {/* Handle Include ,Exclue and Maybe Buttons Here */}
                        {/* {isInclude === index || isExclude === index || mayBe === index ? (
                          <Link to='#' data-bs-toggle='tooltip' className='link' title='Undo' onClick={isInclude === index ? () => handleInclude(index) : isExclude === index ? () => handleExclude(index) : mayBe === index ? () => handleMayBe(index) : ""}>
                            <GrRotateLeft />
                          </Link>
                        ) : (
                          <span>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Include' onClick={() => handleInclude(index)}>
                              <FaPlus />
                            </Link>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Exclude' onClick={() => handleExclude(index)}>
                              <FaMinus />
                            </Link>
                            <Link to='#' data-bs-toggle='tooltip' className='link' title='Maybe' onClick={() => handleMayBe(index)}>
                              <BsQuestionLg />
                            </Link>
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
                {show && (
                  <>
                    <div className='template_modal'>
                      <div className='template_modal-wrapper'>
                        <div className='modal--header'>
                          <div className='text-end'>
                            <button
                              className='btn-danger'
                              onClick={() => {
                                handleCloseModal();
                              }}
                            >
                              {" "}
                              Close{" "}
                            </button>
                          </div>
                        </div>
                        <div className='modal--body'>
                          {source && <iframe width='100%' title='attached file' src={`${source}`}></iframe>}

                          {file && <iframe width='100%' title='attached file' src={`${API_SRC + "/" + file}`}></iframe>}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))
          ) : (
            <p className='p-3 text-muted'>There are no included fulltext.</p>
          )
        )}
      </div>
    </div>
  );
};

export default IncludedFulltextComponent;
