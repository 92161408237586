import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import articleReviews from "../../services/articleCounts/getReviewService";


export const reviewThnuk = createAsyncThunk(
    "reviews",
    async () => {
        const response = await articleReviews.getReviewId()
        return response.data
        // console.log('Dispatch',response.data)
    })

const createInitialState = () => {
    return {
        data: []
    }
}

const initialState = createInitialState()
const reviewSlice = createSlice({
    name: 'reviewSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [reviewThnuk.fulfilled](state, action) {
            state.data = action.payload
        },
    },
})


export default reviewSlice.reducer