import React from "react";
import User from "./../../Components/userListing"

function UserPage() {
  return (
    <div>
      <User/>
    </div>
  )
}

export default UserPage