import React from "react";
import Aside from "../../Components/aside";
import ExcludedDuplicatedComponent from "../../Components/excludedDuplicateComponent";
import IncludedDuplicateComponent from "../../Components/includedDuplicateComponent";
import TopBar from "../../Components/topBar";

const ExcludedDuplicatePage = () => {
  return <ExcludedDuplicatedComponent />;
};

export default ExcludedDuplicatePage;
