import React from 'react'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'
import notFoundImage from './../../assets/img/not-found.jpg'

const PageNotFound = () => {
    return (
        <div className="main-wrapper">
            <Aside />
            <div className="content-wrapper">
                <TopBar />
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 text-center mt-3'>
                            <a href='/dashboard'> <img src={notFoundImage} alt="" className='img-fluid' /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound