import React, { useState } from "react";
import axios from "axios";
import { MD5hash, API_Link } from "../commons/Constants";
import { FaUpload } from "react-icons/fa";
import gif from "./../assets/img/spin.gif";
import { useEffect } from "react";
const RisImportComponent = () => {
  // a local state to store the currently selected file.
  const [selectedFile, setSelectedFile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [getFileName, setgetFileName] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;

  const handleFileSelect1 = (event) => {
    console.log("EVENT", event.target.files);
    setSelectedFile(event.target.files[0]);

    // alert(event.target.files)
    // setSelectedFile(event.target.files)

    console.log("Selected FIle", selectedFile);
  };
  useEffect(() => {
    localStorage.setItem("search", "");
    localStorage.setItem("info", "");
    localStorage.setItem("picoData", "");
  }, []);
  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    var formData = new FormData();
    formData.append("review_id", localStorage.getItem("studyId") || 1);
    formData.append("file", selectedFile);
    console.log(...formData);

    try {
      const response = await axios({
        method: "post",
        // url: `${API_Link}importData`,
        url: "http://misr.pacemis.com:78/load_ris",
        data: formData,
        headers: {
          //"content-type": 'multipart/form-data',
          // "content-type": 'application/json',
          Accept: "application/json",
          //'Content-Type': 'application/json',
          Authorization: "Bearer " + token,
          "misr-auth": MD5hash,
          ...formData.headers,
        },
      });
      //   return response.data
      localStorage.setItem("search", JSON.stringify(response.data));
      localStorage.setItem("PICO", 0);
      var data = JSON.stringify({
        Database: "RISimport",
        Population: "",
        Intervention: "",
        Comparison: "",
        Outcomes: "",
        Exclusion: "",
      });
      localStorage.setItem("picoData", data);
      window.location.href = "/listingselections";
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setgetFileName(event.target.files[0].name);
  };
  if (isLoading)
    return (
      <div className='preload'>
        <img src={gif} alt='' />
      </div>
    );
  return (
    <div className='page--content'>
      <div className='row'>
        <div className='col-lg-8'>
          <h1 className='page--heading'>RIS Import</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          {/* <form onSubmit={handleSubmit}> */}

          <div className='mt-2 risimportform'>
            <form onSubmit={handleSubmit} className='form--ris'>
              {!getFileName ? (
                <span>
                  <span className='text-info drag--drop-font' style={{ fontSize: "22px" }}>
                    Drag and drop file here, or click to select file
                  </span>
                  {/* <FaUpload /> */}
                </span>
              ) : (
                <span id='fileName' className='text-info' style={{ fontSize: "22px" }}>
                  {getFileName}
                </span>
              )}
              <input id='fileUpload' type='file' name='myFile' required className='inputFileRis' onChange={handleFileSelect} />
              <input type='submit' value='Upload File' className='btn btn-lg btn-success upload-file-ris ' />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RisImportComponent;
