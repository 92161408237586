import React from "react";
import Login from "./../../Components/loginComponent/login"
import Header from "./../../Components/header"
import Footer from "./../../Components/footer";
import { useEffect, useState } from "react";
import user from './../../services/user/userService'
import { setting } from '../../features/setting/settingSlice'
import { useSelector, useDispatch } from 'react-redux'




function LoginPage() {

  const userSetting = useSelector((state) => state.setting.data)
  
  console.log('Slice', userSetting)
  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setting())
  }, [])

  return (
    <div className='loginPage'>
      <Header logo={userSetting.logo} mainHeading={userSetting.full_title} subHeading={userSetting.sub_title} />
      <Login login_page_bg={userSetting.login_page_bg} short_title={userSetting.short_title} sponsor_logo={userSetting.sponsor_logo} />
      <Footer associated_link={userSetting.associated_link} associated_name={userSetting.associated_name} associated_verb={userSetting.associated_verb} copyright_text={userSetting.copyright_text} />
    </div>
  )
}

export default LoginPage

