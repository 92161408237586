import React from "react";
export default function TableHeadComponent({ rowAttributes, options }) {
    return (
        <tr className={rowAttributes ? rowAttributes.rowClass : ""}>
            {
                options.map((option, index) => {
                    return (
                        <th key={index} className={option.class} rowSpan={option.rowSpan} colSpan={option.colSpan}>
                            {option.title}
                        </th>
                    )
                })
            }
        </tr>
    )
}