import React, { useRef, useEffect, useState } from 'react';
import './../assets/sass/_login.scss';
import './../assets/sass/_extract.scss';
import { saveAs } from 'file-saver';
import htmlDocx from 'html-docx-js/dist/html-docx';
import Aside from './aside';
import TopBar from './topBar';
import HTA from './FileTemplateComponent/HTA';
import TR from './FileTemplateComponent/TR';
import IB from './FileTemplateComponent/IB';
import TechBrief from './FileTemplateComponent/TechBrief';
import TechScan from './FileTemplateComponent/TechScan';
import { API_Link, MD5hash } from './../commons/Constants';
import axios from 'axios';

function Extraction() {
  const [studyId, setStudyId] = useState(localStorage.getItem('studyId'));
  const [filteredReview, setFilteredReview] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  const divRef = useRef(null);

  useEffect(() => {
    // Fetch reviews when component mounts
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`${API_Link}getReviews`, {
          headers: {
            'misr-auth': MD5hash,
            Authorization: `Bearer ${token}`,
          },
          params: { id: studyId },
        });
        setFilteredReview(response.data.reviews);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReviews();
  }, [studyId, token]);

  const downloadAsDocx = () => {
    if (divRef.current) {
      const content = divRef.current.innerHTML;

      // Include CSS styles in the document
      const css = (
        '<style>' +
        '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
        'div.WordSection1 {page: WordSection1;}' +
        'table{border-collapse:collapse; width: 100%;} ' +
        '.table-bordered-td td {border:1px gray solid; padding:2px;}' +
        '</style>'
      );

      // Combine CSS and content
      const htmlContent = css + content;

      // Convert HTML to DOCX
      const converted = htmlDocx.asBlob(htmlContent);
      saveAs(converted, 'document.docx');
    }
  };

  if (isLoading) return '';

  const reviewType =
    filteredReview && filteredReview.length > 0
      ? filteredReview[0].review_type_id
      : '';

  return (
    <div className='main-wrapper'>
      <Aside
        extractname={'Extract Data'}
        extracttitle={'Prisma FlowChart'}
        extractpath={'Evidence Table'}
      />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content extract__page p-3 pb-0'>
          <a onClick={downloadAsDocx} className='export-btn'>
            <i className='bi bi-file-earmark-word'></i> Download
          </a>
          <div id='docx' ref={divRef}>
            <div className='WordSection1'>
              <table className=''>
                <tbody>
                  <tr>
                    <td className='table-bordered-td'>
                      {reviewType === 2 ? (
                        <HTA />
                      ) : reviewType === 3 ? (
                        <TR />
                      ) : reviewType === 4 ? (
                        <IB />
                      ) : reviewType === 6 ? (
                        <TechBrief />
                      ) : (
                        <TechScan />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Extraction;
