import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Avatar from "./../assets/img/Avatar.png";
import ButtonComponent from "./ButtonComponent";

const InvitationCard = ({ invitations, reviewStage, status, deleteInvitation, tabKey }) => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
  });
  const { name, email } = user;
  const openModal = () => {
    setShow(true);
    // console.log("reviewid", reviewId);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("userrrr", user);
  };
  let stages = [];
  if (invitations) {
    stages = invitations.map((invitation) => invitation.stage);
  }
  // console.log("stages", stages);
  let date = new Date();
  var current_date = date.getFullYear() + "-" + "0" + (date.getMonth() + 1) + "-" + date.getDate() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  // console.log("Current date Time", current_date);
  return (
    <>
      <div className='invitataion--div pb-1'>
        {invitations && invitations.length > 0 && stages.includes(reviewStage) ? (
          invitations.map(({ id, review_id, stage, name, review, email, created_at, accepted_at, rejected_at, expiration }, index) => {
            return (
              stage === reviewStage && (
                <div className='row  pending--invitation--row mb-2'>
                  <div className='col-md-2 col-sm-12 text-center pe-2 '>
                    <img src={Avatar} alt='' />
                  </div>
                  <div className='col-md-10 col-sm-12   ps-3'>
                    <div className='row p-2 d-flex align-items-center'>
                      <div className='col-md-8 col-sm-12 invitation-name'>
                        <div className='row'>
                          <div className='col'>{name}</div>
                        </div>

                        <div className='row'>
                          <div className='col want--friend ms-1'>
                            Study Title: <strong>{review.title}</strong>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col want--friend ms-1'>
                            Reviewer Stage:{" "}
                            <strong>
                              {reviewStage === 1 && "Information Specialists for Data Searching"} {reviewStage === 2 && "Reviewer for Data Screening"} {reviewStage === 3 && "Reviewer for Full Text Review"} {reviewStage === 4 && "Reviewer for Data Extraction"}
                            </strong>
                          </div>
                        </div>
                        {accepted_at == null && rejected_at == null ? (
                          <div className='row'>
                            <div className='col want--friend ms-1'>
                              Date: <strong>{created_at.slice(0, 10)}</strong>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className='col-md-4 col-sm-12  text-center'>
                        {accepted_at !== null ? (
                          <>
                            <div className='row py-3'>
                              <div className='col-sm-12  text-center '>
                                <span className='bg-success text-white rounded  accepted-status'>Accepted</span>
                                <br /> <span className='text-white'>{accepted_at.slice(0, 10)}</span>
                              </div>
                            </div>
                          </>
                        ) : rejected_at !== null ? (
                          <>
                            <div className='row py-3'>
                              <div className='col-sm-12  text-center p-1'>
                                <span className='bg-danger text-white rounded  accepted-status'>Rejected</span>
                                <br /> <span className='text-white'>{rejected_at.slice(0, 10)}</span>
                              </div>
                            </div>
                          </>
                        ) : rejected_at === null && accepted_at === null && expiration > current_date ? (
                          <div>
                            <div>
                              <div className='row py-3'>
                                <div className='col-sm-12  text-center '>
                                  <button id='Decline1' onClick={() => deleteInvitation(id)}>
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {/* <div>
                                  <div className='row py-3'>
                                    <div className='col-sm-12  text-center '>
                                      <button id='Decline1' disabled>
                                        Cancel
                                      </button>
                                    </div>
                                  </div>
                                </div> */}
                            <div className='row py-3'>
                              <div className='col-sm-12  text-center p-1'>
                                <span className='bg-warning text-white rounded  expired-status'>Expired</span>
                                <br /> <span className='text-white'>{expiration.slice(0, 10)}</span>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* {tick[id] ? <span>tick</span> : cross[id] ? <span>cross</span> : ""} */}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          })
        ) : (
          <div className='pending--invitation--row-empty-box d-flex align-items-center justify-content-center flex-column'>
            <h3 className=' text-center text-muted '>No Invitation</h3>

            <div>
              {tabKey === "one" ? (
                <p className='text-center text-muted'>
                  for Data Searching
                  {/* <strong>for Data Searching</strong> */}
                </p>
              ) : tabKey === "two" ? (
                <p className='text-center text-muted'>
                  for Data Screening
                  {/* <strong> for Data Screening</strong> */}
                </p>
              ) : tabKey === "three" ? (
                <p className='text-center text-muted'>
                  for Fulltext Screening
                  {/* <strong> for Fulltext Screening</strong> */}
                </p>
              ) : tabKey === "four" ? (
                <p className='text-center text-muted'>for Data Extraction</p>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        <Modal className='modal-md modal-dialog-centered edit-invitation-form' show={show} onHide={handleClose}>
          <Modal.Header className=''>
            <h4>Edit Invitation</h4>
            <button className='btn btn-danger' onClick={handleClose}>
              X
            </button>
          </Modal.Header>
          <div className='container '>
            <form action='' className='p-2'>
              <div className='mb-3 row'>
                <label className='col-sm-2 col-form-label'>Name</label>
                <div className='col-sm-10'>
                  <input type='text' className='form-control' name='name' value={name} onChange={(e) => handleChange(e)} />
                </div>
              </div>
              <div className='mb-3 row'>
                <label className='col-sm-2 col-form-label'>Email</label>
                <div className='col-sm-10'>
                  <input type='email' className='form-control' name='email' value={email} onChange={(e) => handleChange(e)} />
                </div>
              </div>
              <div className='mb-3 row'>
                <div className='col-sm-12'>
                  <div className='text-end mt-3'>
                    <ButtonComponent text={"Invite User"} handleSubmit={handleSubmit} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default InvitationCard;
