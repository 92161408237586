import React from "react";
import { useState } from "react";
import { API_Link, MD5hash } from "../commons/Constants";
import axios from "axios";
import { useEffect } from "react";
import { Link } from "react-router-dom";
const AdminReviewTypeListingComponent = () => {
  const [reviewTypes, setReviewTypes] = useState([]);
  const users = JSON.parse(localStorage.getItem("user"));
  const token = users.token;

  // getReviewTypes
  const getReviewTypes = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewTypes`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "misr-auth": MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        const resData = response;
        setReviewTypes(resData.data.reviewTypes);

        //   console.log(resData.data.reviewTypes)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getReviewTypes();
  }, []);

  return (
    <div className='page--content '>
      <div className='row'>
        <div className='col-md-6 col-lg-6'>
          <h1 className='page--heading '>Review Type Listing</h1>
        </div>
      </div>

      <div className='row'>
        <div className='padding-table'>
          <div className='table--template'>
            <table className='table table-striped'>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th></th>
                  {/* <td>Action</td> */}
                </tr>
              </thead>
              <tbody>
                {reviewTypes &&
                  reviewTypes.map((type, index) => (
                    <tr>
                      <td>{index + 1} </td>
                      <td>{type.type.charAt(0).toUpperCase() + type.type.slice(1)}</td>
                      <td> {type.description}</td>
                      <td></td>
                      {/* <td>
                        <Link to='' className='tem--add--btn'>
                          Edit
                        </Link>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminReviewTypeListingComponent;
