import React from 'react'
import Aside from '../../Components/aside'
import ListingSelectionsComponent from '../../Components/listingSelectionsComponent'
import TopBar from '../../Components/topBar'

const ListingSelections = () => {
  return (
    <div className='main-wrapper'>
    <Aside />
    <div className="content-wrapper">
      <TopBar />
      <ListingSelectionsComponent/>
    </div>
  </div>
  )
}

export default ListingSelections