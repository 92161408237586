import React from 'react'
import Aside from '../../Components/aside'
import IncludedComponent from '../../Components/IncludedComponent'
import TopBar from '../../Components/topBar'

function IncludedPage() {
  return (
    <div className="main-wrapper">
      <Aside />
      <div className="content-wrapper">
        <TopBar />
        <IncludedComponent />
      </div>
    </div>
  )
}

export default IncludedPage