import React from 'react'
import Aside from '../../Components/aside'
import ExcludedComponent from '../../Components/ExcludedComponent'
import TopBar from '../../Components/topBar'

function ExcludedPage() {
    return (
        <div className="main-wrapper">
            <Aside />
            <div className="content-wrapper">
                <TopBar />
                <ExcludedComponent/>
            </div>
        </div>
    )
}

export default ExcludedPage