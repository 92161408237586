import React, { useEffect, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { PdfHighlighter } from 'react-pdf-highlighter';


// import 'react-pdf-highlighter/dist/index.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfView({ pdfAid }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [highlights, setHighlights] = useState([]);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const highlightRenderer = ({ content }) => (
        <span style={{ backgroundColor: 'yellow' }}>{content}</span>

        // console.log(window.getSelection().toString())
    );


    let text = document.body.innerText;
    text = text.replace(/\r?\n|\r/g, "");
    console.warn('text', text);
    
    let searchText = "Evaluation of Safety";  
    // let searchText = "For eight (20%), the safety feature had not been activated, and for two (5%), the mechanism of in¬ jury was unknown.";
    searchText = searchText.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&'); // escape special characters for regex
    console.log('searchText', searchText);
    
    let regex = new RegExp(searchText, 'g'); // create a regex object with 'g' flag for global search
    let result = text.match(regex);
    
    if (result) {
       document.getElementById("demo").innerHTML = text.replace(regex, '<span class="highlight">$&</span>');
    
      const highlightSpans = document.querySelectorAll('.highlight');
      highlightSpans.forEach(span => {
        const rect = span.getBoundingClientRect();
        console.log(rect); 
        // do something with the rect value
      });
    }
    
    window.getSelection().toString() 
    
    const handleSelection = (highlight) => {
      setHighlights([...highlights, highlight]);
    };
    




    console.log('highlight', highlights)

    const handleUpdateHighlight = (highlightId, position, content) => {
        setHighlights((highlights) =>
            highlights.map((highlight) =>
                highlight.id === highlightId
                    ? { ...highlight, position, content }
                    : highlight
            )
        );
    };

    const handleDeleteHighlight = (highlightId) => {
        setHighlights((highlights) =>
            highlights.filter((highlight) => highlight.id !== highlightId)
        );
    };


    document.addEventListener('mouseup', function () {
        let selection = window.getSelection();
        //  selection = selection.replace(/[-[\]{}()*+!<=:?.\/\\^$|#\s,]/g, '\\$&'); // escape special characters for regex
        
        // console.log('selection', selection)
        if (selection.isCollapsed) {
            console.error('No selection or collapsed selection.');
            return;
        }
        const range = selection.getRangeAt(0);

        console.log('range', range)
        const newParent = document.createElement('span');
        newParent.classList.add('my-class'); // add class to new element
        range.surroundContents(newParent);
    });

   
    useEffect(() => {
        // function handleMouseUp() {
        //     const selection = window.getSelection().toString();
        //     if (selection !== '' && selection.length > 20) {
        //         alert('You selected: ' + selection);
        //     }
        // }
        // document.addEventListener('mouseup', handleMouseUp);
        // return () => {
        //     document.removeEventListener('mouseup', handleMouseUp);
        // };



    }, [])


    const alertHello = () => {}
    const filePath = `http://misr.pacemis.com:83/assets/articlesdata/article${pdfAid}_datafile.pdf`;
    return (
        <div>
            <div id="demo">4</div>
            <Document
                file={filePath}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1}  />
                ))}


                <PdfHighlighter 
                    pdfScaleValue={1}
                    onSelection={handleSelection}
                    highlights={highlights}
                    highlightRenderer={highlightRenderer}
                    onUpdateHighlight={handleUpdateHighlight}
                    onDeleteHighlight={handleDeleteHighlight}
                />
            </Document>
            <p>
                Page {pageNumber} of {numPages}
            </p>
        </div>
    );
}

export default PdfView;