import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import Aside from './aside';
import PicoBotComponent from './picoBotComponent.tsx';
import TopBar from './topBar';

const PicoBot = () => {
  const { id } = useParams();

  useEffect(() => {
    localStorage.setItem('pdfAId', '');
    if (id) {
      localStorage.setItem('pdfAId', id);
    }
  }, []);
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content--abstract'>
          <div className='row'>
            <div className='col-md-12'>
              <PicoBotComponent pdfAId={id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PicoBot;
