import React from "react";
import Searching from "./../../Components/searching"

function SearchingPage() {
  return (
    <div>
      <Searching/>
    </div>
  )
}

export default SearchingPage

