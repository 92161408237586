import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./../assets/css/topbar.css";
import "./../assets/sass/_topbar.scss";
import { TfiMenuAlt } from "react-icons/tfi";
import InputComponent from "./InputComponent";
import { API_SRC, MD5hash, API_Link } from "../commons/Constants";
import axios from "axios";
import { reviewThnuk } from '../features/reviews/reviewSlice'
import { useSelector, useDispatch } from 'react-redux'
import TopbarChildComponent from "./childComponents/TopbarChildComponent";
import { getPermission } from "../features/setting/permissionSlice";


function TopBar() {
  const [studyName, setStudyName] = useState();
  const [studyId, setStudyId] = useState(localStorage.getItem("studyId"));
  const [selectValue, setSelectValue] = useState(1);
  const [loading, setLoading] = useState(true);
  // const [reviewList, setreviewList] = useState([]);
  const [currentUrl, setCurrentUrl] = useState("");
  const [reviewList, setFilteredReview] = useState();
  const [permission, setPermission] = useState([]);
  const [isTeamlead, setIsTeamlead] = useState(false);

  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));
  const token = user.token;

  console.log('user', user.role.role)


  const permission1 = useSelector((state) => state.getPermission.data)
  
  console.log('permision', permission1)

  // useEffect(() => {

  //   getReviewsList();
  //   console.log(window.location.href[1]);

  // }, []);

  const getReviewsList = () => {
    var config = {
      method: "get",
      url: `${API_Link}getReviewsList`,
      headers: {
        "misr-auth": MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        const reviewdata = JSON.stringify(response.data.reviews);
        const valuesArray = JSON.parse(reviewdata);
        console.log("value aray:", response.data)


        if (localStorage.getItem("studyId") === null) {
         let sorted =  valuesArray.sort((a, b) => (a.id < b.id ? 1 : -1))
          localStorage.setItem('studyId', sorted.at(0).id)
        }


        setFilteredReview(valuesArray);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  // 

  // const getPermission = () => {

  //   if (localStorage.getItem("studyId") !== null) {
  //     var config = {
  //       method: "get",
  //       url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem("studyId")}`,
  //       headers: {
  //         "misr-auth": MD5hash,
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };
  //     axios(config)
  //       .then(function (response) {



  //         setPermission(response.data.permissions)



  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }

  // };


  useEffect(() => {

    dispatch(getPermission())

    getReviewsList();
    setLoading(false);
    const currentUrl = window.location.pathname;
    setCurrentUrl(currentUrl);
    console.log("This is current Url", currentUrl);

    if(user.role.role === 'teamlead'){
      setIsTeamlead(true)
    }
    // getPermission();

  }, []);


  const sidebars = JSON.parse(localStorage.getItem("user"));
  const userType = JSON.parse(localStorage.getItem("user"));

  // console.warn('userType', userType.role.role)
  const menus = sidebars.menu;
  const topBarMenu = menus.filter((menu) => menu.module === "topBar");

  console.log("topBar", topBarMenu);

  const handleClick = (event) => {
    console.log(event.currentTarget.id);
    localStorage.setItem("pageId", event.currentTarget.id);
  };

  const currentPage = localStorage.getItem("pageId");

  const studyCheck = (event) => {
    console.log("selected", event);
    setSelectValue(event.target.value);
    localStorage.setItem("studyId", event.target.value);
    setStudyId(localStorage.getItem("studyId"));
    window.location.reload(false);
  };

  // console.log("Dynamic ReviewList", reviewList);

  localStorage.setItem('permission', permission)

  if (loading) return '';

  // if (userType.role.role === 'admin') return <TopbarChildComponent />
  return (
    <>
      {
        userType && userType.role.role === 'admin' ? <TopbarChildComponent /> : <div className='row top_bar_first'>
          <h2 className='col-11'>
            {currentUrl && currentUrl === "/dashboard" ? (
              <InputComponent for={"top_bar"} type={"text"} placeholder={"MaHTAS Intelligent Systematic Review System"} disabled />
            ) : (
              <select className='form-select' value={studyId} onChange={(event) => studyCheck(event)}>
                {reviewList &&
                  reviewList.sort((a, b) => (a.id < b.id ? 1 : -1)) &&
                  reviewList.map(({ title, id }, index) => {
                    return (
                      <option value={id} {...(index === studyId ? "selected" : "unSelected")}>
                        {title}
                      </option>
                    );
                  })}
              </select>
            )}
          </h2>
          {
           isTeamlead && <div className='col-1 icon'>
              <Link to='/review' style={{ color: "black" }}>
                <TfiMenuAlt className='iconfirst' />
              </Link>
            </div>
          }

        </div>
      }
      {/* <div className='row top_bar_first'>
        <h2 className='col-11'>
          {currentUrl && currentUrl === "/dashboard" ? (
            <InputComponent for={"top_bar"} type={"text"} placeholder={"MaHTAS Intelligent Systematic Review System"} disabled />
          ) : (
            <select class='form-select' value={studyId} onChange={(event) => studyCheck(event)}>
              {reviewList &&
                reviewList.sort((a, b) => (a.id < b.id ? 1 : -1)) &&
                reviewList.map(({ title, id }, index) => {
                  return (
                    <option value={id} {...(index === studyId ? "selected" : "unSelected")}>
                      {title}
                    </option>
                  );
                })}
            </select>
          )}
        </h2>
        <div className='col-1 icon'>
          <Link to='/review' style={{ color: "black" }}>
            <TfiMenuAlt className='iconfirst' />
          </Link>
        </div>
      </div> */}

      <div className='top-bar'>
        <div className='topbar-link'>
          {/* <Link to="/dashboard" className='li' >{''}<BsGrid1X2Fill className='linkicon' />Dashboard</Link>
          <Link to="/searchinginclusion" className='li'><FaSearchengin className='linkicon' />Searching</Link>
          <Link to="/screeningduplicate" className='li'><FaArrowsAlt className='linkicon' />Screening</Link>
          <Link to="/extraction" className='li'><FaFileExport className='linkicon' />Extraction</Link> */}

          {topBarMenu.map((topLink, index, link) => {
            return (
              <Link onClick={handleClick} key={index} id={topLink.id} to={"/" + topLink.link} className={topLink.id == currentPage ? "li active" : "li"}>
                {" "}
                <img src={API_SRC + topLink.icon} alt='' /> {topLink.title}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default TopBar;
