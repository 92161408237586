import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const LineChart = ({ numberOfReviewedRecords, numberOfRelevantRecords }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "line",
    },
    title: {
      text: null,
    },
    subtitle: {
      text: null,
    },
    tooltip: {
      pointFormat: "{series.name}</b>",
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      tickmarkPlacement: "on",
      categories: ["1", "2", "3", "4"],
    },
    yAxis: {
      title: {
        text: "Number of Relevant Records",
      },
    },
    exporting: {
      enabled: true,
    },
    accessibility: {
      point: {},
    },

    plotOptions: {
      line: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          connectorWidth: 0,
        },
        showInLegend: true,
      },
      series: {
        pointPlacement: "on",
        marker: {
          enabled: false,
        },
      },
    },

    series: [
      {
        data: [1, 2, 2, 3],
        name: "Number of Reviewed Records",
        color: "#ffbf00",
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default LineChart;
