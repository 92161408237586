import React from 'react'
import Aside from '../aside'
import RisImportComponent from '../risImportComponent'
import TopBar from '../topBar'

const RisImport = () => {
  return (
    <div className='main-wrapper'>
    <Aside />
    <div className="content-wrapper">
      <TopBar />
      <RisImportComponent/>
    </div>
  </div>
  )
}

export default RisImport