import React from "react";
import RisImport from "../../Components/screeningComponent/RisImport";
import "./../../assets/sass/style.css";

const RisImportPage = () => {
  return (
    <div>
      <RisImport />
    </div>
  );
};

export default RisImportPage;
