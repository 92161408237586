import React from 'react'
import AdminReviewTypeListingComponent from '../../Components/adminReviewTypeListingComponent'
import Aside from '../../Components/aside'
import TopBar from '../../Components/topBar'


const ReviewTypeListingPage = () => {
    return (

        <div className="main-wrapper">
            <Aside />
            <div className="content-wrapper">
                <TopBar />
                <AdminReviewTypeListingComponent />
            </div>
        </div>
    )
}

export default ReviewTypeListingPage