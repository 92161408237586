import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
//import "./../../assets/sass/_dashboard.scss"

function PieChart() {
   const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'Another Chart'
        },
        tooltip: {
            pointFormat: '{series.name}</b>'
        },
        accessibility: {
            point: {
               
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>'
                }
            }
        },
        series: [{
            name: 'Brands',
            colorByPoint: true,
             innerSize: '20%',
            data: [{
                name: 'In Progress',
                y: 27,
                  color: "#023047"
            }, {
                name: 'Conflicts',
                y: 33,
                  color: "#D3E3EB"
            },  {
                name: 'Resolved Conflicts',
                y: 47,
                color: "#219EBC"
            }, {
                name: 'Total Screened',
                y: 107,
                   color: "#8ECAE6"
            }]
        }]
    }
  return (
    <div style={{border: '1px solid lightgray', marginTop: '50px', borderRadius: '-36px', overflow: 'hidden', marginLeft:'10px', maxWidth:'100%'}}>
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  </div>
  )
}

export default PieChart
