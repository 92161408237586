import React from "react";
import EvidenceTable from "../../Components/evidencetable";
import ExtractionTable from "../../Components/extractiontable";

function ExtractiontablePage() {
  return (
    <div>
      {/* <ExtractionTable /> */}
      <EvidenceTable />
    </div>
  );
}

export default ExtractiontablePage;
