import React, { useState, useEffect } from 'react'
import { API_Link, MD5hash } from '../commons/Constants'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getPermission } from "../features/setting/permissionSlice";



const SearchAbstractsComponent = () => {

    const navigate = useNavigate();
    const [searchResults, setSearchResults] = useState([])
    const [isActive, setIsActive] = useState(null);
    const [studyId, setStudyId] = useState('')
    const [arrayToPost, setArrayToPost] = useState([])
    const [countPost, SetCountPost] = useState([])
    const user = JSON.parse(localStorage.getItem('user'))
    const token = user.token


    // call for get reviewer permissions
    const [getPermission, setGetPermission] = useState([]);

    // code for capitalize
    const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
    function shouldBeUppercase(word, index, length) {
        //always capitalize the first and last words
        if (index == 0 || index == length - 1)
            return true;

        //only capitalize other words if they don't match the small words pattern
        return !smallWords.test(word.toLowerCase());
    }

    function convertFirstLetterToUpperCase(str) {
        //split into words 
        const words = str.split(' ');

        //change the case and rejoin into a string
        return words
            .map((word, index, length) => shouldBeUppercase(word, index, length) ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase())
            .join(' ');
    }

    const getPermissionCall = () => {
        var config = {
            method: "get",
            url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem("studyId")}`,
            headers: {
                "misr-auth": MD5hash,
                Authorization: `Bearer ${token}`,
            },
        };
        axios(config)
            .then(function (response) {

                setGetPermission(response.data.permissions[0])

                console.log('after api call in search inclusion', getPermission)
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    useEffect(() => {

        getPermissionCall();
        setStudyId(localStorage.getItem('studyId'))

        var config = {
            method: 'get',
            url: API_Link + 'getReviewArticles?review_id=' + localStorage.getItem('studyId'), headers: {
                'Content-Type': 'application/json',
                "Authorization": 'Bearer ' + token,
                'misr-auth': MD5hash
            }
        };

        axios(config)
            .then(function (response) {
                const resData = response;
                // document.querySelector(".preload").style.display = "none"//stop the load
                setSearchResults(resData.data.articles);

                const { status, ...updatedObject } = resData.data;
                // console.log('SetSearch Results', updatedObject)

                setArrayToPost(updatedObject)
                SetCountPost(updatedObject.articles.length)
                console.log('count', updatedObject.articles.length)
            })
            .catch(function (error) {
                console.log(error);
            });

        localStorage.setItem('pageId', '1101')
        localStorage.setItem('subPageId', '1114')
    }, [])

    const handleClick = (index) => {
        setIsActive(!isActive);
        if (isActive === index) {
            return setIsActive(null);
        }
        setIsActive(index);
    };


    const findDuplicate = async (event) => {


        // var formData = new FormData();

        // formData.append("review_id", 2);
        // formData.append("file", selectedFile);
        // console.log(...formData);
        // getReviewArticles

        navigate("/screeningduplicate")
        // try {
        //     const response = await axios({
        //         method: "post",
        //         url: API_Link + 'getReviewArticles?review_id=' + localStorage.getItem('studyId') ,
        //         data: arrayToPost,
        //         headers: {
        //             Accept: "application/json",
        //         },
        //     });
        //     console.log('Duplicate APIs response', response)
        //     console.log(JSON.stringify(response.data))
        //     if (localStorage.setItem('localAPIs', JSON.stringify(response.data))){

        //         navigate("/screeningduplicate")

        //     }
        //     localStorage.setItem('localAPIs', JSON.stringify(response.data))
        //     navigate("/screeningduplicate")
        // } catch (error) {
        //     console.log(error);
        // }
    };
    const [permission1] = useSelector((state) => state.getPermission.data)

    return (<>
        <div className='page--content'>
            <div className='row'>
                <div className='col-lg-8'>
                    <h1 className='page--heading'>
                        Total Results
                    </h1>
                    <p>Total Articles <strong>{countPost && countPost}</strong></p>
                </div>
                <div className='col-lg-4 text-right text--align--right '>
                    {/* <Link to="/screeningduplicate" className="tem--add--btn" onClick={() => findDuplicate()}>Find Duplicates</Link> */}


                    {
                        getPermission && getPermission.includes(1) ? <button className="tem--add--btn" onClick={() => findDuplicate()}>Find Duplicates</button> : ''
                    }

                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    {
                        searchResults && searchResults.length > 0 && searchResults.map(({ title, authors, abstract, journal }, index) => {
                            return (
                                <div className='inner--box--row' key={index}>
                                    <span className='index--numbering'>{index + 1}</span>
                                    <div className='width-100'>
                                        <h1 className='heading3'>{convertFirstLetterToUpperCase(title)}</h1>
                                        <p className='small text-muted' onClick={() => handleClick()}>{authors}</p>
                                        {
                                            journal ? <label className='form-check-label'>  Journal {journal}   </label> : ''
                                        }
                                        <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{abstract}</p>
                                    </div>

                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    </>

    )
}

export default SearchAbstractsComponent