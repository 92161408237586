import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios';
import { MD5hash, API_Link } from './../../commons/Constants';
import { ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import Aside from './../aside';
import TopBar from './../topBar';
import { useNavigate } from 'react-router-dom';

const ScreeningDuplicate = () => {
  function updateArray(arr, num) {
    // Check if the number exists in the array
    const index = arr.indexOf(num);
    if (index > -1) {
      // If the number exists, remove it from the array
      arr.splice(index, 1);
    } else {
      // If the number doesn't exist, add it to the array
      arr.push(num);
    }

    return arr;
  }

  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(null);
  const [studyActive, setStudyActive] = useState(null);
  const [duplicateActive, setDuplicateActive] = useState(null);
  const [isChecked, setIsChecked] = useState(null);
  const [apiResponse, setAPIResponse] = useState([]);
  const [excludedCount, setExcludedCount] = useState(0);
  const [excludedArticlesId, setExcludedArticlesId] = useState([]);
  const [countDuplicate, setCountDuplicate] = useState(0);
  const [getScreeningDuplicate, setGetScreeningDuplicate] = useState([]);
  const [excludeOnPecentage, setExcludeOnPercentage] = useState([]);

  //handle increase Decrease Height
  const handleClick = (index) => {
    setIsActive(!isActive);
    if (isActive === index) {
      return setIsActive(null);
    }
    setIsActive(index);
  };

  const handleStudyActive = (index) => {
    setStudyActive(!studyActive);
    setDuplicateActive(!duplicateActive);

    if (isChecked === true && studyActive === index) {
      return setStudyActive(null);
    }

    setStudyActive(index);
    setIsChecked(index);

    // setExcludedArticlesId([...excludedArticlesId, index])

    // const updatedArray = updateArray(excludedArticlesId, index);
    const withoutDuplicates = [...new Set(excludedArticlesId)];

    const updatedArray = updateArray(withoutDuplicates, index);

    // setExcludedArticlesId(withoutDuplicates)

    setExcludedArticlesId(updatedArray);

    console.warn('withoutDuplicate', withoutDuplicates);
    console.warn('updateArray', updatedArray);
  };

  const handleDuplicateActive = (index, id) => {
    console.log('duplicate 12', index, 'id', id);
    setStudyActive(!studyActive);
    setDuplicateActive(!duplicateActive);
    if (isChecked === true && duplicateActive === index) {
      setDuplicateActive(null);
    }

    // if (excludeOnPecentage.includes(id)) {
    //   setExcludeOnPercentage(excludeOnPecentage.filter((ids) => ids !== id));
    // }
    setIsChecked(index);
    setDuplicateActive(index);

    const withoutDuplicates = [...new Set(excludedArticlesId)];
    const updatedArray = updateArray(withoutDuplicates, index);
    console.log('without duplicates', withoutDuplicates);
    console.log('updated array', updateArray);
    setExcludedArticlesId(updatedArray);

    console.log(excludedArticlesId);
    // setExcludedArticlesId([...excludedArticlesId, index])
  };

  const handleChecked = (index) => {
    setIsChecked(!isChecked);
    if (
      isChecked === index &&
      studyActive === index &&
      duplicateActive === index
    ) {
      return (
        setStudyActive(null) && setDuplicateActive(null) && setIsChecked(null)
      );
    }
    setIsChecked(index);
    setStudyActive(index);
    setDuplicateActive(index);
  };

  // code for capitalize
  const smallWords =
    /^(a|an|and|as|at|but|by|en|for|if|in|of|is|it|on|or|The|to|vs?\.?|via)$/i;
  function shouldBeUppercase(word, index, length) {
    //always capitalize the first and last words
    if (index == 0 || index == length - 1) return true;

    //only capitalize other words if they don't match the small words pattern
    return !smallWords.test(word.toLowerCase());
  }

  function convertFirstLetterToUpperCase(str) {
    //split into words
    const words = str.split(' ');

    //change the case and rejoin into a string
    return words
      .map((word, index, length) =>
        shouldBeUppercase(word, index, length)
          ? word.charAt(0).toUpperCase() + word.slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }
  const user = JSON.parse(localStorage.getItem('user'));
  const studyLocalId = JSON.parse(localStorage.getItem('studyId'));
  const token = user.token;
  // const getDuplicateIds = getScreeningDuplicate.filter((article) => (article.id = article.duplicates.filter((article2) => article2.article)));
  const excludeArticles = () => {
    const withoutDuplicates = [...new Set(excludedArticlesId)];
    console.warn(withoutDuplicates);
    var config = {
      method: 'post',
      // url: `${API_Link}excludeArticles`,
      url: `${API_Link}excludeDuplicates`,
      params: {
        review_id: studyLocalId,
        stage: '1',
        exclusion_purpose: '1',
        articles: withoutDuplicates,
      },

      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        console.warn(resData);
        if (resData.status === 200) {
          navigate('/includedduplicates');
        }
      })
      .catch(function (error) {
        // console.log(error);
        alert(error);
      });
  };

  useEffect(() => {
    if (user.role.role !== 'teamlead') {
      navigate('/screeningAbstractTotal');
    }
    var config = {
      method: 'get',
      url: `${API_Link}getReviewArticles`,

      params: {
        duplicates: 'Yes',
        review_id: studyLocalId || 1,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };

    axios(config)
      .then(function (response) {
        const resData = response;
        setGetScreeningDuplicate(resData.data.articles);
        setExcludedCount(resData.data.excluded.length);
        setCountDuplicate(resData.data.articles.length);
        setExcludedArticlesId(resData.data.excluded);

        const duplicateArticleIds = resData.data.articles
          .map((article) =>
            article.duplicates
              .filter((duplicateArticle) => duplicateArticle.percentage === '1')
              .map((article3) => article3.duplicate_with)
          )
          .flat();
        if (duplicateArticleIds.length > 0) {
          setExcludedArticlesId(...excludedArticlesId, duplicateArticleIds);
        }

        console.log('excludedeeeee', excludedArticlesId.length);
        //   const duplicateArticleIds = resData.data.articles.map((article) => article.duplicates.filter((duplicateArticle) => duplicateArticle.percentage === "1").map((article3) => article3.id)).flat();

        //   if (duplicateArticleIds.length > 0) {
        //     setExcludedArticlesId(...excludedArticlesId, duplicateArticleIds);
        //   }
        console.log('duplicate7777', excludedArticlesId);
      })
      .catch(function (error) {
        console.log(error);
      });
    localStorage.setItem('pageId', '1201');
    localStorage.setItem('subPageId', '1211');

    console.log('excluded id array', excludedArticlesId);
    setAPIResponse(localStorage.getItem('localAPIs'));
    console.log('apiResponse', localStorage.getItem('localAPIs'));
  }, []);
  // console.log("get Screening duplicate 11", getScreeningDuplicate.map((article) => article.duplicates.filter((article2) => article2.percentage === "1").map((article3) => article3.id)).flat());

  // const hasMatch = getScreeningDuplicate.some((obj) => excludedArticlesId.includes(obj.id));
  // const hasMatchCount = hasMatch.length;
  console.log('ppercentage', excludeOnPecentage);
  // console.log("has Match", hasMatch);
  console.log('excluded123', excludedArticlesId);
  const uniquearticleIds = [...new Set(excludedArticlesId)];
  return (
    <div className='main-wrapper'>
      <Aside />
      <div className='content-wrapper'>
        <TopBar />
        <div className='page--content duplicate--percentage--page'>
          <div className='row'>
            <div className='col-lg-6'>
              <h1 className='page--heading'>Duplicates</h1>
            </div>
            <div className='col-lg-6 text-end'>
              <button
                className='tem--add--btn'
                onClick={() => excludeArticles()}
              >
                Exclude articles ({uniquearticleIds.length || 0})
              </button>

              <a
                href='screeningAbstractTotal'
                className='tem--add--btn link-new ms-2'
              >
                Move to Abstract Screening
              </a>
            </div>
          </div>

          {getScreeningDuplicate && getScreeningDuplicate.length > 0 ? (
            getScreeningDuplicate.map(
              (
                {
                  id,
                  abstract,
                  journal,
                  authors,
                  doi,
                  duplicates,
                  source,
                  title,
                  pub_date,
                },
                index
              ) => {
                return (
                  <div className='duplicate--div'>
                    <div className='row'>
                      <div className='col'>
                        <div
                          id={id}
                          style={{
                            backgroundColor:
                              excludedArticlesId.includes(id) === true
                                ? 'rgba(255,0,0,0.2)'
                                : 'rgba(0,0,0,0)',
                          }}
                          className={
                            studyActive === id && isChecked === true
                              ? 'inner--box--row--active'
                              : 'inner--box--row'
                          }
                          onClick={() => handleStudyActive(id)}
                        >
                          {/* <input type='radio' isChecked={false} name={index} onChange={() => handleChecked(index)} /> */}

                          <div className='w-100'>
                            <div className='progressBar'></div>
                            <div className='form-check'>
                              Authors : {authors}{' '}
                            </div>
                            <h1 className='heading3'>
                              {convertFirstLetterToUpperCase(title)}
                            </h1>
                            <p
                              className={
                                isActive === index
                                  ? 'Increase-height--of--paragraph'
                                  : 'Reduce-height--of--paragraph'
                              }
                            >
                              {abstract}
                            </p>
                            <p className='small text-muted'>{journal}</p>
                            <p>{pub_date}</p>
                          </div>
                        </div>
                      </div>
                      {duplicates && duplicates.length > 0
                        ? duplicates.map((duplicate, key) => {
                            return (
                              <>
                                <div className='col'>
                                  <div
                                    className={
                                      duplicateActive !==
                                        duplicate.duplicate_with ||
                                      duplicateActive !== duplicate.id
                                        ? 'inner--box--row'
                                        : 'inner--box--row--active'
                                    }
                                    id={duplicate.duplicate_with}
                                    style={{
                                      backgroundColor:
                                        excludedArticlesId.includes(
                                          duplicate.duplicate_with
                                        ) === true
                                          ? 'rgba(255,0,0,0.2)'
                                          : 'rgba(0,0,0,0)',
                                    }}
                                    onClick={() =>
                                      handleDuplicateActive(
                                        duplicate.duplicate_with,
                                        duplicate.id
                                      )
                                    }
                                  >
                                    {/* <label>
                                  <input type='radio' value={duplicate.id} name={index} /> */}
                                    <div className='w-100'>
                                      <div className='progressBar'>
                                        <ProgressBar
                                          now={duplicate.percentage * 100}
                                          label={`${(
                                            duplicate.percentage * 100
                                          ).toFixed(2)} %`}
                                        />
                                      </div>
                                      <div className='form-check'>
                                        Authors : {duplicate.article.authors}
                                      </div>
                                      <h1 className='heading3'>
                                        {convertFirstLetterToUpperCase(
                                          duplicate.article.title
                                        )}{' '}
                                      </h1>
                                      <p
                                        className={
                                          isActive === index
                                            ? 'Increase-height--of--paragraph'
                                            : 'Reduce-height--of--paragraph'
                                        }
                                      >
                                        {duplicate.article.abstract}
                                      </p>
                                      <p className='small text-muted'>
                                        {duplicate.article.journal}
                                      </p>
                                      <p>{duplicate.article.pub_date}</p>
                                    </div>
                                    {/* </label> */}
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : ''}
                    </div>
                    <div className='row'>
                      <div className='col-lg-12 text-center'>
                        {isActive === index ? (
                          <Link
                            to='#'
                            data-bs-toggle='tooltip'
                            className='link read--more'
                            title='View'
                            onClick={() => handleClick(index)}
                          >
                            Read Less...
                          </Link>
                        ) : (
                          <Link
                            to='#'
                            data-bs-toggle='tooltip'
                            className='link read--more'
                            title='View'
                            onClick={() => handleClick(index)}
                          >
                            Read More...
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            )
          ) : (
            <p className='p-3 text-muted'>There are no duplicate articles.</p>
          )}

          {/* {getScreeningDuplicate
        .filter((getScreeningDuplicate) => getScreeningDuplicate.duplicates.length > 0)
        .map(({ id, abstract, journal, authors, doi, duplicates, source, title, pub_date }, index) => {
          return (
            <div className='duplicate--div'>
              <div className='row'>
                <div className='col'>
                  <div className={studyActive === id && isChecked === true ? "inner--box--row--active" : "inner--box--row"} onClick={() => handleStudyActive(id)}>
                    <label>
                      <input type='radio' isChecked={false} name={index} onChange={() => handleChecked(index)} />

                      <div className='w-100'>
                        <div className='progressBar'></div>
                        <div className='form-check'>
                          Authors : {authors}
                        </div>
                        <h1 className='heading3'>{title}</h1>
                        <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{abstract}</p>
                        <p className='small text-muted'>{journal}</p>
                        <p>{pub_date}</p>
                      </div>
                    </label>
                  </div>
                </div>
                {duplicates && duplicates.length > 0
                  ? duplicates.map((duplicate, key) => {
                    return (
                      <>
                        <div className='col'>
                          <div className={duplicateActive !== duplicate.id ? "inner--box--row" : "inner--box--row--active"} onClick={() => handleDuplicateActive(duplicate.id)}>
                            <label>
                              <input type='radio' value={duplicate.id} name={index} />

                              <div className='w-100'>
                                <div className='progressBar'>
                                  <ProgressBar now={duplicate.percentage} label={`${duplicate.percentage.slice(0, 5)}%`} />
                                </div>
                                <div className='form-check'>Authors : {duplicate.article.authors}</div>
                                <h1 className='heading3'>{duplicate.article.title}</h1>
                                <p className={isActive === index ? "Increase-height--of--paragraph" : "Reduce-height--of--paragraph"}>{duplicate.article.abstract}</p>
                                <p className='small text-muted'>{duplicate.article.journal}</p>
                                <p>{duplicate.article.pub_date}</p>
                              </div>
                            </label>
                          </div>
                        </div>
                      </>
                    );
                  })
                  : ""}
              </div>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <Link to='#' data-bs-toggle='tooltip' className='link read--more' title='View' onClick={() => handleClick(index)}>
                   Read More...
                  </Link>
                </div>
              </div>
            </div>
          );
        })
        } */}
        </div>
      </div>
    </div>
  );
};
export default ScreeningDuplicate;
