import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
const ReviewPie = ({ totalRecords, relevantRecords, LabeledRecords, relevantData, labeledData }) => {
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    subtitle: {
      text: `Total Records <div style='font-size: 40px'>${totalRecords}</div> `,
      align: "center",
      verticalAlign: "middle",
      style: {
        textAlign: "center",
      },
      x: 0,
      y: -2,
      useHTML: true,
    },
    tooltip: {
      // backgroundColor: "#003D5B",
      style: {
        color: "#000",
        fontWeight: "bold",
      },
      formatter: function () {
        if (this.y == 90) {
          return false;
        } else if (this.y == relevantData) {
          return ["<b>" + "Relevant Records: " + relevantData + "</b>"];
        } else {
          return ["<b>" + "Labeled Records: " + " = " + this.y + "</b>"];
        }
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    accessibility: {
      point: {},
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          connectorWidth: 0,
        },
        showInLegend: true,
      },
    },

    series: [
      {
        type: "pie",
        enableMouseTracking: true,
        innerSize: "90%",
        size: 230,
        dataLabels: {
          enabled: false,
        },
        data: [
          {
            name: "Labeled",
            y: LabeledRecords,
            color: "#01A4DD",
          },
          {
            y: 90,
            color: "#e3e3e3",
          },
        ],
      },
      {
        type: "pie",
        enableMouseTracking: true,
        innerSize: "90%",
        size: 190,
        dataLabels: {
          enabled: false,
        },
        data: [
          {
            name: "Relevant",
            y: relevantData,
            color: "#ffbf00",
          },
          {
            y: 90,
            color: "#e3e3e3",
          },
        ],
      },
    ],
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ReviewPie;
