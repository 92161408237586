import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_Link, MD5hash } from "../../commons/Constants";




export const getPermission = createAsyncThunk(

'getPermission',
    async () => {

        const user = JSON.parse(localStorage.getItem("user"));
        const token = user.token;
        
        var config = {
            method: "get",
            url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem("studyId")}`,
            headers: {
                "misr-auth": MD5hash,
                Authorization: `Bearer ${token}`,
            },
        };
        const response = await axios(config)
            .then(function (response) {

                return response.data.permissions
                // console.log('permissionSlice', response.data)
            })
            .catch(function (error) {
                console.log(error);
            });

        return response
    }

)

const createInitialState = () => {
    return {
        data: []
    }
}
const initialState = createInitialState()
const permissionSlice = createSlice({
    name: 'permissionSlice',
    initialState,
    reducers: {},
    extraReducers: {
        [getPermission.fulfilled](state, action) {
            state.data = action.payload
        },
    },
})

export default permissionSlice.reducer