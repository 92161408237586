import React from 'react'
import "./../assets/sass/style.css"
// import "./../assets/sass/_extract.scss"
// import BGV1 from "./../assets/img/loginBGV1.png"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from 'react-bootstrap/Button';
// import { Card, CardGroup } from 'react-bootstrap';
 import { BiSave } from "react-icons/bi";
// import { AiFillDelete } from "react-icons/ai";
// import ListGroup from 'react-bootstrap/ListGroup';
import Aside from './aside'
import TopBar from './topBar'
// import { Link } from "react-router-dom"

function Role() {
	return (
		<div className='main-wrapper'>

			<Aside
			/>
			<div className="content-wrapper">
				<TopBar />
				<div className="page--content ">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <h1 className="page--heading ">
                            Add Role
                        </h1>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <div className="row Role--setting--page--main--row ">
                        <div className="col-sm-12 col-md-12 col-lg-6 ">
                            <form className="general--setting--page">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Role Title</label>
                                    <input type="Text" className="form-control" placeholder="Manager"></input>
                               
                                </div>
                                <div className="mb-3">
                                    <label for="exampleInputPassword1" className="form-label">Role landing page</label>
                                    <input type="text" className="form-control" placeholder="/opddashboard"></input>
                                </div>
                            </form>

                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <form className="general--setting--page">
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Role Description</label>
                                    <input type="text" className="form-control" placeholder="Manager"></input>
                                   
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-6 d-flex justify-content-end">
                                <a href="#">
                                    <span><button type="submit" className="btn btn-primary-Admin">ADMIN</button></span>
                                </a>
                            </div>
                            <div className="col-6">
                                <a href="#">
                                    <span><button type="submit" className="btn btn-primary-mobile">MOBILE</button></span>
                                </a>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-sm-10 col-lg-11 d-flex justify-content-end">
                                <span className="save-icon-seetting-page">
                                    <BiSave/>
                                </span>
                                <span><a href="#">
                                        <button type="submit" className="btn btn-primary-save">SAVE</button>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			</div>
		</div>





	)
}

export default Role
