import React from "react";
import Conflict from "./../../Components/screeningComponent/conflict";
import Aside from "./../../Components/aside";
import TopBar from "./../../Components/topBar";
import { useSelector } from "react-redux";
function StartedPage() {
  const invitation = useSelector((state) => state.invitation.value);

  return (
    <div className='main-wrapper'>
      <Aside />

      <div className='content-wrapper'>
        <TopBar />
        <Conflict />
      </div>
    </div>
  );
}

export default StartedPage;
