import React, { useEffect, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import InputComponent from './InputComponent';
import DropDownComponent from './DropDownComponent';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './../assets/sass/style.css';
import './../assets/sass/style.scss';
import gif from './../assets/img/spin.gif';
import { API_Link, MD5hash } from '../commons/Constants';

import { useSelector, useDispatch } from 'react-redux';
import { getPermission } from '../features/setting/permissionSlice';

function SearchingInclusion(props) {
  const [getPermission, setGetPermission] = useState([]);

  const navigate = useNavigate();
  const [optionSelected, setOptionSelected] = useState('');
  const [population, setPopulation] = useState('');
  const [intervention, setIntervention] = useState('');
  const [comparison, setComparison] = useState('');
  const [outcomes, setOutcomes] = useState('');
  const [exclude, setExclude] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [picoSearchs, setPicoSearches] = useState([]);
  //

  const user = JSON.parse(localStorage.getItem('user'));
  const token = user.token;

  const getPermissionCall = () => {
    setIsLoading(true);
    var config = {
      method: 'get',
      url: `${API_Link}getAllowedStages?review_id=${localStorage.getItem(
        'studyId'
      )}`,
      headers: {
        'misr-auth': MD5hash,
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setGetPermission(response.data.permissions[0]);

        console.log('after api call in search inclusion', getPermission);
      })
      .catch(function (error) {
        console.log(error);
      });

    setIsLoading(false);
  };

  const getPicoSearches = () => {
    let review_id = localStorage.getItem('studyId');

    const user = JSON.parse(localStorage.getItem('user'));
    const token = user.token;

    var config = {
      method: 'get',
      url: `${API_Link}getPicoSearches?review_id=${review_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        'misr-auth': MD5hash,
      },
    };
    axios(config)
      .then(function (response) {
        setPicoSearches(
          response.data.picoSearches.filter(
            (item) => item.database !== 'RISimport'
          )
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPicoSearches();
    getPermissionCall();
  }, [optionSelected]);

  console.log('picoSearchs', picoSearchs);
  // "Database":"PubMed",
  // "Population": "The population of patients with eczema",
  // "Intervention": "The intervention of probiotics",
  // "Comparison": "In comparison to no treatment, placebo, or non-probiotic treatment",
  // "Outcomes": "The outcome of changes in participant-, parent-, and doctor-rated symptoms of eczema and quality of life",
  // "Exclusion": "animals"
  //
  let handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    var data = JSON.stringify({
      user_email: user.user.email,
      Database: optionSelected,
      Population: population,
      Intervention: intervention,
      Comparison: comparison,
      Outcomes: outcomes,
      Exclusion: exclude,
      start: 1,
    });

    var config = {
      method: 'post',
      url: 'http://misr.pacemis.com:78/search',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.articles.length === 0) {
          navigate('/searchingInclusion');
        }
        console.log('resData111', response.data.articles.length);
        if (response.data.articles.length < 1000) {
          const resData = JSON.stringify(response.data.articles);
          const query = JSON.stringify(response.data.info);
          console.log('resData', resData);
          localStorage.setItem('search', '');
          localStorage.setItem('PICO', 1);
          localStorage.setItem('picoData', data);
          const localresData = localStorage.setItem('search', resData);
          const info = localStorage.setItem('info', query);
          // console.log("resposee", localresData.length);
          if (localresData !== null) {
            navigate('/listingselections');
          }
        }
        if (response.data.articles.length >= 1000) {
          // const resData = JSON.stringify(response.data);
          const resData = JSON.stringify(response.data.articles);
          const query = JSON.stringify(response.data.info);
          console.log('resData', resData);
          localStorage.setItem('search', '');
          localStorage.setItem('PICO', 1);
          localStorage.setItem('picoData', data);
          const localresData = localStorage.setItem('search', resData);
          const info = localStorage.setItem('info', query);
          // console.log("resposee", localresData.length);
          if (localresData !== null) {
            navigate('/listingselections');
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      })
      .finally(() => setIsLoading(false));
  };

  function handleChange(type, value) {
    switch (type) {
      case 'databaseid':
        setOptionSelected(value);
        break;

      case 'intervention':
        setIntervention(value.target.value);
        break;

      case 'comparison':
        setComparison(value.target.value);
        break;

      case 'outcomes':
        setOutcomes(value.target.value);
        break;

      case 'population':
        setPopulation(value.target.value);
        break;

      case 'exclude':
        setExclude(value.target.value);
        break;

      default:
        break;
    }
  }

  const getPicoSearch = (search) => {
    setOptionSelected(search.database);
    setIntervention(search.intervention === null ? '' : search.intervention);
    setComparison(search.comparison === null ? '' : search.comparison);
    setPopulation(search.population === null ? '' : search.population);
    setOutcomes(search.outcome === null ? '' : search.outcome);
    setExclude(search.exclusion === null ? '' : search.exclusion);
  };

  const database_options = [
    {
      value: '',
      label: 'Please select database type',
    },
    {
      value: 'pubMeD',
      label: 'PubMeD',
    },
    {
      value: 'OVID',
      label: 'OVID',
    },
  ];

  if (isLoading)
    return (
      <div className='preload'>
        <img src={gif} alt='' />
      </div>
    );
  return (
    <div className='page--content'>
      <h1 className='page--heading'>Inclusion Criterion</h1>

      <div className='white--bg'>
        <form onSubmit={handleSubmit}>
          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Database
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10 custom--form--control'>
              <DropDownComponent
                selected={optionSelected}
                id={'databaseid'}
                options={database_options}
                icon={'fas fa-chevron-down'}
                handleChange={handleChange}
              />
            </div>
          </div>

          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Population
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10'>
              <div className=''>
                <div className='chips input-field'>
                  <InputComponent
                    for={'population'}
                    type={'text'}
                    name='population'
                    placeholder={'Search a review'}
                    onChange={handleChange}
                    value={population}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Intervention
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10'>
              <div className=''>
                <div className='chips input-field'>
                  <InputComponent
                    for={'intervention'}
                    type={'text'}
                    name='intervention'
                    placeholder={'Search a review'}
                    onChange={handleChange}
                    is_optional={true}
                    value={intervention}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Comparison
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10'>
              <div className=''>
                <div className='chips input-field'>
                  <InputComponent
                    for={'comparison'}
                    type={'text'}
                    name='comparison'
                    placeholder={'Search a review'}
                    onChange={handleChange}
                    is_optional={true}
                    value={comparison}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Outcomes
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10'>
              <div className=''>
                <div className='chips input-field'>
                  <InputComponent
                    for={'outcomes'}
                    type={'text'}
                    name='outcomes'
                    placeholder={'Search a review'}
                    onChange={handleChange}
                    is_optional={true}
                    value={outcomes}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 row'>
            <label className='col-sm-12 col-md-3 col-lg-2 col-form-label'>
              Exclude
            </label>
            <div className='col-sm-12  col-md-9 col-lg-10'>
              <div className=''>
                <div className='chips input-field'>
                  <InputComponent
                    for={'exclude'}
                    type={'text'}
                    name='exclude'
                    placeholder={'Search a review'}
                    onChange={handleChange}
                    is_optional={true}
                    value={exclude}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='mb-3 row form--submit'>
            <div className='col-sm-12 text-end'>
              <button type='submit' className='btn--submit'>
                <BiSearch /> Search
              </button>
            </div>
          </div>
        </form>
      </div>

      {/*  */}
      <div className='table-div mt-5'>
        <h4>
          <strong>Pico Search History</strong>
        </h4>
        <table className='table table-bordered'>
          <thead>
            <tr>
              <th>Database</th>
              <th>Population</th>
              <th>Intervention</th>
              <th>Comparison</th>
              <th>Outcomes</th>
              <th>Included</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {picoSearchs.length > 0 ? (
              picoSearchs.map((search, index) => (
                <tr key={index}>
                  <td>{search.database}</td>
                  <td>{search.population}</td>
                  <td>{search.intervention}</td>
                  <td>{search.comparison}</td>
                  <td>{search.outcome}</td>
                  <td>{search.included}</td>
                  <td>{search.total_records}</td>
                  <td>
                    {getPermission && getPermission.includes(1) ? (
                      <button
                        id={search.id}
                        className='btn--submit'
                        onClick={() => getPicoSearch(search)}
                      >
                        {' '}
                        Use PICO
                      </button>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className='text-center text-muted'>
                  You have no pico search History
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/*  */}
    </div>
  );
}

export default SearchingInclusion;
